import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../base/useLoadingAction";

const useScheduleDBActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const [scheduleActionError, setScheduleActionError] = useState<Error | null>(
    null,
  );

  // 病院ID
  const hospitalId: string = currentUser
    ? (appContext.userObject?.hospital_id as string)
    : "";

  // 枠削除
  const deleteSchedules = (
    bookingType: "mri" | "pet" | "interview",
    timeInterval: number,
  ) => {
    void (async () => {
      try {
        openUpdateActionLoading();
        await appContext.functions(["booking/removeSlotRange", [
          hospitalId,
          bookingType,
          timeInterval,
        ]]);
      } catch (err) {
        setScheduleActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    scheduleActionError,
    deleteSchedules,
  };
};

export default useScheduleDBActions;
