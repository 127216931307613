import { useState, useEffect } from "react";

import { getAggregateOneHospital } from "../../utils/query";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection } from "../../constants/common";
import useForceUpdate from "./useForceUpdate";
import useId from "./useId";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";

// 特定IDの医療機関情報を取得
const useOneHospitalFetch = () => {
  const appContext = useFirebaseApp();
  const [id] = useId();

  const [fetchResult, setFetchResult] =
    useState<hospitalNli016StateType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 特定IDのhospitalを取得
        const conditions = { _id: id };
        const aggregate = getAggregateOneHospital(conditions);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, aggregate }])) as hospitalNli016StateType[];

        if (result.length < 1) redirectToNoDataPage();
        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [appContext, forceUpdate.forceUpdateCount, id]);

  return { fetchError, fetchResult };
};

export default useOneHospitalFetch;
