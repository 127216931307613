import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useUserEditInput from "../../input/useUserEditInput";
import useForceUpdate from "../../common/useForceUpdate";

const useNlc025DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [id] = useId();
  const [userInfo] = useUserEditInput();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 更新処理
  const handleUpdateUserInfo = (setIsOpen: (isOpen: boolean) => void) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新データ
        const updateData = {
          _id: id,
          name_sei: userInfo.nameSei.value,
          name_mei: userInfo.nameMei.value,
          name_sei_kana: userInfo.nameSeiKana.value,
          name_mei_kana: userInfo.nameMeiKana.value,
          gender: userInfo.gender,
          birth_year: Number(userInfo.birthYear.value),
          birth_month: Number(userInfo.birthMonth.value),
          birth_day: Number(userInfo.birthDay.value),
          tel: userInfo.tel.value,
          email: userInfo.email.value,
          zipcode: userInfo.zipcode.value,
          pref: userInfo.pref,
          city: userInfo.city.value,
          town1: userInfo.town1.value,
          town2: userInfo.town2,
        };

        // ユーザ情報変更API
        await appContext.functions(["updatePatientApi",updateData]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });

        setIsOpen(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateUserInfo,
  };
};

export default useNlc025DbActions;
