import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<{
  isSendConfirm: boolean;
  isCancelConfirm: boolean;
  isModifyBooking: boolean;
}>({
  isSendConfirm: false,
  isCancelConfirm: false,
  isModifyBooking: false,
});

const useNlc014Dialog = (): ReactiveVarHooks<{
  isSendConfirm: boolean;
  isCancelConfirm: boolean;
  isModifyBooking: boolean;
}> => useReactiveVarHooks(stateReactiveVar);

export const openNlc014SendDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isSendConfirm: true,
  });
};

export const closeNlc014SendDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isSendConfirm: false,
  });
};

export const openNlc014CancelDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isCancelConfirm: true,
  });
};

export const closeNlc014CancelDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isCancelConfirm: false,
  });
};

export const openNlc014ModifyDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isModifyBooking: true,
  });
};

export const closeNlc014ModifyDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isModifyBooking: false,
  });
};

export default useNlc014Dialog;
