import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection, ROLE_KEY } from "../../../constants/common";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

const useNli006DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // ユーザ一括更新
  const handleUpdateUser = (
    idArray: string[],
    role: string,
    status: string,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, appContext);

        // 更新データ
        const updateData = {
          admin_role: Number(role),
          status: Number(status),
        };

        await appContext.functions(["mongo/client", {
          collection: Collection.USERS, updateMany: {
            filter:
              { _id: { $in: idArray } },
            update: { $set: updateData },
          }
        }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateUser,
  };
};

export default useNli006DbActions;
