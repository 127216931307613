import React from "react";
import Tab from "./Tab";

export type TabsProps = {
  selectedTab: string | number;
  size?: "medium" | "large";
  tabs: {
    title: string;
    icon?: string;
    iconColor?: string;
    counter?: number;
    error?: boolean;
    disabled?: boolean;
  }[];
  tabClassName?: string;
  bottomBorder?: boolean;
  // changeEmp
  onClick?: (value: number) => void | (() => void);
};

const Tabs = ({
  selectedTab,
  tabs,
  size = "large",
  tabClassName = "",
  bottomBorder = false,
  // changeEmp
  onClick = () => {},
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(selectedTab);

  // changeEmp
  const selectEvent = (index: number) => {
    // 親で設定したクリックイベントにindexを渡す
    onClick(index);
    setSelectedIndex(index);
  };

  const tabClass = React.useMemo(() => {
    const borderClass = bottomBorder ? [`tabs--bordered`] : [`tabs`];

    return ["tabs", ...borderClass].join(" ");
  }, [bottomBorder]);

  return (
    <div className={tabClass}>
      {tabs.map((tab, index) => {
        const isSelected = index === selectedIndex;

        return (
          <Tab
            {...tab}
            index={index}
            isSelected={isSelected}
            // changeEmp
            selectTab={selectEvent}
            key={`tab-${index}`}
            tabClassName={tabClassName}
            size={size}
          >
            {tab.title}
          </Tab>
        );
      })}
    </div>
  );
};

export default Tabs;
