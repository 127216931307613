import React from "react";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

export type NotificationProps = {
  open?: boolean;
  type?: "inline" | "snackbar";
  // changeEmp
  colorType?: "error" | "warning" | "information" | "success";
  text?: string;
  description?: string;
  detail?: string;
  optionText?: string;
  timestamp?: Date;
  showCloseButton?: boolean;
  displayTime?: number;
  clickOption?: () => void;
  close?: () => void;
};

const Notification = ({
  open = true,
  type = "inline",
  // changeEmp
  colorType = "error",
  text = "",
  description = "",
  detail = "",
  optionText = "",
  timestamp = undefined,
  showCloseButton = false,
  displayTime = undefined,
  clickOption = () => {},
  close = () => {},
}: NotificationProps) => {
  const [isDetailOpen, setIsDetailOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(open);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    if (
      type === "snackbar" &&
      // changeEmp
      (colorType === "information" || colorType === "success") &&
      displayTime
    )
      setInterval(() => {
        setIsOpen(false);
      }, displayTime);
  }, [displayTime, colorType, type]);

  const notificationClass = React.useMemo(() => {
    const typeClass = [`notification--${type}`];
    // changeEmp
    const roleClass = [`notification--${colorType}`];

    return ["notification", ...typeClass, ...roleClass].join(" ");
  }, [colorType, type]);

  const icon = React.useMemo(() => {
    // changEmp
    switch (colorType) {
      case "error":
        return "not_interested";
      case "warning":
        return "warning";
      case "information":
        return "error";
      case "success":
        return "check_circle";
      default:
        return "not_interested";
    }
  }, [colorType]);

  const timestampFormatted = React.useMemo(() => {
    if (!timestamp) return null;
    const year = timestamp.getFullYear();
    const month = timestamp.getMonth() + 1;
    const day = timestamp.getDate();
    const hour = `00${timestamp.getHours()}`.slice(-2);
    const minute = `00${timestamp.getMinutes()}`.slice(-2);

    return `${year}/${month}/${day} ${hour}:${minute}`;
  }, [timestamp]);

  const expandIcon = React.useMemo(
    () => (isDetailOpen ? "expand_less" : "expand_more"),
    [isDetailOpen],
  );

  const onClickClose = React.useCallback(() => {
    setIsOpen(false);
    if (close) close();
  }, [close]);

  const onClickDetail = React.useCallback(() => {
    setIsDetailOpen((prev) => !prev);
  }, []);

  const onclickOption = React.useCallback(() => {
    if (clickOption) clickOption();
  }, [clickOption]);

  if (!isOpen) return <></>;

  return (
    <div className={notificationClass}>
      <span className="notification__icon">
        <Icon icon={icon} size="xs" />
      </span>
      <div className="notification__textArea">
        <div className="notification__text">{text}</div>
        {description && (
          <div className="notification__description">{description}</div>
        )}
        {detail && (
          <div className="notification__detail">
            <Button
              className="notification__detail-button"
              icon={expandIcon}
              color="main"
              type="sub"
              size="small"
              onClick={onClickDetail}
            >
              詳細
            </Button>
            {isDetailOpen && (
              <div className="notification__detail-content">{detail}</div>
            )}
          </div>
        )}
        {timestampFormatted && (
          <div className="notification__timestamp">{timestampFormatted}</div>
        )}
      </div>
      <div className="notification__upper-right-buttons">
        {optionText && (
          <Button
            color="main"
            type="secondary"
            size="small"
            onClick={onclickOption}
          >
            {optionText}
          </Button>
        )}

        {showCloseButton && (
          <Button
            color="neutral"
            type="sub"
            size="small"
            icon="close"
            onClick={onClickClose}
          />
        )}
      </div>
    </div>
  );
};

export default Notification;
