import { useState, useEffect } from "react";
import { Collection } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNli003Interview } from "../../../utils/query";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useStateCustomObj from "../../base/useStateCustomObj";

// Pet受診可能な病院を都道府県で取得
const useNli003Fetch = (id: string) => {
  const appContext = useFirebaseApp();

  const [fetchInterviewResult, setFetchInterviewResult] = useStateCustomObj<
    Nli003InterviewStateType[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateNli003Interview(id);
        const fetchResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_INTERVIEWS, aggregate }])) as Nli003InterviewStateType[];

        setFetchInterviewResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (id) void fetchData();
  }, [appContext, id, setFetchInterviewResult]);

  return { error, fetchInterviewResult };
};

export default useNli003Fetch;
