import { PetStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  status: PetStatusID;
  selectPetId: string;
  updateDate: Date;
  updateTime: Date;
  interval: number;
};

// ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  status: PetStatusID.ALL,
  selectPetId: "",
  updateDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  updateTime: new Date(),
  interval: 0,
});
const useNlc017Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export const setSelectPetId = (selectPetId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectPetId,
  });
};

export const setUpdateDate = (updateDate: Date) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    updateDate,
  });
};

export const setUpdateTime = (updateTime: Date) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    updateTime,
  });
};

export const setInterval = (interval: number) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    interval,
  });
};

export default useNlc017Conditions;
