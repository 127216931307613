import { useState, useEffect, useMemo } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
  UserStatusID,
} from "../../../constants/common";
import { getAggregateNlc035 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nlc035FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLC035;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLC035;
};

// NLC035画面のデータ取得処理
const useNlc035Fetch = ({
  sortExamples,
  submittedSearchCriteria,
}: Nlc035FetchParams) => {
  const appContext = useFirebaseApp();

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: PatientNlc035StateType[];
    error: Error | null;
  }>({
    result: [],
    error: null,
  });

  // 検索条件
  const name = convertSearchName(submittedSearchCriteria.name);
  const { userId, email } = submittedSearchCriteria;
  const tel = submittedSearchCriteria.phoneNum;

  // ユーザタイプのチェック状態
  const patientTypeArr = useMemoStatusArr(
    SEARCH_INFO.NLC035_type,
    submittedSearchCriteria.patientType,
  );

  // ユーザステータスのチェック状態
  const initialUserStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLC035_status,
    submittedSearchCriteria.userStatus,
  );
  // 招待者のアクティブ状態を付与・除去
  const userStatusArr = useMemo(() => {
    let statusArr = initialUserStatusArr;
    if (
      statusArr.includes(UserStatusID.ACTIVE) &&
      !statusArr.includes(UserStatusID.INVITATION)
    ) {
      statusArr = [...statusArr, UserStatusID.INVITATION];
    } else if (
      !statusArr.includes(UserStatusID.ACTIVE) &&
      statusArr.includes(UserStatusID.INVITATION)
    ) {
      statusArr = statusArr.filter(
        (status) => status !== UserStatusID.INVITATION,
      );
    }

    return statusArr;
  }, [initialUserStatusArr]);

  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "user_id", sortDirection = "desc" } = currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNlc035(
          name,
          email,
          tel,
          patientTypeArr,
          userStatusArr,
          userId,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        // mongoDBのデータ
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as AggregateResultType<PatientNlc035StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, error: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({ result: [], error: checkFetchErr(err) });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [colName, email, patientTypeArr, userStatusArr, name, perPage, skip, sortDirection, tel, userId, appContext]);

  return errorAndResult;
};

export default useNlc035Fetch;
