import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc021NewDialog } from "./useNlc021NewDialog";
import { Collection } from "../../../constants/common";

const useNlc021UpdateStatus = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // ステータス更新処理
  const handleUpdateStatus = (id: string, status: number) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        await appContext.functions(["mongo/client", {
          collection: Collection.PATIENT_INTERVIEWS,
          updateOne: {
            filter: { _id: id },
            update: {
              $set: {
                status,
                updated_at: new Date(),
              },
            },
          }
        }]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc021NewDialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateStatus,
  };
};

export default useNlc021UpdateStatus;
