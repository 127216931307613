import React from "react";
import Label from "./Label";

export type FormSetProps = {
  label: string;
  toolTip?: { text: React.ReactNode; width: number; directionUnder?: boolean };
  required?: "badge" | "icon";
  vertical?: boolean;
  option?: {
    text: string;
    href: string;
  };
  base?: boolean;
  helperTextList?: string[];
  errorTextList?: string[];
  labelSize?: "small" | "medium" | "large";
  labelWidth?: string;
  withRadio?: boolean;
  withCheckbox?: boolean;
  checked?: boolean;
  children: React.ReactNode;
  selectedValue?: string;
  minWidth?: string;
  maxWidth?: string;
  flex?: string;
  alignLabel?: "start" | "center";
  formVertical?: boolean;
  fullWidth?: boolean;
};

const FormSet = ({
  label,
  toolTip,
  required,
  vertical = false,
  option,
  base = false,
  helperTextList = [],
  errorTextList = [],
  labelSize,
  labelWidth,
  withRadio = false,
  withCheckbox = false,
  checked = false,
  children,
  selectedValue = "",
  minWidth = "",
  maxWidth = "",
  flex = "",
  formVertical = true,
  alignLabel = "center",
  fullWidth = true,
}: FormSetProps) => {
  const [disabled, setDisabled] = React.useState(!checked);

  const formSetClass = (() => {
    const classValue = ["form-set"];
    const verticalClass = vertical ? ["form-set--vertical"] : [];
    const fullWidthClass = fullWidth ? ["util-full-width"] : [];

    return [...classValue, ...verticalClass, ...fullWidthClass].join(" ");
  })();

  React.useEffect(() => {
    if (selectedValue) {
      setDisabled(selectedValue !== label);
    }
  }, [label, selectedValue]);

  return (
    <div className={formSetClass} style={{ minWidth, flex, maxWidth }}>
      <Label
        required={required}
        toolTip={toolTip}
        option={option}
        base={base}
        size={labelSize ? labelSize : vertical ? "small" : "large"} // eslint-disable-line
        withRadio={withRadio}
        withCheckbox={withCheckbox}
        disabled={disabled}
        setDisabled={setDisabled}
        radioValue={label}
        width={vertical ? null : labelWidth ?? "120px"}
        alignLabel={alignLabel}
      >
        {label}
      </Label>
      <div
        className={`${
          formVertical
            ? "form-set__form form-set__form--vertical"
            : "form-set__form"
        }`}
      >
        {children}
        {helperTextList.map((helperText, index) => (
          <div key={`helper-text-${index}`} className="form-set__helper-text">
            {helperText}
          </div>
        ))}
        {errorTextList.map((errorText, index) => (
          <div
            key={`caption-massage-${index}`}
            className="form-set__caption-massage--error"
          >
            {errorText}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormSet;
