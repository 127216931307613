import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type GcsUrl = {
  gcs_path: string;
  signed_url: string;
};

const useSignedBrainCheckUrl = () => {
  const appContext = useFirebaseApp();

  const [signedBrainCheckError, setSignedBrainCheckError] =
    useState<Error | null>(null);

  const fetchSignedBrainCheckUrl = async (
    brainCheckId: string,
    status: number,
  ) => {
    addLoadCount();
    try {
      let signedUrl = "";
      if (status !== 0) {
        const response = (await appContext.functions([
          "braincheck/downloadSignUrl",
          brainCheckId,
        ])) as GcsUrl;
        signedUrl = response.signed_url;

        if (!signedUrl) {
          signedUrl = "";
        }
      }

      return signedUrl;
    } catch (err) {
      setSignedBrainCheckError(checkActionErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    fetchSignedBrainCheckUrl,
    signedBrainCheckError,
  };
};

export default useSignedBrainCheckUrl;
