/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import Text from "../Text/Text";
import Table from "./Table";
import TableRow from "./TableRow";
import TableColumn from "./TableColumn";
import LayoutBox from "../LayoutBox/LayoutBox";
import TimeTableRow, { TimeTableRowProps } from "./TimeTableRow";
import Checkbox from "../Checkbox/Checkbox";

type TimeFrame = 0 | 15 | 30 | 60 | 90 | 120;

export const DAYS = {
  monday: "月",
  tuesday: "火",
  wednesday: "水",
  thursday: "木",
  friday: "金",
  saturday: "土",
  sunday: "日",
} as const;
export type DaysType = keyof typeof DAYS;

export const number2Day: Record<number, string> = {
  0: DAYS.monday,
  1: DAYS.tuesday,
  2: DAYS.wednesday,
  3: DAYS.thursday,
  4: DAYS.friday,
  5: DAYS.saturday,
  6: DAYS.sunday,
};

interface TimeTableProps {
  timeFrame?: TimeFrame;
  direction?: "row" | "column";
  amStart: string;
  amEnd: string;
  pmStart: string;
  pmEnd: string;
  checkbox?: boolean;
  renderTimeSlot: TimeTableRowProps["renderTimeSlot"];
  defaultItemChecked?: Record<number, Record<string, boolean>>;
  listDays?: Record<number, string>;
  itemChecked: Record<number, Record<string, boolean>>;
  setItemChecked: (value: Record<number, Record<string, boolean>>) => void;
  cancelCount: number;
  frameToggleType: string;
  selectDailyItem: Record<number, Record<string, string>>;
  setSelectDailyItem: (value: Record<number, Record<string, string>>) => void;
  startOfWeek: Date;
  selectedDate: Date;
  dailyScheduleItem: ScheduleDailyInfo;
  setDailyScheduleItem: (value: ScheduleDailyInfo) => void;
  bulkChecked: Record<number, Record<string, boolean>>;
  setBulkChecked: (value: Record<number, Record<string, boolean>>) => void;
}

const TimeTable = ({
  timeFrame = 15,
  direction = "row",
  renderTimeSlot,
  checkbox = false,
  defaultItemChecked,
  amStart,
  amEnd,
  pmStart,
  pmEnd,
  listDays = number2Day,
  itemChecked,
  setItemChecked,
  cancelCount,
  frameToggleType,
  selectDailyItem,
  setSelectDailyItem,
  startOfWeek,
  selectedDate,
  dailyScheduleItem,
  setDailyScheduleItem,
  bulkChecked,
  setBulkChecked,
}: TimeTableProps) => {
  const splitTimeSlots = ({
    amStart,
    amEnd,
    pmStart,
    pmEnd,
    splitTime,
  }: {
    amStart: string;
    amEnd: string;
    pmStart: string;
    pmEnd: string;
    splitTime: TimeFrame;
  }) => {
    const result = [];

    const [amStartHour, amStartMinute] = amStart.split(":").map(Number);
    const [amEndHour, amEndMinute] = amEnd.split(":").map(Number);

    const [pmStartHour, pmStartMinute] = pmStart.split(":").map(Number);
    const [pmEndHour, pmEndMinute] = pmEnd.split(":").map(Number);

    const amTotalMinutes =
      amEndHour * 60 + amEndMinute - (amStartHour * 60 + amStartMinute);

    const pmTotalMinutes =
      pmEndHour * 60 + pmEndMinute - (pmStartHour * 60 + pmStartMinute);

    for (let i = 0; i < amTotalMinutes; i += splitTime) {
      const hour = Math.floor((amStartHour * 60 + amStartMinute + i) / 60);
      const minute = (amStartHour * 60 + amStartMinute + i) % 60;

      const timeSlot = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;

      result.push(timeSlot);
    }

    const [amEndHourRes, amEndMinuteRes] = result[result.length - 1]
      .split(":")
      .map(Number);

    const space =
      (new Date(0, 0, 0, pmStartHour, pmStartMinute).getTime() -
        new Date(0, 0, 0, amEndHourRes, amEndMinuteRes).getTime()) /
      60000;

    if (splitTime > space) result.pop();

    for (let i = 0; i < pmTotalMinutes; i += splitTime) {
      const hour = Math.floor((pmStartHour * 60 + pmStartMinute + i) / 60);
      const minute = (pmStartHour * 60 + pmStartMinute + i) % 60;

      const timeSlot = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;

      if (!result.includes(timeSlot)) result.push(timeSlot);
    }

    return result;
  };

  const timeFrameRowsOnly = useMemo(
    () =>
      splitTimeSlots({
        amStart,
        amEnd,
        pmStart,
        pmEnd,
        splitTime: timeFrame,
      }),
    [amStart, amEnd, pmStart, pmEnd, timeFrame],
  );

  const timeFrameRowsOnlyAM = useMemo(() => {
    const [amEndHour, amEndMinute] = amEnd.split(":").map(Number);

    return timeFrameRowsOnly.filter((item) => {
      const [hour, minute] = item.split(":").map(Number);
      if (hour > amEndHour) return false;
      if (hour === amEndHour && minute >= amEndMinute) return false;

      return true;
    });
  }, [timeFrameRowsOnly]);

  const timeFrameRowsOnlyPM = useMemo(() => {
    const [pmStartHour, pmStartMinute] = pmStart.split(":").map(Number);

    return timeFrameRowsOnly.filter((item) => {
      const [hour, minute] = item.split(":").map(Number);
      if (hour < pmStartHour) return false;
      if (hour === pmStartHour && minute < pmStartMinute) return false;

      return true;
    });
  }, [timeFrameRowsOnly]);

  const initItemChecked = (
    defaultItemChecked?: Record<number, Record<string, boolean>>,
  ) =>
    Array.from({ length: 7 }).reduce<Record<number, Record<string, boolean>>>(
      (acc, _, index) => {
        acc[index] = timeFrameRowsOnly.reduce((acc, item) => {
          if (defaultItemChecked && defaultItemChecked?.[index]?.[item]) {
            acc[item] = defaultItemChecked[index][item];
          } else acc[item] = false;

          return acc;
        }, {} as Record<string, boolean>);

        return acc;
      },
      {} as Record<number, Record<string, boolean>>,
    );

  const initSelectDailyItem = (
    startOfWeek: Date,
    dailyScheduleItem: ScheduleDailyInfo,
  ) =>
    Array.from({ length: 7 }).reduce<Record<number, Record<string, string>>>(
      (acc, _, index) => {
        const currentDay = new Date(startOfWeek);
        currentDay.setDate(startOfWeek.getDate() + index);

        acc[index] = timeFrameRowsOnly.reduce((acc, item) => {
          const year = currentDay.getFullYear();
          const month = currentDay.getMonth();
          const day = currentDay.getDate();
          const [hours, minutes] = item.split(":").map(Number);
          const combinedDate = new Date(year, month, day, hours, minutes);

          const matchingSchedules = dailyScheduleItem.filter((schedule) => {
            const scheduleDate = new Date(schedule.datetime);

            return (
              scheduleDate.getFullYear() === combinedDate.getFullYear() &&
              scheduleDate.getMonth() === combinedDate.getMonth() &&
              scheduleDate.getDate() === combinedDate.getDate() &&
              scheduleDate.getHours() === combinedDate.getHours() &&
              scheduleDate.getMinutes() === combinedDate.getMinutes()
            );
          });
          if (matchingSchedules.length > 0) {
            const isOpen = matchingSchedules[0].is_open;
            acc[item] = isOpen ? "◯" : "×";
          } else {
            acc[item] = "-";
          }

          return acc;
        }, {} as Record<string, string>);

        currentDay.setDate(currentDay.getDate() + 1);

        return acc;
      },
      {} as Record<number, Record<string, string>>,
    );

  // チェック・選択肢を付与
  useEffect(() => {
    setSelectDailyItem(initSelectDailyItem(startOfWeek, dailyScheduleItem));
    setItemChecked(initItemChecked(defaultItemChecked));
  }, [
    timeFrameRowsOnly,
    cancelCount,
    frameToggleType,
    selectedDate,
    defaultItemChecked,
  ]);

  const handleClickItem: TimeTableRowProps["onClickItem"] = (item) => {
    const { hour, minute, dayNumber } = item;
    const timeFormat = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    const isChecked = !itemChecked[dayNumber][timeFormat];

    const newCheckedState = {
      ...itemChecked,
      [dayNumber]: {
        ...itemChecked[dayNumber],
        [timeFormat]: isChecked,
      },
    };
    setItemChecked(newCheckedState);
  };

  const handleSelectItem: TimeTableRowProps["onSelectItem"] = (
    item,
    value: string,
  ) => {
    const { hour, minute, dayNumber } = item;
    const timeFormat = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    let newValue: string;
    switch (value) {
      case "-":
        newValue = "-";
        break;
      case "◯":
        newValue = "◯";
        break;
      case "×":
        newValue = "×";
        break;
      default:
        newValue = "-";
    }

    const newCheckedState = {
      ...selectDailyItem,
      [dayNumber]: {
        ...selectDailyItem[dayNumber],
        [timeFormat]: newValue,
      },
    };
    setSelectDailyItem(newCheckedState);

    // 登録用データ
    const dateTime = new Date(startOfWeek);
    dateTime.setDate(dateTime.getDate() + dayNumber);
    dateTime.setHours(hour);
    dateTime.setMinutes(minute);
    dateTime.setSeconds(0);

    if (value === "-") {
      const filteredDailySchedule = dailyScheduleItem.filter((item) => {
        const itemDateTime = new Date(item.datetime);
        const dateTimeCopy = new Date(dateTime);

        // 秒の値を無視して比較
        return (
          itemDateTime.getFullYear() !== dateTimeCopy.getFullYear() ||
          itemDateTime.getMonth() !== dateTimeCopy.getMonth() ||
          itemDateTime.getDate() !== dateTimeCopy.getDate() ||
          itemDateTime.getHours() !== dateTimeCopy.getHours() ||
          itemDateTime.getMinutes() !== dateTimeCopy.getMinutes()
        );
      });
      setDailyScheduleItem([...filteredDailySchedule]);

      return;
    }

    setDailyScheduleItem([
      ...dailyScheduleItem,
      {
        datetime: dateTime,
        is_open: value === "◯",
      },
    ]);
  };

  const leftTableRows = React.useMemo(() => {
    const mapByHour: Record<string, string[]> = {};
    timeFrameRowsOnlyAM.forEach((item) => {
      const [hour] = item.split(":").map(Number);
      if (!mapByHour[hour]) mapByHour[hour] = [item];
      else mapByHour[hour].push(item);
    });

    return timeFrameRowsOnlyAM.map((item, index) => {
      const [hour, minute] = item.split(":").map(Number);

      // const showHourCell = mapByHour[hour][0] === item;
      const rowspan = mapByHour[hour].length;
      const isTopBorder = index !== 0 && index % rowspan === 0;

      return (
        <TimeTableRow
          rowspan={rowspan}
          key={`${hour}-${minute}`}
          topBorder={isTopBorder}
          renderTimeSlot={(item) =>
            renderTimeSlot({
              ...item,
              topBorder: isTopBorder,
              bottomBorder: rowspan - 1 === index % rowspan,
            })
          }
          hour={hour}
          minute={minute}
          formattedMinutes={minute.toString().padStart(2, "0")}
          formattedHour={hour.toString().padStart(2, "0")}
          onClickItem={handleClickItem}
          itemChecked={itemChecked}
          onSelectItem={handleSelectItem}
          selectDailyItem={selectDailyItem}
          bulkChecked={bulkChecked}
          timePeriod="AM"
        />
      );
    });
  }, [amStart, amEnd, pmStart, pmEnd, timeFrame, renderTimeSlot, itemChecked]);

  const rightTableRows = React.useMemo(() => {
    const mapByHour: Record<string, string[]> = {};
    timeFrameRowsOnlyPM.forEach((item) => {
      const [hour] = item.split(":").map(Number);
      if (!mapByHour[hour]) mapByHour[hour] = [item];
      else mapByHour[hour].push(item);
    });

    return timeFrameRowsOnlyPM.map((item, index) => {
      const [hour, minute] = item.split(":").map(Number);
      // const showHourCell = mapByHour[hour][0] === item;
      const rowspan = mapByHour[hour].length;
      const isTopBorder = index !== 0 && index % rowspan === 0;

      return (
        <TimeTableRow
          rowspan={rowspan}
          topBorder={isTopBorder}
          key={`${hour}-${minute}`}
          renderTimeSlot={(item) =>
            renderTimeSlot({
              ...item,
              topBorder: isTopBorder,
              bottomBorder: rowspan - 1 === index % rowspan,
            })
          }
          hour={hour}
          minute={minute}
          formattedHour={hour.toString().padStart(2, "0")}
          formattedMinutes={minute.toString().padStart(2, "0")}
          onClickItem={handleClickItem}
          itemChecked={itemChecked}
          onSelectItem={handleSelectItem}
          selectDailyItem={selectDailyItem}
          bulkChecked={bulkChecked}
          timePeriod="PM"
        />
      );
    });
  }, [amStart, amEnd, pmStart, pmEnd, timeFrame, renderTimeSlot, itemChecked]);

  const alignTableColumn = direction === "column" ? "center" : "left";

  const renderCheckbox = (index: number, timePeriod: "AM" | "PM") => {
    const itemObj: Record<string, boolean> = {};

    for (const [key, value] of Object.entries(itemChecked[index])) {
      if (
        (timePeriod === "AM" && timeFrameRowsOnlyAM.includes(key)) ||
        (timePeriod === "PM" && timeFrameRowsOnlyPM.includes(key))
      ) {
        itemObj[key] = value;
      }
    }

    const listChecked = Object.values(itemObj);

    const isEveryChecked =
      listChecked.every((item) => item) && listChecked.some((item) => item);

    const indeterminate =
      listChecked.some((item) => !item) && listChecked.some((item) => item);

    const bulkCheck = bulkChecked[index][timePeriod];

    return (
      <Checkbox
        checked={frameToggleType === "1" ? isEveryChecked : bulkCheck}
        indeterminate={frameToggleType === "1" ? indeterminate : false}
        onChecked={
          frameToggleType === "1"
            ? () => {
                const nextItemChecked = { ...itemChecked };
                for (const [key, _] of Object.entries(nextItemChecked[index])) {
                  if (
                    (timePeriod === "AM" &&
                      timeFrameRowsOnlyAM.includes(key)) ||
                    (timePeriod === "PM" && timeFrameRowsOnlyPM.includes(key))
                  )
                    nextItemChecked[index][key] = !isEveryChecked;
                }
                setItemChecked(nextItemChecked);
              }
            : (checked) => {
                const newCheckedState = {
                  ...bulkChecked,
                  [index]: {
                    ...bulkChecked[index],
                    [timePeriod]: checked,
                  },
                };
                setBulkChecked(newCheckedState);
              }
        }
      />
    );
  };

  return (
    <LayoutBox
      fullWidth
      gap={direction === "column" ? "2x" : "5x"}
      direction={direction}
    >
      <Table
        className="time-table"
        type="condensed"
        width="100%"
        fixedLayout
        head={
          <TableRow>
            <TableColumn
              width="79px"
              id="col-0"
              colSpan={1}
              className="time-table-header--no-right-border"
            >
              午前
            </TableColumn>
            {Object.values(listDays).map((day, index) => (
              <TableColumn
                key={day}
                minWidth="96px"
                id="col-1"
                className="time-table-header--no-right-border"
                padding="0 16px"
                textAlign={alignTableColumn}
              >
                <LayoutBox direction="column" align="center" gap="none">
                  {checkbox && renderCheckbox(index, "AM")}
                  <Text bold size="xs" lineHeight="17px">
                    {day}
                  </Text>
                </LayoutBox>
              </TableColumn>
            ))}
          </TableRow>
        }
        body={leftTableRows}
      />
      <Table
        className="time-table"
        type="condensed"
        width="100%"
        fixedLayout
        head={
          <TableRow>
            <TableColumn
              width="79px"
              id="col-0"
              colSpan={1}
              className="time-table-header--no-right-border"
            >
              午後
            </TableColumn>
            {Object.values(DAYS).map((day, index) => (
              <TableColumn
                key={day}
                minWidth="96px"
                id="col-1"
                className="time-table-header--no-right-border"
                padding="0 16px"
                textAlign={alignTableColumn}
              >
                <LayoutBox direction="column" align="center" gap="none">
                  {checkbox && renderCheckbox(index, "PM")}
                  <Text bold size="xs" lineHeight="17px">
                    {day}
                  </Text>
                </LayoutBox>
              </TableColumn>
            ))}
          </TableRow>
        }
        body={rightTableRows}
      />
    </LayoutBox>
  );
};

export default TimeTable;
