/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactNode, useEffect } from "react";
import { FirebaseError, initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updateEmail,
  User,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import { redirectToInvalidFunctionPage } from "./CustomErrorBoundary";
import { handleFirebaseError } from "./errors";

export interface FirebaseAppContextInterface {
  functions: (data: [string, any]) => Promise<string | number | KV | KV[]>;
  loading?: boolean;
  currentUser?: User | null;
  userObject: KV | null;
  refreshCustomData: () => Promise<void>;
  register: (email: string, password: string) => Promise<{ uid?: string, error?: string }>;
  logIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  changeEmail: (newEmail: string) => Promise<void>;
  deleteUser: (userId: string) => Promise<string | null>;
}

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const firebaseErrorMessage = (error: unknown) => {
  if (!(error instanceof FirebaseError)) {
    return error instanceof Error ? error.message : "エラーが発生しました。";
  }

  switch (error.code) {
    case "auth/invalid-email":
      return "メールアドレスが正しくありません。";
    case "auth/user-not-found":
      return "そのメールアドレスを持つユーザーが見つかりませんでした。";
    case "auth/wrong-password":
      return "パスワードが間違っています。";
    case "auth/user-disabled":
      return "このアカウントは無効になっています。";
    case "auth/email-already-in-use":
      return "このメールアドレスはすでに別のアカウントで使用されています。";
    case "auth/weak-password":
      return "パスワードが弱すぎます。";
    case "auth/popup-closed-by-user":
      return "認証が完了する前にポップアップが閉じられました。";
    case "auth/cancelled-popup-request":
      return "ポップアップのリクエストがキャンセルされました。";
    case "auth/unverified-email":
      return "メールアドレスが認証されていません。";
    case "auth/too-many-requests":
      return "リクエストが多すぎます。しばらくしてからもう一度お試しください。";
    case "auth/operation-not-allowed":
      return "この操作は許可されていません。";
    case "auth/requires-recent-login":
      return "この操作には最近のログインが必要です。";
    case "auth/invalid-api-key":
      return "APIキーが無効です。";
    case "auth/app-not-authorized":
      return "この操作は許可されていません。";
    default:
      return "認証エラーが発生しました。";
  }
};

const auth = getAuth(app);
const firebaseFunctions = getFunctions(app, "asia-northeast1");

const FirebaseAppContext = React.createContext<FirebaseAppContextInterface>(
  {} as FirebaseAppContextInterface,
);

// const transferMongoType = (obj: any): any => {
//   if (typeof obj !== "object" || obj === null) {
//     return obj; // Return primitives, null, and Dates as-is
//   }

//   if (Array.isArray(obj)) {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//     return obj.map(transferMongoType); // Recursively process arrays
//   }

//   if ("$numberInt" in obj) {
//     return parseInt((obj as KV).$numberInt as string, 10); // Convert and return the number
//   }
//   if ("$numberLong" in obj) {
//     return Number((obj as KV).$numberLong); // Convert and return the number
//   }

//   if ("$numberDouble" in obj) {
//     return Number((obj as KV).$numberDouble); // Convert and return the number
//   }

//   const newObj: KV = {};
//   Object.keys(obj as KV).forEach((key) => {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//     newObj[key] = transferMongoType((obj as KV)[key]); // Recursively process values
//   });

//   return newObj;
// };

export const useFirebaseApp = () => {
  const appContext = React.useContext(FirebaseAppContext);
  if (!appContext) {
    return redirectToInvalidFunctionPage();
  }

  return appContext;
};

const isoStringToDate = (isoString: any): Date | null => {
  if (typeof isoString !== "string") return null;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(isoString)) {
    return null;
  }
  const testDate = new Date(isoString);

  return !Number.isNaN(testDate.getTime()) &&
    testDate.toISOString() === isoString
    ? testDate
    : null;
};

const objectToNumber = (obj: any): number | null => {
  if (!(obj instanceof Object)) return null;
  if ((obj as KV).$numberInt)
    return parseInt((obj as KV).$numberInt as string, 10);
  if ((obj as KV).$numberLong)
    return parseInt((obj as KV).$numberLong as string, 10);
  if ((obj as KV).$numberDouble) return Number((obj as KV).$numberDouble);
  if ((obj as KV).$numberDecimal) return Number((obj as KV).$numberDecimal);

  return null;
};

/**
 * Call Firebase function.
 * Unauthenticated call is allowed, but checked at portal function that require authentication of same firebase project.
 * @param data Attributes to pass to the function
 * @returns Returned value from executed firebase function.  Date and number are transformed to Date and number of javascript.
 */
const functions = async (payloads: [string, any]) => {
  // eslint-disable-next-line prefer-destructuring
  const data = (
    await httpsCallable<
      string,
      string | string[] | number | number[] | KV | KV[]
    >(
      firebaseFunctions,
      "portal",
    )(JSON.stringify(payloads))
  ).data;

  if (typeof data !== "string") return data;

  try {
    const parsedData = JSON.parse(
      data,
      (_key, value) =>
        isoStringToDate(value) ??
        objectToNumber(value) ??
        (value as string | string[] | number | number[] | KV | KV[]),
    ) as string | string[] | number | number[] | KV | KV[];

    return parsedData;
  } catch (e) {
    return data;
  }
};

/**
 * Send verification email using own SMTP.  Use confirmEmail in firebase function
 * @param user User to send verification
 * @returns Error message or null if succeed
 */
export const sendVerification = async (email?: string | null) => {
  try {
    if (!email) return "メールアドレスが正しく設定されていません。";
    const result = await functions(["confirmEmail", [email, true]]);
    if (result) throw new Error(result as string);

    return null;
  } catch (e) {
    return "認証メールが送信できませんでした。";
  }
};

/**
 * Send password reset email using own SMTP.  Use resetPassword in firebase function
 * @param email Email to send password reset
 * @returns Error message or null if succeed
 */
export const sendPasswordReset = async (email?: string | null) => {
  try {
    if (!email) return "メールアドレスが正しく設定されていません。";
    const result = await functions(["resetPassword", [email, true]]);
    if (result) throw new Error(result as string);

    return null;
  } catch (e) {
    return "パスワードリセットメールが送信できませんでした。";
  }
};

export const FirebaseAppProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = React.useState<User | null>();
  const [loading, setLoading] = React.useState(true);
  const [userObject, setUserObject] = React.useState<KV | null>(null);
  const isAuthenticating = currentUser === undefined;

  // Used in useUsa002DbActions.  Set error handling at useUsa002DbActions.  Create user in db here.
  const register = async (email: string, password: string) => {
    try {
      const result = (await functions(["auth/createFirebaseUser", [email, password]])) as { user?: KV, error?: string }
      if (result?.user) {
        const error = await sendVerification(result.user.email as string);
        if (error) return { error };

        return { uid: result.user.uid as string };
      }
      if (result?.error) {
        return { error: handleFirebaseError(result.error) }
      }
    } catch (e) {
      return { error: handleFirebaseError(e) };
    }

    return {};
  };

  // sendpasdswordresetemail before auth is in US-G001.  Need to use custom email sending
  // sendemailverification needs to do in server-side if not authenticated
  // updatePassword is called in US-G015

  // Called from US-A001 Login
  const logIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    setCurrentUser(userCredential.user);
  };

  // Called from US-A022 Logout and several other logout handler
  const logOut = async () => {
    await auth.signOut();
    setCurrentUser(null);
    // セッションストレージクリア
    sessionStorage.clear();
  };

  // Called from useUsg017DbActions (Logged in status is required);
  const changeEmail = async (newEmail: string) => {
    if (auth.currentUser) {
      await updateEmail(auth.currentUser, newEmail);
    }
  };

  // Usually not called.  Using flag to cancel user.
  const deleteUser = React.useCallback(
    async (userId: string) => {
      if (!currentUser)
        return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
      if (
        !window.confirm(
          "アカウントを削除します。この操作は取り消せません。よろしいですか？",
        )
      )
        await functions(["auth/deleteUser", [userId, currentUser.email]]); // Set cancelled flag to user db and delete firebase user by admin app
      // await auth.currentUser?.delete(); // Usually not required because above function handles it.  Session invalidate and logout are performed automatically

      return null;
    },
    [currentUser],
  );

  useEffect(() => {
    if (!isAuthenticating && (currentUser === null || userObject)) setLoading(false);
  }, [currentUser, isAuthenticating, userObject])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      // Get user data from db and set it to userObject
      const func = async () => {
        if (currentUser?.uid !== user?.uid) setCurrentUser(user);
        if (user) {
          if (!userObject || userObject._id !== user.uid) {
            // Mainly initial load, login or user change
            const userData = await functions([
              "mongo/client",
              { collection: "users", findOne: { filter: { _id: user.uid } } },
            ]);
            if (userData) {
              if ((userData as KV).email !== user.email) {
                // User change email and click verification when logged out
                await functions([
                  "mongo/client",
                  {
                    collection: "users",
                    updateOne: {
                      filter: { _id: user.uid },
                      update: { $set: { email: user.email } },
                    },
                  },
                ]);
                (userData as KV).email = user.email;
              }
              setUserObject(userData as KV);
            }
          } else if (user.email !== userObject?.email) {
            // User change email and click verification when logged in
            await functions([
              "mongo/client",
              {
                collection: "users",
                updateOne: {
                  filter: { _id: user.uid },
                  update: { $set: { email: user.email } },
                },
              },
            ]);
            setUserObject({ ...userObject, email: user.email });
          }
        } else {
          if (currentUser !== null) setCurrentUser(null);
          setUserObject(null);
        }
      };
      func().catch((e: any) => {
        console.error(e);
      });
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get updated user data from db
  const refreshCustomData = async () => {
    if (currentUser) {
      const userData = await functions([
        "mongo/client",
        { collection: "users", findOne: { filter: { _id: currentUser.uid } } },
      ]);
      if (userData) {
        setUserObject(userData as KV);
      } else {
        setUserObject(null);
      }
    }
  };

  const memoizedFirebaseAppContext = React.useMemo(() => {
    const appContext = {
      functions,
      loading,
      currentUser,
      userObject,
      refreshCustomData,
      register,
      logIn,
      logOut,
      changeEmail,
      deleteUser,
    };

    return appContext;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, userObject]);

  return (
    <FirebaseAppContext.Provider value={memoizedFirebaseAppContext}>
      {children}
    </FirebaseAppContext.Provider>
  );
};
