import { useEffect } from "react";
import { ADMIN, COMMON, HOSPITAL } from "../constants/pagePaths";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { UserTypeID } from "../constants/common";

// ログイン分岐処理
const LoginProcessing = () => {
  const navigate = useNavigateCustom();
  const appContext = useFirebaseApp();

  useEffect(() => {
    void (async () => {
      try {
        // ファンクション
        await appContext.refreshCustomData();

        // 変換処理
        const userTypeObject = appContext.userObject?.user_type as usersNumberIntType;
        const userTypeString = userTypeObject
          ? (userTypeObject.$numberInt ?? String(appContext.userObject?.user_type ?? ""))
          : null;
        const userType = userTypeString ? Number(userTypeString) : null;
        console.log(appContext.userObject?.user_type)

        if (userType === UserTypeID.ADMIN) {
          navigate(ADMIN.NLB001);
        } else if (userType === UserTypeID.HOSPITAL) {
          navigate(HOSPITAL.MIB001);
        } else {
          // 該当なしの場合、ログアウトして、エラー画面
          void appContext.logOut();
          navigate(COMMON.NLJ007);
        }
      } catch (e) {
        // 該当なしの場合、ログアウトして、エラー画面
        void appContext.logOut();
        navigate(COMMON.NLJ007);
      }
    })();
  }, [navigate, appContext]);

  return <>Processing</>;
};

export default LoginProcessing;
