import { useState, useEffect } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
} from "../../../constants/common";
import { getAggregateNlc033 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { convertSearchName, useMemoUserTypeArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nlc033FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLC033;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLC033;
};

// NLC033画面のデータ取得処理
const useNlc033Fetch = ({
  sortExamples,
  submittedSearchCriteria,
}: Nlc033FetchParams) => {
  const appContext = useFirebaseApp();

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: PatientNlc033StateType[];
    error: Error | null;
  }>({
    result: [],
    error: null,
  });

  // 検索条件をquery用に整形
  const name = convertSearchName(submittedSearchCriteria.name);

  const { userId } = submittedSearchCriteria;

  // ユーザタイプのチェック状態をquery用に整形
  const patientTypeArr = useMemoUserTypeArr(
    SEARCH_INFO.NLC033_type,
    submittedSearchCriteria.userType,
  );

  // ユーザステータスのチェック状態をquery用に整形
  const userStatusArr = useMemoUserTypeArr(
    SEARCH_INFO.NLC033_status,
    submittedSearchCriteria.userStatus,
  );

  // ユーザステータスのチェック状態をquery用に整形
  const checkListArr = useMemoUserTypeArr(
    SEARCH_INFO.NLC033_check,
    submittedSearchCriteria.checkList,
  );

  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "register_date", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNlc033(
          userId,
          name,
          patientTypeArr,
          userStatusArr,
          checkListArr,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        // mongoDBのデータ
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.CHATS, aggregate }])) as AggregateResultType<PatientNlc033StateType>;
        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, error: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({ result: [], error: checkFetchErr(err) });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [colName, patientTypeArr, userStatusArr, name, perPage, skip, sortDirection, checkListArr, userId, appContext]);

  return errorAndResult;
};

export default useNlc033Fetch;
