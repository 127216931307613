import React from "react";
import { Navigate } from "react-router-dom";

import { ADMIN, HOSPITAL } from "../constants/pagePaths";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { UserTypeID } from "../constants/common";

// ログイン不要画面
const PublicElement: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const appContext = useFirebaseApp();
  const userType = Number(appContext.userObject?.user_type);
  const isEmailVerified = Boolean(appContext.currentUser?.emailVerified);

  return isEmailVerified ? (userType === UserTypeID.HOSPITAL ? <Navigate to={HOSPITAL.MIB001} /> : <Navigate to={ADMIN.NLB001} />) : element;
};

export default PublicElement;
