import { useEffect, useState } from "react";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { getAggregateOnePatientUser } from "../utils/query";
import { Collection } from "../constants/common";
import { addLoadCount, decrementLoadCount } from "./base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
} from "../contexts/CustomErrorBoundary";
import useId from "./common/useId";
import useForceUpdate from "./common/useForceUpdate";

// 特定IDのPatientユーザ情報を取得
const usePatientInfoFetch = () => {
  const appContext = useFirebaseApp();

  const [patientInfo, setPatientInfo] = useState<PatientsType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // 特定IDのPatientユーザ情報を取得
        const conditions = { _id: id };
        const aggregate = getAggregateOnePatientUser(conditions);
        const searchPatientInfo = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as PatientsType[];

        if (searchPatientInfo.length < 1) redirectToNoDataPage();
        setPatientInfo(searchPatientInfo[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [id, forceUpdate.forceUpdateCount, appContext]);

  return { error, patientInfo };
};

export default usePatientInfoFetch;
