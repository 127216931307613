import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";

const useNlc026DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [id] = useId();

  // 解約処理
  const handleCancellation = (
    setIsToastOpen: (isToastOpen: boolean) => void,
    setIsOpen: (isOpen: boolean) => void,
  ) => {
    void (async () => {
      setIsOpen(false);
      openUpdateActionLoading();
      try {
        // 解約API
        await appContext.functions(["stripe/cancel/cancelUser", id]);
        setIsToastOpen(true);
      } catch (err) {
        setError(checkActionErr(err, () => setIsOpen(true)));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleCancellation,
  };
};

export default useNlc026DbActions;
