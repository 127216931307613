import { useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  CampaignsStatusID,
  Collection,
  ROLE_KEY,
} from "../../../constants/common";
import useCampaignInfoAction from "../../common/useCampaignInfoAction";
import {
  openUpdateActionLoading,
  closeActionLoading,
  openDeleteActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";
import { ADMIN } from "../../../constants/pagePaths";
import { useNavigateCustom } from "../../base/usePageTransitionCustom";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

// NLH001画面のデータ取得処理
const useNlh003DbActions = () => {
  const navigate = useNavigateCustom();
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [id] = useId();

  // データ取得共通処理
  const { fetchResult, fetchError } = useCampaignInfoAction(true);

  // DB操作の共通filter
  const filter = { _id: id };

  // 配信停止トグルボタン動作
  const handleDeliveryToggle = (isDeliveryStopped: boolean) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.CAMPAIGN_DETAIL_EDIT, appContext);

        // ステータスを更新
        const status = isDeliveryStopped
          ? CampaignsStatusID.UNPUBLISHED
          : CampaignsStatusID.NOW_OPEN;
        const updateData = { status, updated_at: new Date() };
        await appContext.functions(["mongo/client", { collection: Collection.CAMPAIGNS, updateOne: { filter, update: { $set: updateData } } }]);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 削除処理
  const deleteAction = () => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.CAMPAIGN_DETAIL_EDIT, appContext);

        // 初期化
        await appContext.functions(["upsertCampaignApi", [filter, true]]);

        // 一覧画面遷移
        navigate(ADMIN.NLH001);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error || fetchError,
    fetchResult,
    handleDeliveryToggle,
    deleteAction,
  };
};

export default useNlh003DbActions;
