import { useState, useEffect } from "react";

import { getAggregateModalityBooks } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection } from "../../../constants/common";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

const useMib002DBFetch = (id: string) => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const [fetchResult, setFetchResult] = useState<Mbi002TableType>();
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {

        const aggregate = getAggregateModalityBooks(id);
        const aggregateResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate }])) as Mbi002TableType[];

        setFetchResult(aggregateResult[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [currentUser, setFetchResult, forceUpdate.forceUpdateCount, id, appContext]);

  return {
    fetchResult,
    fetchError,
  };
};

export default useMib002DBFetch;
