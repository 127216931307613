import { useState, useEffect } from "react";

import {
  getAggregateHospitalPaymentTotalAmount,
} from "../../utils/query";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection } from "../../constants/common";
import useId from "./useId";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

// 病院のhospitalPaymentの合計値を取得
const useHospitalPaymentTotalAmountFetch = () => {
  const appContext = useFirebaseApp();
  const [id] = useId();

  const [hospitalPaymentTotal, setHospitalPaymentTotal] = useState(0);
  const [hospitalPaymentTotalError, setHospitalPaymentTotalError] =
    useState<Error | null>(null);

  useEffect(() => {
    addLoadCount();
    const fetchData = async () => {
      try {
        const aggregate = getAggregateHospitalPaymentTotalAmount(id);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.HOSPITAL_PAYMENTS, aggregate }])) as {
          _id: string;
          totalAmount: number;
        }[];

        setHospitalPaymentTotal(result[0] ? result[0].totalAmount : 0);
      } catch (err) {
        setHospitalPaymentTotalError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, id]);

  return { hospitalPaymentTotal, hospitalPaymentTotalError };
};

export default useHospitalPaymentTotalAmountFetch;
