import React from "react";

import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import useMie005DbActions from "../hooks/pages/MIE005/useMie005DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useHospitalInput, {
  checkHospitalInputError,
  setAddress,
  setEmail,
  setHospitalName,
  setPref,
  setTel,
} from "../hooks/input/useHospitalInput";
import Dropdown from "../components/Dropdown/Dropdown";
import { PREFECTURE_OPTIONS } from "../constants/common";

type MIE005Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  hospitalData?: hospitalNli016StateType | null;
};

const MIE005: React.FC<MIE005Props> = ({
  isOpen,
  setIsOpen,
  title,
  hospitalData = null,
}) => {
  const [{ hospitalName, address, tel, email, pref, hasMri, hasPet }] =
    useHospitalInput();
  const { actionError, handleRegisterHospital, handleUpdateHospital } =
    useMie005DbActions();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  // 編集時の値反映
  React.useEffect(() => {
    if (hospitalData) {
      setHospitalName(hospitalData.hospital_name);
      setTel(hospitalData.tel);
      setAddress(hospitalData.address);
      setEmail(hospitalData.email);
      setPref(hospitalData.prefecture);
    }
  }, [hospitalData]);

  // 閉じるアイコン、キャンセル
  const handleCloseButton = () => {
    setIsOpen(false);
  };

  // 保存ボタン
  const handleRegisterHospitalButton = () => {
    if (hospitalData) {
      // 更新
      handleUpdateHospital(
        hospitalData.hospital_id,
        hospitalName.value,
        tel.value,
        address.value,
        email.value,
        pref,
        hasMri,
        hasPet,
      );
    } else {
      // 登録
      handleRegisterHospital(
        hospitalName.value,
        tel.value,
        address.value,
        email.value,
        pref,
        hasMri,
        hasPet,
      );
      setHospitalName("");
      setTel("");
      setAddress("");
      setEmail("");
    }
    setIsOpen(false);
  };

  return (
    <div className="admin-area">
      <div className="admin-inner">
        <DialogCustom
          title={title}
          open={isOpen}
          size="large"
          height="622px"
          closeDialog={handleCloseButton}
          footerRight={
            <LayoutBox>
              <Button
                type="sub"
                color="neutral"
                size="large"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
              <Button
                size="large"
                onClick={handleRegisterHospitalButton}
                disabled={checkHospitalInputError()}
              >
                保存
              </Button>
            </LayoutBox>
          }
        >
          <LayoutBox fullWidth direction="column">
            <FormSet
              label="医療機関名"
              base
              errorTextList={[hospitalName.validationMessage]}
            >
              <Input
                width="100%"
                placeholder="医療機関名"
                size="default"
                value={hospitalName.value}
                onChange={(value) => setHospitalName(value)}
                error={hospitalName.isError}
              />
            </FormSet>
            <LayoutBox fullWidth>
              <FormSet
                label="電話番号"
                base
                errorTextList={[tel.validationMessage]}
              >
                <Input
                  width="173px"
                  value={tel.value}
                  placeholder="電話番号"
                  size="default"
                  onChange={(value) => setTel(value)}
                  error={tel.isError}
                />
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet
                label="メールアドレス"
                base
                errorTextList={[email.validationMessage]}
              >
                <Input
                  width="100%"
                  value={email.value}
                  placeholder="メールアドレス"
                  size="default"
                  onChange={(value) => setEmail(value)}
                  error={email.isError}
                />
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth direction="column">
              <FormSet
                label="住所"
                base
                errorTextList={[address.validationMessage]}
              >
                <LayoutBox direction="column" fullWidth>
                  <Dropdown
                    value={pref}
                    items={PREFECTURE_OPTIONS}
                    width="124px"
                    onChange={(value) => setPref(value)}
                  />
                  <Input
                    width="100%"
                    value={address.value}
                    placeholder="住所"
                    size="default"
                    onChange={(value) => setAddress(value)}
                    error={address.isError}
                  />
                </LayoutBox>
              </FormSet>
            </LayoutBox>
          </LayoutBox>
        </DialogCustom>
      </div>
    </div>
  );
};
export default MIE005;
