// changeEmp
import React, { useCallback, useEffect, useState } from "react";

export type SwitchProps = {
  disabled?: boolean;
  checked?: boolean;
  labelPosition?: "right" | "left";
  children?: React.ReactNode;
  onChange: (checked: boolean) => void;
};

const Switch = ({
  disabled = false,
  checked = false,
  labelPosition = "right",
  children = [],
  onChange,
}: SwitchProps) => {
  // changeEmp
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
      onChange(e.currentTarget.checked);
    },
    // changeEmp
    [onChange],
  );

  return (
    <label className={disabled ? "switch switch--disabled" : "switch"}>
      {children && labelPosition === "left" && (
        <span className="switch__label">{children}</span>
      )}
      <div className="switch__input-wrapper">
        <input
          id="switch-input"
          type="checkbox"
          className="switch__input"
          disabled={disabled}
          checked={isChecked}
          onChange={handleInputChange}
        />
        <label className="switch__input-label" htmlFor="switch-input" />
      </div>
      {children && labelPosition === "right" && (
        <span className="switch__label">{children}</span>
      )}
    </label>
  );
};

export default Switch;
