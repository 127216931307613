import { useEffect, useState } from "react";
import { Collection } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateShippings } from "../../../utils/query";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useNli029Dialog from "./useNli029Dialog";

// NLI029画面のデータ取得処理
const useNli029DbFetch = () => {
  const appContext = useFirebaseApp();
  const [isOpen] = useNli029Dialog();
  const [fetchResult, setFetchResult] = useState<ShippingsType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aggregate = getAggregateShippings();
        const result = (await appContext.functions(["mongo/client", { collection: Collection.SHIPPINGS, aggregate }])) as ShippingsType[];

        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    if (isOpen) void fetchData();
  }, [setFetchResult, isOpen, appContext]);

  return { fetchResult, fetchError };
};

export default useNli029DbFetch;
