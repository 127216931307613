import React from "react";
import Icon from "../Icon/Icon";
import Badge from "../Badge/Badge";

export type AvatarProps = {
  counter?: number;
  icon?: string;
  initials?: string;
  imageUrl?: string;
  size?: "small" | "medium" | "large";
  border?: boolean;
};

const Avatar = ({
  counter = 0,
  icon = "person",
  initials = "",
  imageUrl = "",
  size = "medium",
  border = false,
}: AvatarProps) => {
  const avatarClass = React.useMemo(() => {
    const sizeClass = [`avatar--${size}`];
    const borderClass = border ? ["avatar--border"] : [];

    return ["avatar", ...sizeClass, ...borderClass].join(" ");
  }, [border, size]);

  const iconSize = React.useMemo(() => {
    if (size === "small") {
      return "xs";
    }

    return "medium";
  }, [size]);

  const content = React.useMemo(() => {
    if (imageUrl)
      return <img className="avatar__image" src={imageUrl} alt="avatar" />;
    if (initials) return <span>{initials.substring(0, 2)}</span>;

    return <Icon icon={icon} size={iconSize} />;
  }, [icon, iconSize, imageUrl, initials]);

  return (
    <div className={avatarClass}>
      {content}
      {counter > 0 && <Badge counter={counter} color="red" size="medium" />}
    </div>
  );
};

export default Avatar;
