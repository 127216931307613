import React from "react";
import Icon from "../Icon/Icon";

export type StepProps = {
  icon?: string;
  error?: boolean;
  isToDo: boolean;
  isDone: boolean;
  isCurrent: boolean;
  stepCounter: number;
  totalCount: number;
  children: React.ReactNode;
  isVertical?: boolean;
};

const Step = ({
  icon = "",
  error,
  isToDo,
  isDone,
  isCurrent,
  stepCounter,
  totalCount,
  children,
  isVertical = false,
}: StepProps) => {
  const topContainerClass = React.useMemo(() => {
    const nextConnectorClass =
      stepCounter < totalCount ? ["step__top-container--next-connector"] : [];
    const prevConnectorClass =
      isVertical && stepCounter > 1
        ? ["step__top-container--prev-connector--vertical"]
        : ["step__top-container--prev-connector"];
    const currentStepClass =
      isCurrent && isVertical
        ? ["step__top-container--current--vertical"]
        : ["step__top-container--current"];

    const toDoStepClass =
      isToDo && isVertical
        ? ["step__top-container--to-do--vertical"]
        : ["step__top-container--to-do"];
    const errorClass = error ? ["step__top-container--error"] : [];

    return [
      isVertical ? "step__top-container--vertical" : "step__top-container",
      ...nextConnectorClass,
      ...prevConnectorClass,
      ...currentStepClass,
      ...toDoStepClass,
      ...errorClass,
    ].join(" ");
  }, [error, isCurrent, isToDo, stepCounter, totalCount, isVertical]);

  const stepContentClass = React.useMemo(() => {
    const toDoStepClass = isToDo ? ["step__content--to-do"] : [];
    const errorClass = error ? ["step__content--error"] : [];

    return ["step__content", ...toDoStepClass, ...errorClass].join(" ");
  }, [error, isToDo]);

  const content = React.useMemo(() => {
    if (error) return <Icon icon="error" size="xs" />;
    if (isDone) return <Icon icon="check" size="xs" />;
    if (icon) return <Icon icon={icon} size="xs" />;

    return <span>{stepCounter}</span>;
  }, [error, icon, isDone, stepCounter]);

  return (
    <div className={`step${isVertical ? "--vertical" : ""}`}>
      <div className={topContainerClass}>{content}</div>
      <span className={stepContentClass}>{children}</span>
    </div>
  );
};

export default Step;
