import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import Divider from "../components/Divider/Divider";
import {
  BULLET_LIST_ITEMS,
  CampaignCouponsPaymentType,
  INIT_EVALUATION,
  MASTER_MANAGEMENT_OPTIONS,
  PatientTypeID,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import {
  convertChildPatients,
  convertPatientInfo,
} from "../utils/convertDisplay";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";
import { ADMIN } from "../constants/pagePaths";
import Chat from "../features/Chat";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import Tag from "../components/Tag/Tag";
import useStripeSubscriptionFetch from "../hooks/common/useStripeSubscriptionFetch";
import RadioButton from "../components/RadioButton/RadioButton";
import useChildPatientFetch from "../hooks/common/useChildPatientFetch";
import Accordion from "../components/Accordion/Accordion";
import BulletList from "../components/List/BulletList";
import MailIcon from '../assets/mail.svg';
import GoogleIcon from '../assets/google.svg';
import LineIcon from '../assets/line.svg';

const NLC008 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(INIT_EVALUATION);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const [{ userDetailTab }] = useUserDetailTabConditions();

  // 被招待者情報を取得
  const { fetchChildPatientResult, fetchChildPatientError } =
    useChildPatientFetch();
  const childPatients = convertChildPatients(fetchChildPatientResult);

  // 患者情報取得
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    googleId,
    lineId,
    evaluation,
    planName,
    planType,
    mriPetConditions,
    address,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
    activationDate,
    campaignCode,
    paymentTypeNum,
    discountPrice,
    discountRate,
    term,
    parentUserId,
    parentUserName,
    campaignName,
    pastDiagnosis,
    cancellationDate,
    afterCampaignStr,
  } = convertPatientInfo(patientInfo);

  // 次回更新日取得
  const { error: subscriptionError, nextUpdateDateStr } =
    useStripeSubscriptionFetch();

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
    subscriptionError,
    fetchChildPatientError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC004}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <img src={googleId ? GoogleIcon : lineId ? LineIcon : MailIcon} alt="" />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="stretch">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between" align="stretch">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    住所
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <Tabs
                      selectedTab={0}
                      tabs={userDetailTabItems}
                      size="medium"
                      bottomBorder
                      onClick={handleSelectAdminUserDetailTab}
                    />
                    {patientTypeNum === PatientTypeID.PRESENT && (
                      <>
                        <LayoutBox gap="2x" fullWidth direction="column">
                          <Sheet
                            type="header"
                            className="util-px-12 util-py-12"
                            padding="4px 8px"
                          >
                            <Text bold size="xl">
                              招待者
                            </Text>
                          </Sheet>
                          <LayoutBox gap="1x" direction="column">
                            <LayoutBox gap="2x" align="center">
                              <Label base width="120px">
                                招待者
                              </Label>
                              <span className="util-font-neutral--light font-component-chip">
                                {parentUserId}
                              </span>
                              {parentUserName}
                            </LayoutBox>
                          </LayoutBox>
                        </LayoutBox>
                        <Divider />
                      </>
                    )}

                    {/* 招待者が会員になった場合 */}
                    {patientTypeNum === PatientTypeID.MEMBER &&
                      childPatients.length > 0 && (
                        <LayoutBox gap="2x" fullWidth direction="column">
                          <Text size="xl" bold>
                            {`${name}（本人）`}
                          </Text>
                        </LayoutBox>
                      )}

                    {patientTypeNum !== PatientTypeID.INVITATION && (
                      <LayoutBox gap="3x" direction="column" fullWidth>
                        <LayoutBox gap="2x" fullWidth direction="column">
                          <Sheet
                            type="header"
                            className="util-px-12 util-py-12"
                            padding="4px 8px"
                          >
                            <Text bold size="xl">
                              プラン
                            </Text>
                          </Sheet>
                          <LayoutBox gap="1x" direction="column">
                            <LayoutBox gap="2x" align="center">
                              <Label base width="120px">
                                プラン
                              </Label>
                              {planName}
                            </LayoutBox>
                            <LayoutBox gap="2x" align="center">
                              <Label base width="120px">
                                お支払いコース
                              </Label>
                              {planType}
                            </LayoutBox>
                          </LayoutBox>
                          {campaignCode ? (
                            <>
                              <LayoutBox fullWidth gap="2x" align="center">
                                <LayoutBox fullWidth gap="2x" align="center">
                                  <Label base width="120px">
                                    キャンペーンコード
                                  </Label>
                                  {campaignCode}
                                </LayoutBox>
                                <LayoutBox fullWidth gap="2x" align="center">
                                  <Label base width="120px">
                                    キャンペーン名
                                  </Label>
                                  {campaignName}
                                </LayoutBox>
                                <LayoutBox fullWidth gap="2x" align="center">
                                  <Label base width="120px">
                                    内容
                                  </Label>
                                  <LayoutBox gap="2x">
                                    <Text>{term}</Text>
                                    <Text>
                                      {paymentTypeNum ===
                                      CampaignCouponsPaymentType.FIXED_AMOUNT
                                        ? discountPrice
                                        : discountRate}
                                    </Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </LayoutBox>
                              <LayoutBox fullWidth gap="2x" align="center">
                                <LayoutBox fullWidth gap="2x" align="center">
                                  <Label base width="120px">
                                    キャンペーン終了日
                                  </Label>
                                  {cancellationDate}
                                </LayoutBox>
                                <LayoutBox fullWidth gap="2x" align="center">
                                  <Label base width="120px">
                                    キャンペーン終了後
                                  </Label>
                                  {afterCampaignStr}
                                </LayoutBox>
                                <LayoutBox fullWidth gap="2x" align="center">
                                  <></>
                                </LayoutBox>
                              </LayoutBox>
                            </>
                          ) : (
                            <LayoutBox gap="2x" align="center">
                              <Label base width="120px">
                                キャンペーンコード
                              </Label>
                              なし
                            </LayoutBox>
                          )}
                          <LayoutBox gap="2x" align="center">
                            <Label base width="120px">
                              契約日
                            </Label>
                            {activationDate}
                          </LayoutBox>
                          <LayoutBox gap="2x" align="center">
                            <Label base width="120px">
                              次回更新日
                            </Label>
                            {nextUpdateDateStr}
                          </LayoutBox>
                        </LayoutBox>
                        <LayoutBox gap="2x" fullWidth direction="column">
                          <Sheet
                            type="header"
                            className="util-px-12 util-py-12"
                            padding="4px 8px"
                          >
                            <Text bold size="xl">
                              過去の診断について
                            </Text>
                          </Sheet>
                          <Text>{pastDiagnosis}</Text>
                          <Accordion title="内容">
                            <Text>
                              本サービスは、認知症 もしくはMCI（軽度認知障害）
                              と診断されたことがない方を対象とします。
                              これまでに該当する診断をされていないことを確認してください。
                            </Text>
                          </Accordion>
                        </LayoutBox>
                        <LayoutBox gap="2x" fullWidth direction="column">
                          <Sheet
                            type="header"
                            className="util-px-12 util-py-12"
                            padding="4px 8px"
                          >
                            <Text bold size="xl">
                              MRI検査の禁忌事項について
                            </Text>
                          </Sheet>
                          <Text lineHeight="40px">{mriPetConditions}</Text>
                          <Accordion title="内容">
                            <LayoutBox direction="column" gap="2x">
                              <LayoutBox direction="column" gap="none">
                                <Text color="error">
                                  本サービスにはMRI検査が含まれるため、MRI検査を受検できない方は会員登録することがきません。
                                  <br />
                                  以下に当てはまる項目がないことをご確認ください。
                                </Text>
                              </LayoutBox>
                              <Sheet
                                type="border-blue"
                                borderRadius="8px"
                                padding="16px"
                                borderWidth="1px"
                              >
                                <BulletList
                                  title="体内に下記の医療機器がある方"
                                  items={BULLET_LIST_ITEMS}
                                  color="blue"
                                />
                              </Sheet>
                            </LayoutBox>
                          </Accordion>
                        </LayoutBox>
                      </LayoutBox>
                    )}
                    <LayoutBox gap="3x" direction="column" fullWidth>
                      {childPatients.map((childPatient) => (
                        <React.Fragment key={childPatient.id}>
                          {patientTypeNum === PatientTypeID.MEMBER && (
                            <Divider margin="0" />
                          )}
                          <LayoutBox gap="2x" fullWidth direction="column">
                            <LayoutBox direction="column" gap="1x">
                              <LayoutBox gap="1x" align="center">
                                <span className="util-font-neutral--light font-component-chip">
                                  {childPatient.userId}
                                </span>
                                <Tag
                                  showIcon={false}
                                  state="neutral"
                                  label="被招待者"
                                />
                                <Tag
                                  showIcon={false}
                                  label={childPatient.statusStr}
                                  state={
                                    childPatient.statusNum ===
                                    UserStatusID.ACTIVE
                                      ? "success"
                                      : childPatient.statusNum ===
                                        UserStatusID.CANCELLATION
                                      ? "error"
                                      : childPatient.statusNum ===
                                        UserStatusID.SUSPENDED
                                      ? "warning"
                                      : "neutral"
                                  }
                                />
                              </LayoutBox>
                              <LayoutBox gap="2x">
                                <Text size="xl" bold lineHeight="150%">
                                  {childPatient.name}
                                </Text>
                              </LayoutBox>
                            </LayoutBox>
                            <Sheet
                              type="header"
                              className="util-px-12 util-py-12"
                              padding="4px 8px"
                            >
                              <Text bold size="xl">
                                プラン
                              </Text>
                            </Sheet>
                            <LayoutBox gap="1x" direction="column">
                              <LayoutBox gap="2x" align="center">
                                <Label base width="120px">
                                  プラン
                                </Label>
                                {childPatient.planName}
                              </LayoutBox>
                              <LayoutBox gap="2x" align="center">
                                <Label base width="120px">
                                  お支払いコース
                                </Label>
                                {childPatient.planType}
                              </LayoutBox>
                              <LayoutBox gap="2x" align="center">
                                <Label base width="120px">
                                  契約日
                                </Label>
                                {childPatient.activationDate}
                              </LayoutBox>
                              <LayoutBox gap="2x" align="center">
                                <Label base width="120px">
                                  次回更新日
                                </Label>
                                {childPatient.nextBillingDateStr}
                              </LayoutBox>
                            </LayoutBox>
                            <LayoutBox gap="2x" fullWidth direction="column">
                              <Sheet
                                type="header"
                                className="util-px-12 util-py-12"
                                padding="4px 8px"
                              >
                                <Text bold size="xl">
                                  過去の診断について
                                </Text>
                              </Sheet>
                              {/* TODO */}
                              <Text>
                                認知症 もしくはMCI（軽度認知障害）
                                と診断されたことがない
                              </Text>
                              <Accordion title="内容">
                                <Text>
                                  本サービスは、認知症
                                  もしくはMCI（軽度認知障害）
                                  と診断されたことがない方を対象とします。
                                  これまでに該当する診断をされていないことを確認してください。
                                </Text>
                              </Accordion>
                            </LayoutBox>
                            <LayoutBox gap="2x" fullWidth direction="column">
                              <Sheet
                                type="header"
                                className="util-px-12 util-py-12"
                                padding="4px 8px"
                              >
                                <Text bold size="xl">
                                  MRI検査の禁忌事項について
                                </Text>
                              </Sheet>
                              <Text lineHeight="40px">
                                {childPatient.mriPetConditions}
                              </Text>
                              <Accordion title="内容">
                                <LayoutBox direction="column" gap="2x">
                                  <LayoutBox direction="column" gap="none">
                                    <Text color="error">
                                      本サービスにはMRI検査が含まれるため、MRI検査を受検できない方は会員登録することがきません。
                                      <br />
                                      以下に当てはまる項目がないことをご確認ください。
                                    </Text>
                                  </LayoutBox>
                                  <Sheet
                                    type="border-blue"
                                    borderRadius="8px"
                                    padding="16px"
                                    borderWidth="1px"
                                  >
                                    <BulletList
                                      title="体内に下記の医療機器がある方"
                                      items={BULLET_LIST_ITEMS}
                                      color="blue"
                                    />
                                  </Sheet>
                                </LayoutBox>
                              </Accordion>
                            </LayoutBox>
                          </LayoutBox>
                          {patientTypeNum === PatientTypeID.INVITATION && (
                            <Divider margin="0" />
                          )}
                        </React.Fragment>
                      ))}
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
      </div>
    </>
  );
};

export default NLC008;
