import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNlc015 } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import useNlc015Conditions, { setNlc15Data } from "./useNlc015Conditions";
import useNlc015Dialog from "./useNlc015Dialog";

// MRIのテスト結果を取得
const useNlc015DbFetch = () => {
  const appContext = useFirebaseApp();

  const [fetchError, setFetchError] = useState<Error | null>(null);

  const [isOpen] = useNlc015Dialog();
  const [{ modalityId }] = useNlc015Conditions();
  // 患者ID
  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const aggregate = getAggregateNlc015(modalityId);
        const result = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate }])) as PatientNlc015StateType[];

        if (result[0]) setNlc15Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [appContext, id, isOpen, modalityId]);

  return { fetchError };
};

export default useNlc015DbFetch;
