import React from "react";

export type LayoutBoxProps = {
  children: React.ReactNode;
  direction?: "row" | "column";
  gap?: "none" | "1/2x" | "1x" | "2x" | "3x" | "4x" | "5x";
  rowGap?: "none" | "1/2x" | "1x" | "2x" | "3x" | "4x" | "5x";
  align?: "start" | "center" | "end" | "stretch" | "baseline";
  justify?: "start" | "center" | "end" | "between" | "around";
  wrap?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  flexShrink?: number;
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  opacity?: number;
};

const LayoutBox = ({
  children,
  direction = "row",
  gap = "1x",
  rowGap = "none",
  align = "start",
  justify = "start",
  wrap = false,
  fullWidth = false,
  fullHeight = false,
  flexShrink = 1,
  width = "",
  height = "",
  minWidth = "",
  maxWidth = "",
  opacity,
}: LayoutBoxProps) => {
  const getLayoutBoxClass = React.useCallback(() => {
    const directionClass = direction ? [`layout-box--${direction}`] : [];
    const gapClass = gap ? [`layout-box--gap-${gap.replace("/", "_")}`] : [];
    const rowGapClass = rowGap
      ? [`layout-box--row-gap-${rowGap.replace("/", "_")}`]
      : [];
    const alignClass = align ? [`layout-box--align-${align}`] : [];
    const justifyClass = justify ? [`layout-box--justify-${justify}`] : [];
    const wrapClass = wrap ? ["layout-box--wrap"] : [];
    const fullWidthClass = fullWidth ? ["layout-box--full-width"] : [];
    const fullHeightClass = fullHeight ? ["layout-box--full-height"] : [];

    return [
      "layout-box",
      ...directionClass,
      ...gapClass,
      ...rowGapClass,
      ...alignClass,
      ...justifyClass,
      ...wrapClass,
      ...fullWidthClass,
      ...fullHeightClass,
    ].join(" ");
  }, [direction, rowGap, gap, align, justify, wrap, fullWidth, fullHeight]);

  return (
    <div
      className={getLayoutBoxClass()}
      style={{ flexShrink, width, minWidth, maxWidth, height, opacity }}
    >
      {children}
    </div>
  );
};

export default LayoutBox;
