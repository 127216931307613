import { useState } from "react";
import { ADMIN_MAIN_TAB_ITEMS } from "../constants/common";
import { ADMIN } from "../constants/pagePaths";
import useStateCustomObj from "./base/useStateCustomObj";

// 管理者メインタブの通知取得処理
const useAdminMainTabFetch = () => {
  // 2次以降フェーズ対応 ユーザ管理に医師チャットの新規コメントの数を表示

  // メインタブの通知対象のcollectionと検索条件取得関数
  // const mainTabInfoArr = useMemo(
  //   () => [
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //     // 2次以降フェーズ対応: No お知らせは通知数不要？
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //     {
  //       collection: "",
  //       getSearchConditions: null,
  //     },
  //   ],
  //   [],
  // );

  // const appContext = useFirebaseApp();
  // const { currentUser } = appContext;

  const [tabItems] =
    useStateCustomObj<{ counter: number; title: string; url: ADMIN }[]>(
      ADMIN_MAIN_TAB_ITEMS,
    );

  const [error] = useState<Error | null>(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     addLoadCount();
  //     try {
  //       // 各通知数を取得
  //       const mainTabItems = await Promise.all(
  //         mainTabInfoArr.map(
  //           async ({ collection, getSearchConditions }, index) => {
  //             if (collection && getSearchConditions) {
  //               // 通知取得処理が設定されている場合
  //               const mongoDb = appContext.functions(["mongo/client", { collection: collection);
  //               // const searchConditions = getSearchConditions();
  //               // const count = await mongoDb, countDocuments: { filter: searchConditions);

  //               return {
  //                 ...ADMIN_MAIN_TAB_ITEMS[index],
  //                 // counter: count,
  //               };
  //             }

  //             // 通知取得処理が設定されていない場合
  //             return {
  //               ...ADMIN_MAIN_TAB_ITEMS[index],
  //               counter: 0,
  //             };
  //           },
  //         ),
  //       );

  //       setTabItems(mainTabItems);
  //     } catch (err) {
  //       setError(checkFetchErr(err));
  //     } finally {
  //       decrementLoadCount();
  //     }
  //   };

  //   void fetchData();
  // }, [currentUser, mainTabInfoArr, setTabItems]);

  return { error, tabItems };
};

export default useAdminMainTabFetch;
