import React from "react";
import Text from "../Text/Text";
import LayoutBox from "../LayoutBox/LayoutBox";

export type BulletListProps = {
  title?: string;
  items: {
    id: string;
    value: string;
    helperText?: string;
  }[];
  color?: "blue" | "red";
};

const BulletList = ({ items, title, color = "blue" }: BulletListProps) => {
  const bulletColor = React.useMemo(() => {
    switch (color) {
      case "red":
        return "#CF4900";
      default:
        return "#096AE2";
    }
  }, [color]);

  return (
    <div className="bullet-list">
      {title && <Text size="large">{title}</Text>}
      {items.map((item, index) => (
        <div className="bullet-list--item" key={index}>
          <div className="bullet-list-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill={bulletColor} />
            </svg>
          </div>
          <LayoutBox direction="column">
            <Text size="large">{item.value}</Text>
            {item?.helperText && <Text size="large">{item.helperText}</Text>}
          </LayoutBox>
        </div>
      ))}
    </div>
  );
};

export default BulletList;
