import Icon from "../Icon/Icon";

export type VideoProps = {
  url?: string;
  width?: string;
  height?: string;
};

const Video = ({ url = "", width = "306px", height = "172px" }: VideoProps) => {
  if (url === "") {
    return (
      <div className="video--placeholder">
        <Icon
          icon="play_circle"
          size="large"
          color="white"
          type="outlined"
          className="icon--center"
        />
      </div>
    );
  }

  return (
    <iframe
      src={url}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="video"
      width={width}
      height={height}
    />
  );
};

export default Video;
