import { useState, useEffect } from "react";

import { getAggregateItemTags } from "../../utils/query";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";

// 商品タグを取得
const useItemTagsFetch = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [fetchItemTagsResult, setFetchItemTagsResult] = useState<
    ItemTagsStateType[]
  >([]);
  const [fetchItemTagsError, setFetchItemTagsError] = useState<Error | null>(
    null,
  );

  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregateItemTags = getAggregateItemTags();
  const itemTagsResult = (await appContext.functions(["mongo/client", {
    collection: Collection.ITEM_TAGS, aggregate:
      aggregateItemTags,
  }])) as ItemTagsStateType[];
        setFetchItemTagsResult(itemTagsResult);
      } catch (err) {
        setFetchItemTagsError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, currentUser, forceUpdate]);

  return { fetchItemTagsError, fetchItemTagsResult };
};

export default useItemTagsFetch;
