import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openCreateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import {
  Collection,
  HospitalPaymentsExaminationID,
  PatientInterviewStatusID,
  ROLE_KEY,
} from "../../../constants/common";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";
import generateUniqueId from "../../../utils/generateUniqueId";

const useNlc021UpdateStatus = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // hospital_payments登録;
  const handleRegisterHospitalPayments = (
    patientId: string | undefined,
    hospitalId: string | undefined,
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_ADD, appContext);
        // 有料の時に、hospital_paymentsに登録
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const interview: PatientInterviewType = (await appContext.functions(["mongo/client", {
          collection: Collection.PATIENT_INTERVIEWS,
          findOne: {
            filter: {
              patient_id: patientId,
              status: PatientInterviewStatusID.SETTLED,
            },
            options: {
              sort: { created_at: -1 },
            },
          }
        }])) as PatientInterviewType;
        let examination = HospitalPaymentsExaminationID.INTERVIEW;
        if (interview?.invitation_ticket_id) {
          examination = HospitalPaymentsExaminationID.FREE_INTERVIEW;
        }
        const uniqueId = generateUniqueId();
        // 登録データ
        const insertData = {
          _id: uniqueId,
          patient_id: patientId,
          hospital_id: hospitalId,
          examination,
          amount: 1000,
          exam_date: new Date(),
          created_at: new Date(),
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITAL_PAYMENTS, insertOne: { doc: insertData } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospitalPayments,
  };
};

export default useNlc021UpdateStatus;
