import { useState, useEffect, useMemo } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
  UserStatusID,
} from "../../../constants/common";
import { getAggregateNlc029 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nlc029FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLC029;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLC029;
};

const useNlc029Fetch = ({
  sortExamples,
  submittedSearchCriteria,
}: Nlc029FetchParams) => {
  const appContext = useFirebaseApp();

  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: Nlc029StateType[];
    fetchError: Error | null;
  }>({
    result: [],
    fetchError: null,
  });

  const name = convertSearchName(submittedSearchCriteria.name);
  const {
    userId,
    startExamDate,
    endExamDate,
    startUploadExamDate,
    endUploadExamDate,
  } = submittedSearchCriteria;
  const tel = submittedSearchCriteria.phoneNum;

  // 検索のチェック
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLC029,
    submittedSearchCriteria.brainCheckStatus,
  );

  // ユーザステータスのチェック状態
  const initialUserStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLC035_status,
    submittedSearchCriteria.userStatus,
  );

  // 招待者のアクティブ状態を付与・除去
  const userStatusArr = useMemo(() => {
    let statusArr = initialUserStatusArr;
    if (
      statusArr.includes(UserStatusID.ACTIVE) &&
      !statusArr.includes(UserStatusID.INVITATION)
    ) {
      statusArr = [...statusArr, UserStatusID.INVITATION];
    } else if (
      !statusArr.includes(UserStatusID.ACTIVE) &&
      statusArr.includes(UserStatusID.INVITATION)
    ) {
      statusArr = statusArr.filter(
        (status) => status !== UserStatusID.INVITATION,
      );
    }

    return statusArr;
  }, [initialUserStatusArr]);

  // sort
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "exam_start_date", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {

        const aggregate = getAggregateNlc029(
          name,
          userId,
          tel,
          startExamDate,
          endExamDate,
          startUploadExamDate,
          endUploadExamDate,
          userStatusArr,
          memoStatusArr,
          skip,
          perPage,
          colName,
          sortDirection,
        );

        const aggregateResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_BRAIN_CHECKS, aggregate }])) as AggregateResultType<Nlc029StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, fetchError: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({ result: [], fetchError: checkFetchErr(err) });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [colName, memoStatusArr, name, perPage, skip, sortDirection, tel, endExamDate, startExamDate, userId, userStatusArr, startUploadExamDate, endUploadExamDate, appContext]);

  return errorAndResult;
};

export default useNlc029Fetch;
