import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openDeleteActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import { closeNli034Dialog } from "../NLI034/useNli034Dialog";
import useForceUpdate from "../../common/useForceUpdate";

const useNli033DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // キャンペーン削除
  const handleDeleteCampaign = (id: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        await appContext.functions(["stripe/coupon/deleteCoupon", id]);
        closeNli034Dialog();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    handleDeleteCampaign,
    error,
  };
};

export default useNli033DbActions;
