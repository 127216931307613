import useSWR from "swr";
import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateChats } from "../../utils/query";
import { Collection } from "../../constants/common";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import { FileType } from "../../components/FileUpload/FileUpload";
import { convertUrlToBase64 } from "../../utils/utils";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type UploadFileType = Array<FileType & { intervalId?: NodeJS.Timer }>;

const useChatsDbActions = () => {
  const [actionError, setActionError] = useState<Error | null>(null);
  const appContext = useFirebaseApp();
  const [id] = useId();

  /**
   *  ユーザーの全てのチャットを取得します。
   */
  const getAllChatData = async (): Promise<Chats[]> => {
    const aggregate = getAggregateChats(id);
    const chats = (await appContext.functions([
      "mongo/client",
      { collection: Collection.CHATS, aggregate },
    ])) as unknown as Chats[];

    return chats;
  };

  // 1秒間隔で、チャットデータが更新されたかを取得
  const {
    data: allChatData = [],
    error: fetchError = null,
    mutate,
  } = useSWR<Partial<Chats>[], Error>("getAllChatData", getAllChatData, {
    refreshInterval: 1000,
  });

  /**
   *  チャットを追加します。
   * @param {String} ご相談内容
   */
  const addComment = async (props: {
    comment: string;
    uploadFiles: UploadFileType;
    isMonthlyReport: boolean;
  }) => {
    const { comment, uploadFiles, isMonthlyReport } = props;
    addLoadCount();
    try {
      let base64Data;
      let fileName;
      if (uploadFiles.length) {
        const imageUrl = uploadFiles[0].url;
        fileName = uploadFiles[0].name;
        base64Data = await convertUrlToBase64(imageUrl);
      }

      const insertData = {
        patient_id: id,
        admin_user_id: appContext.currentUser?.uid || "",
        comment,
        commented_user: "admin",
        file_name: "",
        file_data: "",
        monthly_report: isMonthlyReport,
      };

      if (base64Data && fileName) {
        insertData.file_name = fileName;
        insertData.file_data = base64Data;
      }

      await appContext.functions(["createChatApi", insertData]);
      await mutate();
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      decrementLoadCount();
    }
  };

  // 署名付きURL取得
  const getSignedUrl = async (props: {
    fileGcsPath: string;
  }): Promise<string> => {
    const { fileGcsPath } = props;
    try {
      const signedUrl = (await appContext.functions([
        "getSignUrlApi",
        fileGcsPath,
      ])) as string;

      return signedUrl;
    } catch (err) {
      setActionError(checkActionErr(err));

      return "";
    }
  };

  const deleteComment = async (props: { chatId: string }): Promise<void> => {
    const { chatId } = props;
    try {
      await appContext.functions([
        "mongo/client",
        {
          collection: Collection.CHATS,
          deleteOne: {
            filter: {
              _id: chatId,
            },
          },
        },
      ]);
    } catch (err) {
      setActionError(checkActionErr(err));
    }
  };

  useEffect(() => {
    // 会員側のメッセージを既読に変更します。
    const changeToRead = async () => {
      try {
        const updateData = {
          is_read: true,
        };
        await appContext.functions([
          "mongo/client",
          {
            collection: Collection.CHATS,
            updateMany: {
              filter: {
                patient_id: id,
                commented_user: "patient",
                is_read: false,
              },
              update: { $set: updateData },
            },
          },
        ]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    };
    void changeToRead();
  }, [id, allChatData, appContext]);

  return {
    fetchError,
    actionError,
    allChatData,
    addComment,
    getSignedUrl,
    deleteComment,
  };
};

export default useChatsDbActions;
