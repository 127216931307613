import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNlc021 } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useNlc021NewDialog from "./useNlc021NewDialog";
import useNlc021Conditions, { setNlc021Data } from "./useNlc021Conditions";

// 面談の予約情報を取得
const useNlc021InterviewFetch = () => {
  const appContext = useFirebaseApp();

  const [isOpen] = useNlc021NewDialog();
  const [{ interviewId }] = useNlc021Conditions();

  const [fetchError, setFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!interviewId) redirectToInvalidAccessPage();

        const aggregate = getAggregateNlc021(interviewId);
        const result = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_INTERVIEWS, aggregate }])) as Nlc021StateType[];

        if (result[0]) setNlc021Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [appContext, interviewId, isOpen]);

  return { fetchError };
};

export default useNlc021InterviewFetch;
