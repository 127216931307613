import { useEffect, useMemo, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import useForceUpdate from "../../common/useForceUpdate";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { getAggregateNli009 } from "../../../utils/query";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import useItemTagsFetch from "../../common/useItemTagsFetch";
import useProductMgmtDbActions from "../../common/useProductMgmtDbActions";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

type Nli009FetchParams = {
  submittedSearchCriteria: {
    id: string;
    name: string;
    skuName: string;
    status?: { [key: string]: boolean };
  };
};

// NLI010画面のデータ操作処理
const useNli009DbActions = ({ submittedSearchCriteria }: Nli009FetchParams) => {
  const appContext = useFirebaseApp();
  const [forceUpdate] = useForceUpdate();
  const [fetchResult, setFetchResult] = useStateCustomObj<
    SkusNli009StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  // 商品種別、SKUのDB操作
  const { actionError, deleteSku, dragAndDropSku } = useProductMgmtDbActions();

  // 商品タグ取得
  const { fetchItemTagsResult: checkboxItems, fetchItemTagsError } =
    useItemTagsFetch();

  // 検索欄入力値
  const { id: skuCode, name: itemName, skuName } = submittedSearchCriteria;

  // タグチェック有のid配列
  const tagIdArray = useMemo(() => {
    const status = submittedSearchCriteria?.status || {};

    return Object.keys(status).filter((key) => status[key] === true);
  }, [submittedSearchCriteria?.status]);

  // 最終更新日の降順
  const colName = "sort";
  const sortDirection = "desc";

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_BROWSING);

  // データ取得
  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateNli009(
          skuCode,
          itemName,
          skuName,
          tagIdArray,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        // mongoDBのデータ
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.SKUS, aggregate }])) as AggregateResultType<SkusNli009StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    if (hasBrowsingRole) void fetchData();
  }, [appContext, forceUpdate, hasBrowsingRole, itemName, perPage, setFetchResult, skip, skuCode, skuName, tagIdArray]);

  return {
    fetchResult,
    checkboxItems,
    fetchError,
    actionError,
    fetchItemTagsError,
    deleteSku,
    dragAndDropSku,
  };
};

export default useNli009DbActions;
