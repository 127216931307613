import { useEffect, useState } from "react";
import { OrderStatusID, Collection, ROLE_KEY } from "../../../constants/common";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNlf005 } from "../../../utils/query";

import {
  openDeleteActionLoading,
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { ADMIN } from "../../../constants/pagePaths";
import { useNavigateCustom } from "../../base/usePageTransitionCustom";
import useForceUpdate from "../../common/useForceUpdate";
import {
  checkActionErr,
  checkFetchErr,
  redirectToNoDataPage,
  redirectToNoRolePage,
} from "../../../contexts/CustomErrorBoundary";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import useOrderId from "../../common/useOrderId";
import {
  useCheckMasterRoleFetching,
  useCheckHasRole,
  checkMasterRole,
} from "../../common/useMasterRoleDbActions";

const useNlf005DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const navigate = useNavigateCustom();
  const [id] = useOrderId();
  const [fetchResult, setFetchResult] = useState<Nlf005TableType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 権限チェック
  const isFetchingRole = useCheckMasterRoleFetching(ROLE_KEY.EC_ORDER_BROWSING);
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.EC_ORDER_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        if (!hasBrowsingRole) redirectToNoRolePage();

        const conditions = { _id: id };
        const aggregate = getAggregateNlf005(conditions);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.ORDERS, aggregate }])) as Nlf005TableType[];
        if (result.length < 1) redirectToNoDataPage();
        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    // 権限取得中はデータ取得しない
    if (isFetchingRole) return;

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount, isFetchingRole, hasBrowsingRole, appContext]);

  // 注文削除処理
  const handleOrderDelete = () => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.EC_ORDER_CANCEL, appContext);

        // 取消API
        await appContext.functions(["stripe/ec/cancelPayment", id]);

        // 一覧画面遷移
        navigate(ADMIN.NLF001);
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 配送手配
  const handleItemDelivery = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.EC_ORDER_DELIVERY, appContext);

        // 配送手配
        const updateData = {
          status: OrderStatusID.SHIPPED,
          updated_at: new Date(),
        };
        await appContext.functions(["mongo/client", {
          collection: Collection.ORDERS, updateOne: {
            filter: { _id: id, status: OrderStatusID.PAYMENT_COMPLETED },
            update: { $set: updateData },
          }
        }]);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 配送完了
  const deliveryFix = (id: string, trackingNumber: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.EC_ORDER_DELIVERY, appContext);

        // 配送完了API
        await appContext.functions(["stripe/ec/deliveryFix", [
          id,
          trackingNumber,
        ]]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchError,
    fetchResult,
    handleOrderDelete,
    actionError,
    handleItemDelivery,
    deliveryFix,
  };
};

export default useNlf005DbActions;
