import React from "react";
import { format, getMonth, getYear } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export type MonthSelectProps = {
  value: Date;
  onChange: (date: Date) => void;
  locale: "ja" | "en";
  dateFormat?: string;
};

const MonthSelect = ({
  value,
  onChange,
  locale,
  dateFormat = "yyyy/MM",
}: MonthSelectProps) => {
  const activatorRef = React.useRef<HTMLButtonElement>();
  const popoverRef = React.useRef<HTMLDivElement>();
  const [selectedYear, setSelectedYear] = React.useState(getYear(value));
  const [isOpen, setIsOpen] = React.useState(false);

  const onPopoverToggleOpen = React.useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  const selectedValue = React.useMemo(
    () => format(value, dateFormat),
    [dateFormat, value],
  );

  const months = React.useMemo((): string[] => {
    if (locale === "ja") {
      return new Array(12).fill("").map(
        (_, i) =>
          ja.localize?.month(i, {
            width: "abbreviated",
          }) as string,
      );
    }

    return new Array(12)
      .fill("")
      .map(
        (_, i) => enUS?.localize?.month(i, { width: "abbreviated" }) as string,
      );
  }, [locale]);

  const decreaseYear = React.useCallback(
    () => setSelectedYear((prev) => prev - 1),
    [],
  );

  const increaseYear = React.useCallback(
    () => setSelectedYear((prev) => prev + 1),
    [],
  );

  const onClickItem = React.useCallback(
    (index: number) => {
      const date = new Date(`${selectedYear}-${index + 1}`);
      onChange(date);
      onPopoverToggleOpen(false);
    },
    [onChange, onPopoverToggleOpen, selectedYear],
  );

  const onClickOutside = React.useCallback(
    (e: MouseEvent) => {
      const refDom = popoverRef.current;
      if (
        e.target &&
        (refDom?.contains(e.target as Node) ||
          activatorRef.current?.contains(e.target as Node))
      ) {
        return;
      }
      setIsOpen(false);
      if (onPopoverToggleOpen) {
        onPopoverToggleOpen(false);
      }
    },
    [activatorRef, onPopoverToggleOpen],
  );

  React.useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", onClickOutside);
    } else {
      window.removeEventListener("click", onClickOutside);
    }
  }, [activatorRef, isOpen, onClickOutside]);

  const today = React.useMemo(() => new Date(), []);

  const selectMonthClass = [
    "select-month",
    isOpen ? "select-month--open" : "",
  ].join(" ");

  return (
    <div>
      <button
        type="button"
        ref={activatorRef as React.RefObject<HTMLButtonElement>}
        className="select-month_btn"
        onClick={() => onPopoverToggleOpen(!isOpen)}
      >
        {selectedValue}
        <Icon icon="arrow_drop_down" size="small" color="#777777" />
      </button>
      <div
        className={selectMonthClass}
        ref={popoverRef as React.RefObject<HTMLDivElement>}
      >
        <div className="select-month_popover-container">
          <div className="select-month_nav-header">
            <Button
              onClick={decreaseYear}
              type="sub"
              color="background-dark"
              size="small"
              icon="chevron_left"
            />
            {selectedYear}
            <Button
              onClick={increaseYear}
              type="sub"
              color="background-dark"
              size="small"
              icon="chevron_right"
            />
          </div>
          <div className="select-month__item-list">
            {months.map((month, index) => {
              const isSelectedClass =
                getYear(value) === selectedYear && getMonth(value) === index
                  ? ["is-active"]
                  : [];
              const isCurrentClass =
                format(today, "yyyy/M") === `${selectedYear}/${index + 1}`
                  ? ["is-current"]
                  : [];
              const itemClass = [
                "select-month__item",
                ...isSelectedClass,
                ...isCurrentClass,
              ].join(" ");

              return (
                <button
                  type="button"
                  className={itemClass}
                  onClick={() => onClickItem(index)}
                  key={month}
                >
                  {month}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthSelect;
