import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAppProvider } from "./contexts/FirebaseApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// 開発用レンダリング調査処理
if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, global-require, @typescript-eslint/no-var-requires, import/no-extraneous-dependencies
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

root.render(
    <FirebaseAppProvider>
      <App />
    </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
