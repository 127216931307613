import React from "react";
import Icon from "../Icon/Icon";
import Popover from "../Popover/Popover";
import SuggestionItem from "./SuggestionItem";

export type SuggestionProps = {
  size?: "default" | "small";
  status?: "error" | "default";
  value?: string;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  items: {
    label: string;
    subLabel?: string;
    value: string;
  }[];
  onChange: (value: string) => void;
};

const Suggestion = ({
  size = "default",
  status = "default",
  value = "",
  placeholder = "",
  width = "240px",
  disabled = false,
  items = [],
  onChange,
}: SuggestionProps) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [focusedValue, setFocusedValue] = React.useState(value ?? "");
  const [isOpen, setIsOpen] = React.useState(false);
  const activatorRef = React.useRef(null);
  const [filteredItems, setFilteredItems] = React.useState(items);
  const [innerValue, setInnerValue] = React.useState(value);

  const selectItem = React.useCallback(
    (value: string) => {
      setIsOpen(false);
      setSelectedValue(value);
      setFocusedValue("");
      setInnerValue(value);
      onChange(value);
    },
    [onChange],
  );

  const selectedOption = React.useMemo(() => {
    const item = items.find((item) => item.value === selectedValue);

    return item && item.label ? item.label : item?.value ?? "";
  }, [items, selectedValue]);

  React.useEffect(() => {
    setInnerValue(selectedOption);
    setInnerValue(value);
  }, [selectedOption, value]);

  const suggestionInputClass = React.useMemo(() => {
    const sizeClass = [`suggestion__input--${size}`];
    const statusClass =
      status !== "default" ? [`suggestion__input--${status}`] : [];

    return ["suggestion__input", ...sizeClass, ...statusClass].join(" ");
  }, [size, status]);

  const onFocus = React.useCallback(() => {
    if (activatorRef.current === document.activeElement) {
      setIsOpen(true);
      setFocusedValue(selectedValue);
    }
  }, [activatorRef, selectedValue]);

  const _onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isOpen) {
        setIsOpen(true);
      }
      onChange(e.currentTarget.value);
      const inputValue = e.currentTarget.value;
      setInnerValue(inputValue);
      if (inputValue === "") {
        setFocusedValue("");
        setFilteredItems(items);
      } else {
        const includedItems = items.filter((item) =>
          item.label.toLowerCase().includes(inputValue.toLowerCase()),
        );
        setFilteredItems(includedItems);
        const exactItem = items.find(
          (item) => item.label.toLowerCase() === inputValue.toLowerCase(),
        );
        const includesItem = items.find((item) =>
          item.label.toLowerCase().includes(inputValue.toLowerCase()),
        );

        if (exactItem) {
          setFocusedValue(exactItem.value);
        } else if (includesItem) {
          setFocusedValue(includesItem.value);
        } else {
          setFocusedValue("");
        }
      }
    },
    [isOpen, items, onChange],
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        selectItem(focusedValue);
      }

      if (event.key === "ArrowDown") {
        if (!isOpen) {
          setIsOpen(true);
          setFocusedValue(selectedValue);
        }

        const index = filteredItems.findIndex(
          (item) => item.value === focusedValue,
        );
        if (index < filteredItems.length - 1) {
          setFocusedValue(filteredItems[index + 1].value);
        } else if (index === filteredItems.length - 1) {
          setFocusedValue(filteredItems[0].value);
        }
      }

      if (event.key === "ArrowUp") {
        if (isOpen) {
          setIsOpen(true);
          setFocusedValue(selectedValue);
        }

        const index = filteredItems.findIndex(
          (item) => item.value === focusedValue,
        );
        if (index > 0) {
          setFocusedValue(filteredItems[index - 1].value);
        } else if (index === 0 || index === -1) {
          setFocusedValue(filteredItems[filteredItems.length - 1].value);
        }
      }
    },
    [filteredItems, focusedValue, isOpen, selectItem, selectedValue],
  );

  const onBlur = React.useCallback(() => {
    setFocusedValue(selectedValue);
  }, [selectedValue]);

  const onPopoverToggleOpen = React.useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);
  const closePopoverOnScroll = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="suggestion" style={{ width }}>
      <input
        ref={activatorRef}
        className={suggestionInputClass}
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        value={innerValue}
        onChange={_onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {status === "error" && (
        <span className="suggestion__icon-error">
          <Icon icon="error" size="xs" />
        </span>
      )}
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        toggleOpen={onPopoverToggleOpen}
        onClose={closePopoverOnScroll}
      >
        <div className="suggestion__item-list">
          {filteredItems.map((item, index) => {
            const isSelected = item.value === selectedValue;
            const isFocused = item.value === focusedValue;

            return (
              <SuggestionItem
                {...item}
                isSelected={isSelected}
                isFocused={isFocused}
                selectItem={selectItem}
                key={`suggestion__item-${index}`}
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default Suggestion;
