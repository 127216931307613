import { useState, useEffect } from "react";

import { getAggregateOneNotification } from "../../utils/query";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection, ROLE_KEY } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useId from "./useId";
import {
  checkFetchErr,
  redirectToNoDataPage,
  redirectToNoRolePage,
} from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";
import {
  useCheckHasRole,
  useCheckMasterRoleFetching,
} from "./useMasterRoleDbActions";

// 特定IDのお知らせを取得
const useOneNotificationFetch = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [id] = useId();

  const [fetchResult, setFetchResult] = useState<NotificationsTableType | null>(
    null,
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  // 権限チェック
  const isFetchingRole = useCheckMasterRoleFetching(
    ROLE_KEY.NOTICE_LIST_BROWSING,
  );
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.NOTICE_LIST_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!hasBrowsingRole) redirectToNoRolePage();

        // 特定IDのnotificationを取得
        const conditions = { _id: id };
        const aggregate = getAggregateOneNotification(conditions);
        const result = (await appContext.functions(["mongo/client", { collection:           Collection.NOTIFICATIONS, aggregate }])) as NotificationsTableType[];

        if (result.length < 1) redirectToNoDataPage();
        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限取得中はデータ取得しない
    if (isFetchingRole) return;

    void fetchData();
  }, [currentUser, id, forceUpdate.forceUpdateCount, hasBrowsingRole, isFetchingRole, appContext]);

  return { fetchError, fetchResult };
};

export default useOneNotificationFetch;
