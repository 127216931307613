import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import DatePicker from "../components/DatePicker/DatePicker";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import MenuList from "../components/MenuList/MenuList";
import Popover from "../components/Popover/Popover";
import {
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  HospitalReservationStatusID,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
} from "../constants/common";
import {
  getHospitalStatusTagEmphasis,
  getHospitalStatusTagState,
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
} from "../utils/utils";
import useMib001DbActions from "../hooks/pages/MIB001/useMib001DbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertMib001Table } from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import MIB002 from "./MIB002";

const initialReservation: Mib001TableType = {
  id: "",
  patientName: "",
  patientId: "",
  modalityStr: "",
  modalityNum: 0,
  patientTel: "",
  createdAt: "",
  status: "",
  statusNum: 0,
  patientBirthday: "",
  patientGender: "",
  patientEmail: "",
  candidateBookDates: {},
  candidateBookDatesDisplayObj: {
    firstDisplay: "",
    secondDisplay: "",
    thirdDisplay: "",
    fourthDisplay: "",
    fifthDisplay: "",
  },
  fixBookDate: "",
  fixBookDateOrg: undefined,
  fixBookDateTime00: "",
  userId: "",
  checked: false,
};

const MIB001 = () => {
  const [reservations, setReservations] = useStateCustomObj<Mib001TableType[]>(
    [],
  );
  const [allReservations, setAllReservations] = useStateCustomObj<
    Mib001TableType[]
  >([]);

  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES.MIB001);
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.MIB001,
  );
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.MIB001,
  );
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { fetchResult, allData, fetchError } = useMib001DbActions({
    sortExamples,
    submittedSearchCriteria,
  });
  const [isDetailDialogOpen, setIsDetailDialogOpen] = React.useState(false);
  const [selectReservation, setSelectReservation] =
    React.useState<Mib001TableType>(initialReservation);

  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError]);

  useEffect(() => {
    const convertResult = convertMib001Table(fetchResult);
    const convertResultAll = convertMib001Table(allData);
    setReservations(convertResult);
    setAllReservations(convertResultAll);
  }, [fetchResult, allData, setReservations, setAllReservations]);

  // ID
  const handleChangeUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, userId: value }));
  };

  // お客様名
  const handleChangePatientName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, patientName: value }));
  };

  // 電話番号
  const handleChangeTel = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, tel: value }));
  };

  // 申込日
  const onChangeApplicationDate = React.useCallback((date: Date) => {
    setSearchCriteria((prevState) => ({ ...prevState, applicationDate: date }));
  }, []);

  // ステータスチェックボックス
  const handleChangeCheckStatus = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 検査内容チェックボックス
  const handleChangeCheckModality = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      modality: {
        ...searchCriteria.modality,
        [checkedItem]: checked,
      },
    }));
  };

  // 検索ボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.MIB001);

  // 詳細ボタン押下
  const handleOpenDetailDialog = (reservation: Mib001TableType) => {
    setSelectReservation(reservation);
    setIsDetailDialogOpen(true);
  };

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // 予約確定後のステータスかどうか
  const isReservationStatus = (statusNum: number) => {
    const validStatuses = [
      HospitalReservationStatusID.CONFIRM,
      HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL,
      HospitalReservationStatusID.WITHDRAWAL,
      HospitalReservationStatusID.INSPECTED,
      HospitalReservationStatusID.ADMIN_CANCEL,
      HospitalReservationStatusID.NO_EXAMINATION,
      HospitalReservationStatusID.USER_CANCEL,
    ];

    return validStatuses.includes(statusNum);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={0}
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <LayoutBox align="center" justify="around" gap="3x" fullWidth>
                  <FormSet label="ID" labelWidth="96px" base flex="1">
                    <Input
                      value={searchCriteria.userId}
                      placeholder="ID"
                      width="100%"
                      onChange={handleChangeUserId}
                    />
                  </FormSet>
                  <FormSet label="お客様名" labelWidth="96px" base flex="1">
                    <Input
                      value={searchCriteria.patientName}
                      placeholder="お客様名"
                      width="100%"
                      onChange={handleChangePatientName}
                    />
                  </FormSet>
                  <FormSet label="電話番号" labelWidth="96px" base flex="1">
                    <Input
                      value={searchCriteria.tel}
                      placeholder="電話番号"
                      width="100%"
                      onChange={handleChangeTel}
                    />
                  </FormSet>
                </LayoutBox>

                <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                  <FormSet label="申込日" labelWidth="96px" base flex="1">
                    <DatePicker
                      width="100%"
                      placeholder="申込日"
                      selectedDate={searchCriteria.applicationDate}
                      onChangeDate={onChangeApplicationDate}
                    />
                  </FormSet>
                  <FormSet
                    label="内容"
                    labelWidth="96px"
                    base
                    flex="1"
                    formVertical={false}
                  >
                    <LayoutBox justify="start" gap="3x">
                      <Checkbox
                        label="MRI検査"
                        checked={searchCriteria.modality.isMRI}
                        onChecked={(checked) =>
                          handleChangeCheckModality(checked, "isMRI")
                        }
                      />
                      <Checkbox
                        label="FDG PET検査"
                        checked={searchCriteria.modality.isPET}
                        onChecked={(checked) =>
                          handleChangeCheckModality(checked, "isPET")
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                  <FormSet label="" flex="1">
                    <></>
                  </FormSet>
                </LayoutBox>
                <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                  <FormSet
                    label="ステータス"
                    labelWidth="96px"
                    base
                    flex="2 1 24px"
                    formVertical={false}
                  >
                    <LayoutBox gap="3x">
                      <Checkbox
                        label="新規予約"
                        checked={searchCriteria.status.isNew}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isNew")
                        }
                      />
                      <Checkbox
                        label="確定"
                        checked={searchCriteria.status.isConfirm}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isConfirm")
                        }
                      />
                      <Checkbox
                        label="検査済"
                        checked={searchCriteria.status.isInspected}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isInspected")
                        }
                      />
                      <Checkbox
                        label="全候補日NG"
                        checked={searchCriteria.status.isNg}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isNg")
                        }
                      />
                      <Checkbox
                        label="取り下げ申請"
                        checked={
                          searchCriteria.status.isApplicationForWithdrawal
                        }
                        onChecked={(checked) =>
                          handleChangeCheckStatus(
                            checked,
                            "isApplicationForWithdrawal",
                          )
                        }
                      />
                      <Checkbox
                        label="予約取り下げ"
                        checked={searchCriteria.status.isWithdrawal}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isWithdrawal")
                        }
                      />
                      <Checkbox
                        label="管理者取消"
                        checked={searchCriteria.status.isAdminCancel}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isAdminCancel")
                        }
                      />
                      <Checkbox
                        label="受検なし"
                        checked={searchCriteria.status.isNoExamination}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isNoExamination")
                        }
                      />
                      <Checkbox
                        label="会員キャンセル"
                        checked={searchCriteria.status.isUserCancel}
                        onChecked={(checked) =>
                          handleChangeCheckStatus(checked, "isUserCancel")
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <Table
                  type="condensed"
                  fixedLayout
                  width="100%"
                  head={
                    <>
                      <TableRow>
                        <TableColumn
                          width="105px"
                          id="col-1"
                          canSort
                          sortedDirection={sortExamples[0].sortDirection}
                          onClickSort={onClickSort}
                          rowSpan={2}
                        >
                          ID
                        </TableColumn>
                        <TableColumn
                          width="186px"
                          id="col-2"
                          canSort
                          sortedDirection={sortExamples[1].sortDirection}
                          onClickSort={onClickSort}
                          rowSpan={2}
                        >
                          お客様名
                        </TableColumn>
                        <TableColumn
                          width="93px"
                          canSort
                          sortedDirection={sortExamples[2].sortDirection}
                          id="col-3"
                          onClickSort={onClickSort}
                          rowSpan={2}
                        >
                          内容
                        </TableColumn>
                        <TableColumn width="110px" id="col-4" rowSpan={2}>
                          電話番号
                        </TableColumn>
                        <TableColumn
                          width="101px"
                          id="col-5"
                          textAlign="right"
                          canSort
                          sortedDirection={sortExamples[3].sortDirection}
                          onClickSort={onClickSort}
                          rowSpan={2}
                        >
                          申込日
                        </TableColumn>
                        <TableColumn
                          width="126px"
                          id="col-6"
                          canSort
                          sortedDirection={sortExamples[4].sortDirection}
                          onClickSort={onClickSort}
                          rowSpan={2}
                        >
                          ステータス
                        </TableColumn>
                        <TableColumn width="49px" id="col-7" rowSpan={2} />

                        <TableColumn
                          id="col-8"
                          width="620px"
                          textAlign="center"
                          colSpan={5}
                        >
                          予約候補日
                        </TableColumn>
                      </TableRow>
                      <TableRow>
                        <TableColumn
                          width="126px"
                          id="col-9"
                          height="40px"
                          textAlign="center"
                        >
                          ①
                        </TableColumn>
                        <TableColumn
                          width="126px"
                          id="col-10"
                          textAlign="center"
                        >
                          ②
                        </TableColumn>
                        <TableColumn
                          width="126px"
                          id="col-11"
                          textAlign="center"
                        >
                          ③
                        </TableColumn>
                        <TableColumn
                          width="126px"
                          id="col-12"
                          textAlign="center"
                        >
                          ④
                        </TableColumn>
                        <TableColumn
                          width="126px"
                          id="col-13"
                          textAlign="center"
                        >
                          ⑤
                        </TableColumn>
                      </TableRow>
                    </>
                  }
                  body={reservations.map((reservation) => (
                    <TableRow
                      key={reservation.id}
                      isSelected={reservation.checked}
                    >
                      <TableCell>
                        <Text size="xs">{reservation.userId}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{reservation.patientName}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{reservation.modalityStr}</Text>
                      </TableCell>
                      <TableCell textAlign="center">
                        <Text size="xs"> {reservation.patientTel}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{reservation.createdAt}</Text>
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={reservation.status}
                          showIcon={false}
                          state={getHospitalStatusTagState(
                            reservation.statusNum,
                          )}
                          emphasis={getHospitalStatusTagEmphasis(
                            reservation.statusNum,
                          )}
                        />
                      </TableCell>
                      <TableCell textAlign="center" padding="0">
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => handleOpenDetailDialog(reservation)}
                        >
                          詳細
                        </Button>
                      </TableCell>

                      <TableCell>
                        <Text
                          size="xs"
                          bold={
                            isReservationStatus(reservation.statusNum) &&
                            reservation.fixBookDateTime00 ===
                              reservation.candidateBookDates.first
                              ? true
                              : undefined
                          }
                          color={
                            reservation.statusNum ===
                              HospitalReservationStatusID.NEW ||
                            (isReservationStatus(reservation.statusNum) &&
                              reservation.fixBookDateTime00 ===
                                reservation.candidateBookDates.first)
                              ? "neutralBlack"
                              : "disabled"
                          }
                        >
                          {
                            reservation.candidateBookDatesDisplayObj
                              .firstDisplay
                          }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          size="xs"
                          bold={
                            isReservationStatus(reservation.statusNum) &&
                            reservation.fixBookDateTime00 ===
                              reservation.candidateBookDates.second
                              ? true
                              : undefined
                          }
                          color={
                            reservation.statusNum ===
                              HospitalReservationStatusID.NEW ||
                            (isReservationStatus(reservation.statusNum) &&
                              reservation.fixBookDateTime00 ===
                                reservation.candidateBookDates.second)
                              ? "neutralBlack"
                              : "disabled"
                          }
                        >
                          {
                            reservation.candidateBookDatesDisplayObj
                              .secondDisplay
                          }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          size="xs"
                          bold={
                            isReservationStatus(reservation.statusNum) &&
                            reservation.fixBookDateTime00 ===
                              reservation.candidateBookDates.third
                              ? true
                              : undefined
                          }
                          color={
                            reservation.statusNum ===
                              HospitalReservationStatusID.NEW ||
                            (isReservationStatus(reservation.statusNum) &&
                              reservation.fixBookDateTime00 ===
                                reservation.candidateBookDates.third)
                              ? "neutralBlack"
                              : "disabled"
                          }
                        >
                          {
                            reservation.candidateBookDatesDisplayObj
                              .thirdDisplay
                          }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          size="xs"
                          bold={
                            isReservationStatus(reservation.statusNum) &&
                            reservation.fixBookDateTime00 ===
                              reservation.candidateBookDates.fourth
                              ? true
                              : undefined
                          }
                          color={
                            reservation.statusNum ===
                              HospitalReservationStatusID.NEW ||
                            (isReservationStatus(reservation.statusNum) &&
                              reservation.fixBookDateTime00 ===
                                reservation.candidateBookDates.fourth)
                              ? "neutralBlack"
                              : "disabled"
                          }
                        >
                          {
                            reservation.candidateBookDatesDisplayObj
                              .fourthDisplay
                          }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text
                          size="xs"
                          bold={
                            isReservationStatus(reservation.statusNum) &&
                            reservation.fixBookDateTime00 ===
                              reservation.candidateBookDates.fifth
                              ? true
                              : undefined
                          }
                          color={
                            reservation.statusNum ===
                              HospitalReservationStatusID.NEW ||
                            (isReservationStatus(reservation.statusNum) &&
                              reservation.fixBookDateTime00 ===
                                reservation.candidateBookDates.fifth)
                              ? "neutralBlack"
                              : "disabled"
                          }
                        >
                          {
                            reservation.candidateBookDatesDisplayObj
                              .fifthDisplay
                          }
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                />

                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isDetailDialogOpen && (
        <MIB002
          isOpen={isDetailDialogOpen}
          setIsOpen={setIsDetailDialogOpen}
          selectReservation={selectReservation}
          reservationArray={allReservations}
        />
      )}
    </>
  );
};

export default MIB001;
