import { useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  HospitalPaymentsExaminationID,
  PetStatusID,
} from "../../../constants/common";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import useNlc028Conditions from "./useNlc028Conditions";
import { closeNlc028Dialog } from "./useNlc028Dialog";
import { closeNlc028CancelDialog } from "./useNlc028CancelDialog";
import generateUniqueId from "../../../utils/generateUniqueId";

const useNlc028DbActions = () => {
  interface Modality {
    _id: string;
    hospital_id: string;
  }
  interface Hospital {
    _id: string;
    service_price: {
      mri: number;
      pet: number;
    };
  }
  const appContext = useFirebaseApp();

  const [actionError, setActionError] = useState<Error | null>(null);

  const [{ modalityId, interpretationNote }] = useNlc028Conditions();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // PET 読影登録、ステータスを診断済
  const confirmSelection = () => {
    void (async () => {
      try {
        openUpdateActionLoading();
        await appContext.functions(["mongo/client", {
          collection: Collection.PATIENT_MODALITY_BOOKS,
          updateOne: {
            filter: { _id: modalityId },
            update: {
              $set: {
                pet_status: PetStatusID.DIAGNOSED,
                interpretation_note: interpretationNote,
                updated_at: new Date(),
              },
            },
          }
        }]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc028Dialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // (PET)結果送信取消
  const sendCancel = (patientId: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const updateData = {
          pet_status: PetStatusID.DIAGNOSED,
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, updateOne: { filter: { _id: modalityId }, update: { $set: updateData } }}]);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const modality: Modality | null = (await appContext.functions(["mongo/client", {
          collection: Collection.PATIENT_MODALITY_BOOKS, findOne: {
            filter: {
              _id: modalityId,
            }
          }
        }])) as Modality | null;
        if (!modality) {
          throw new Error("Modality not found");
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const hospital: Hospital | null = (await appContext.functions(["mongo/client", {
          collection: Collection.HOSPITALS,
          findOne: { filter: { _id: modality?.hospital_id } }
        }])) as Hospital | null;
        if (!hospital) {
          throw new Error("Hospital not found");
        }

        const uniqueId = generateUniqueId();
        // 検査費用支払いデータ登録
        const insertData = {
          _id: uniqueId,
          patient_id: patientId,
          hospital_id: modality.hospital_id,
          examination: HospitalPaymentsExaminationID.PET,
          amount: -hospital.service_price.pet,
          exam_date: new Date(),
          created_at: new Date(),
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITAL_PAYMENTS, insertOne: { doc: insertData } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc028Dialog();
        closeNlc028CancelDialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, confirmSelection, sendCancel };
};

export default useNlc028DbActions;
