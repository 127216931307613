import React from "react";

export type LegendItemProps = {
  label?: string;
  value?: string;
  type?: "color" | "mark";
  column: number;
  no: "1" | "2" | "3" | "4" | "5" | "6" | "7";
  isVertical: boolean;
};

const LegendItem = ({
  label = "",
  value = "",
  type = "color",
  column,
  no,
  isVertical,
}: LegendItemProps) => {
  const legendItemClass = React.useMemo(() => {
    const verticalClass = isVertical ? ["legend-item--vertical"] : [];

    return ["legend-item", ...verticalClass].join(" ");
  }, [isVertical]);

  const width = React.useMemo(() => `${100 / column}%`, [column]);

  const markerClass = React.useMemo(() => {
    const typeClass = type ? [`legend-item__marker--type-${type}`] : [];
    const noClass = no ? [`legend-item__marker--no-${no}`] : [];

    return ["legend-item__marker", ...typeClass, ...noClass].join(" ");
  }, [no, type]);

  return (
    <div className={legendItemClass} style={{ width }}>
      <div className="legend-item__left">
        <span className={markerClass} />
        {label && <span className="legend-item__label">{label}</span>}
      </div>
      {value && <span className="legend-item__value">{value}</span>}
    </div>
  );
};

export default LegendItem;
