import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ADMIN } from "../../constants/pagePaths";
import { FirebaseAppContextInterface, useFirebaseApp } from "../../contexts/FirebaseApp";
import {
  checkFetchErr,
  openNoRoleDialog,
} from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import {
  AdminsRoleID,
  Collection,
  ROLE_KEY,
  UserTypeID,
} from "../../constants/common";
import { getAggregateMaterRole } from "../../utils/query";
import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";
import { getAdminRole, getUserType } from "../../utils/utils";

type MasterRoleType = {
  [key in keyof typeof ROLE_KEY]?: boolean;
};
// セットしたページを保持
type PagePathType = {
  path: string;
};

type ResultType = {
  _id: string;
  roleKey: ROLE_KEY;
  auth: { [key in AdminsRoleID]: boolean };
}[];

// 各ページのチェックする権限一覧
const ROLE_SETTINGS: {
  [key: string]: ROLE_KEY[];
} = {
  [ADMIN.NLC008]: [ROLE_KEY.USER_EVENT_HISTORY_REGISTER],
  [ADMIN.NLC011]: [
    ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
    ROLE_KEY.USER_SCREENING_CONFIRM,
  ],
  [ADMIN.NLC014]: [ROLE_KEY.USER_EVENT_HISTORY_REGISTER],
  [ADMIN.NLC017]: [ROLE_KEY.USER_EVENT_HISTORY_REGISTER],
  [ADMIN.NLC019]: [ROLE_KEY.USER_EVENT_HISTORY_REGISTER],
  [ADMIN.NLC020]: [ROLE_KEY.USER_EVENT_HISTORY_REGISTER],
  [ADMIN.NLC023]: [
    ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
    ROLE_KEY.USER_ORDER_HISTORY_BROWSING,
    ROLE_KEY.EC_ORDER_BROWSING,
    ROLE_KEY.EC_ORDER_DELIVERY,
    ROLE_KEY.EC_ORDER_CANCEL,
  ],
  [ADMIN.NLC030]: [ROLE_KEY.USER_EVENT_HISTORY_REGISTER],
  [ADMIN.NLF001]: [
    ROLE_KEY.EC_ORDER_BROWSING,
    ROLE_KEY.EC_ORDER_DETAIL,
    ROLE_KEY.EC_ORDER_DELIVERY_NUM,
    ROLE_KEY.EC_ORDER_CANCEL,
  ],
  [ADMIN.NLF005]: [
    ROLE_KEY.EC_ORDER_BROWSING,
    ROLE_KEY.EC_ORDER_DETAIL,
    ROLE_KEY.EC_ORDER_DELIVERY,
    ROLE_KEY.EC_ORDER_DELIVERY_NUM,
    ROLE_KEY.EC_ORDER_CANCEL,
  ],
  [ADMIN.NLF006]: [
    ROLE_KEY.EC_ORDER_BROWSING,
    ROLE_KEY.EC_ORDER_DELIVERY,
    ROLE_KEY.EC_ORDER_CANCEL,
    ROLE_KEY.EC_SALES_BROWSING,
    ROLE_KEY.EC_SALES_DETAIL,
  ],
  [ADMIN.NLE001]: [
    ROLE_KEY.YOSE_LIST_BROWSING,
    ROLE_KEY.YOSE_LIST_ADD,
    ROLE_KEY.YOSE_DETAIL_EDIT,
  ],
  [ADMIN.NLE002]: [ROLE_KEY.YOSE_LIST_BROWSING, ROLE_KEY.YOSE_DETAIL_EDIT],
  [ADMIN.NLG001]: [
    ROLE_KEY.NOTICE_LIST_BROWSING,
    ROLE_KEY.NOTICE_LIST_ADD,
    ROLE_KEY.NOTICE_DETAIL_EDIT,
  ],
  [ADMIN.NLG003]: [ROLE_KEY.NOTICE_LIST_BROWSING, ROLE_KEY.NOTICE_DETAIL_EDIT],
  [ADMIN.NLH001]: [ROLE_KEY.CAMPAIGN_LIST_BROWSING, ROLE_KEY.CAMPAIGN_LIST_ADD],
  [ADMIN.NLH003]: [
    ROLE_KEY.CAMPAIGN_LIST_BROWSING,
    ROLE_KEY.CAMPAIGN_DETAIL_EDIT,
  ],
  [ADMIN.NLI001]: [
    ROLE_KEY.MASTER_AUTHORITY_BROWSING,
    ROLE_KEY.MASTER_AUTHORITY_EDIT,
  ],
  [ADMIN.NLI003]: [
    ROLE_KEY.MASTER_USER_BROWSING,
    ROLE_KEY.MASTER_USER_CSV_DOWNLOAD,
    ROLE_KEY.MASTER_USER_ADD,
    ROLE_KEY.MASTER_USER_EDIT,
  ],
  [ADMIN.NLI007]: [
    ROLE_KEY.MASTER_PRODUCT_BROWSING,
    ROLE_KEY.MASTER_PRODUCT_ADD,
    ROLE_KEY.MASTER_PRODUCT_EDIT,
  ],
  [ADMIN.NLI009]: [
    ROLE_KEY.MASTER_PRODUCT_BROWSING,
    ROLE_KEY.MASTER_PRODUCT_ADD,
    ROLE_KEY.MASTER_PRODUCT_EDIT,
  ],
  [ADMIN.NLI011]: [
    ROLE_KEY.MASTER_PRODUCT_BROWSING,
    ROLE_KEY.MASTER_PRODUCT_ADD,
    ROLE_KEY.MASTER_PRODUCT_EDIT,
  ],
  [ADMIN.NLI016]: [
    ROLE_KEY.MASTER_HOSPITAL_BROWSING,
    ROLE_KEY.MASTER_HOSPITAL_CSV_DOWNLOAD,
    ROLE_KEY.MASTER_HOSPITAL_ADD,
    ROLE_KEY.MASTER_HOSPITAL_EDIT,
  ],
  [ADMIN.NLI017]: [
    ROLE_KEY.MASTER_HOSPITAL_BROWSING,
    ROLE_KEY.MASTER_HOSPITAL_ADD,
    ROLE_KEY.MASTER_HOSPITAL_EDIT,
  ],
};

// 権限を管理
const stateReactiveVar = createReactiveVar<
  (MasterRoleType & PagePathType) | null
>(null);
const useMasterRole = (): ReactiveVarHooks<
  (MasterRoleType & PagePathType) | null
> => useReactiveVarHooks(stateReactiveVar);

// PageCommonで呼び出して権限をセット
const useMasterRoleDbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const adminRole = getAdminRole(appContext);

  const [, setRoleObj] = useMasterRole();
  const [roleFetchError, setRoleFetchError] = useState<Error | null>(null);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const fetchData = async () => {
      if (adminRole) {
        addLoadCount();
        try {
          // 現在ページから確認する必要のある権限を取得
          const currentSettings = ROLE_SETTINGS[pathname];
          if (currentSettings) {
            // ページに必要な権限をすべて取得
            const pipelines = currentSettings.map((roleKey) =>
              getAggregateMaterRole(roleKey),
            );
            const results = (await Promise.all(
              pipelines.map(async (pipeline) => appContext.functions(["mongo/client", { collection: Collection.MASTER_ROLES, aggregate: pipeline }])),
            )) as ResultType[];

            // 現在のユーザ権限に合わせて各権限取得
            const roleObj = results.reduce<MasterRoleType>((acc, subArray) => {
              subArray.forEach((item) => {
                acc[item.roleKey] = item.auth[adminRole];
              });

              return acc;
            }, {});
            setRoleObj({ ...roleObj, path: pathname });
          }
        } catch (err) {
          setRoleFetchError(checkFetchErr(err));
        } finally {
          decrementLoadCount();
        }
      }
    };

    void fetchData();

    // ページを離れたときに初期化
    return () => setRoleObj(null);
  }, [currentUser, adminRole, setRoleObj, pathname, appContext]);

  return {
    roleFetchError,
  };
};

// 指定した権限をチェック
// 取得中はnull
// 取得失敗、権限無し時はfalse,
// 取得成功かつ権限有りの場合はtrue
const _useGetMasterRole = (roleKey: ROLE_KEY | undefined) => {
  const [masterRole] = useMasterRole();

  const appContext = useFirebaseApp();
  const userType = getUserType(appContext);
  const isInvalidUser = userType === null;
  const isNotAdmin = userType !== UserTypeID.ADMIN;

  if (isInvalidUser) {
    // ユーザタイプが取得できない場合は権限無し
    return false;
  }

  if (isNotAdmin) {
    // 管理者以外は権限チェックする必要が無いので常に権限あり
    return true;
  }

  // 権限をセットしたページと現在のページが一致するかをチェック
  const isMatchCurrentPath = window.location.pathname === masterRole?.path;

  // 取得中
  if (!isMatchCurrentPath) return null;
  if (!masterRole) return null;

  return roleKey ? masterRole[roleKey] : false;
};

export const useCheckMasterRoleFetching = (roleKey: ROLE_KEY | undefined) =>
  _useGetMasterRole(roleKey) === null;

export const useCheckHasRole = (roleKey: ROLE_KEY | undefined) =>
  _useGetMasterRole(roleKey) === true;

// 指定した権限をDBからチェック、無い場合はエラーダイアログを表示
export const checkMasterRole = async (
  roleKey: ROLE_KEY,
  appContext: FirebaseAppContextInterface,
) => {
  const userType = getUserType(appContext);
  const isInvalidUser = userType === null;
  const isAdmin = userType === UserTypeID.ADMIN;

  // ユーザタイプが取得できない場合
  if (isInvalidUser) openNoRoleDialog();
  // 管理者でのみ権限チェック
  if (isAdmin) {
    const pipeline = getAggregateMaterRole(roleKey);
    const result = (await appContext.functions(["mongo/client", { collection: Collection.MASTER_ROLES, aggregate: pipeline }])) as ResultType;

    const adminRole = getAdminRole(appContext);
    const hasRole = adminRole ? result[0].auth[adminRole] : false;
    if (!hasRole) openNoRoleDialog();
  }
};

export default useMasterRoleDbActions;
