import React, { useEffect, useState } from "react";
import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Dropdown from "../components/Dropdown/Dropdown";
import useNli020Dialog, {
  closeNli020Dialog,
} from "../hooks/pages/NLI020/useNli020Dialog";
import useOrganizationInput, {
  checkOrganizationInputError,
  setAddress,
  setCampaignCouponId,
  setOrganizationName,
  setTel,
} from "../hooks/input/useOrganizationInput";
import useNli020DbActions from "../hooks/pages/NLI020/useNli020DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNotSetCampaignCouponsFetch from "../hooks/common/useNotSetCampaignCouponsFetch";
import { paymentSpanID } from "../constants/common";

type OptionType = {
  label: string;
  value: string;
  helperText: string;
};

type NLI020Props = {
  organizationData?: {
    id: string | undefined;
    organizationName: string | undefined;
    address: string | undefined;
    tel: string | undefined;
    campaignCouponId: string | undefined;
    campaignName: string | undefined;
    term: number | undefined;
  } | null;
};

const NLI020: React.FC<NLI020Props> = ({ organizationData = null }) => {
  const [options, setOptions] = useState<OptionType[]>([]);

  const [isNli020Open] = useNli020Dialog();
  const { fetchResult, fetchError } = useNotSetCampaignCouponsFetch();
  const { handleRegisterOrganization, handleUpdateOrganization, actionError } =
    useNli020DbActions();
  const [{ organizationName, address, tel, campaignCouponId, campaignError }] =
    useOrganizationInput();

  // エラー処理
  useCheckErrorThrowError([actionError, fetchError]);

  useEffect(() => {
    // キャンペーン表示変換
    let displayOptions = fetchResult.map((coupon) => ({
      label: coupon.campaignName,
      value: coupon._id,
      helperText: coupon.payment_span
        ? coupon.payment_span === paymentSpanID.MONTH
          ? `期間：${coupon.term}ヶ月`
          : `期間：${coupon.term}年`
        : "",
    }));
    if (organizationData) {
      setOrganizationName(organizationData.organizationName ?? "");
      setAddress(organizationData.address ?? "");
      setTel(organizationData.tel ?? "");
      setCampaignCouponId(organizationData.campaignCouponId ?? "");
      if (organizationData.campaignCouponId) {
        // 選択済の項目のみ
        displayOptions = [
          {
            label: organizationData.campaignName ?? "",
            value: organizationData.campaignCouponId ?? "",
            helperText: `期間：${organizationData.term ?? 0}ヶ月`,
          },
        ];
      }
    }
    setOptions(displayOptions);
  }, [fetchResult, organizationData]);

  // キャンペーン変更
  const handleChangeCampaign = React.useCallback((value: string) => {
    setCampaignCouponId(value);
  }, []);

  // 登録ボタン
  const handleRegister = React.useCallback(() => {
    if (organizationData) {
      // 更新
      handleUpdateOrganization(organizationData.id ?? "");
    } else {
      // 登録
      handleRegisterOrganization();
    }
  }, [handleRegisterOrganization, handleUpdateOrganization, organizationData]);

  return (
    <>
      <DialogCustom
        title={organizationData ? "団体編集" : "新規団体登録"}
        size="large"
        height="480px"
        open={isNli020Open}
        closeDialog={closeNli020Dialog}
        footerRight={
          <LayoutBox>
            <Button
              onClick={closeNli020Dialog}
              color="neutral"
              type="sub"
              size="large"
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleRegister}
              disabled={checkOrganizationInputError()}
            >
              登録
            </Button>
          </LayoutBox>
        }
      >
        <LayoutBox gap="2x" direction="column" fullWidth>
          <LayoutBox direction="column" gap="1/2x" fullWidth>
            <FormSet
              label="団体名"
              vertical
              errorTextList={[organizationName.validationMessage]}
            >
              <Input
                size="default"
                placeholder="団体名"
                width="100%"
                value={organizationName.value}
                onChange={(value) => setOrganizationName(value)}
                error={organizationName.isError}
              />
            </FormSet>
          </LayoutBox>

          <FormSet
            label="住所"
            vertical
            errorTextList={[address.validationMessage]}
          >
            <Input
              size="default"
              placeholder="住所"
              width="100%"
              value={address.value}
              onChange={(value) => setAddress(value)}
              error={address.isError}
            />
          </FormSet>

          <FormSet
            label="電話番号"
            vertical
            errorTextList={[tel.validationMessage]}
          >
            <Input
              size="default"
              placeholder="電話番号"
              width="229px"
              value={tel.value}
              onChange={(value) => setTel(value)}
              error={tel.isError}
            />
          </FormSet>

          <FormSet
            label="キャンペーン"
            vertical
            errorTextList={[campaignError]}
          >
            <Dropdown
              placeholder="キャンペーン"
              width="229px"
              onChange={handleChangeCampaign}
              value={campaignCouponId}
              items={options}
              disabled={!!organizationData?.campaignCouponId}
            />
          </FormSet>
        </LayoutBox>
      </DialogCustom>
    </>
  );
};

export default NLI020;
