import React, { useEffect } from "react";
import { getAuth, updatePassword } from "firebase/auth";

import Dialog from "../components/Dialog/Dialog";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import usePasswordInput, {
  checkPasswordInputError,
  setInitPasswordInput,
  setPassword,
  setPasswordError,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import {
  openNlj002Dialog,
  setIsError,
  setMessage,
} from "../hooks/pages/NLJ002/useNlj002Dialog";
import { checkActionErr } from "../contexts/CustomErrorBoundary";

type MIE007Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const MIE007: React.FC<MIE007Props> = ({ isOpen, setIsOpen }) => {
  const [{ password, rePassword }] = usePasswordInput();

  // パスワード入力初期化
  useEffect(() => {
    setInitPasswordInput();
  }, []);

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = React.useCallback(() => {
    setInitPasswordInput();
    setIsOpen(false);
  }, [setIsOpen]);

  // 登録ボタン押下
  const handleChangePassword = async () => {
    if (password.value !== rePassword.value) {
      setPasswordError();

      return;
    }
    // パスワード変更
    try {
      await updatePassword(getAuth().currentUser!, password.value);

      setMessage("パスワード変更完了", "パスワードの変更が完了しました。");
      setIsError(false);
      openNlj002Dialog();
      setInitPasswordInput();
      setIsOpen(false);
    } catch (error) {
      const err = error as Error;
      checkActionErr(err);
    }
  };

  return (
    <>
      <Dialog
        title="パスワード変更"
        size="small"
        height="304px"
        open={isOpen}
        closeDialog={handleCloseButton}
      >
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            void handleChangePassword();
          }}
        >
          <LayoutBox direction="column" fullWidth>
            <FormSet
              label="新しいパスワード"
              vertical
              errorTextList={[password.validationMessage]}
            >
              <Input
                size="default"
                width="100%"
                rightButton="password"
                password
                autoComplete="off"
                value={password.value}
                onChange={(value) => setPassword(value)}
                error={password.isError}
              />
            </FormSet>

            <FormSet
              label="新しいパスワード（確認用）"
              vertical
              errorTextList={[rePassword.validationMessage]}
            >
              <Input
                size="default"
                width="100%"
                rightButton="password"
                password
                autoComplete="off"
                value={rePassword.value}
                onChange={(value) => setRePassword(value)}
                error={rePassword.isError}
              />
            </FormSet>
          </LayoutBox>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <LayoutBox>
              <Button
                color="neutral"
                type="sub"
                width="108px"
                size="large"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
              <Button
                type="primary"
                width="64px"
                size="large"
                buttonType="submit"
                disabled={checkPasswordInputError()}
              >
                登録
              </Button>
            </LayoutBox>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default MIE007;
