import { useMemo } from "react";
import DatePicker, { DatePickerProps } from "../DatePicker/DatePicker";

export interface CalenderProps extends DatePickerProps {
  bordered?: boolean;
}

const Calender = ({ bordered = true, ...rest }: CalenderProps) => {
  const classCalender = useMemo(() => {
    const classValue = ["date-picker calendar"];
    if (bordered) classValue.push("date-picker--bordered");

    return classValue.join(" ");
  }, [bordered]);

  return (
    <DatePicker
      open
      inline
      showPopperArrow={false}
      minDate={new Date()}
      calendarClassName={classCalender}
      {...rest}
    />
  );
};

export default Calender;
