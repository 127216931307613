import { useEffect, useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  HospitalReservationStatusID,
  ModalityID,
  ModalityStatusID,
  MriStatusID,
  MriStatusLabels,
  PetStatusID,
} from "../../../constants/common";
import {
  getAggregateMid002HospitalUser,
  getAggregateMid002Modality,
  getAggregateMid002User,
} from "../../../utils/query";
import useId from "../../common/useId";
import {
  redirectToNoDataPage,
  checkFetchErr,
  checkActionErr,
} from "../../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import { FileType } from "../../../components/FileUpload/FileUpload";

type Modality = {
  attendingHospitalUserId: string;
  fixBookDateRange: string;
  examDate: string;
  hospitalName: string;
  id: string;
  modalityStr: string;
  modalityNum: number;
  mriDicomImagePath: string;
  doctorNote: string;
  doctorReview: string;
  statusNum: number;
  statusStr: string;
  hospitalId: string;
  mriStatus: number;
  petStatus: number;
  contraindications: boolean;
  mriPrecautions: number[];
  petPrecautions: number[];
};

type GcsUrl = {
  file_name: string;
  signedURL: string;
};

type UploadUpdateData = {
  dicom_image_path: string;
  updated_at: Date;
  mri_status?: number;
  pet_status?: number;
  status: number;
};

const useMid002DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [fetchUserResult, setFetchUserResult] =
    useState<Mid002UserStateType | null>(null);
  const [fetchModalityResult, setFetchModalityResult] = useStateCustomObj<
    Mid002ModalityStateType[]
  >([]);
  const [fetchHospitalUserResult, setFetchHospitalUserResult] =
    useStateCustomObj<Mid002HospitalUserStateType[]>([]);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // ユーザ情報
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const conditions = { _id: id };
        const aggregate = getAggregateMid002User(conditions);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as Mid002UserStateType[];

        if (result.length < 1) redirectToNoDataPage();
        setFetchUserResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, currentUser, id, setFetchUserResult]);

  // 検査履歴
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const conditions = {
          patient_id: id,
          status: {
            $in: [
              HospitalReservationStatusID.CONFIRM,
              HospitalReservationStatusID.INSPECTED,
            ],
          },
        };
        const aggregate = getAggregateMid002Modality(conditions);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate }])) as Mid002ModalityStateType[];

        setFetchModalityResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, setFetchModalityResult, forceUpdate, appContext]);

  // 担当医リスト取得
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 病院ID
        const hospitalId: string = currentUser
          ? (appContext.userObject?.hospital_id as string)
          : "";

        const conditions = { hospital_id: hospitalId };
        const aggregate = getAggregateMid002HospitalUser(conditions);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as Mid002HospitalUserStateType[];

        setFetchHospitalUserResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, currentUser, id, setFetchHospitalUserResult]);

  // コメント更新処理
  const updateComment = (
    modalityId: string,
    hospitalUserId: string,
    doctorReview: string,
    doctorNote: string,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const updateData = {
          attending_hospital_user_id: hospitalUserId,
          doctor_review: doctorReview,
          doctor_note: doctorNote,
        };
        await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, updateOne: { filter: { _id: modalityId }, update: { $set: updateData } } }]);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 診断画像アップロード
  const uploadFile = async (
    modality: Modality,
    files: FileType[],
    setUploadFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
    setSelectedModality: React.Dispatch<React.SetStateAction<Modality>>,
  ) => {
    openUpdateActionLoading();
    try {
      // Blob URLからBlobオブジェクトを取得
      const blobResponse = await fetch(files[0].url);
      if (!blobResponse.ok) {
        const error = new Error("Failed to read Blob");
        setActionError(checkActionErr(error));

        return;
      }
      const blob = await blobResponse.blob();

      // GCS URL取得
      let gcsUrl: GcsUrl | undefined;
      if (modality.modalityNum === ModalityID.MRI) {
        gcsUrl = (await appContext?.functions(["mri/uploadSignedUrl", [
          modality.hospitalId,
          modality.id,
          files[0].name,
        ]])) as GcsUrl;
      } else if (modality.modalityNum === ModalityID.PET) {
        gcsUrl = (await appContext?.functions(["pet/uploadSignedUrl", [
          modality.hospitalId,
          modality.id,
          files[0].name,
        ]])) as GcsUrl;
      } else {
        return;
      }

      // アップロード
      const uploadResponse = await fetch(gcsUrl.signedURL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: blob,
      });

      if (uploadResponse.ok) {
        // ステータス更新、ファイルパス更新
        const updateData: UploadUpdateData = {
          dicom_image_path: gcsUrl.file_name,
          status: ModalityStatusID.INSPECTED,
          updated_at: new Date(),
        };
        if (modality.modalityNum === ModalityID.MRI) {
          updateData.mri_status = MriStatusID.EXAMINED;
        } else if (modality.modalityNum === ModalityID.PET) {
          updateData.pet_status = PetStatusID.EXAMINED;
        }
        await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, updateOne: { filter: { _id: modality.id }, update: { $set: updateData } } }]);
        setUploadFiles([]);
        setSelectedModality((prevState) => ({
          ...prevState,
          statusNum: MriStatusID.EXAMINED,
          statusStr: MriStatusLabels[MriStatusID.EXAMINED],
        }));
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } else {
        const error = new Error("Failed to upload File");
        setActionError(checkActionErr(error));
      }
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      closeActionLoading();
    }
  };

  return {
    fetchUserResult,
    fetchModalityResult,
    fetchHospitalUserResult,
    updateComment,
    uploadFile,
    fetchError,
    actionError,
  };
};

export default useMid002DbActions;
