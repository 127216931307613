import { useCallback, useState } from "react";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { getYoseStatus } from "../../../utils/utils";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

const useNLE002DbActions = (props: { id: string }) => {
  const { id } = props;
  const appContext = useFirebaseApp();
  const [actionError, setActionError] = useState<Error | null>(null);

  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.YOSE_LIST_BROWSING);
  const hasEditRole = useCheckHasRole(ROLE_KEY.YOSE_DETAIL_EDIT);

  /**
   *  vimeoのサムネイルを取得します。
   */
  const getVimeoThumbnail = async (vimeoId: string) => {
    try {
      const url = `https://vimeo.com/api/v2/video/${vimeoId}.json`;
      const response = await fetch(url);
      const jsonData = (
        (await response.json()) as unknown as VimeoInfoType[]
      )[0];

      return jsonData.thumbnail_large;
    } catch (err) {
      setActionError(checkActionErr(err));

      return undefined;
    }
  };

  /**
   *  idに一致する寄席のデータを取得します。
   */
  const getYoseDetailData = useCallback(async () => {
    addLoadCount();

    try {
      if (!hasBrowsingRole) return undefined;

      const yoseDetail = (await appContext.functions(["getYoseDetail", id])) as yoseDetailData;

      const yoseDocument = yoseDetail.document;

      // ステータスを計算して設定する。
      const status = getYoseStatus({
        streamingDate: yoseDocument.streaming_date,
        streamingEndDate: yoseDocument.streaming_end_date,
        newPeriod: yoseDocument.new_period,
        isSuspend: yoseDocument.is_suspend,
      });
      yoseDocument.status = status;
      // サムネイルを取得する。
      const thumbnail = await getVimeoThumbnail(yoseDocument.vimeo_id);
      yoseDocument.thumbnail = thumbnail;

      return yoseDetail;
    } catch (err) {
      setActionError(checkActionErr(err));

      return undefined;
    } finally {
      decrementLoadCount();
    }
  }, [appContext, hasBrowsingRole, id]);

  /**
   *  配信停止を切り替えます。
   */
  const updateDeliveryStatus = async (isSuspend: boolean) => {
    if (!hasEditRole) return;
    openUpdateActionLoading();
    try {
      const updateData = {
        is_suspend: isSuspend,
      };
      await appContext.functions(["mongo/client", {
        collection: Collection.YOSES,
        updateOne: { filter: { _id: id }, update: { $set: updateData } }
      }]);
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      closeActionLoading();
    }
  };

  /**
   *  寄席を削除します。
   */
  const deleteYose = async () => {
    if (!hasEditRole) return;
    openUpdateActionLoading();

    try {
      await appContext.functions(["mongo/client", {
        collection: Collection.YOSES, deleteOne: {
          filter: {
            _id: id,
          }
        }
      }]);
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      closeActionLoading();
    }
  };

  return {
    getYoseDetailData,
    updateDeliveryStatus,
    deleteYose,
    actionError,
  };
};

export default useNLE002DbActions;
