import {
  HospitalInspectionStatusID,
  PatientStatusID,
  JST,
  ItemsSkusStatusID,
  JST_OFFSET_HOURS,
  HospitalReservationStatusID,
  MriStatusID,
  PetStatusID,
  ModalityID,
  UserTypeID,
  OrderStatusID,
  PatientInterviewStatusID,
  PatientNouKnowStatusID,
  SalesCategoryID,
  SalesStatusID,
  EventHistoryStatusID,
  BookTypeID,
  AdminsRoleID,
  ROLE_KEY,
  UserStatusID,
  YOSE_STATUS,
  SEARCH_INFO,
  onlineTestStatus,
  MedicalRecordsStatusID,
  HospitalPaymentsExaminationID,
  PatientBrainCheckStatusID,
  CampaignCouponsStatusID,
  HospitalStatusID,
} from "../constants/common";

// MongoDB Query作成処理
type SortStage = { [key: string]: 1 | -1 };
type MatchStage = { [key: string]: unknown };
type ProjectStage = { [key: string]: unknown };
type AddFieldsStage = { [key: string]: unknown };
type GroupStage = { _id: string; [key: string]: unknown };
type LookupStage = {
  from: string;
  localField?: string;
  foreignField?: string;
  as: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let?: Record<string, any>;
  pipeline?: PipelineStage[];
};
type UnwindStage = {
  path: string;
  preserveNullAndEmptyArrays?: boolean;
  includeArrayIndex?: string;
};
type ReplaceRootStage = {
  newRoot: string;
};

type LookUp = {
  [collection: string]: {
    [lookupField: string]: LookupStage;
  };
};

type PipelineStage =
  | { $skip: number }
  | { $limit: number }
  | { $count: string }
  | { $sort: SortStage }
  | { $match: MatchStage }
  | { $group: GroupStage }
  | { $project: ProjectStage }
  | { $lookup: LookupStage }
  | { $unwind: UnwindStage | string }
  | { $facet: FacetStage }
  | { $addFields: AddFieldsStage }
  | { $replaceRoot: ReplaceRootStage };
type FacetStage = { [key: string]: PipelineStage[] };

// 予約などのFrom,Toの時間の差分、1時間で固定
const TIME_DIFF_MS = 3600000;

// Dateフィールドの値を指定されたフォーマットに変換
const _formatDateField = (
  fieldValue: string,
  format: string,
  addTimeMs: string | number = 0,
) => {
  // 時間を指定されていた場合は加算
  if (addTimeMs) {
    return {
      $dateToString: {
        format,
        date: { $add: ["$$fieldValue", addTimeMs] },
        timezone: JST,
      },
    };
  }

  return {
    $dateToString: {
      format,
      date: fieldValue,
      timezone: JST,
    },
  };
};

// Date値から曜日を取得
const _getDayOfWeekSwitch = (fieldValue: string) => ({
  $switch: {
    branches: [
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 1] }, then: "日" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 2] }, then: "月" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 3] }, then: "火" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 4] }, then: "水" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 5] }, then: "木" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 6] }, then: "金" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 7] }, then: "土" },
    ],
    default: "",
  },
});

// 年月日日時の取得条件作成
const _createDateToStringStage = (
  fieldName: string,
  format = "%Y/%m/%d %H:%M",
) => _formatDateField(`$${fieldName}`, format);

// 「年月日(曜日)日時from ~ to」の取得条件作成 DB値が空の場合「‐」を表示
const _createDateToStringWithWeekStage = (
  fieldName: string,
  timeDiffMs: string | number = TIME_DIFF_MS,
) => ({
  $let: {
    vars: {
      fieldValue: { $ifNull: [`$${fieldName}`, "-"] },
    },
    in: {
      $cond: [
        { $eq: ["$$fieldValue", "-"] },
        // DB値が空の場合「‐」
        "-",
        // DB値がある場合変換して取得
        {
          $concat: [
            _formatDateField("$$fieldValue", "%Y/%m/%d"),
            "(",
            _getDayOfWeekSwitch("$$fieldValue"),
            ") ",
            _formatDateField("$$fieldValue", "%H:%M"),
            " ～ ",
            _formatDateField("$$fieldValue", "%H:%M", timeDiffMs),
          ],
        },
      ],
    },
  },
});

// スラッシュ区切りのフルネーム取得条件作成
const _createFullNameSlashStage = () => ({
  $concat: [
    "$last_name",
    "$first_name",
    " / ",
    "$last_name_kana",
    "$first_name_kana",
  ],
});

// usersテーブルのスラッシュ区切りのフルネーム取得条件作成
const _createUsersFullNameSlashStage = () => ({
  $concat: [
    "$name_sei",
    "$name_mei",
    " / ",
    "$name_sei_kana",
    "$name_mei_kana",
  ],
});

// 半角区切りのフルネーム取得条件作成
const _createFullNameSpaceStage = () => ({
  $concat: ["$user_info.name_sei", " ", "$user_info.name_mei"],
});

// 会員のスラッシュ区切りのフルネーム取得条件作成
const _createFullNameSlashPatientStage = () => ({
  $concat: [
    "$user_info.name_sei",
    "$user_info.name_mei",
    " / ",
    "$user_info.name_sei_kana",
    "$user_info.name_mei_kana",
  ],
});

// スラッシュ区切りの誕生日取得条件作成
const _createBirthdaySlashStage = (fieldPath?: string) => {
  const prefix = fieldPath ? `${fieldPath}.` : "";

  const zeroPad = (field: string) => ({
    $cond: [
      { $lt: [field, 10] },
      { $concat: ["0", { $toString: field }] },
      { $toString: field },
    ],
  });

  return {
    $concat: [
      { $toString: `${prefix}birth_year` },
      "/",
      zeroPad(`${prefix}birth_month`),
      "/",
      zeroPad(`${prefix}birth_day`),
    ],
  };
};

// スラッシュ区切りのうのうのrank
const _createNouKnowRankSlashStage = () => ({
  $concat: [
    "集中力スコア:",
    "$patient_nouknow_info.bpi1_rank",
    "/記憶力スコア:",
    "$patient_nouknow_info.bpi2_rank",
  ],
});

const _createSortCondition = (
  sortField: string,
  sortDirection: string,
): SortStage => {
  const sortDirectionNum = sortDirection === "asc" ? 1 : -1;

  return { [sortField]: sortDirectionNum };
};

// ソート条件作成
const _createSortConditions = (
  baseSortField: string, // ソートして値が同じ場合のソート対象
  baseSortDirection: string,
  sortField: string,
  sortDirection: string,
): SortStage => {
  const baseSortCondition = _createSortCondition(
    baseSortField,
    baseSortDirection,
  );
  const sortCondition = _createSortCondition(sortField, sortDirection);

  if (sortField === baseSortField) {
    return sortCondition;
  }

  return {
    ...sortCondition,
    ...baseSortCondition,
  };
};

// 正規表現に編集
const _escapeRegex = (str: string) =>
  str.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

// 部分一致検索条件作成
const _createPartialMatch = (field: string, value: string | null): MatchStage =>
  value ? { [field]: { $regex: value, $options: "i" } } : {};

// 部分一致検索を複数条件作成
const _createInputConditions = (searchConditions: {
  [field: string]: string | null; // { DBのカラム名: 検索値 }
}): MatchStage =>
  Object.entries(searchConditions).reduce(
    (prevSearchConditions, [field, value]) => ({
      ...prevSearchConditions,
      ..._createPartialMatch(field, value),
    }),
    {},
  );

// NLC系画面検索条件
const _createNlcInputConditions = ({
  id,
  name,
  tel,
}: {
  id: string;
  name: string;
  tel: string;
}): MatchStage =>
  _createInputConditions({
    user_id: id,
    full_name: name,
    "patient_info.tel": tel,
  });

// ORで部分一致検索を複数条件作成
const _createInputOrConditions = (
  value: string | null,
  fields: string[],
): MatchStage =>
  value
    ? { $or: fields.map((field) => _createPartialMatch(field, value)) }
    : {};

// チェック欄の検索条件作成
const _createCheckConditions = (
  field: string,
  statusArr: number[] | string[],
): MatchStage => ({
  [field]: { $in: statusArr },
});

// チェック欄の検索条件作成
const _createIsReadCheckConditions = (
  field: string,
  statusArr: number[] | string[],
): MatchStage | undefined => {
  if (statusArr.length === 2) {
    return undefined;
  }
  if (statusArr[0] === "isRead") {
    return {
      [field]: true,
    };
  }
  if (statusArr[0] === "isUnread") {
    return {
      [field]: false,
    };
  }

  return {
    [field]: "none",
  };
};

const _createIsInterviewTypeArrCheckConditions = (
  field: string,
  statusArr: number[] | string[],
): MatchStage | undefined => {
  if (statusArr.length === 2) {
    return undefined;
  }
  if (statusArr[0] === "isExtraFee") {
    return {
      [field]: null,
    };
  }
  if (statusArr[0] === "isIncludesMRI") {
    return {
      $expr: { $gt: [`$${field}`, null] },
    };
  }

  return {
    [field]: "none",
  };
};

// チェック欄の検索条件(String)作成
const _createCheckStringConditions = (
  field: string,
  statusArr: string[],
): MatchStage => ({
  [field]: { $in: statusArr },
});

// チェック欄のキーなし、空を含む検索条件作成
const _createCheckConditionsIncludeNullOrEmpty = (
  field: string,
  valuesArray: string[],
): MatchStage => {
  if (!valuesArray.length) {
    // valuesArrayが空の場合、存在しないまたは空の配列の条件だけを返す
    return {
      $or: [{ [field]: { $exists: false } }, { [field]: { $eq: [] } }],
    };
  }

  return {
    $or: [
      { [field]: { $exists: false } },
      { [field]: { $eq: [] } },
      _createCheckStringConditions(field, valuesArray),
    ],
  };
};

// 日付の範囲検索条件作成
const _createDateRangeConditions = (
  field: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
): MatchStage => {
  if (startDate && endDate) {
    return {
      [field]: {
        $gte: new Date(startDate.setHours(0, 0, 0, 0)),
        $lte: new Date(endDate.setHours(23, 59, 59, 999)),
      },
    };
  }

  return {};
};

// 日付指定の検索条件作成
const _createDateConditions = (
  field: string,
  date: Date | undefined,
): MatchStage => {
  if (date) {
    return {
      [field]: {
        $gte: new Date(date.setHours(0, 0, 0, 0)),
        $lte: new Date(date.setHours(23, 59, 59, 999)),
      },
    };
  }

  return {};
};

// 年、月から検索条件を作成
const _createOrderDateConditions = (
  year: string | null,
  month: string | null,
  key: string,
): MatchStage => {
  if (year && month) {
    const startDate = new Date(
      Date.UTC(parseInt(year), parseInt(month) - 1, 1, -JST_OFFSET_HOURS, 0, 0),
    );
    const endDate = new Date(
      Date.UTC(parseInt(year), parseInt(month), 0, 14, 59, 59),
    );

    return {
      [key]: {
        $gte: startDate,
        $lte: endDate,
      },
    };
  }

  if (year) {
    const startDate = new Date(
      Date.UTC(parseInt(year), 0, 1, -JST_OFFSET_HOURS, 0, 0),
    );
    const endDate = new Date(Date.UTC(parseInt(year), 11, 31, 14, 59, 59));

    return {
      [key]: {
        $gte: startDate,
        $lte: endDate,
      },
    };
  }

  if (month) {
    // 年がない場合、現在の年で検索
    const currentYear = new Date().getFullYear();
    const startDate = new Date(
      Date.UTC(currentYear, parseInt(month) - 1, 1, -JST_OFFSET_HOURS, 0, 0),
    );
    const endDate = new Date(
      Date.UTC(currentYear, parseInt(month), 0, 14, 59, 59),
    );

    return {
      [key]: {
        $gte: startDate,
        $lte: endDate,
      },
    };
  }

  return {};
};

// 合計金額検索条件作成
const _createAmountRangeConditions = (
  field: string,
  minTotal: number | undefined,
  maxTotal: number | undefined,
): MatchStage => {
  // 両方がundefinedの場合
  if (!minTotal && !maxTotal) {
    return {};
  }

  // minTotalがundefinedで、maxTotalが有効な数値の場合
  if (!minTotal && maxTotal) {
    return {
      [field]: {
        $lte: maxTotal,
      },
    };
  }

  // maxTotalがundefinedで、minTotalが有効な数値の場合
  if (minTotal && !maxTotal) {
    return {
      [field]: {
        $gte: minTotal,
      },
    };
  }

  // 両方が有効な数値の場合
  return {
    [field]: {
      $gte: minTotal,
      $lte: maxTotal,
    },
  };
};

// facetを合成
const _createCombineFacetStages = (concatArr: string[]) => [
  {
    $project: {
      all_entries: { $concatArrays: concatArr },
    },
  },
  { $unwind: "$all_entries" },
  { $replaceRoot: { newRoot: "$all_entries" } },
];

// 注文ステータス検索条件を作成
const _createOrderStatusConditions = (tabId: string): { status?: number } => {
  const statusMap: { [key: string]: number } = {
    "2": 2, // 支払い完了
    "3": 3, // 出荷済
    "4": 4, // 配送完了
  };

  return statusMap[tabId] ? { status: statusMap[tabId] } : {};
};

// 1ページ分のデータと全体件数条件
const _createSearchAndCountFacet = (
  sortConditions: SortStage,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  project: ProjectStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [
    { $sort: sortConditions },
    { $skip: skip },
    { $limit: limit },
    { $project: project },
  ],
  // 全体件数をカウント
  count: [{ $count: "count" }],
});

// 1ページ分のデータと全体件数条件(制限なし)
const _createSearchAndCountNoLimitFacet = (
  sortConditions: SortStage,
  project: ProjectStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [{ $sort: sortConditions }, { $project: project }],
  // 全体件数をカウント
  count: [{ $count: "count" }],
});

// 1ページ分のデータと全体件数条件
const _createSearchAndAllDataFacet = (
  sortConditions: SortStage,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  project: ProjectStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [
    { $project: project },
    { $sort: sortConditions },
    { $skip: skip },
    { $limit: limit },
  ],
  // 全件データ
  allData: [{ $project: project }, { $sort: sortConditions }],
  // 全体件数をカウント
  count: [{ $count: "count" }],
});

// 1ページ分のデータと全体件数条件(group毎)
const _createSearchAndCountGroupFacet = (
  sortConditions: SortStage,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  project: ProjectStage,
  group: GroupStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [
    { $project: project },
    { $group: group },
    { $sort: sortConditions },
    { $skip: skip },
    { $limit: limit },
  ],
  // 全体件数をカウント
  count: [{ $project: project }, { $group: group }, { $count: "count" }],
});

// 1ページ分のデータと全体件数条件(グループ、条件付き)
const _createSearchAndCountConditionGroupFacet = (
  sortConditions: SortStage,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  project: ProjectStage,
  group: GroupStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [
    { $group: group },
    { $project: project },
    { $sort: sortConditions },
    { $skip: skip },
    { $limit: limit },
  ],
  // 全体件数をカウント
  count: [{ $group: group }, { $project: project }, { $count: "count" }],
});

// DB紐づけ条件 LOOK_UP.[Collection名].[紐づけるCollection名]で定義
const LOOK_UP: LookUp = {
  PATIENTS: {
    PATIENTS_INTERVIEW: {
      from: "patient_interviews",
      localField: "patient_id",
      foreignField: "patient_id",
      as: "interviews_info",
    },
    USERS: {
      from: "users",
      localField: "_id",
      foreignField: "_id",
      as: "user_info",
    },
    ORGANIZATIONS: {
      from: "organizations",
      localField: "organization_id",
      foreignField: "_id",
      as: "organization_info",
    },
  },
  NOTIFICATIONS: {
    USERS: {
      from: "users",
      localField: "last_updated_admin_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  CAMPAIGNS: {
    ADMINS: {
      from: "admins",
      localField: "last_updated_admin_id",
      foreignField: "_id",
      as: "admin_info",
    },
  },
  ITEMS: {
    ITEM_TAGS: {
      from: "item_tags",
      localField: "tag",
      foreignField: "_id",
      as: "item_tags_info",
    },
    OPTION_CONFIGS: {
      from: "option_configs",
      localField: "_id",
      foreignField: "item_id",
      as: "option_configs_info",
    },
    SKUS: {
      from: "skus",
      localField: "_id",
      foreignField: "item_id",
      as: "skus_info",
    },
    // SKUとオプションの情報
    SKUS_OPTION_CONTENTS: {
      from: "skus",
      let: { item_id: "$_id" },
      pipeline: [
        {
          $match: {
            $expr: { $eq: ["$item_id", "$$item_id"] },
          },
        },
        {
          $lookup: {
            from: "option_contents",
            let: { sku_id: "$_id" },
            pipeline: [
              {
                $match: {
                  $expr: { $eq: ["$sku_id", "$$sku_id"] },
                },
              },
            ],
            as: "option_contents_info",
          },
        },
      ],
      as: "skus_info",
    },
  },
  ORDERS: {
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    TRANSACTIONS: {
      from: "transactions",
      localField: "_id",
      foreignField: "order_id",
      as: "transactions_info",
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  SKUS: {
    ITEMS: {
      from: "items",
      localField: "item_id",
      foreignField: "_id",
      as: "items_info",
    },
    ITEMS_TAGS: {
      from: "item_tags",
      localField: "items_info.tag",
      foreignField: "_id",
      as: "item_tags_info",
    },
    OPTION_CONTENTS: {
      from: "option_contents",
      localField: "_id",
      foreignField: "sku_id",
      as: "option_contents_info",
    },
    OPTION_CONTENTS_OPTION_CONFIGS: {
      from: "option_contents",
      localField: "option_contents_info.option_config_id",
      foreignField: "_id",
      as: "option_configs_info",
    },
  },
  SALES: {
    ORDERS: {
      from: "orders",
      localField: "order_id",
      foreignField: "_id",
      as: "orders_info",
    },
    ORDERS_USERS: {
      from: "users",
      localField: "orders_info.patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENT_INTERVIEWS: {
      from: "patient_interviews",
      localField: "patient_interview_id",
      foreignField: "_id",
      as: "interview_info",
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENT_SUBSCRIPTIONS: {
      from: "patient_subscriptions",
      localField: "patient_subscription_id",
      foreignField: "_id",
      as: "subscription_info",
    },
    PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS: {
      from: "subscription_plans",
      localField: "subscription_info.subscription_plan_id",
      foreignField: "_id",
      as: "subscription_plan_info",
    },
    PATIENT_MODALITY_BOOKS: {
      from: "patient_modality_books",
      localField: "patient_modality_book_id",
      foreignField: "_id",
      as: "patient_modality_book_info",
    },
    PATIENT_MODALITY_BOOKS_HOSPITALS: {
      from: "hospitals",
      localField: "patient_modality_book_info.hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
  },
  PATIENT_MODALITY_BOOKS: {
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENTS_USERS: {
      from: "users",
      localField: "patient_info._id",
      foreignField: "_id",
      as: "user_info",
    },
    HOSPITALS: {
      from: "hospitals",
      localField: "hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    HOSPITAL_USERS: {
      from: "users",
      localField: "attending_hospital_user_id",
      foreignField: "_id",
      as: "hospital_user_info",
    },
  },
  PATIENT_ONLINE_TESTS: {
    PATIENT_MODALITY_BOOK_INFO: {
      from: "patient_modality_books",
      localField: "_id",
      foreignField: "patient_online_test_id",
      as: "patient_modality_book_info",
    },
    PATIENT_NOUKNOWS_INFO: {
      from: "patient_nouknows",
      localField: "patient_nouknow_id",
      foreignField: "_id",
      as: "patient_nouknow_info",
    },
    PATIENT_HOSPITAL: {
      from: "hospitals",
      localField: "patient_modality_book_info.hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
  },
  USERS: {
    ADMINS: {
      from: "admins",
      localField: "_id",
      foreignField: "_id",
      as: "admin_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENTS_PARENT_USER: {
      from: "users",
      localField: "patient_info.parent_patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENT_NOUKNOWS: {
      from: "patient_nouknows",
      localField: "_id",
      foreignField: "patient_id",
      as: "patient_nouknow_info",
    },
    PATIENT_MODALITY_BOOKS: {
      from: "patient_modality_books",
      localField: "_id",
      foreignField: "patient_id",
      as: "patient_modality_book_info",
    },
    PATIENT_MODALITY_BOOKS_HOSPITALS: {
      from: "hospitals",
      localField: "patient_modality_book_info.hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    PATIENTS_INTERVIEW: {
      from: "patient_interviews",
      localField: "_id",
      foreignField: "patient_id",
      as: "interviews_info",
    },
    PATIENT_INTERVIEW_DOCTOR: {
      from: "patient_interviews",
      localField: "_id",
      foreignField: "doctor_user_id",
      as: "interviews_info",
    },
    PATIENTS_INTERVIEW_USER: {
      from: "users",
      localField: "interviews_info.doctor_user_id",
      foreignField: "_id",
      as: "interview_users_info",
    },
    PATIENT_SUBSCRIPTIONS: {
      from: "patient_subscriptions",
      localField: "_id",
      foreignField: "patient_id",
      as: "patient_subscriptions_info",
    },
    PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS: {
      from: "subscription_plans",
      localField: "patient_subscriptions_info.subscription_plan_id",
      foreignField: "_id",
      as: "subscription_plan_info",
    },
    PATIENTS_ORGANIZATIONS: {
      from: "organizations",
      localField: "patient_info.organization_id",
      foreignField: "_id",
      as: "organization_info",
    },
    PATIENTS_CAMPAIGN_COUPONS: {
      from: "campaign_coupons",
      localField: "patient_info.campaign_coupon_id",
      foreignField: "_id",
      as: "campaign_coupon_info",
    },
  },
  PATIENTS_INTERVIEW: {
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
  },
  EVENT_HISTORIES: {
    USERS: {
      from: "users",
      localField: "registered_user_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  PATIENT_NOUKNOWS: {
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  CHATS: {
    USERS: {
      from: "users",
      localField: "admin_user_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENT_USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  KARTES: {
    USERS: {
      from: "users",
      localField: "commented_user_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  HOSPITALS: {
    HOSPITAL_DEFAULT_SCHEDULES: {
      from: "hospital_default_schedules",
      localField: "_id",
      foreignField: "hospital_id",
      as: "schedule_info",
    },
    HOSPITAL_DAILY_SCHEDULES: {
      from: "hospital_daily_schedules",
      localField: "_id",
      foreignField: "hospital_id",
      as: "schedule_daily_info",
    },
  },
  HOSPITAL_PAYMENTS: {
    HOSPITALS: {
      from: "hospitals",
      localField: "hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  CAMPAIGN_COUPONS: {
    ORGANIZATIONS: {
      from: "organizations",
      localField: "_id",
      foreignField: "campaign_coupon_id",
      as: "organization_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "_id",
      foreignField: "campaign_coupon_id",
      as: "patient_info",
    },
  },
  ORGANIZATIONS: {
    CAMPAIGN_COUPONS: {
      from: "campaign_coupons",
      localField: "campaign_coupon_id",
      foreignField: "_id",
      as: "campaign_coupon_info",
    },
  },
  PATIENT_BRAIN_CHECKS: {
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    ADMIN_USERS: {
      from: "users",
      localField: "commented_user_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
  },
  PATIENT_INVITATIONS: {
    USERS: {
      from: "users",
      localField: "invited_patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "invited_patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENT_SUBSCRIPTIONS: {
      from: "patient_subscriptions",
      localField: "invited_patient_id",
      foreignField: "patient_id",
      as: "subscription_info",
    },
    PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS: {
      from: "subscription_plans",
      localField: "subscription_info.subscription_plan_id",
      foreignField: "_id",
      as: "subscription_plan_info",
    },
  },
};

// queryで取得したCollectionのカラム 0で非表示 1で表示
const PROJECTS: { [key: string]: ProjectStage } = {
  NLC002: {
    _id: 0,
    patient_id: 1,
    full_name_slash: _createFullNameSlashStage(),
    email: 1,
    tel: 1,
    insuranse_entry_date: 1,
    created_at: 1,
    status: 1,
  },
  NLC004: {
    _id: 1,
    user_id: 1,
    nouknowId: "$patient_nouknow_info._id",
    full_name_slash: _createUsersFullNameSlashStage(),
    email: 1,
    tel: "$patient_info.tel",
    score: _createNouKnowRankSlashStage(),
    status: 1,
    reportStatus: "$patient_nouknow_info.status",
    finished_at: _createDateToStringStage(
      "patient_nouknow_info.finished_at",
      "%Y/%m/%d",
    ),
  },
  NLC005: {
    _id: 1,
    full_name_slash: _createFullNameSlashPatientStage(),
    email: "$user_info.email",
    userId: "$user_info._id",
    displayUserId: "$user_info.user_id",
    tel: "$patient_info.tel",
    status: "$user_info.status",
    mriStatus: "$patient_modality_book_info.mri_status",
    mriProblem: "$patient_modality_book_info.mri_problem",
    appointmentDateMRI: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$patient_modality_book_info.fix_book_date_start", null],
          },
          endExists: {
            $ifNull: ["$patient_modality_book_info.fix_book_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$patient_modality_book_info.fix_book_date_start",
                  },
                  endMs: {
                    $toLong: "$patient_modality_book_info.fix_book_date_end",
                  },
                  timeDiffMs: {
                    $subtract: [
                      {
                        $toLong:
                          "$patient_modality_book_info.fix_book_date_end",
                      },
                      {
                        $toLong:
                          "$patient_modality_book_info.fix_book_date_start",
                      },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "patient_modality_book_info.fix_book_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
    hospital: "$hospital_info.hospital_name",
  },
  NLC006: {
    _id: "$patient_modality_book_info._id",
    userId: "$_id",
    displayUserId: "$user_id",
    full_name_slash: _createUsersFullNameSlashStage(),
    email: 1,
    status: 1,
    tel: "$patient_info.tel",
    petStatus: "$patient_modality_book_info.pet_status",
    petProblem: "$patient_modality_book_info.pet_problem",
    appointmentDateMRI: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$patient_modality_book_info.fix_book_date_start", null],
          },
          endExists: {
            $ifNull: ["$patient_modality_book_info.fix_book_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$patient_modality_book_info.fix_book_date_start",
                  },
                  endMs: {
                    $toLong: "$patient_modality_book_info.fix_book_date_end",
                  },
                  timeDiffMs: {
                    $subtract: [
                      {
                        $toLong:
                          "$patient_modality_book_info.fix_book_date_end",
                      },
                      {
                        $toLong:
                          "$patient_modality_book_info.fix_book_date_start",
                      },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "patient_modality_book_info.fix_book_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
    hospital: "$hospital_info.hospital_name",
  },
  NLC007: {
    _id: 1,
    user_id: 1,
    status: 1,
    interviewId: "$interviews_info._id",
    invitationTicketId: "$interviews_info.invitation_ticket_id",
    full_name_slash: _createUsersFullNameSlashStage(),
    tel: "$patient_info.tel",
    email: 1,
    interviewStatus: "$interviews_info.status",
    interviewDate: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$interviews_info.interview_fix_date_start", null],
          },
          endExists: {
            $ifNull: ["$interviews_info.interview_fix_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$interviews_info.interview_fix_date_start",
                  },
                  endMs: { $toLong: "$interviews_info.interview_fix_date_end" },
                  timeDiffMs: {
                    $subtract: [
                      { $toLong: "$interviews_info.interview_fix_date_end" },
                      { $toLong: "$interviews_info.interview_fix_date_start" },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "interviews_info.interview_fix_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
    manager: "$interview_users_info.full_name",
  },
  NLC011: {
    _id: 1,
    date: _createDateToStringStage("finished_at", "%Y/%m/%d"),
    status: 1,
    concentrationScore: "$bpi1_rank",
    memoryScore: "$bpi2_rank",
    inspectionPeriod: {
      $concat: [
        _createDateToStringStage("exam_start", "%Y/%m/%d"),
        "～",
        _createDateToStringStage("exam_end", "%Y/%m/%d"),
      ],
    },
  },
  NLC012: {
    _id: 0,
    date: _createDateToStringStage("finished_at", "%Y/%m/%d"),
    status: 1,
    concentrationScore: "$bpi1_rank",
    concentrationScoreValue: "$bpi1_score",
    memoryScore: "$bpi2_rank",
    memoryScoreValue: "$bpi2_score",
    comment: "$doctor_comment",
    pdfUrl: "$report_path",
  },
  NLC014: {
    _id: 1,
    problem: "$patient_modality_book_info.mri_problem",
    date: "$patient_modality_book_info.fix_book_date_start",
    hospital: "$hospital_info.hospital_name",
    tagLabel: {
      $ifNull: [
        "$patient_modality_book_info.mri_status",
        MriStatusID.ONLINE_MEDICAL_COMPLETED,
      ],
    },
    mriUrl: "$patient_nouknow_info.nouknow_url",
    modalityBookStatus: "$patient_modality_book_info.status",
    modality: "$patient_modality_book_info.status",
    mri_status: "$patient_modality_book_info.mri_status",
    fix_book_date_start: "$patient_modality_book_info.fix_book_date_start",
    fix_book_date_end: "$patient_modality_book_info.fix_book_date_end",
    patient_modality_book_id: "$patient_modality_book_info._id",
    isExitMriData: 1,
    nouknowStatus: "$patient_nouknow_info.status",
    reportPath: "$patient_nouknow_info.report_path",
    brainsuiteReportPath: "$patient_modality_book_info.brainsuite_report_path",
    term: 1,
    maxTerm: 1,
    created_at: "$patient_modality_book_info.created_at",
  },
  NLC015: {
    _id: 0,
    status: "$mri_status",
    date: _createDateToStringStage("fix_book_date_start", "%Y/%m/%d"),
    doctor: "$hospital_user_info.full_name",
    doctorReview: "$doctor_review",
    doctorNote: "$doctor_note",
    problem: "$mri_problem",
    interpretationNote: "$interpretation_note",
    studyUid: "$study_uid",
  },
  NLC017: {
    _id: 1,
    date: _createDateToStringStage("fix_book_date_start", "%Y/%m/%d"),
    hospital: "$hospital_info.hospital_name",
    tagLabel: "$pet_status",
    servicePrice: "$hospital_info.service_price",
    fix_book_date_start: "$fix_book_date_start",
    fix_book_date_end: "$fix_book_date_end",
    created_at: "$created_at",
  },
  NLC019: {
    _id: 1,
    payment_date: {
      $cond: {
        if: { $gt: ["$payment_date", null] },
        then: _createDateToStringStage("payment_date", "%Y/%m/%d"),
        else: "-",
      },
    },
    authorization_date: {
      $cond: {
        if: { $gt: ["$authorization_date", null] },
        then: _createDateToStringStage("authorization_date", "%Y/%m/%d"),
        else: "-",
      },
    },
    amount: 1,
    category: 1,
    subscription_span: {
      $cond: {
        if: { $gt: ["$patient_subscription_id", null] },
        then: "$subscription_plan_info.span",
        else: null,
      },
    },
    status: 1,
    stripe_payment_intent: 1,
  },
  NLC020_INTERVIEW: {
    _id: 0,
    type: BookTypeID.INTERVIEW,
    id: "$interviews_info._id",
    invitationTicketId: "$interviews_info.invitation_ticket_id",
    content: "面談",
    hospital: "ドクター",
    status: "$interviews_info.status",
    date: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$interviews_info.interview_fix_date_start", null],
          },
          endExists: {
            $ifNull: ["$interviews_info.interview_fix_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$interviews_info.interview_fix_date_start",
                  },
                  endMs: { $toLong: "$interviews_info.interview_fix_date_end" },
                  timeDiffMs: {
                    $subtract: [
                      { $toLong: "$interviews_info.interview_fix_date_end" },
                      { $toLong: "$interviews_info.interview_fix_date_start" },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "interviews_info.interview_fix_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
    orderDate: "$interviews_info.interview_fix_date_start",
  },
  NLC020_MODALITY: {
    _id: 0,
    type: BookTypeID.MODALITY,
    id: "$patient_modality_book_info._id",
    content: "$patient_modality_book_info.modality",
    hospital: "$hospital_info.hospital_name",
    status: "$patient_modality_book_info.status",
    mriStatus: "$patient_modality_book_info.mri_status",
    petStatus: "$patient_modality_book_info.pet_status",
    date: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$patient_modality_book_info.fix_book_date_start", null],
          },
          endExists: {
            $ifNull: ["$patient_modality_book_info.fix_book_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$patient_modality_book_info.fix_book_date_start",
                  },
                  endMs: {
                    $toLong: "$patient_modality_book_info.fix_book_date_end",
                  },
                  timeDiffMs: {
                    $subtract: [
                      {
                        $toLong:
                          "$patient_modality_book_info.fix_book_date_end",
                      },
                      {
                        $toLong:
                          "$patient_modality_book_info.fix_book_date_start",
                      },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "patient_modality_book_info.fix_book_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
    orderDate: "$patient_modality_book_info.fix_book_date_start",
  },
  NLC023_ORDERS: {
    _id: 1,
    status: 1,
    total_amount: 1,
    updated_at: 1,
    settlement_time: _createDateToStringStage("settlement_time"),
  },
  NLC028: {
    _id: 0,
    status: "$pet_status",
    date: _createDateToStringStage("fix_book_date_start", "%Y/%m/%d"),
    doctor: "$hospital_user_info.full_name",
    doctorReview: "$doctor_review",
    doctorNote: "$doctor_note",
    problem: "$pet_problem",
    interpretationNote: "$interpretation_note",
    // 2次以降フェーズ対応: 他の値
    images: [],
  },
  NLC029: {
    _id: 1,
    userId: "$user_info._id",
    displayUserId: "$user_info.user_id",
    name: _createFullNameSlashPatientStage(),
    status: 1,
    userStatus: "$user_info.status",
    examStartTermStr: {
      $dateToString: { format: "%Y/%m/%d", date: "$upload_date" },
    },
    examTermStr: {
      $concat: [
        { $dateToString: { format: "%Y/%m/%d", date: "$exam_term.start" } },
        "〜",
        { $dateToString: { format: "%Y/%m/%d", date: "$exam_term.end" } },
      ],
    },
    tel: "$patient_info.tel",
    email: "$user_info.email",
  },
  NLC030: {
    _id: 1,
    examStartDate: _createDateToStringStage("exam_start_date", "%Y/%m/%d"),
    status: 1,
  },
  NLC031: {
    _id: 0,
    examStartDate: _createDateToStringStage("exam_start_date", "%Y/%m/%d"),
    status: 1,
    reportPath: "$report_path",
    note: 1,
    commentedUserName: "$user_info.full_name",
  },
  NLC033: {
    _id: 1,
    patient_id: 1,
    full_name_slash: _createFullNameSlashPatientStage(),
    comment: 1,
    commented_user: 1,
    register_date: _createDateToStringStage("register_date", "%Y/%m/%d %H:%M"),
    is_read: 1,
    check_list: 1,
    user_id: "$user_info.user_id",
  },
  NLC035: {
    _id: 1,
    full_name_slash: _createUsersFullNameSlashStage(),
    patient_type: "$patient_info.patient_type",
    status: 1,
    tel: "$patient_info.tel",
    email: 1,
    user_id: 1,
    line_id: 1,
    google_id: 1
  },
  NLD001: {
    _id: 0,
    patient_id: 1,
    full_name_slash: _createFullNameSlashStage(),
    tel: 1,
    email: 1,
    // 2次以降フェーズ対応: No162 ステータス、検査、金額、支払い期日、支払い日、FDG PET検査日
  },
  NLD002: {
    _id: 1,
    hospital_id: 1,
    name: _createFullNameSlashPatientStage(),
    examination: 1,
    amount: 1,
    exam_date: 1,
    hospital_name: "$hospital_info.hospital_name",
    prefecture: "$hospital_info.prefecture",
  },
  NLD003: {
    _id: 1,
    name: _createFullNameSlashPatientStage(),
    examination: 1,
    amount: 1,
    exam_date: 1,
  },
  NLG001: {
    _id: 1,
    title: 1,
    status: 1,
    deliveried_at: _createDateToStringStage("deliveried_at"),
    last_updated_at: _createDateToStringStage("last_updated_at"),
    full_name: "$user_info.full_name",
    last_updated_admin_id: 1,
  },
  NLE001: {
    _id: 1,
    vimeo_id: 1,
    yose_title: 1,
    cast: 1,
    status: 1,
    streaming_date: 1,
    streaming_end_date: 1,
    new_period: 1,
    is_suspend: 1,
    updated_at: 1,
  },
  ADMIN: {
    _id: 1,
    full_name: 1,
  },
  NLD004: {
    _id: 1,
    patient_id: 1,
    user_id: "$user_info.user_id",
    category: 1,
    full_name_slash: _createFullNameSlashPatientStage(),
    amount: 1,
    payment_date: _createDateToStringStage("payment_date", "%Y/%m/%d"),
    authorization_date: _createDateToStringStage(
      "authorization_date",
      "%Y/%m/%d",
    ),
    tel: "$patient_info.tel",
    email: "$user_info.email",
    subscription_span: "$subscription_plan_info.span",
    status: 1,
  },
  NLD005: {
    _id: 1,
    patient_id: 1,
    user_id: "$user_info.user_id",
    category: 1,
    full_name_slash: _createFullNameSlashPatientStage(),
    amount: 1,
    receipt_issue_date: _createDateToStringStage("receipt_issue_date"),
    authorization_date: _createDateToStringStage(
      "authorization_date",
      "%Y/%m/%d",
    ),
    payment_date: _createDateToStringStage("payment_date", "%Y/%m/%d"),
    subscription_span: "$subscription_plan_info.span",
  },
  NLF001: {
    _id: 1,
    status: 1,
    total_amount: 1,
    updated_at: 1,
    delivery_date: _createDateToStringStage(
      "desired_delivery_date",
      "%Y/%m/%d",
    ),
    patient_name: _createFullNameSpaceStage(),
    settlement_time: _createDateToStringStage("settlement_time"),
    delivery_method: 1,
    user_id: "$user_info.user_id",
  },
  ALL_ORDER_YEAR: {
    _id: 0,
    settlement_time: 1,
  },
  NLF005: {
    _id: 1,
    status: 1,
    consumption_tax_amount: 1,
    settlement_time: _createDateToStringStage("settlement_time", "%Y/%m/%d"),
    delivery_method: 1,
    delivery_date: _createDateToStringStage(
      "desired_delivery_date",
      "%Y/%m/%d",
    ),
    delivery_fee: 1,
    total_item_amount: 1,
    total_amount: 1,
    patient_name: _createFullNameSpaceStage(),
    patient_tel: "$patient_info.tel",
    patient_email: "$user_info.email",
    order_zipcode: 1,
    order_address: 1,
    total_amount_per_tax: 1,
    transactions_info: {
      $map: {
        input: "$transactions_info",
        as: "transaction",
        in: {
          _id: "$$transaction._id",
          quantity: "$$transaction.quantity",
          total_amount: "$$transaction.total_amount",
          sku_name: "$$transaction.sku_name",
          item_name: "$$transaction.item_name",
          consumption_tax_rate: "$$transaction.consumption_tax_rate",
          image_urls: "$$transaction.skus_info.image_urls",
        },
      },
    },
  },
  NLF006: {
    payment_date_id: {
      $dateToString: { format: "%Y%m", date: "$payment_date" },
    },
    payment_date: {
      $dateToString: { format: "%Y年%m月", date: "$payment_date" },
    },
    amount: 1,
  },
  NLF007: {
    _id: 1,
    order_id: 1,
    amount: 1,
    settlement_time: _createDateToStringStage("orders_info.settlement_time"),
    patient_name: _createFullNameSpaceStage(),
  },
  ALL_SALES_YEAR: {
    _id: 0,
    payment_date: 1,
  },
  ONE_PATIENT: {
    _id: 1,
    user_id: 1,
    full_name_slash: _createUsersFullNameSlashStage(),
    email: 1,
    google_id: 1,
    line_id: 1,
    tel: "$patient_info.tel",
    birth: _createBirthdaySlashStage("$patient_info"),
    gender: "$patient_info.gender",
    evaluation: "$patient_info.evaluation",
    is_enabled_modality: "$patient_info.is_enabled_modality",
    is_notification: "$patient_info.is_notification",
    past_diagnosis: "$patient_info.past_diagnosis",
    patient_type: "$patient_info.patient_type",
    plan_name: "$subscription_plan_info.plan_name",
    price: "$subscription_plan_info.price",
    span: "$subscription_plan_info.span",
    zipcode: "$patient_info.zipcode",
    pref: "$patient_info.pref",
    city: "$patient_info.city",
    town1: "$patient_info.town1",
    town2: "$patient_info.town2",
    name_sei: 1,
    name_mei: 1,
    name_sei_kana: 1,
    name_mei_kana: 1,
    birth_year: "$patient_info.birth_year",
    birth_month: "$patient_info.birth_month",
    birth_day: "$patient_info.birth_day",
    status: 1,
    organization_name: "$organization_info.organization_name",
    activation_date: _createDateToStringStage("activation_date", "%Y/%m/%d"),
    campaign_code: "$campaign_coupon_info.campaign_code",
    campaign_name: "$campaign_coupon_info.campaign_name",
    discount_rate: "$campaign_coupon_info.discount_rate",
    discount_price: "$campaign_coupon_info.discount_price",
    payment_type: "$campaign_coupon_info.payment_type",
    payment_span: "$campaign_coupon_info.payment_span",
    term: "$campaign_coupon_info.term",
    cancel_period: _createDateToStringStage(
      "campaign_coupon_info.cancel_period",
      "%Y/%m/%d",
    ),
    cancellation_date: _createDateToStringStage(
      "patient_info.cancellation_date",
      "%Y/%m/%d",
    ),
    canceled_after_campaign: "$campaign_coupon_info.canceled_after_campaign",
    parent_user_id: "$user_info.user_id",
    parent_user_name: _createFullNameSlashPatientStage(),
    parent_name_sei: "$user_info.name_sei",
    parent_name_mei: "$user_info.name_mei",
  },
  ONE_NOTIFICATION: {
    _id: 1,
    title: 1,
    detail: 1,
    image_url: 1,
    status: 1,
    deliveried_at: _createDateToStringStage("deliveried_at"),
    last_updated_at: _createDateToStringStage("last_updated_at"),
    full_name: "$user_info.full_name",
  },
  ONE_HOSPITAL: {
    _id: 1,
    hospital_id: "$_id",
    hospital_name: 1,
    prefecture: 1,
    address: 1,
    tel: 1,
    email: 1,
    has_mri: 1,
    has_pet: 1,
    has_brain_health_check: 1,
    has_nouknow: 1,
    service_price: 1,
  },
  NLH001: {
    _id: 1,
    embedded_page: 1,
    embedded_location: 1,
    title: 1,
    url: 1,
    pc_image: "$pc_image_url",
    status: 1,
    delivery_date: _createDateToStringStage("delivery_date"),
    updated_at: _createDateToStringStage("updated_at"),
    "user_info.full_name": 1,
    is_registered: 1,
  },
  NLH003: {
    _id: 0,
    title: 1,
    embedded_page: 1,
    embedded_location: 1,
    pc_image: "$pc_image_url",
    sp_image: "$sp_image_url",
    status: 1,
    delivery_date: _createDateToStringStage("delivery_date"),
    is_limited: 1,
    suspend_date: _createDateToStringStage("suspend_date"),
    url: 1,
  },
  NLI003: {
    _id: 1,
    full_name: 1,
    email: 1,
    user_id: 1,
    admin_role: 1,
    status: 1,
  },
  NLI005: {
    _id: 1,
    user_id: 1,
    full_name: 1,
  },
  NLI007: {
    _id: 1,
    item_code: 1,
    item_name: 1,
    sort: 1,
    status: 1,
    item_tags_info: 1,
    skuCount: {
      $size: {
        $filter: {
          input: "$skus_info",
          as: "sku",
          cond: { $ne: ["$$sku.status", ItemsSkusStatusID.DELETED] },
        },
      },
    },
    image_urls: 1,
  },
  NLI009: {
    _id: 1,
    sku_code: 1,
    images: "$image_urls",
    sku_name: 1,
    "items_info.item_name": 1,
    amount: 1,
    stock: 1,
    item_tags_info: 1,
    status: 1,
    sort: 1,
  },
  NLI011: {
    _id: 1,
    item_code: 1,
    item_name: 1,
    item_tags_info: 1,
    item_description: 1,
    images: "$image_urls",
    skus_info: 1,
    consumption_tax_rate: 1,
    "option_configs_info.option_title": 1,
    "option_configs_info._id": 1,
    conditions: 1,
    range: 1,
  },
  NLI016: {
    _id: 1,
    hospital_name: 1,
    tel: 1,
    prefecture: 1,
    address: 1,
  },
  NLI017: {
    _id: 1,
    user_id: 1,
    hospital_id: 1,
    full_name: 1,
    email: 1,
    status: 1,
  },
  NLI019: {
    _id: 1,
    organization_name: 1,
    tel: 1,
    address: 1,
  },
  NLI033: {
    _id: 1,
    campaign_code: 1,
    campaign_name: 1,
    payment_type: 1,
    payment_span: 1,
    discount_rate: 1,
    discount_price: 1,
    term: 1,
    limit: 1,
    cancel_period: _createDateToStringStage("cancel_period", "%Y/%m/%d"),
    created_at: 1,
    organization_name: "$organization_info.organization_name",
    patient_id: "$patient_info._id",
    canceled_after_campaign: 1,
  },
  ONE_ITEM: {
    _id: 1,
    item_code: 1,
    item_name: 1,
    item_description: 1,
    images: "$image_urls",
    tag: 1,
    status: 1,
    item_tags_info: 1,
  },
  ONE_SKU: {
    _id: 0,
    sku_code: 1,
    sku_name: 1,
    images: "$image_urls",
    amount: 1,
    stock: 1,
    status: 1,
    sort: 1,
    item_id: 1,
    consumption_tax_rate: 1,
  },
  OPTIONS: {
    _id: 1,
    option_title: 1,
  },
  ITEM_TAGS: {
    _id: 1,
    tag_name: 1,
  },
  MIB001: {
    _id: 1,
    patient_name: _createFullNameSlashPatientStage(),
    patient_id: 1,
    modality: 1,
    patient_tel: "$patient_info.tel",
    created_at: _createDateToStringStage("created_at", "%Y/%m/%d"),
    created_at_db: "$created_at",
    status: 1,
    fix_book_date_start: {
      $ifNull: [
        _createDateToStringStage("fix_book_date_start", "%Y/%m/%d %H:%M"),
        "",
      ],
    },
    fix_book_date_db: "$fix_book_date_start",
    patient_gender: "$patient_info.gender",
    patient_birthday: _createBirthdaySlashStage("$patient_info"),
    patient_email: "$user_info.email",
    user_id: "$user_info.user_id",
    note: 1,
    candidate_book_dates: {
      first: {
        $cond: [
          { $eq: ["$candidate_book_dates.first", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_book_dates.first",
              timezone: JST,
            },
          },
        ],
      },
      second: {
        $cond: [
          { $eq: ["$candidate_book_dates.second", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_book_dates.second",
              timezone: JST,
            },
          },
        ],
      },
      third: {
        $cond: [
          { $eq: ["$candidate_book_dates.third", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_book_dates.third",
              timezone: JST,
            },
          },
        ],
      },
      fourth: {
        $cond: [
          { $eq: ["$candidate_book_dates.fourth", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_book_dates.fourth",
              timezone: JST,
            },
          },
        ],
      },
      fifth: {
        $cond: [
          { $eq: ["$candidate_book_dates.fifth", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_book_dates.fifth",
              timezone: JST,
            },
          },
        ],
      },
    },
  },
  MIC001: {
    _id: 1,
    patient_name: _createFullNameSlashPatientStage(),
    patient_id: "$patient_info._id",
    status: 1,
    patient_tel: "$patient_info.tel",
    patient_email: "$user_info.email",
    user_id: "$user_info.user_id",
    modality: 1,
    fix_book_date_start: 1,
    fix_book_date_range: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$fix_book_date_start", null],
          },
          endExists: {
            $ifNull: ["$fix_book_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$fix_book_date_start",
                  },
                  endMs: {
                    $toLong: "$fix_book_date_end",
                  },
                  timeDiffMs: {
                    $subtract: [
                      {
                        $toLong: "$fix_book_date_end",
                      },
                      {
                        $toLong: "$fix_book_date_start",
                      },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "fix_book_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
  },
  MID001: {
    patient_name: 1,
    fix_book_date_start: 1,
    name_sei_kana: 1,
    tel: 1,
    email: 1,
    user_id: 1,
    lastReservationObj: {
      $arrayElemAt: [
        {
          $filter: {
            input: "$details",
            as: "detail",
            cond: {
              $eq: ["$$detail.fix_book_date_start", "$fix_book_date_start"],
            },
          },
        },
        0,
      ],
    },
  },
  MID002_USERS: {
    _id: 1,
    user_id: 1,
    full_name_slash: _createUsersFullNameSlashStage(),
    email: 1,
    gender: "$patient_info.gender",
    tel: "$patient_info.tel",
    birthDay: _createBirthdaySlashStage("$patient_info"),
  },
  MID002_MODALITY: {
    _id: 1,
    modality: 1,
    exam_date: {
      $ifNull: [
        _createDateToStringStage("fix_book_date_start", "%Y/%m/%d"),
        "",
      ],
    },
    attending_hospital_user_id: 1,
    mri_dicom_image_path: 1,
    doctor_review: 1,
    doctor_note: 1,
    hospital_name: "$hospital_info.hospital_name",
    hospital_id: 1,
    mri_status: 1,
    pet_status: 1,
    contraindications: 1,
    mri_precautions: 1,
    pet_precautions: 1,
    status: {
      $cond: {
        if: { $eq: ["$modality", ModalityID.MRI] },
        then: {
          $cond: {
            if: {
              $in: ["$mri_status", [MriStatusID.RESERVATION_CONFIRMED]],
            },
            then: HospitalInspectionStatusID.NOT_INSPECTED,
            else: HospitalInspectionStatusID.INSPECTED,
          },
        },
        else: {
          $cond: {
            if: { $eq: ["$modality", ModalityID.PET] },
            then: {
              $cond: {
                if: {
                  $in: ["$pet_status", [PetStatusID.RESERVATION_CONFIRMED]],
                },
                then: HospitalInspectionStatusID.NOT_INSPECTED,
                else: HospitalInspectionStatusID.INSPECTED,
              },
            },
            else: 99,
          },
        },
      },
    },
    fix_book_date_range: {
      $let: {
        vars: {
          startExists: {
            $ifNull: ["$fix_book_date_start", null],
          },
          endExists: {
            $ifNull: ["$fix_book_date_end", null],
          },
        },
        in: {
          $cond: [
            {
              $or: [
                { $eq: ["$$startExists", null] },
                { $eq: ["$$endExists", null] },
              ],
            },
            "-",
            {
              $let: {
                vars: {
                  startMs: {
                    $toLong: "$fix_book_date_start",
                  },
                  endMs: {
                    $toLong: "$fix_book_date_end",
                  },
                  timeDiffMs: {
                    $subtract: [
                      {
                        $toLong: "$fix_book_date_end",
                      },
                      {
                        $toLong: "$fix_book_date_start",
                      },
                    ],
                  },
                },
                in: _createDateToStringWithWeekStage(
                  "fix_book_date_start",
                  "$$timeDiffMs",
                ),
              },
            },
          ],
        },
      },
    },
  },
  MID002_HOSPITAL_USER: {
    _id: 1,
    user_id: 1,
    full_name: 1,
  },
  MIE001: {
    _id: 1,
    hospital_id: "$_id",
    hospital_name: 1,
    prefecture: 1,
    address: 1,
    tel: 1,
    mri_interval: 1,
    pet_interval: 1,
    email: 1,
    hasMri: 1,
    hasPet: 1,
    mri_open_am: 1,
    mri_open_pm: 1,
    pet_open_am: 1,
    pet_open_pm: 1,
    schedule_info: "$schedule_info",
    schedule_daily_info: "$schedule_daily_info",
  },
  HOSPITAL_TIME_INTERVAL: {
    _id: 1,
    mri_interval: 1,
    pet_interval: 1,
  },
  EVENT_HISTORIES: {
    _id: 1,
    description: 1,
    priority: 1,
    registered_date: 1,
    registered_date_display: _createDateToStringStage(
      "registered_date",
      "%Y/%m/%d",
    ),
    registered_user_name: "$user_info.full_name",
  },
  NLB001_TASK: {
    _id: 1,
    patient_id: 1,
    patient_name: _createFullNameSpaceStage(),
    category: 1,
    updated_at: _createDateToStringStage("updated_at", "%Y/%m/%d"),
  },
  ADMIN_ROLE: {
    _id: 0,
    roleKey: "$role_sort_no",
    auth: {
      [AdminsRoleID.ADMIN]: "$has_admin",
      [AdminsRoleID.CMC]: "$has_doctor",
      [AdminsRoleID.PUBLIC_USER]: "$has_user",
    },
  },
  NLC021: {
    _id: 1,
    status: 1,
    name: _createFullNameSlashPatientStage(),
    created_at: _createDateToStringStage("created_at", "%Y/%m/%d"),
    birth: _createBirthdaySlashStage("$patient_info"),
    gender: "$patient_info.gender",
    tel: "$patient_info.tel",
    email: "$user_info.email",
    zoom_start_link: 1,
    interview_fix_date_start: _createDateToStringStage(
      "interview_fix_date_start",
    ),
    interview_fix_date_end: _createDateToStringStage("interview_fix_date_end"),
    interview_fix_date_start_date: "$interview_fix_date_start",
    interview_fix_date_end_date: "$interview_fix_date_end",
    doctor_user_id: 1,
    doctor_name: 1,
    doctor_zoom_user_id: 1,
    patient_id: 1,
    invitation_ticket_id: 1,
    candidate_interview_dates: {
      first: {
        $cond: [
          { $eq: ["$candidate_interview_dates.first", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_interview_dates.first",
              timezone: JST,
            },
          },
        ],
      },
      second: {
        $cond: [
          { $eq: ["$candidate_interview_dates.second", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_interview_dates.second",
              timezone: JST,
            },
          },
        ],
      },
      third: {
        $cond: [
          { $eq: ["$candidate_interview_dates.third", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_interview_dates.third",
              timezone: JST,
            },
          },
        ],
      },
      fourth: {
        $cond: [
          { $eq: ["$candidate_interview_dates.fourth", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_interview_dates.fourth",
              timezone: JST,
            },
          },
        ],
      },
      fifth: {
        $cond: [
          { $eq: ["$candidate_interview_dates.fifth", null] },
          "",
          {
            $dateToString: {
              format: "%Y/%m/%d %H:%M",
              date: "$candidate_interview_dates.fifth",
              timezone: JST,
            },
          },
        ],
      },
    },
  },
  ADMIN_DOCTOR: {
    _id: 1,
    full_name: 1,
    email: 1,
    interview_fix_date: {
      $map: {
        input: "$interviews_info",
        as: "interview",
        in: {
          $cond: {
            if: { $ne: ["$$interview.interview_fix_date_start", null] },
            then: {
              $dateToString: {
                format: "%Y/%m/%d %H:%M",
                date: "$$interview.interview_fix_date_start",
                timezone: JST,
              },
            },
            else: "",
          },
        },
      },
    },
  },
  CHATS: {
    _id: 1,
    comment: 1,
    patient_id: 1,
    admin_user_id: 1,
    commented_user: 1,
    register_date: 1,
    files: 1,
    monthly_report: 1,
    is_read: 1,
    created_at: 1,
    file_name: 1,
    file_gcs_path: 1,
    admin_user_name: "$user_info.full_name",
  },
  KARTES: {
    _id: 1,
    description: 1,
    created_at: 1,
    admin_user_name: "$user_info.full_name",
  },
  SALES_TOTAL_AMOUNT: {
    totalAmount: 1,
  },
  STRIPE_SUBSCRIPTION: {
    _id: 0,
    stripeSubscriptionId: "$patient_subscriptions_info.stripe_subscription_id",
  },
  NOT_SET_CAMPAIGN_COUPONS: {
    _id: 1,
    campaignName: "$campaign_name",
    term: 1,
    createdAt: "$created_at",
    payment_span: 1,
  },
  ONE_ORGANIZATION: {
    _id: 1,
    organization_name: 1,
    tel: 1,
    address: 1,
    campaign_coupon_id: 1,
    campaign_code: "$campaign_coupon_info.campaign_code",
    campaign_name: "$campaign_coupon_info.campaign_name",
    payment_type: "$campaign_coupon_info.payment_type",
    discount_rate: "$campaign_coupon_info.discount_rate",
    discount_price: "$campaign_coupon_info.discount_price",
    term: "$campaign_coupon_info.term",
    limit: "$campaign_coupon_info.limit",
  },
  NLI021: {
    _id: 1,
    name: _createFullNameSlashPatientStage(),
    status: "$user_info.status",
    activation_date: _createDateToStringStage(
      "user_info.activation_date",
      "%Y/%m/%d",
    ),
    tel: 1,
    email: "$user_info.email",
    user_id: "$user_info.user_id",
  },
  CHILD_PATIENT: {
    _id: 1,
    user_id: "$user_info.user_id",
    name: _createFullNameSlashPatientStage(),
    status: "$user_info.status",
    activationDate: _createDateToStringStage(
      "user_info.activation_date",
      "%Y/%m/%d",
    ),
    patientType: "$patient_info.patient_type",
    planName: "$subscription_plan_info.plan_name",
    price: "$subscription_plan_info.price",
    span: "$subscription_plan_info.span",
    stripeSubscriptionId: "$subscription_info.stripe_subscription_id",
    is_enabled_modality: "$patient_info.is_enabled_modality",
  },
  SHIPPINGS: {
    _id: 1,
    companyName: "$company_name",
    taxCalculationType: "$tax_calculation_type",
    deliveryTimes: "$delivery_times",
    deliveryFee: "$delivery_fee",
    deliveryCondition: "$delivery_condition",
  },
  MBI002: {
    _id: 1,
    contraindications: 1,
    pet_precautions: 1,
    mri_precautions: 1,
  },
  MRI_HOSPITALS: {
    _id: 1,
    hospital_name: 1,
    mri_interval: 1,
  },
  PET_HOSPITALS: {
    _id: 1,
    hospital_name: 1,
    pet_interval: 1,
  },
  NLI003_INTERVIEW: {
    _id: 1,
  },
};

const GROUP: { [key: string]: GroupStage } = {
  NLF006: {
    _id: "$payment_date_id",
    payment_date: { $first: "$payment_date" },
    count: { $sum: 1 },
    totalAmount: { $sum: "$amount" },
  },
  MID001: {
    _id: "$patient_id",
    patient_name: { $first: _createFullNameSlashPatientStage() },
    name_sei_kana: { $first: "$user_info.name_sei_kana" },
    fix_book_date_start: { $max: "$fix_book_date_start" },
    tel: { $first: "$patient_info.tel" },
    email: { $first: "$user_info.email" },
    user_id: { $first: "$user_info.user_id" },
    details: {
      $push: {
        fix_book_date_start: "$fix_book_date_start",
        fix_book_date_end: "$fix_book_date_end",
        doc_id: "$_id",
      },
    },
  },
  SALES_TOTAL_AMOUNT: {
    _id: "",
    totalAmount: { $sum: "$amount" },
  },
  NLI033: {
    _id: "$_id",
    campaign_code: { $first: "$campaign_code" },
    campaign_name: { $first: "$campaign_name" },
    payment_type: { $first: "$payment_type" },
    payment_span: { $first: "$payment_span" },
    discount_rate: { $first: "$discount_rate" },
    discount_price: { $first: "$discount_price" },
    term: { $first: "$term" },
    limit: { $first: "$limit" },
    cancel_period: { $first: "$cancel_period" },
    created_at: { $first: "$created_at" },
    organization_name: { $first: "$organization_name" },
    usageLimit: { $first: "$patient_id" },
    canceled_after_campaign: { $first: "$canceled_after_campaign" },
  },
};

// TransactionsテーブルからSkusテーブル、Itemsテーブルまでのlookup関数
const lookupTransactionToSkusItems = (): LookupStage => ({
  from: "skus",
  let: { sku_id: "$sku_id" },
  pipeline: [
    { $match: { $expr: { $eq: ["$_id", "$$sku_id"] } } },
    {
      $lookup: {
        from: "items",
        localField: "item_id",
        foreignField: "_id",
        as: "items_info",
      },
    },
  ],
  as: "skus_info",
});

// ordersテーブルからTransactionsテーブルまでのlookup関数
const lookupOrderToTransactions = (): LookupStage => ({
  from: "transactions",
  let: { order_id: "$_id" },
  pipeline: [
    { $match: { $expr: { $eq: ["$order_id", "$$order_id"] } } },
    { $lookup: lookupTransactionToSkusItems() },
  ],
  as: "transactions_info",
});

// メインタブのユーザ管理の通知数
export const getSearchConditionsAdminMainTabUserMgmt = () => {
  const searchConditions: MatchStage = {
    status: { $in: [PatientStatusID.UNSUPPORTED] },
  };

  return searchConditions;
};

// 医療機関メインタブの予約受付の通知数
export const getSearchConditionsHospitalMainTabReservation = () => {
  const searchConditions: MatchStage = {
    status: { $in: [HospitalReservationStatusID.NEW] },
  };

  return searchConditions;
};

// 注文履歴タブの支払い完了の数
export const getSearchConditionsAdminUserDetailOrder = () => {
  const searchConditions: MatchStage = {
    status: { $in: [OrderStatusID.PAYMENT_COMPLETED] },
  };

  return searchConditions;
};

// テスト状況のタブのテスト終了の数
export const getSearchConditionsAdminUserDetailNouknow = () => {
  const searchConditions: MatchStage = {
    status: {
      $in: [PatientNouKnowStatusID.UPLOADED, PatientNouKnowStatusID.CONFIRMED],
    },
  };

  return searchConditions;
};

// 予約状況タブの新規の数
export const getSearchConditionsAdminUserDetailInterviews = () => {
  const searchConditions: MatchStage = {
    status: { $in: [PatientInterviewStatusID.NEW_RESERVATION] },
  };

  return searchConditions;
};

// データ取得の取得条件
export const getAggregateNlc002 = (
  id: string,
  name: string,
  tel: string,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    patient_id: id,
    full_name: name,
    tel,
  });
  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions("status", statusArr);
  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...checkConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "patient_id",
    "asc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC002,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc004 = (
  id: string,
  name: string,
  tel: string,
  startExamDate: Date | undefined,
  endExamDate: Date | undefined,
  userStatusArr: Array<number>,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createNlcInputConditions({ id, name, tel });
  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions(
    "patient_nouknow_info.status",
    statusArr,
  );
  // 会員ステータス チェックボックスの検索条件
  const userStatusCheckConditions = _createCheckConditions(
    "status",
    userStatusArr,
  );
  // 受検期間
  const rangeConditions = _createDateRangeConditions(
    "patient_nouknow_info.finished_at",
    startExamDate,
    endExamDate,
  );

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...checkConditions,
    ...userStatusCheckConditions,
    ...rangeConditions,
    user_type: UserTypeID.PATIENT,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "patient_nouknow_info.finished_at",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC004,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $lookup: LOOK_UP.USERS.PATIENT_NOUKNOWS },
    { $unwind: "$patient_nouknow_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc005 = (
  id: string,
  name: string,
  tel: string,
  startExamDate: Date | undefined,
  endExamDate: Date | undefined,
  hospital: string,
  userStatusArr: Array<number>,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "user_info.user_id": id,
    "user_info.full_name": name,
    "patient_info.tel": tel,
    "patient_modality_book_info.hospital_id": hospital,
  });

  // チェックボックスの検索条件
  const checkStatusConditions = {
    $or: [
      { "patient_modality_book_info.mri_status": { $in: statusArr } },
      ...(statusArr.includes(MriStatusID.ONLINE_MEDICAL_COMPLETED)
        ? [{ "patient_modality_book_info.mri_status": { $exists: false } }]
        : []),
    ],
  };

  // 会員ステータス チェックボックスの検索条件
  const userStatusCheckConditions = _createCheckConditions(
    "user_info.status",
    userStatusArr,
  );

  // 予約日
  const rangeConditions = _createDateRangeConditions(
    "patient_modality_book_info.fix_book_date_start",
    startExamDate,
    endExamDate,
  );

  const searchConditions = {
    ...inputConditions,
    ...checkStatusConditions,
    ...userStatusCheckConditions,
    ...rangeConditions,
    online_test_status: onlineTestStatus.done,
    "patient_modality_book_info.modality": {
      $not: {
        $in: [ModalityID.PET],
      },
    },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "patient_modality_book_info.created_at",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC005,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENT_MODALITY_BOOK_INFO },
    {
      $unwind: {
        path: "$patient_modality_book_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENT_NOUKNOWS_INFO },
    {
      $unwind: {
        path: "$patient_nouknow_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENT_HOSPITAL },
    {
      $unwind: {
        path: "$hospital_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.USERS },
    {
      $unwind: {
        path: "$user_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENTS },
    {
      $unwind: {
        path: "$patient_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $addFields: {
        isExitMriData: {
          $cond: {
            if: { $gt: ["$patient_modality_book_info._id", null] },
            then: true,
            else: false,
          },
        },
      },
    },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc006 = (
  id: string,
  name: string,
  tel: string,
  startExamDate: Date | undefined,
  endExamDate: Date | undefined,
  hospital: string,
  userStatusArr: Array<number>,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createNlcInputConditions({ id, name, tel });

  const dropdownConditions = _createInputConditions({
    "patient_modality_book_info.hospital_id": hospital,
  });
  // チェックボックスの検索条件
  const checkStatusConditions = _createCheckConditions(
    "patient_modality_book_info.pet_status",
    statusArr,
  );
  // 会員ステータス チェックボックスの検索条件
  const userStatusCheckConditions = _createCheckConditions(
    "status",
    userStatusArr,
  );
  // 予約日
  const rangeConditions = _createDateRangeConditions(
    "patient_modality_book_info.fix_book_date_start",
    startExamDate,
    endExamDate,
  );
  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...dropdownConditions,
    ...checkStatusConditions,
    ...userStatusCheckConditions,
    ...rangeConditions,
    user_type: UserTypeID.PATIENT,
    "patient_modality_book_info.modality": ModalityID.PET,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "patient_modality_book_info.created_at",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC006,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $lookup: LOOK_UP.USERS.PATIENT_MODALITY_BOOKS },
    { $unwind: "$patient_modality_book_info" },
    { $lookup: LOOK_UP.USERS.PATIENT_MODALITY_BOOKS_HOSPITALS },
    { $unwind: "$hospital_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc007 = (
  id: string,
  name: string,
  tel: string,
  startExamDate: Date | undefined,
  endExamDate: Date | undefined,
  manager: string,
  interviewTypeArr: Array<string>,
  userStatusArr: Array<number>,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createNlcInputConditions({ id, name, tel });
  // 担当医の検索条件
  const interviewDoctorUserId = manager === "すべて" ? null : manager;
  const selectDoctorConditions = _createInputConditions({
    "interviews_info.doctor_user_id": interviewDoctorUserId,
  });
  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions(
    "interviews_info.status",
    statusArr,
  );
  // 会員ステータス チェックボックスの検索条件
  const userStatusCheckConditions = _createCheckConditions(
    "status",
    userStatusArr,
  );
  // 予約日
  const rangeConditions = _createDateRangeConditions(
    "interviews_info.interview_fix_date_start",
    startExamDate,
    endExamDate,
  );
  // 面談タイプ
  const interviewTypeCheckConditions = _createIsInterviewTypeArrCheckConditions(
    "interviews_info.invitation_ticket_id",
    interviewTypeArr,
  );

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...selectDoctorConditions,
    ...checkConditions,
    ...userStatusCheckConditions,
    ...rangeConditions,
    ...interviewTypeCheckConditions,
    user_type: UserTypeID.PATIENT,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "interviews_info.created_at",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC007,
  );

  const aggregate: PipelineStage[] = [
    // 結合条件
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $lookup: LOOK_UP.USERS.PATIENTS_INTERVIEW },
    { $unwind: "$interviews_info" },
    { $lookup: LOOK_UP.USERS.PATIENTS_INTERVIEW_USER },
    {
      $unwind: {
        path: "$interview_users_info",
        // 担当が割り振られる前の可能性もあるので空も許容
        preserveNullAndEmptyArrays: true,
      },
    },
    // 検索条件
    { $match: searchConditions },
    // 1ページ分のデータと全体件数条件
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc011 = (
  id: string,
  nouKnowStatus: number,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
): PipelineStage[] => {
  const statusConditions =
    PatientNouKnowStatusID.ALL === nouKnowStatus
      ? {}
      : _createCheckConditions("status", [nouKnowStatus]);
  // 検索条件
  const searchConditions = {
    patient_id: id,
    ...statusConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "exam_start",
    "asc",
    "exam_start",
    "asc",
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC011,
  );

  const aggregate: PipelineStage[] = [
    // 検索条件
    { $match: searchConditions },
    // 1ページ分のデータと全体件数条件
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc012 = (id: string): PipelineStage[] => {
  // 検索条件
  const searchConditions = {
    _id: id,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    "updated_at",
    "desc",
  );

  const aggregate: PipelineStage[] = [
    // 検索条件
    { $match: searchConditions },
    { $sort: sortConditions },
    { $skip: 0 },
    { $limit: 1 },
    { $project: PROJECTS.NLC012 },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc014 = (
  id: string,
  mriStatus: number,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
): PipelineStage[] => {
  const getStatusConditions = () => {
    if (MriStatusID.ALL === mriStatus) {
      return {
        mri_status: { $nin: [MriStatusID.NON_TARGET, MriStatusID.GUIDING] },
      };
    }

    if (MriStatusID.ONLINE_MEDICAL_COMPLETED === mriStatus) {
      return {
        isExitMriData: false,
      };
    }

    // ステータスが対象外、案内中以外
    return _createCheckConditions("patient_modality_book_info.mri_status", [
      mriStatus,
    ]);
  };

  // 検索条件
  const searchConditions = {
    patient_id: id,
    online_test_status: onlineTestStatus.done,
    ...getStatusConditions(),
    "patient_modality_book_info.modality": {
      $not: {
        $in: [ModalityID.PET],
      },
    },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    "updated_at",
    "desc",
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC014,
  );

  const aggregate: PipelineStage[] = [
    // 結合条件
    {
      $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENT_MODALITY_BOOK_INFO,
    },
    {
      $unwind: {
        path: "$patient_modality_book_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENT_NOUKNOWS_INFO,
    },
    {
      $unwind: {
        path: "$patient_nouknow_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $lookup: LOOK_UP.PATIENT_ONLINE_TESTS.PATIENT_HOSPITAL,
    },
    {
      $unwind: {
        path: "$hospital_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $addFields: {
        isExitMriData: {
          $cond: {
            if: { $gt: ["$patient_modality_book_info._id", null] },
            then: true,
            else: false,
          },
        },
      },
    },
    // 検索条件
    { $match: searchConditions },
    // 1ページ分のデータと全体件数条件
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc015 = (id: string): PipelineStage[] => {
  // 検索条件
  const searchConditions = {
    _id: id,
    modality: ModalityID.MRI,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    "updated_at",
    "desc",
  );

  const aggregate: PipelineStage[] = [
    // 結合条件
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.HOSPITAL_USERS },
    {
      $unwind: {
        path: "$hospital_user_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    // 検索条件
    { $match: searchConditions },
    { $sort: sortConditions },
    { $skip: 0 },
    { $limit: 1 },
    { $project: PROJECTS.NLC015 },
  ];

  return aggregate;
};

export const getAggregateNlc028 = (id: string): PipelineStage[] => {
  // 検索条件
  const searchConditions = {
    _id: id,
    modality: ModalityID.PET,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    "updated_at",
    "desc",
  );

  const aggregate: PipelineStage[] = [
    // 結合条件
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.HOSPITAL_USERS },
    {
      $unwind: {
        path: "$hospital_user_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    // 検索条件
    { $match: searchConditions },
    { $sort: sortConditions },
    { $skip: 0 },
    { $limit: 1 },
    { $project: PROJECTS.NLC028 },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc017 = (
  id: string,
  petStatus: number,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
): PipelineStage[] => {
  const statusConditions =
    PetStatusID.ALL === petStatus
      ? {}
      : _createCheckConditions("pet_status", [petStatus]);
  // 検索条件
  const searchConditions = {
    patient_id: id,
    ...statusConditions,
    modality: ModalityID.PET,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    "updated_at",
    "desc",
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC017,
  );

  const aggregate: PipelineStage[] = [
    // 結合条件
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.HOSPITALS },
    { $unwind: "$hospital_info" },
    // 検索条件
    { $match: searchConditions },
    // 1ページ分のデータと全体件数条件
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNld001 = (
  id: string,
  name: string,
  tel: string,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    patient_id: id,
    full_name: name,
    tel,
  });
  // チェックボックスの検索条件
  // 2次以降フェーズ対応: No162 正式なIDに検索対象のカラムを入れる
  const checkConditions = {};
  // const checkConditions = _createCheckConditions("status", statusArr);
  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...checkConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "patient_id",
    "asc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLD001,
  );

  // 2次以降フェーズ対応: No165 紐づけデータ作成次第取得データ追加
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNld002 = (
  hospitalName: string,
  name: string,
  tel: string,
  statusArr: Array<number>,
  startDate: Date | undefined,
  endDate: Date | undefined,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "hospital_info.hospital_name": hospitalName,
    "user_info.full_name": name,
  });

  // 実施日の検索条件
  const fixBookDateConditions = _createDateRangeConditions(
    "exam_date",
    startDate,
    endDate,
  );

  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions("examination", statusArr);

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...fixBookDateConditions,
    ...checkConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "exam_date",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLD002,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.HOSPITAL_PAYMENTS.HOSPITALS },
    {
      $unwind: { path: "$hospital_info", preserveNullAndEmptyArrays: true },
    },
    { $lookup: LOOK_UP.HOSPITAL_PAYMENTS.USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNld003 = (
  hospitalId: string,
  name: string,
  statusArr: Array<number>,
  startDate: Date | undefined,
  endDate: Date | undefined,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "user_info.full_name": name,
  });

  // 実施日の検索条件
  const fixBookDateConditions = _createDateRangeConditions(
    "exam_date",
    startDate,
    endDate,
  );

  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions("examination", statusArr);

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...fixBookDateConditions,
    ...checkConditions,
    "hospital_info._id": hospitalId,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "exam_date",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLD003,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.HOSPITAL_PAYMENTS.HOSPITALS },
    { $unwind: "$hospital_info" },
    { $lookup: LOOK_UP.HOSPITAL_PAYMENTS.USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlg001 = (
  title: string | null,
  lastUpdatedAdminId: string | null,
  memoStatusArr: Array<number>,
  deliveryStartDate: Date | undefined,
  deliveryEndDate: Date | undefined,
  lastUpdatedStartDate: Date | undefined,
  lastUpdatedEndDate: Date | undefined,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    title,
    last_updated_admin_id: lastUpdatedAdminId,
  });
  // 配信日時の検索条件
  const deliveriedConditions = _createDateRangeConditions(
    "deliveried_at",
    deliveryStartDate,
    deliveryEndDate,
  );
  // 更新日時の検索条件
  const lastUpdatedConditions = _createDateRangeConditions(
    "last_updated_at",
    lastUpdatedStartDate,
    lastUpdatedEndDate,
  );
  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions("status", memoStatusArr);

  // 検索条件
  const searchConditions: MatchStage = {
    ...inputConditions,
    ...deliveriedConditions,
    ...lastUpdatedConditions,
    ...checkConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "title",
    "asc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLG001,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.NOTIFICATIONS.USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateAdmins = (
  adminSearchConditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: adminSearchConditions },
    { $project: PROJECTS.ADMIN },
  ];

  return aggregate;
};

export const getAggregateNld004 = (
  name: string,
  email: string,
  tel: string,
  itemSearchArray: Array<number>,
  paymentSearchArray: Array<number>,
  authorizationStartDate: Date | undefined,
  authorizationEndDate: Date | undefined,
  paymentStartDate: Date | undefined,
  paymentEndDate: Date | undefined,
  userId: string,
  skip: number,
  limit: number,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  // emailを正規表現に変換
  const escapeEmail = _escapeRegex(email);

  const inputConditions = _createInputConditions({
    "user_info.full_name": name,
    "user_info.email": escapeEmail,
    "patient_info.tel": tel,
    "user_info.user_id": userId,
  });

  const itemCheckConditions = _createCheckConditions(
    "category",
    itemSearchArray,
  );
  const paymentCheckConditions = _createCheckConditions(
    "status",
    paymentSearchArray,
  );

  // 請求日の検索条件
  const authorizationDateConditions = _createDateRangeConditions(
    "authorization_date",
    authorizationStartDate,
    authorizationEndDate,
  );
  // 支払日の検索条件
  const paymentDateConditions = _createDateRangeConditions(
    "payment_date",
    paymentStartDate,
    paymentEndDate,
  );

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...itemCheckConditions,
    ...paymentCheckConditions,
    ...authorizationDateConditions,
    ...paymentDateConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "payment_date",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLD004,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.SALES.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: { path: "$subscription_info", preserveNullAndEmptyArrays: true },
    },
    { $lookup: LOOK_UP.SALES.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.SALES.USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.SALES.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNld005 = (
  id: string,
  name: string,
  itemSearchArray: Array<number>,
  authorizationStartDate: Date | undefined,
  authorizationEndDate: Date | undefined,
  paymentStartDate: Date | undefined,
  paymentEndDate: Date | undefined,
  receiptIssueStartDate: Date | undefined,
  receiptIssueEndDate: Date | undefined,
  minTotal: string | undefined,
  maxTotal: string | undefined,
  skip: number,
  limit: number,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  const inputConditions = _createInputConditions({
    "user_info.user_id": id,
    "user_info.full_name": name,
  });

  const itemCheckConditions = _createCheckConditions(
    "category",
    itemSearchArray,
  );

  // 請求日の検索条件
  const paymentDateConditions = _createDateRangeConditions(
    "payment_date",
    paymentStartDate,
    paymentEndDate,
  );
  // 支払日の検索条件
  const authorizationDateConditions = _createDateRangeConditions(
    "authorization_date",
    authorizationStartDate,
    authorizationEndDate,
  );
  // 発行日時の検索条件
  const receiptIssueDateConditions = _createDateRangeConditions(
    "receipt_issue_date",
    receiptIssueStartDate,
    receiptIssueEndDate,
  );
  // 合計の検索条件
  const amountDateConditions = _createAmountRangeConditions(
    "amount",
    Number(minTotal),
    Number(maxTotal),
  );

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...itemCheckConditions,
    ...authorizationDateConditions,
    ...paymentDateConditions,
    ...receiptIssueDateConditions,
    ...amountDateConditions,
    stripe_payment_intent: { $ne: null },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "authorization_date",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLD005,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.SALES.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: { path: "$subscription_info", preserveNullAndEmptyArrays: true },
    },
    { $lookup: LOOK_UP.SALES.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.SALES.USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlh001 = (): PipelineStage[] => {
  // 並び替え条件
  const sortConditions = {
    ..._createSortCondition("embedded_page", "asc"),
    ..._createSortCondition("embedded_location", "asc"),
  };

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.NOTIFICATIONS.USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $project: PROJECTS.NLH001 },
    { $sort: sortConditions },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlh003 = (id: string): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: { _id: id } },
    { $project: PROJECTS.NLH003 },
  ];

  return aggregate;
};

// お知らせテーブル1件検索
export const getAggregateOneNotification = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.NOTIFICATIONS.USERS },
    { $unwind: "$user_info" },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_NOTIFICATION },
  ];

  return aggregate;
};

// ユーザテーブルに紐づく患者テーブル1件検索
export const getAggregateOnePatientUser = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $lookup: LOOK_UP.USERS.PATIENTS_ORGANIZATIONS },
    {
      $unwind: {
        path: "$organization_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.USERS.PATIENTS_CAMPAIGN_COUPONS },
    {
      $unwind: {
        path: "$campaign_coupon_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $lookup: {
        from: "patient_subscriptions",
        let: { patientId: "$_id" },
        pipeline: [
          { $match: { $expr: { $eq: ["$patient_id", "$$patientId"] } } },
          { $sort: { created_at: -1 } },
          { $limit: 1 },
        ],
        as: "patient_subscriptions_info",
      },
    },
    {
      $unwind: {
        path: "$patient_subscriptions_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.USERS.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.USERS.PATIENTS_PARENT_USER },
    {
      $unwind: {
        path: "$user_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_PATIENT },
  ];

  return aggregate;
};

// 医療機関テーブル1件検索
export const getAggregateOneHospital = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_HOSPITAL },
  ];

  return aggregate;
};

export const getAggregateNli005 = (
  searchConditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $project: PROJECTS.NLI005 },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNli007 = (
  itemCode: string | null,
  itemName: string | null,
  tagIdArray: Array<string>,
  skip: number,
  limit: number,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  // 入力欄の検索条件
  let inputConditions = _createInputConditions({
    item_code: itemCode,
    item_name: itemName,
  });

  // 空の商品種別を除く
  if (!itemCode)
    inputConditions = {
      ...inputConditions,
      item_code: { $ne: "" },
    };

  // チェックボックスの検索条件 tagがないデータも取得
  const checkConditions = _createCheckConditionsIncludeNullOrEmpty(
    "tag",
    tagIdArray,
  );

  // ステータス条件
  const statusConditions = {
    status: { $in: [ItemsSkusStatusID.HIDDEN, ItemsSkusStatusID.VISIBLE] },
  };

  // 検索条件
  const searchConditions: MatchStage = {
    ...inputConditions,
    ...checkConditions,
    ...statusConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI007,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ITEMS.ITEM_TAGS },
    { $lookup: LOOK_UP.ITEMS.SKUS },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNli009 = (
  skuCode: string | null,
  itemName: string | null,
  skuName: string | null,
  tagIdArray: Array<string>,
  skip: number,
  limit: number,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    sku_code: skuCode,
    "items_info.item_name": itemName,
    sku_name: skuName,
  });

  // チェックボックスの検索条件 tagがないデータも取得
  const checkConditions = _createCheckConditionsIncludeNullOrEmpty(
    "items_info.tag",
    tagIdArray,
  );

  // ステータス条件
  const statusConditions = {
    status: { $in: [ItemsSkusStatusID.HIDDEN, ItemsSkusStatusID.VISIBLE] },
  };

  // 検索条件
  const searchConditions: MatchStage = {
    ...inputConditions,
    ...checkConditions,
    ...statusConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI009,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.SKUS.ITEMS },
    { $unwind: "$items_info" },
    { $lookup: LOOK_UP.SKUS.ITEMS_TAGS },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// 商品種別1件検索
export const getAggregateOneItem = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ITEMS.ITEM_TAGS },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_ITEM },
  ];

  return aggregate;
};

// SKU1件検索
export const getAggregateOneSku = (conditions: MatchStage): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_SKU },
  ];

  return aggregate;
};

// オプション一覧を検索
export const getAggregateOptions = (itemId: string): PipelineStage[] => {
  // 並び替え条件
  const sortConditions = _createSortConditions(
    "created_at",
    "asc",
    "created_at",
    "asc",
  );
  const aggregate: PipelineStage[] = [
    {
      $match: {
        item_id: itemId,
        status: 1,
      },
    },
    { $sort: sortConditions },
    { $project: PROJECTS.OPTIONS },
  ];

  return aggregate;
};

export const getAggregateNli003 = (
  search: string | null,
  skip: number,
  limit: number,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  // 入力欄の検索条件
  const fields = ["user_id", "full_name", "email"];
  const inputConditions = _createInputOrConditions(search, fields);

  // 検索条件
  const searchConditions: MatchStage = {
    ...(Object.keys(inputConditions).length > 0
      ? {
          $or: Object.entries(inputConditions).map(([key, value]) => ({
            [key]: value,
          })),
        }
      : {}),
    // ユーザタイプ: Admin
    user_type: UserTypeID.ADMIN,
    status: { $ne: UserStatusID.CANCELLATION },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "created_at",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI003,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNli011 = (itemId: string): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ITEMS.ITEM_TAGS },
    { $lookup: LOOK_UP.ITEMS.OPTION_CONFIGS },
    { $lookup: LOOK_UP.ITEMS.SKUS_OPTION_CONTENTS },
    {
      $match: {
        _id: itemId,
        status: { $ne: ItemsSkusStatusID.DELETED },
      },
    },
    {
      $addFields: {
        option_configs_info: {
          $filter: {
            input: "$option_configs_info",
            as: "option",
            cond: { $eq: ["$$option.status", 1] },
          },
        },
      },
    },
    {
      $project: {
        ...PROJECTS.NLI011,
        skus_info: {
          $filter: {
            input: "$skus_info",
            as: "sku",
            cond: { $ne: ["$$sku.status", ItemsSkusStatusID.DELETED] },
          },
        },
      },
    },
  ];

  return aggregate;
};

export const getAggregateNli016 = (
  search: string | null,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const fields = ["_id", "hospital_name", "tel", "address"];
  const inputConditions = _createInputOrConditions(search, fields);

  // 検索条件
  const searchConditions: MatchStage = {
    ...(Object.keys(inputConditions).length > 0
      ? {
          $or: Object.entries(inputConditions).map(([key, value]) => ({
            [key]: value,
          })),
        }
      : {}),
    status: 0,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "_id",
    "asc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI016,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNli017 = (
  hospitalId: string,
  hospitalUserId: string | null,
  doctorName: string | null,
  email: string | null,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    hospital_id: hospitalId,
    user_id: hospitalUserId,
    full_name: doctorName,
    email,
  });
  // チェックボックスの検索条件
  // 権限無し
  // const checkConditions = _createCheckConditions("role", memoStatusArr);

  // 検索条件
  const searchConditions: MatchStage = {
    ...inputConditions,
    // ...checkConditions,
    user_type: UserTypeID.HOSPITAL,
    status: { $ne: UserStatusID.CANCELLATION },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "created_at",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI017,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNli019 = (
  search: string | null,
  skip: number,
  limit: number,
): PipelineStage[] => {
  // 入力欄の検索条件
  const fields = ["organization_name", "tel", "address"];
  const inputConditions = _createInputOrConditions(search, fields);

  // 検索条件
  const searchConditions: MatchStage = {
    ...(Object.keys(inputConditions).length > 0
      ? {
          $or: Object.entries(inputConditions).map(([key, value]) => ({
            [key]: value,
          })),
        }
      : {}),
  };

  // 並び替え条件
  const sortConditions = {
    created_at: -1,
  } as SortStage;

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI019,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlf001 = (
  id: string | null,
  patientName: string | null,
  orderYear: string | null,
  orderMonth: string | null,
  statusArray: Array<number>,
  deliveryMethodArray: Array<number>,
  userId: string | null,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    _id: id,
    "user_info.full_name": patientName,
    "user_info.user_id": userId,
  });
  // 注文日(年、月)の検索条件
  const orderDateConditions = _createOrderDateConditions(
    orderYear,
    orderMonth,
    "settlement_time",
  );
  // ステータスチェックボックスの検索条件
  const checkStatusConditions = _createCheckConditions("status", statusArray);
  // 配送チェックボックスの検索条件
  const checkDeliveryConditions = _createCheckConditions(
    "delivery_method",
    deliveryMethodArray,
  );

  // 検索条件
  const searchConditions: MatchStage = {
    ...inputConditions,
    ...checkDeliveryConditions,
    ...checkStatusConditions,
    ...orderDateConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLF001,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORDERS.USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateAllOrderYear = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [{ $project: PROJECTS.ALL_ORDER_YEAR }];

  return aggregate;
};

export const getAggregateNlf005 = (conditions: MatchStage): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: conditions },
    { $lookup: lookupOrderToTransactions() },
    { $lookup: LOOK_UP.ORDERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $lookup: LOOK_UP.ORDERS.USERS },
    { $unwind: "$user_info" },
    { $limit: 1 },
    { $project: PROJECTS.NLF005 },
  ];

  return aggregate;
};

export const getAggregateNlf006 = (
  salesYear: string,
  salesMonth: string,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 注文日(年、月)の検索条件
  const orderDateConditions = _createOrderDateConditions(
    salesYear,
    salesMonth,
    "payment_date",
  );

  // 検索条件
  const searchConditions: MatchStage = {
    category: SalesCategoryID.PRODUCT_PURCHASE,
    status: SalesStatusID.SOLD,
    ...orderDateConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "_id",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountGroupFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLF006,
    GROUP.NLF006,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlf007 = (
  salesId: string,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  // 注文日(年、月)の検索条件
  const salesYear = salesId.substring(0, 4);
  const salesMonth = salesId.substring(4, 6);
  const orderDateConditions = _createOrderDateConditions(
    salesYear,
    salesMonth,
    "payment_date",
  );

  // 検索条件
  const searchConditions: MatchStage = {
    category: SalesCategoryID.PRODUCT_PURCHASE,
    status: SalesStatusID.SOLD,
    ...orderDateConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "settlement_time",
    "asc",
    sortField,
    sortDirection,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $lookup: LOOK_UP.SALES.ORDERS },
    { $unwind: { path: "$orders_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.SALES.ORDERS_USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $project: PROJECTS.NLF007 },
    { $sort: sortConditions },
  ];

  return aggregate;
};

export const getAggregateAllSalesYear = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        category: SalesCategoryID.PRODUCT_PURCHASE,
        status: SalesStatusID.SOLD,
      },
    },
    { $project: PROJECTS.ALL_SALES_YEAR },
  ];

  return aggregate;
};

// 商品タグテーブル検索
export const getAggregateItemTags = (): PipelineStage[] => [
  { $project: PROJECTS.ITEM_TAGS },
];

// 管理者ドクター一覧取得
export const getAggregateAdminDoctorList = (): PipelineStage[] => [
  {
    $match: {
      user_type: UserTypeID.ADMIN,
      admin_role: AdminsRoleID.CMC,
    },
  },
  { $project: { _id: 0, value: "$_id", label: "$full_name" } },
];

export const getAggregateMib001 = (
  hospitalId: string,
  userId: string | null,
  patientName: string | null,
  tel: string | null,
  applicationDate: Date | undefined,
  modalityArray: Array<number>,
  statusArray: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "patient_info.tel": tel,
    "user_info.full_name": patientName,
    "user_info.user_id": userId,
  });
  // 申込日の検索条件
  const applicationDateConditions = _createDateConditions(
    "created_at",
    applicationDate,
  );

  // 検査内容チェックボックスの検索条件
  const checkInspectionConditions = _createCheckConditions(
    "modality",
    modalityArray,
  );

  // ステータスチェックボックスの検索条件
  const checkStatusConditions = _createCheckConditions("status", statusArray);

  // 検索条件
  const searchConditions: MatchStage = {
    hospital_id: hospitalId,
    ...inputConditions,
    ...checkInspectionConditions,
    ...checkStatusConditions,
    ...applicationDateConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "created_at_db",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数、全件データ条件
  const facet = _createSearchAndAllDataFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.MIB001,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.PATIENTS_USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateMic001 = (
  hospitalId: string,
  userId: string | null,
  patientName: string | null,
  tel: string | null,
  fixBookDate: Date | undefined,
  statusArray: Array<number>,
  contentArray: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  const inputConditions = _createInputConditions({
    "user_info.user_id": userId,
    "patient_info.tel": tel,
    "user_info.full_name": patientName,
  });
  // 申込日の検索条件
  const applicationDateConditions = _createDateConditions(
    "fix_book_date_start",
    fixBookDate,
  );

  // チェックボックスの検索条件
  const checkStatusConditions = _createCheckConditions("status", statusArray);
  const checkContentConditions = _createCheckConditions(
    "modality",
    contentArray,
  );

  // 検索条件
  const searchConditions: MatchStage = {
    hospital_id: hospitalId,
    ...inputConditions,
    ...checkStatusConditions,
    ...checkContentConditions,
    ...applicationDateConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "fix_book_date_start",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数、全件データ条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.MIC001,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.PATIENTS_USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateMid001 = (
  hospitalId: string,
  id: string | null,
  patientName: string | null,
  tel: string | null,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "user_info.user_id": id,
    "patient_info.tel": tel,
    "user_info.full_name": patientName,
  });

  // 検索条件
  const searchConditions: MatchStage = {
    hospital_id: hospitalId,
    ...inputConditions,
    status: {
      $in: [
        HospitalReservationStatusID.CONFIRM,
        HospitalReservationStatusID.INSPECTED,
      ],
    },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "fix_book_date_start",
    "desc",
    sortField,
    sortDirection,
  );
  // 1ページ分のデータと全体件数、全件データ条件
  const facet = _createSearchAndCountConditionGroupFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.MID001,
    GROUP.MID001,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.PATIENTS_USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateMid002User = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.MID002_USERS },
  ];

  return aggregate;
};

export const getAggregateMid002Modality = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.HOSPITALS },
    { $unwind: "$hospital_info" },
    { $match: conditions },
    { $sort: { fix_book_date_start: -1 } },
    { $project: PROJECTS.MID002_MODALITY },
  ];

  return aggregate;
};

export const getAggregateMid002HospitalUser = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: conditions },
    { $sort: { hospital_user_id: 1 } },
    { $project: PROJECTS.MID002_HOSPITAL_USER },
  ];

  return aggregate;
};

export const getAggregateNlc019 = (
  conditions: MatchStage,
  skip: number,
  limit: number,
): PipelineStage[] => {
  // ソート
  const sortConditions = {
    created_at: -1,
  } as SortStage;

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC019,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.SALES.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: { path: "$subscription_info", preserveNullAndEmptyArrays: true },
    },
    { $lookup: LOOK_UP.SALES.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: conditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc020 = (_id: string): PipelineStage[] => {
  // 検索条件
  const searchConditions: MatchStage = {
    _id,
  };

  const interviewKey = "interviews";
  const modalityKey = "modality";
  // 各DBの紐づけ条件と取得値をfacetでまとめる
  const interviewOperations = [
    { $lookup: LOOK_UP.USERS.PATIENTS_INTERVIEW },
    { $unwind: "$interviews_info" },
    { $project: PROJECTS.NLC020_INTERVIEW },
  ];
  const modalityOperations = [
    { $lookup: LOOK_UP.USERS.PATIENT_MODALITY_BOOKS },
    { $unwind: "$patient_modality_book_info" },
    { $lookup: LOOK_UP.USERS.PATIENT_MODALITY_BOOKS_HOSPITALS },
    { $unwind: "$hospital_info" },
    { $project: PROJECTS.NLC020_MODALITY },
  ];
  const facetStage = {
    [interviewKey]: interviewOperations,
    [modalityKey]: modalityOperations,
  };

  // facetを合成
  const combineFacetStages = _createCombineFacetStages([
    `$${interviewKey}`,
    `$${modalityKey}`,
  ]);

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "id",
    "desc",
    "orderDate",
    "desc",
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facetStage },
    ...combineFacetStages,
    { $sort: sortConditions },
  ];

  return aggregate;
};

export const getAggregateNlc023Orders = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORDERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $match: conditions },
    { $project: PROJECTS.NLC023_ORDERS },
  ];

  return aggregate;
};

export const getAggregateMie001 = (hospitalId: string): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.HOSPITALS.HOSPITAL_DEFAULT_SCHEDULES },
    { $lookup: LOOK_UP.HOSPITALS.HOSPITAL_DAILY_SCHEDULES },
    {
      $match: {
        _id: hospitalId,
      },
    },
    { $project: PROJECTS.MIE001 },
  ];

  return aggregate;
};

export const getAggregateHospitalTimeInterval = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.HOSPITAL_TIME_INTERVAL },
  ];

  return aggregate;
};

export const getAggregateEventHistories = (
  id: string,
  eventSort: string,
  searchCriteria: string,
): PipelineStage[] => {
  // 入力検索
  const fields = ["description"];
  const inputConditions = _createInputOrConditions(searchCriteria, fields);

  // 検索条件
  const searchConditions: MatchStage = {
    target_patient_id: id,
    status: EventHistoryStatusID.VALID,
    ...(Object.keys(inputConditions).length > 0
      ? {
          $or: Object.entries(inputConditions).map(([key, value]) => ({
            [key]: value,
          })),
        }
      : {}),
  };

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.EVENT_HISTORIES.USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $project: PROJECTS.EVENT_HISTORIES },
    { $sort: { [eventSort]: -1 } },
  ];

  return aggregate;
};

export const getAggregateNlb001Number = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [{ $match: conditions }];

  return aggregate;
};

export const getAggregateNlb001ChartYear = (
  searchCondition: MatchStage,
  column: string,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    {
      $match: searchCondition,
    },
    {
      $project: {
        yearMonth: {
          $dateToString: { format: "%Y/%m", date: `$${column}` },
        },
      },
    },
    {
      $group: {
        _id: "$yearMonth",
        count: { $sum: 1 },
      },
    },
    {
      $sort: { _id: 1 },
    },
  ];

  return aggregate;
};

export const getAggregateNlb001ChartMonth = (
  searchCondition: MatchStage,
  column: string,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    {
      $match: searchCondition,
    },
    {
      $project: {
        yearMonth: {
          $dateToString: { format: "%Y/%m/%d", date: `$${column}` },
        },
      },
    },
    {
      $group: {
        _id: "$yearMonth",
        count: { $sum: 1 },
      },
    },
    {
      $sort: { _id: 1 },
    },
  ];

  return aggregate;
};

export const getAggregateNlb001ChartWeek = (
  searchCondition: MatchStage,
  column: string,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    {
      $match: searchCondition,
    },
    {
      $project: {
        yearMonth: {
          $dateToString: { format: "%Y/%m/%d", date: `$${column}` },
        },
      },
    },
    {
      $group: {
        _id: "$yearMonth",
        count: { $sum: 1 },
      },
    },
    {
      $sort: { _id: 1 },
    },
  ];

  return aggregate;
};

export const getAggregateNlb001TaskInterview = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENTS_INTERVIEW.USERS },
    {
      $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        status: PatientInterviewStatusID.NEW_RESERVATION,
      },
    },
    { $sort: { updated_at: -1 } },
    {
      $addFields: {
        category: "interview",
      },
    },
    { $project: PROJECTS.NLB001_TASK },
  ];

  return aggregate;
};

export const getAggregateNlb001TaskMri = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.USERS },
    {
      $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        modality: ModalityID.MRI,
        mri_status: MriStatusID.DONE_AI_DIAGNOSED,
      },
    },
    { $sort: { updated_at: -1 } },
    {
      $addFields: {
        category: "mri",
      },
    },
    { $project: PROJECTS.NLB001_TASK },
  ];

  return aggregate;
};

export const getAggregateNlb001TaskPet = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.USERS },
    {
      $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        modality: ModalityID.PET,
        pet_status: PetStatusID.EXAMINED,
      },
    },
    { $sort: { updated_at: -1 } },
    {
      $addFields: {
        category: "pet",
      },
    },
    { $project: PROJECTS.NLB001_TASK },
  ];

  return aggregate;
};

export const getAggregateNlb001TaskOrders = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORDERS.USERS },
    {
      $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        status: OrderStatusID.PAYMENT_COMPLETED,
      },
    },
    { $sort: { updated_at: -1 } },
    {
      $addFields: {
        category: "ec",
      },
    },
    { $project: PROJECTS.NLB001_TASK },
  ];

  return aggregate;
};

export const getAggregateNlb001TaskChats = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.CHATS.PATIENT_USERS },
    {
      $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        is_read: false,
        commented_user: "patient",
      },
    },
    { $sort: { updated_at: -1 } },
    {
      $addFields: {
        category: "chats",
      },
    },
    { $project: PROJECTS.NLB001_TASK },
  ];

  return aggregate;
};

export const getAggregateNlb001TaskNouknow = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_NOUKNOWS.USERS },
    {
      $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        status: PatientNouKnowStatusID.UPLOADED,
      },
    },
    { $sort: { updated_at: -1 } },
    {
      $addFields: {
        category: "nouknow",
      },
    },
    { $project: PROJECTS.NLB001_TASK },
  ];

  return aggregate;
};

export const getAggregateMaterRole = (
  roleSortNo: ROLE_KEY,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    {
      $match: { role_sort_no: roleSortNo },
    },
    { $project: PROJECTS.ADMIN_ROLE },
  ];

  return aggregate;
};

export const getAggregateNLE001 = (
  props: yoseDataTermsType & { skip: number; perPage: number },
) => {
  const {
    yoseTitle,
    deliveryStartDate,
    deliveryEndDate,
    unDeliveryStartDate,
    unDeliveryEndDate,
    status,
    sortDirection,
    skip,
    perPage,
  } = props;

  const searchInfo = SEARCH_INFO.NLE001;

  const statusArr = searchInfo.flatMap(({ id, flgKey }) =>
    status[flgKey as keyof typeof status] ? [id] : [],
  );
  const checkConditions = _createCheckConditions("status", statusArr);

  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    yose_title: yoseTitle,
  });

  // 配信日時の検索条件
  const deliveriedConditions = _createDateRangeConditions(
    "streaming_date",
    deliveryStartDate,
    deliveryEndDate,
  );
  // 更新日時の検索条件
  const lastUpdatedConditions = _createDateRangeConditions(
    "streaming_end_date",
    unDeliveryStartDate,
    unDeliveryEndDate,
  );

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "updated_at",
    "desc",
    sortDirection.colName,
    sortDirection.direction,
  );

  // 検索条件
  const searchConditions: MatchStage = {
    ...inputConditions,
    ...deliveriedConditions,
    ...lastUpdatedConditions,
    ...checkConditions,
  };

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    perPage,
    PROJECTS.NLE001,
  );

  const toDay = new Date();
  toDay.setHours(0, 0, 0, 0);
  const aggregate: PipelineStage[] = [
    {
      $addFields: {
        status: {
          $switch: {
            branches: [
              {
                case: { $eq: ["$is_suspend", true] },
                then: YOSE_STATUS.suspend,
              },
              {
                case: { $lt: ["$streaming_end_date", toDay] },
                then: YOSE_STATUS.publicClose,
              },
              {
                case: { $lt: [toDay, "$streaming_date"] },
                then: YOSE_STATUS.preDelivery,
              },
              {
                case: { $lt: ["$new_period", toDay] },
                then: YOSE_STATUS.publicOpen,
              },
            ],
            default: YOSE_STATUS.newPeriod,
          },
        },
      },
    },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc021 = (id: string): PipelineStage[] => {
  // 検索条件
  const searchConditions = {
    _id: id,
  };

  const aggregate: PipelineStage[] = [
    // 結合条件
    { $lookup: LOOK_UP.PATIENTS_INTERVIEW.USERS },
    {
      $unwind: {
        path: "$user_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.PATIENTS_INTERVIEW.PATIENTS },
    {
      $unwind: {
        path: "$patient_info",
        preserveNullAndEmptyArrays: true,
      },
    },

    // 検索条件
    { $match: searchConditions },
    { $skip: 0 },
    { $limit: 1 },
    { $project: PROJECTS.NLC021 },
  ];

  return aggregate;
};

// 管理者のドクター取得条件
export const getAggregateAdminDoctor = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENT_INTERVIEW_DOCTOR },
    {
      $match: {
        status: UserStatusID.ACTIVE,
        user_type: UserTypeID.ADMIN,
        admin_role: AdminsRoleID.CMC,
      },
    },
    { $project: PROJECTS.ADMIN_DOCTOR },
  ];

  return aggregate;
};

export const getAggregateChats = (patientId: string | undefined) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.CHATS.USERS },
    {
      $unwind: {
        path: "$user_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: { patient_id: patientId } },
    { $sort: { register_date: 1 } },
    { $project: PROJECTS.CHATS },
  ];

  return aggregate;
};

export const getAggregateMedicalRecords = (
  id: string,
  searchCriteria: string,
): PipelineStage[] => {
  // 入力検索
  const fields = ["description"];
  const inputConditions = _createInputOrConditions(searchCriteria, fields);

  // 検索条件
  const searchConditions: MatchStage = {
    patient_id: id,
    status: MedicalRecordsStatusID.VALID,
    ...(Object.keys(inputConditions).length > 0
      ? {
          $or: Object.entries(inputConditions).map(([key, value]) => ({
            [key]: value,
          })),
        }
      : {}),
  };

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.KARTES.USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $project: PROJECTS.KARTES },
    { $sort: { created_at: -1 } },
  ];

  return aggregate;
};

// hospital_paymentsテーブル合計取得
export const getAggregateHospitalPaymentTotalAmount = (
  id: string,
): PipelineStage[] => {
  // 検索条件
  const searchCondition = {
    "hospital_info._id": id,
    examination: {
      $in: [
        HospitalPaymentsExaminationID.NOUKNOW,
        HospitalPaymentsExaminationID.MRI,
        HospitalPaymentsExaminationID.PET,
        HospitalPaymentsExaminationID.BRAIN_HEALTH_CHECK,
        HospitalPaymentsExaminationID.INTERVIEW,
      ],
    },
  };

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.HOSPITAL_PAYMENTS.HOSPITALS },
    { $unwind: "$hospital_info" },
    { $match: searchCondition },
    { $group: GROUP.SALES_TOTAL_AMOUNT },
    { $project: PROJECTS.SALES_TOTAL_AMOUNT },
  ];

  return aggregate;
};

export const getAggregateNlc035 = (
  name: string,
  email: string,
  tel: string,
  typeArr: Array<number>,
  statusArr: Array<number>,
  userId: string,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // emailを正規表現に変換
  const escapeEmail = _escapeRegex(email);
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    email: escapeEmail,
    full_name: name,
    "patient_info.tel": tel,
    user_id: userId,
  });

  // タイプ チェックボックスの検索条件
  const typeCheckConditions = _createCheckConditions(
    "patient_info.patient_type",
    typeArr,
  );

  // ステータス チェックボックスの検索条件
  const statusCheckConditions = _createCheckConditions("status", statusArr);

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...typeCheckConditions,
    ...statusCheckConditions,
    user_type: UserTypeID.PATIENT,
  };

  // 並び替え条件（数値変換後の user_id を使用）
  const sortConditions: SortStage = {
    user_id_int: sortDirection === "asc" ? 1 : -1,
  };

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC035,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $match: searchConditions },
    {
      $addFields: {
        user_id_int: {
          $cond: {
            if: { $regexMatch: { input: "$user_id", regex: "^[0-9]+$" } },
            then: { $toInt: "$user_id" },
            else: null,
          },
        },
      },
    },
    { $facet: facet },
  ];

  return aggregate;
};

// ユーザテーブルに紐づくサブスクリプション検索
export const getAggregateStripeSubscription = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    {
      $lookup: {
        from: "patient_subscriptions",
        let: { patientId: "$_id" },
        pipeline: [
          { $match: { $expr: { $eq: ["$patient_id", "$$patientId"] } } }, // 患者IDで絞り込み
          { $sort: { created_at: -1 } }, // created_at の降順でソート（最新を先頭に）
          { $limit: 1 }, // 最新の1件だけ取得
        ],
        as: "patient_subscriptions_info",
      },
    },
    {
      $unwind: {
        path: "$patient_subscriptions_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.STRIPE_SUBSCRIPTION },
  ];

  return aggregate;
};

// キャンペーンクーポンテーブルから未設定データを取得
export const getAggregateNotSetCampaignCoupons = (): PipelineStage[] => {
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.CAMPAIGN_COUPONS.ORGANIZATIONS },
    {
      $unwind: { path: "$organization_info", preserveNullAndEmptyArrays: true },
    },
    {
      $match: {
        organization_info: { $exists: false },
        status: CampaignCouponsStatusID.APPLY,
        cancel_period: { $gte: startOfToday },
      },
    },
    { $project: PROJECTS.NOT_SET_CAMPAIGN_COUPONS },
    { $sort: { createdAt: -1 } },
  ];

  return aggregate;
};

// 団体情報を1件検索
export const getAggregateOneOrganization = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORGANIZATIONS.CAMPAIGN_COUPONS },
    {
      $unwind: {
        path: "$campaign_coupon_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_ORGANIZATION },
  ];

  return aggregate;
};

export const getAggregateNli021 = (
  userId: string | null,
  name: string,
  email: string,
  tel: string,
  statusArr: Array<number>,
  organizationId: string,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // emailを正規表現に変換
  const escapeEmail = _escapeRegex(email);
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "user_info.user_id": userId,
    "user_info.full_name": name,
    "user_info.email": escapeEmail,
    tel,
  });

  // チェックボックスの検索条件
  const checkConditions = _createCheckConditions("user_info.status", statusArr);

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...checkConditions,
    "organization_info._id": organizationId,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "user_info.activation_date",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI021,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENTS.USERS },
    { $unwind: "$user_info" },
    { $lookup: LOOK_UP.PATIENTS.ORGANIZATIONS },
    { $unwind: "$organization_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc033 = (
  userId: string | null,
  name: string,
  typeArr: Array<string>,
  statusArr: Array<string>,
  checkArr: Array<string>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    "user_info.full_name": name,
    "user_info.user_id": userId,
  });

  // タイプ チェックボックスの検索条件
  const typeCheckConditions = _createCheckConditions("commented_user", typeArr);

  // ステータス チェックボックスの検索条件
  const statusCheckConditions = _createIsReadCheckConditions(
    "is_read",
    statusArr,
  );

  // 要注意 チェックボックスの検索条件
  const checkConditions =
    checkArr.length === 1 ? { check_list: true } : undefined;

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...typeCheckConditions,
    ...statusCheckConditions,
    ...checkConditions,
  };

  // 並び替え条件;
  const sortConditions = _createSortConditions(
    "activation_date",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC033,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.CHATS.PATIENT_USERS },
    { $unwind: "$user_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc029 = (
  name: string,
  userId: string,
  tel: string,
  startExamDate: Date | undefined,
  endExamDate: Date | undefined,
  startUploadExamDate: Date | undefined,
  endUploadExamDate: Date | undefined,
  userStatusArr: Array<number>,
  statusArr: Array<number>,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  sortField: string, // ソート対象
  sortDirection: string, // ソート昇順・降順
): PipelineStage[] => {
  // 入力欄
  const inputConditions = _createInputConditions({
    "user_info.full_name": name,
    "user_info.user_id": userId,
    "patient_info.tel": tel,
  });

  // 受検期間
  const rangeConditions = _createDateRangeConditions(
    "exam_term.start",
    startExamDate,
    endExamDate,
  );

  const rangeUploadConditions = _createDateRangeConditions(
    "upload_date",
    startUploadExamDate,
    endUploadExamDate,
  );

  // チェックボックス
  const checkConditions = _createCheckConditions("status", statusArr);

  // 会員ステータス チェックボックスの検索条件
  const userStatusCheckConditions = _createCheckConditions(
    "user_info.status",
    userStatusArr,
  );

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...checkConditions,
    ...rangeConditions,
    ...rangeUploadConditions,
    ...userStatusCheckConditions,
  };

  const sortConditions = _createSortConditions(
    "exam_start_date",
    "desc",
    sortField,
    sortDirection,
  );

  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC029,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_BRAIN_CHECKS.USERS },
    { $unwind: "$user_info" },
    { $lookup: LOOK_UP.PATIENT_BRAIN_CHECKS.PATIENTS },
    { $unwind: "$patient_info" },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

// データ取得の取得条件
export const getAggregateNlc030 = (
  id: string,
  status: number,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
): PipelineStage[] => {
  const statusConditions =
    PatientBrainCheckStatusID.ALL === status
      ? {}
      : _createCheckConditions("status", [status]);
  const searchConditions = {
    patient_id: id,
    ...statusConditions,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "exam_start_date",
    "desc",
    "exam_start_date",
    "desc",
  );
  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLC030,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateNlc031 = (id: string): PipelineStage[] => {
  const searchConditions = {
    _id: id,
  };
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_BRAIN_CHECKS.ADMIN_USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $match: searchConditions },
    { $limit: 1 },
    { $project: PROJECTS.NLC031 },
  ];

  return aggregate;
};

export const getAggregateNli033 = (
  name: string | null,
  code: string | null,
  paymentSpanArray: Array<number>,
  afterCampaignArray: Array<number>,
  campaignStartDate: Date | undefined,
  campaignEndDate: Date | undefined,
  skip: number,
  limit: number,
  sortField: string,
  sortDirection: string,
): PipelineStage[] => {
  // 入力欄の検索条件
  const inputConditions = _createInputConditions({
    campaign_name: name,
    campaign_code: code,
  });

  // 月額/年額チェックボックスの検索条件
  const checkPaymentConditions = _createCheckConditions(
    "payment_span",
    paymentSpanArray,
  );

  // キャンペン終了日チェックボックスの検索条件
  const checkAfterCampaignConditions = _createCheckConditions(
    "canceled_after_campaign",
    afterCampaignArray,
  );

  // キャンペーン終了日の検索条件
  const campaignRangeDateConditions = _createDateRangeConditions(
    "cancel_period",
    campaignStartDate,
    campaignEndDate,
  );

  // status の基本条件
  const baseCondition = {
    $or: [{ status: 1 }, { status: null }],
  };

  // 検索条件
  const searchConditions = {
    ...inputConditions,
    ...checkPaymentConditions,
    ...checkAfterCampaignConditions,
    ...campaignRangeDateConditions,
    ...baseCondition,
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "created_at",
    "desc",
    sortField,
    sortDirection,
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountGroupFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.NLI033,
    GROUP.NLI033,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.CAMPAIGN_COUPONS.ORGANIZATIONS },
    {
      $unwind: {
        path: "$organization_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.CAMPAIGN_COUPONS.PATIENTS },
    {
      $unwind: {
        path: "$patient_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateChildPatients = (id: string): PipelineStage[] => {
  const searchConditions = {
    parent_patient_id: id,
    status: PatientInterviewStatusID.SETTLED,
  };
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.USERS },
    { $unwind: { path: "$user_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: { path: "$subscription_info", preserveNullAndEmptyArrays: true },
    },
    {
      $lookup:
        LOOK_UP.PATIENT_INVITATIONS.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS,
    },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: searchConditions },
    { $project: PROJECTS.CHILD_PATIENT },
  ];

  return aggregate;
};

export const getAggregateShippings = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $limit: 1 },
    { $project: PROJECTS.SHIPPINGS },
  ];

  return aggregate;
};

export const getAggregateModalityBooks = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { _id: id } },
    { $project: PROJECTS.MBI002 },
  ];

  return aggregate;
};

export const getAggregateMriHospitals = (prefecture: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        status: HospitalStatusID.IN_USE,
        has_mri: true,
        prefecture,
      },
    },
    { $project: PROJECTS.MRI_HOSPITALS },
  ];

  return aggregate;
};

export const getAggregatePetHospitals = (prefecture: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        status: HospitalStatusID.IN_USE,
        has_pet: true,
        prefecture,
      },
    },
    { $project: PROJECTS.PET_HOSPITALS },
  ];

  return aggregate;
};

export const getAggregateNli003Interview = (id: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const aggregate: PipelineStage[] = [
    {
      $match: {
        status: PatientInterviewStatusID.CONFIRMED_RESERVATION,
        interview_fix_date_start: { $gt: today },
        doctor_user_id: id,
      },
    },
    { $project: PROJECTS.NLI003_INTERVIEW },
  ];

  return aggregate;
};
