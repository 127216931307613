import { useEffect, useMemo, useState } from "react";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  getAggregateNli011,
  getAggregateOneSku,
} from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import useProductMgmtId, { setItemId } from "../../common/useProductMgmtId";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useProductMgmtDbActions from "../../common/useProductMgmtDbActions";
import {
  checkFetchErr,
  redirectToNoDataPage,
  redirectToNoRolePage,
} from "../../../contexts/CustomErrorBoundary";
import {
  useCheckMasterRoleFetching,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

// NLI011画面のデータ操作処理
const useNli011DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const [{ itemId, skuId }] = useProductMgmtId();
  const [forceUpdate] = useForceUpdate();
  const [fetchResult, setFetchResult] = useState<ItemsNli011StateType | null>(
    null,
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);

  // 商品種別、SKUのDB操作
  const {
    actionError,
    deleteItem,
    updateSku,
    deleteSku,
    addSku,
    selectRecommendTerm,
    selectRecommendScope,
  } = useProductMgmtDbActions();

  // 取得、編集対象
  const skuFilter = useMemo(() => ({ _id: skuId }), [skuId]);

  // 権限チェック
  const isFetchingRole = useCheckMasterRoleFetching(
    ROLE_KEY.MASTER_PRODUCT_BROWSING,
  );
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_BROWSING);

  // SKU一覧画面から遷移した場合のSKUに紐づくitemsのid取得処理
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (skuId) {
          if (!hasBrowsingRole) redirectToNoRolePage();

          const aggregateSku = getAggregateOneSku(skuFilter);
          // mongoDBのデータ
          const skuInfo = (await appContext.functions(["mongo/client", {
            collection: Collection.SKUS, aggregate:
              aggregateSku,
          }])) as SkusTableType[];

          if (skuInfo.length < 1) redirectToNoDataPage();

          setItemId(skuInfo[0].item_id);
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限取得中はデータ取得しない
    if (isFetchingRole) return;
    // 商品種別のIDが未取得の場合のみ実行
    if (!itemId) void fetchData();
  }, [appContext, currentUser, hasBrowsingRole, isFetchingRole, itemId, skuFilter, skuId]);

  // 詳細画面情報取得処理
  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!hasBrowsingRole) redirectToNoRolePage();

        // itemsのidから紐づくデータをすべて取得
        const aggregate = getAggregateNli011(itemId);

        const result = (await appContext.functions(["mongo/client", { collection: Collection.ITEMS, aggregate }])) as ItemsNli011StateType[];

        if (result.length < 1) redirectToNoDataPage();

        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限取得中はデータ取得しない
    if (isFetchingRole) return;
    if (itemId) void fetchData();
  }, [currentUser, itemId, forceUpdate, isFetchingRole, hasBrowsingRole, appContext]);

  return {
    fetchResult,
    fetchError,
    actionError,
    deleteItem,
    updateSku,
    addSku,
    deleteSku,
    selectRecommendTerm,
    selectRecommendScope,
  };
};

export default useNli011DbActions;
