import { useState, useEffect } from "react";

import { getAggregateChildPatients } from "../../utils/query";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import { formatDate } from "../../utils/utils";

// 親に紐づく被招待者の情報取得
const useChildPatientFetch = () => {
  const appContext = useFirebaseApp();

  const [fetchChildPatientResult, setFetchChildPatientResult] = useState<
    ChildPatientStateType[]
  >([]);
  const [fetchChildPatientError, setFetchChildPatientError] =
    useState<Error | null>(null);
  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregateChildPatients(id);
        const result = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_INVITATIONS, aggregate }])) as ChildPatientStateType[];

        // ストライプ情報を追加するための処理
        const updatedResult = await Promise.all(
          result.map(async (item) => {
            const stripeInfo = (await appContext.functions([
              "stripe/subscription/info"
            , item.stripeSubscriptionId])) as SubscriptionInfo;

            const nextBillingDateStr = stripeInfo.nextBillingDate
              ? formatDate(stripeInfo.nextBillingDate)
              : "";

            return {
              ...item,
              nextBillingDateStr,
            };
          }),
        );

        setFetchChildPatientResult(updatedResult);
      } catch (err) {
        setFetchChildPatientError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, id]);

  return { fetchChildPatientError, fetchChildPatientResult };
};

export default useChildPatientFetch;
