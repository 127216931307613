import React from "react";

export type TooltipProps = {
  direction?:
    | "top"
    | "right"
    | "under"
    | "left"
    | "topLeft"
    | "topRight"
    | "underLeft"
    | "underRight";
  disabled?: boolean;
  content: React.ReactNode;
  children: React.ReactNode;
};

const Tooltip = ({
  direction = "top",
  disabled = false,
  content,
  children,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [rect, setRect] = React.useState<DOMRect>();

  const tooltipClass = React.useMemo(() => {
    const directionClass = [`tooltip--${direction}`];

    return ["tooltip", ...directionClass].join(" ");
  }, [direction]);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target instanceof HTMLDivElement) {
      setIsOpen(true);
      setRect(event.target.getBoundingClientRect());
    }
  };

  const onMouseLeave = () => {
    setIsOpen(false);
  };
  const toolTipLocationStyles = React.useMemo(() => {
    if (!rect) {
      return {};
    }

    const positioningStyles = {} as React.CSSProperties;
    const spacing = 4;
    const pointerWidth = 5;

    switch (direction) {
      case "top":
        positioningStyles.top = `${rect.top - spacing - pointerWidth}px`;
        positioningStyles.left = `${rect.left + rect.width / 2}px`;
        positioningStyles.transform = `translateY(-100%) translateX(-50%)`;

        return positioningStyles;
      case "under":
        positioningStyles.top = `${rect.bottom + spacing + pointerWidth}px`;
        positioningStyles.left = `${rect.left + rect.width / 2}px`;
        positioningStyles.transform = `translateX(-50%)`;

        return positioningStyles;
      case "left":
        positioningStyles.top = `${rect.top + rect.height / 2}px`;
        positioningStyles.left = `${rect.left - spacing - pointerWidth}px`;
        positioningStyles.transform = `translateX(-100%) translateY(-50%)`;

        return positioningStyles;
      case "right":
        positioningStyles.top = `${rect.top + rect.height / 2}px`;
        positioningStyles.left = `${
          rect.left + rect.width + spacing + pointerWidth
        }px`;
        positioningStyles.transform = `translateY(-50%)`;

        return positioningStyles;
      case "topLeft":
        positioningStyles.top = `${rect.top - spacing - pointerWidth}px`;
        positioningStyles.left = `${rect.left}px`;
        positioningStyles.transform = `translateY(-100%)`;

        return positioningStyles;
      case "topRight":
        positioningStyles.top = `${rect.top - spacing - pointerWidth}px`;
        positioningStyles.left = `${rect.left + rect.width + spacing}px`;
        positioningStyles.transform = `translateX(-100%) translateY(-100%)`;

        return positioningStyles;
      case "underLeft":
        positioningStyles.top = `${rect.bottom + spacing + pointerWidth}px`;
        positioningStyles.left = `${rect.left}px`;

        return positioningStyles;
      case "underRight":
        positioningStyles.top = `${rect.bottom + spacing + pointerWidth}px`;
        positioningStyles.left = `${rect.left + rect.width + spacing}px`;
        positioningStyles.transform = `translateX(-100%)`;

        return positioningStyles;
      default:
        return positioningStyles;
    }
  }, [direction, rect]);

  return (
    <>
      <div
        className="tooltip-container"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
      {!disabled && isOpen && (
        <div className={tooltipClass} style={toolTipLocationStyles}>
          {content}
        </div>
      )}
    </>
  );
};

export default Tooltip;
