import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateAdminDoctor } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useNlc021NewDialog from "./useNlc021NewDialog";
import useStateCustomObj from "../../base/useStateCustomObj";
// import { formatDate } from "../../../utils/utils";

// ドクターを取得
const useNlc021DoctorFetch = (
  selectedValue: string,
  selectedDate: Date | undefined,
  otherTimeStr: string,
) => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [isOpen] = useNlc021NewDialog();
  const [doctors, setDoctors] = useStateCustomObj<AdminDoctorReservation[]>([]);
  const [doctorFetchError, setDoctorFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersAggregate = getAggregateAdminDoctor();
        const userResult = (await appContext.functions(["mongo/client", {
          collection: Collection.USERS, aggregate:
            usersAggregate,
        }])) as AdminDoctor[];

        // ドクターの予約のOKかNGを判定
        const doctorInfo = userResult.map((user) => ({
          title: user.full_name,
          value: user._id,
          email: user.email,
          disabled: false,
        }));

        setDoctors(doctorInfo);
      } catch (err) {
        setDoctorFetchError(checkFetchErr(err));
      }
    };

    if (isOpen) void fetchData();
  }, [currentUser, isOpen, setDoctors, selectedValue, selectedDate, otherTimeStr, appContext]);

  return { doctors, doctorFetchError };
};

export default useNlc021DoctorFetch;
