import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import {
  ADMIN_PAYMENT_SUB_TAB_ITEMS,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
} from "../constants/common";
import {
  downloadCsvNld002,
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectPaymentSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { setPage } from "../hooks/component/pagination/usePagination";
import useNld002Actions from "../hooks/pages/NLD002/useNld002Actions";
import { convertNld002Table } from "../utils/convertDisplay";
import { ADMIN } from "../constants/pagePaths";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setId } from "../hooks/common/useId";
import useNld002Conditions from "../hooks/pages/NLD002/useNld002Conditions";

const NLD002 = () => {
  const [conditions, setConditions] = useNld002Conditions();
  const [users, setUsers] = React.useState<Nld002TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | undefined>(
    conditions.startDate,
  );
  const [endDate, setEndDate] = React.useState<Date | undefined>(
    conditions.endDate,
  );

  const { result, fetchError } = useNld002Actions({
    sortExamples,
    submittedSearchCriteria,
  });
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNld002Table(result);
    setUsers(convertResult);
  }, [result]);

  const handleChangeHospitalName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, hospitalName: value }));
  };

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  // const handleChangePhoneNum = (value: string) => {
  //   setSearchCriteria((prevState) => ({ ...prevState, phoneNum: value }));
  // };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 検索ボタン押下時のイベント
  const handleSearchUsers = () => {
    // 検索条件を確定
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () => {
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLD002);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  // CSVダウンロードボタン押下時の処理
  const handleDownloadCSV = () => {
    downloadCsvNld002(users, window.location.pathname);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = users.map((user) => ({ ...user, checked }));
    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // 支払日変更
  const handleChangeRangeDate = React.useCallback(
    (startDate?: Date, endDate?: Date) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        startDate,
        endDate,
      }));
    },
    [],
  );

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToUserDetails = (hospitalId: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
      startDate,
      endDate,
    });
    setId(hospitalId);
    navigate(ADMIN.NLD003);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={3}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <ToggleButton
              size="large"
              selectedButton="1"
              items={ADMIN_PAYMENT_SUB_TAB_ITEMS}
              onClick={handleSelectPaymentSubTab}
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <LayoutBox align="center" justify="between" gap="3x" fullWidth>
                  <FormSet label="医療機関" labelWidth="90px" base flex="1">
                    <Input
                      value={searchCriteria.hospitalName}
                      width="100%"
                      onChange={handleChangeHospitalName}
                    />
                  </FormSet>
                  <FormSet label="お客様名" labelWidth="90px" base flex="1">
                    <Input
                      value={searchCriteria.name}
                      placeholder="お客様名"
                      width="100%"
                      onChange={handleChangeName}
                    />
                  </FormSet>
                  {/* <FormSet label="電話番号" labelWidth="90px" base flex="1">
                    <Input
                      value={searchCriteria.phoneNum}
                      placeholder="電話番号"
                      width="100%"
                      onChange={handleChangePhoneNum}
                    />
                  </FormSet> */}
                  <FormSet label="実施日" labelWidth="90px" base flex="1">
                    <DatePicker
                      type="range"
                      placeholder="開始日"
                      placeholderEnd="終了日"
                      width="100%"
                      onChangeRangeDate={handleChangeRangeDate}
                      selectedDateRange={{ startDate, endDate }}
                    />
                  </FormSet>
                </LayoutBox>
                <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                  <FormSet label="項目" labelWidth="90px" base>
                    <LayoutBox
                      align="center"
                      justify="start"
                      gap="3x"
                      fullHeight
                    >
                      <Checkbox
                        label="のうKNOW"
                        checked={searchCriteria.status.isKnow}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isKnow")
                        }
                      />
                      <Checkbox
                        label="MRI検査"
                        checked={searchCriteria.status.isMRIExam}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isMRIExam")
                        }
                      />
                      <Checkbox
                        label="FDG-PET検査"
                        checked={searchCriteria.status.isFDGPETScan}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isFDGPETScan")
                        }
                      />
                      <Checkbox
                        label="あたまの健康チェック"
                        checked={searchCriteria.status.isHealthCheck}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isHealthCheck")
                        }
                      />
                      <Checkbox
                        label="面談"
                        checked={searchCriteria.status.isHealthConsultation}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isHealthConsultation")
                        }
                      />
                      <Checkbox
                        label="面談（無料）"
                        checked={searchCriteria.status.isHealthFreeConsultation}
                        onChecked={(checked: boolean) =>
                          handleChangeCheckItem(
                            checked,
                            "isHealthFreeConsultation",
                          )
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox align="center" justify="end" fullWidth>
                  <Button
                    type="secondary"
                    size="medium"
                    icon="file_download"
                    onClick={handleDownloadCSV}
                  >
                    CSVダウンロード
                  </Button>
                </LayoutBox>

                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="291px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="医療機関"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="291px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        都道府県
                      </TableColumn>
                      <TableColumn
                        canSort
                        sortedDirection={sortExamples[2].sortDirection}
                        width="291px"
                        id="col-3"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>
                      <TableColumn width="77px" id="col-4" />
                      <TableColumn
                        canSort
                        sortedDirection={sortExamples[3].sortDirection}
                        width="291px"
                        id="col-5"
                        onClickSort={onClickSort}
                      >
                        項目
                      </TableColumn>
                      <TableColumn textAlign="right" width="291px" id="col-6">
                        金額
                      </TableColumn>
                      <TableColumn width="291px" id="col-7">
                        実施日
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id} isSelected={user.checked}>
                      <TableCell>
                        <Checkbox
                          label={user.hospitalName}
                          checked={user.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{user.prefecture}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => navigateToUserDetails(user.hospitalId)}
                          type="sub"
                          size="small"
                        >
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>{user.examination}</TableCell>
                      <TableCell textAlign="right">{user.amount}</TableCell>
                      <TableCell>{user.examDateStr}</TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLD002;
