import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc021NewDialog } from "./useNlc021NewDialog";
import { parseDateString } from "../../../utils/utils";
import useSettingsFetch from "../../common/useSettingsFetch";

const useNlc021UpdateReservationConfirmed = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const { fetchSettingsResult, fetchSettingsError } = useSettingsFetch();

  // 面談予約確定処理
  const updateReservationConfirmed = (
    id: string,
    selectDoctorObj: AdminDoctorReservation,
    fixDateStartStr: string,
    modifyOnly: boolean,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 開始時間
        const fixDateStart = parseDateString(fixDateStartStr);

        // 枠時間
        const interval = fetchSettingsResult?.interview_interval ?? 0;

        // 終了時間
        const fixDateEnd = fixDateStart
          ? new Date(
              fixDateStart.getFullYear(),
              fixDateStart.getMonth(),
              fixDateStart.getDate(),
              fixDateStart.getHours() + Math.floor(interval / 60),
              fixDateStart.getMinutes() + (interval % 60),
              fixDateStart.getSeconds(),
            )
          : null;

        // 予約確定API
        await appContext.functions(["interview/fix", [
          id,
          fixDateStart,
          fixDateEnd,
          selectDoctorObj.value,
          selectDoctorObj.title,
          selectDoctorObj.email,
          null,
          modifyOnly,
        ]]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc021NewDialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    fetchSettingsError,
    updateReservationConfirmed,
  };
};

export default useNlc021UpdateReservationConfirmed;
