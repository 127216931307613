import { useState, useEffect } from "react";

import {
  getAggregateAllSalesYear,
  getAggregateNlf006,
} from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  ROLE_KEY,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { extractUniqueYearsFromData } from "../../../utils/utils";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

type Nlf006FetchParams = {
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLF006;
};

type SalesType = {
  payment_date: Date;
};

type YearType = {
  value: string;
  label: string;
};

const useNlf006DbActions = ({ submittedSearchCriteria }: Nlf006FetchParams) => {
  const appContext = useFirebaseApp();

  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<
    SalesNlf006StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [selectSalesYear, setSelectSalesYear] = useState<YearType[]>([]);
  const { salesYear, salesMonth } = submittedSearchCriteria;

  const colName = "_id";
  const sortDirection = "desc";

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.EC_SALES_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateNlf006(
          salesYear,
          salesMonth,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.SALES, aggregate }])) as AggregateResultType<SalesNlf006StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    // 権限があるときのみデータ取得
    if (hasBrowsingRole) void fetchData();
  }, [skip, perPage, colName, sortDirection, setFetchResult, salesYear, salesMonth, hasBrowsingRole, appContext]);

  // 売り上げ年のセレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateAllSalesYear();
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.SALES, aggregate }])) as SalesType[];

        setSelectSalesYear(
          extractUniqueYearsFromData({
            key: "payment_date",
            data: aggregateResult,
          }),
        );
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [appContext]);

  return {
    fetchResult,
    fetchError,
    selectSalesYear,
  };
};

export default useNlf006DbActions;
