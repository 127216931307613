import { useState } from "react";

import useOneNotificationFetch from "../../common/useOneNotificationFetch";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  NotificationStatusID,
  ROLE_KEY,
} from "../../../constants/common";
import { ADMIN } from "../../../constants/pagePaths";
import { useNavigateCustom } from "../../base/usePageTransitionCustom";
import {
  closeActionLoading,
  openDeleteActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

const useNlg003DbActions = () => {
  const navigate = useNavigateCustom();
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // お知らせ取得
  const { fetchResult, fetchError } = useOneNotificationFetch();

  // 配信停止トグルボタン動作
  const handleDeliveryToggle = (isDeliveryStopped: boolean) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.NOTICE_DETAIL_EDIT, appContext);

        const status = isDeliveryStopped
          ? NotificationStatusID.UNPUBLISHED
          : NotificationStatusID.NOW_OPEN;
        const updateData = { status };
        await appContext.functions(["mongo/client", {
          collection: Collection.NOTIFICATIONS,
          updateOne: { filter: { _id: id }, update: { $set: updateData } }
        }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // お知らせ削除
  const handleNotificationDelete = () => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.NOTICE_DETAIL_EDIT, appContext);

        await appContext.functions(["mongo/client", {
          collection: Collection.NOTIFICATIONS,
          updateOne: {
            filter: { _id: id },
            update: {
              $set: {
                status: NotificationStatusID.DELETED,
                last_updated_at: new Date(),
              },
            },
          }
        }]);

        // 一覧画面遷移
        navigate(ADMIN.NLG001);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error || fetchError,
    fetchResult,
    handleDeliveryToggle,
    handleNotificationDelete,
  };
};

export default useNlg003DbActions;
