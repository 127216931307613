import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../base/useLoadingAction";
import { Collection } from "../../constants/common";

const useEvaluationDbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);

  // ユーザ評価を更新
  const handleUpdateEvaluation = (id: string, evaluation: string) => {
    void (async () => {
      try {
        openUpdateActionLoading();
        await appContext.functions(["mongo/client", {
          collection: Collection.PATIENTS,
          updateOne: {
            filter: { _id: id },
            update: {
              $set: {
                evaluation: Number(evaluation),
                updated_at: new Date(),
              },
            },
          }
        }]);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    error,
    handleUpdateEvaluation,
  };
};

export default useEvaluationDbActions;
