import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";

export type ListItemProps = {
  id: string;
  isSelected?: boolean;
  narrow?: boolean;
  noBorder?: boolean;
  left?: JSX.Element;
  right?: JSX.Element;
  center?: JSX.Element;
  alignCenter?: boolean;
  onClick?: (value: string) => void;
};

const ListItem = ({
  id,
  isSelected = false,
  narrow = false,
  noBorder = false,
  left,
  right,
  center,
  onClick = () => {},
  alignCenter = false,
}: ListItemProps) => {
  const listItemClass = React.useMemo(() => {
    const noBorderClass = noBorder ? ["list-item--no-border"] : [];
    const narrowClass = narrow ? ["list-item--narrow"] : [];
    const selectedClass = isSelected ? ["list-item--selected"] : [];
    const alignClass = alignCenter ? ["list-item--align-center"] : [];

    return [
      "list-item",
      ...selectedClass,
      ...narrowClass,
      ...noBorderClass,
      ...alignClass,
    ].join(" ");
  }, [isSelected, narrow, noBorder, alignCenter]);

  const _onClick = React.useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    onClick && onClick(id);
  }, [id, onClick]);

  return (
    <div className={listItemClass} onClick={_onClick}>
      {left && <span className="list-item__left">{left}</span>}
      {center && <span className="list-item__center">{center}</span>}
      {right && (
        <LayoutBox fullHeight align="center">
          {right}
        </LayoutBox>
      )}
    </div>
  );
};

export default ListItem;
