import React from "react";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
// changeEmp
import usePagination, {
  setPage,
  setPerPage,
} from "../../hooks/component/pagination/usePagination";

export type PaginationProps = {
  itemsPerPage?: number;
  input?: boolean;
  pagesInformation?: boolean;
  selectItemsPerPage?: boolean;
};

const ITEMS_PER_PAGE_OPTIONS = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

const Pagination = ({
  // changeEmp
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemsPerPage = 10,
  input = true,
  pagesInformation = true,
  selectItemsPerPage = true,
}: PaginationProps) => {
  // changeEmp
  const [pagination] = usePagination();
  const { total, perPage } = pagination;
  const innerPage = pagination.page;

  const [innerItemsPerPage, setCurrentInnerItemsPerPage] =
    React.useState(perPage);

  const totalPageNumber = React.useMemo(
    () => Math.ceil(total / innerItemsPerPage),
    [innerItemsPerPage, total],
  );

  // changeEmp
  const _onChangeItemsPerPage = React.useCallback((item: string) => {
    const page = parseInt(item);
    setCurrentInnerItemsPerPage(page);
    setPerPage(page);
  }, []);

  React.useEffect(() => {
    // changeEmp
    setCurrentInnerItemsPerPage(perPage);
    setPage(innerPage);
  }, [innerPage, perPage]);

  const getPaginationInfoText = React.useCallback(() => {
    const fromItems = (innerPage - 1) * innerItemsPerPage + 1;
    const itemsInPage = innerItemsPerPage * innerPage;
    const toItems = itemsInPage > total ? total : itemsInPage;

    return `${fromItems}〜${toItems}件（全${total}件中）`;
  }, [innerPage, innerItemsPerPage, total]);

  const goToNextPage = React.useCallback(() => {
    if (innerPage < totalPageNumber) {
      // changeEmp
      setPage(innerPage + 1);
    }
  }, [innerPage, totalPageNumber]);

  const goToPreviousPage = React.useCallback(() => {
    if (innerPage > 1) {
      // changeEmp
      setPage(innerPage - 1);
    }
  }, [innerPage]);

  const goToFirstPage = () => {
    // changeEmp
    setPage(1);
  };

  const goToLastPage = () => {
    // changeEmp
    setPage(totalPageNumber);
  };

  const goToPage = (page: number) => {
    setPage(page);
  };

  const getRange = React.useCallback((from: number, to: number, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
      range.push(i.toString());
      i += step;
    }

    return range;
  }, []);

  const getPageNumbers = React.useCallback(() => {
    const ellipsis = "0";
    const pageNeighbors = 1;
    const visibleNumbers = 5;
    const totalBlocks = visibleNumbers + 2;

    if (totalPageNumber > totalBlocks) {
      const firstPage = Math.max(2, innerPage - pageNeighbors);
      const lastPage = Math.min(totalPageNumber - 1, innerPage + pageNeighbors);
      let pages = getRange(firstPage, lastPage);
      const hiddenLeft = firstPage > 2;
      const hiddenRight = totalPageNumber - lastPage > 1;
      const hiddenTotal = visibleNumbers - (pages.length + 1);

      switch (true) {
        case hiddenLeft && !hiddenRight: {
          const extraPages = getRange(firstPage - hiddenTotal, firstPage - 1);
          pages = [ellipsis, ...extraPages, ...pages];
          break;
        }
        case !hiddenLeft && hiddenRight: {
          const extraPages = getRange(lastPage + 1, lastPage + hiddenTotal);
          pages = [...pages, ...extraPages, ellipsis];
          break;
        }
        case hiddenLeft && hiddenRight:
        default: {
          pages = [ellipsis, ...pages, ellipsis];
          break;
        }
      }

      return ["1", ...pages, totalPageNumber.toString()];
    }

    return getRange(1, totalPageNumber);
  }, [getRange, innerPage, totalPageNumber]);

  const paginationButtonClass = React.useCallback(
    (page: number) => {
      const selectedClass =
        page === innerPage ? ["pagination__btn--selected"] : [];
      const ellipsisClass = page === 0 ? ["pagination__btn--ellipsis"] : [];

      return ["pagination-button", ...selectedClass, ...ellipsisClass].join(
        " ",
      );
    },
    [innerPage],
  );

  /** Input */
  const [inputError, setInputError] = React.useState(false);

  const inputClass = React.useMemo(
    () =>
      inputError
        ? "pagination__input pagination__input--error"
        : "pagination__input",
    [inputError],
  );

  const setInputPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const page = parseInt(event.target.value, 10);
      // changeEmp
      setPage(page);
    },
    [],
  );
  const enterSendPage = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const page = parseInt((event.target as HTMLInputElement).value, 10);
        if (page >= 1 && page <= totalPageNumber) {
          // changeEmp
          setPage(page);
          setInputError(false);
        } else {
          // changeEmp
          setPage(innerPage);
          setInputError(true);
        }
      }
    },
    [innerPage, totalPageNumber],
  );
  const blurSendPage = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const page = parseInt(event.target.value, 10);
      if (page >= 1 && page <= totalPageNumber) {
        // changeEmp
        setPage(page);
        setInputError(false);
      } else {
        // changeEmp
        setPage(innerPage);
        setInputError(true);
      }
    },
    [innerPage, totalPageNumber],
  );

  const onFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
    },
    [],
  );

  return (
    <div className="pagination">
      {(pagesInformation || selectItemsPerPage) && (
        <span className="pagination__info">
          {pagesInformation && (
            <span className="pagination__info-text">
              {getPaginationInfoText()}
            </span>
          )}
          {selectItemsPerPage && (
            <span className="pagination__select-items-per-page">
              <Dropdown
                value={innerItemsPerPage.toString()}
                size="small"
                width="66px"
                onChange={_onChangeItemsPerPage}
                items={ITEMS_PER_PAGE_OPTIONS}
              />
              <span>件表示</span>
            </span>
          )}
        </span>
      )}
      <span className="pagination__pages">
        <Button
          color="neutral"
          type="sub"
          size="medium"
          shape="square"
          icon="keyboard_double_arrow_left"
          disabled={innerPage === 1}
          onClick={goToFirstPage}
        />
        <Button
          color="neutral"
          type="sub"
          size="medium"
          shape="square"
          icon="keyboard_arrow_left"
          disabled={innerPage === 1}
          onClick={goToPreviousPage}
        />
        {getPageNumbers().map((page, index) => (
          <button
            type="button"
            className={paginationButtonClass(parseInt(page))}
            key={`pagination-${index}`}
            disabled={innerPage === parseInt(page) || page === "0"}
            onClick={() => goToPage(parseInt(page))}
          >
            {page !== "0" ? page : ""}
            {page === "0" && <Icon icon="more_horiz" size="small" />}
          </button>
        ))}
        <Button
          color="neutral"
          type="sub"
          size="medium"
          shape="square"
          icon="keyboard_arrow_right"
          disabled={innerPage === totalPageNumber}
          onClick={goToNextPage}
        />
        <Button
          color="neutral"
          type="sub"
          size="medium"
          shape="square"
          icon="keyboard_double_arrow_right"
          disabled={innerPage === totalPageNumber}
          onClick={goToLastPage}
        />
      </span>
      {input && (
        <span className="pagination__input-container">
          <input
            className={inputClass}
            value={innerPage}
            min="1"
            max={totalPageNumber}
            type="number"
            onChange={setInputPage}
            onKeyDown={enterSendPage}
            onBlur={blurSendPage}
            onFocus={onFocus}
          />
          <span>ページ</span>
        </span>
      )}
    </div>
  );
};

export default Pagination;
