import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNlc020 } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import useForceUpdate from "../../common/useForceUpdate";

// 予約状況を取得
const useNlc020DbActions = () => {
  const appContext = useFirebaseApp();

  const [books, setBooks] = useState<PatientNlc020StateType[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const aggregate = getAggregateNlc020(id);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as PatientNlc020StateType[];

        setBooks(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, id, forceUpdate]);

  return { fetchError, books };
};

export default useNlc020DbActions;
