import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import generateUniqueId from "../../../utils/generateUniqueId";
import {
  closeActionLoading,
  openCreateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { FileType } from "../../../components/FileUpload/FileUpload";
import useForceUpdate from "../../common/useForceUpdate";
import { NotificationStatusID, ROLE_KEY } from "../../../constants/common";
import { convertUrlToBase64 } from "../../../utils/utils";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

type InsertData = {
  _id: string;
  title: string;
  detail: string;
  status: number;
  deliveried_at: Date;
  last_updated_admin_id: string | undefined;
  image?: string;
};

const useNlg002DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // お知らせ登録
  const handleRegisterNotification = (
    title: string,
    detail: string,
    selectedDate: Date,
    uploadFiles: FileType[],
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.NOTICE_LIST_ADD, appContext);

        const id = generateUniqueId();

        let base64Url;
        if (uploadFiles.length) {
          const imageUrl = uploadFiles[0].url;
          base64Url = await convertUrlToBase64(imageUrl);
        }

        // 登録データ
        const insertData: InsertData = {
          _id: id,
          title,
          detail,
          status: NotificationStatusID.UNPUBLISHED,
          deliveried_at: selectedDate,
          last_updated_admin_id: currentUser?.uid,
        };
        if (base64Url) {
          insertData.image = base64Url;
        }
        // お知らせAPI
        await appContext.functions(["upsertNotificationApi", insertData]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterNotification,
  };
};

export default useNlg002DbActions;
