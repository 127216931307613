import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Header from "../components/Header/Header";
import Link from "../components/Link/Link";
import Icon from "../components/Icon/Icon";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import useEmailInput, {
  checkEmailInputError,
  setEmail,
} from "../hooks/input/useEmailInput";
import { sendVerification, useFirebaseApp } from "../contexts/FirebaseApp";
import { ADMIN, COMMON, HEADER_LINK } from "../constants/pagePaths";
import { checkActionErr } from "../contexts/CustomErrorBoundary";
import Logo from "../components/Logo/Logo";

type LocationState = {
  isFailed: boolean;
  isChangeEmailFailed: boolean;
};

/* 認証メール再送画面 */
const NLJ004 = () => {
  const navigate = useNavigate();
  const appContext = useFirebaseApp();
  const location = useLocation();
  const isFailed = (location.state as LocationState)?.isFailed || false;
  const isChangeEmailFailed =
    (location.state as LocationState)?.isChangeEmailFailed || false;
  const [{ email }] = useEmailInput();

  const handleSendEmail = async () => {
    try {
      if (isChangeEmailFailed) {
        // メールアドレス変更
        // ファンクション
        await appContext.functions(["changeEmail", [
          appContext.userObject?._id as string,
          email,
          true,
        ]]);
      } else {
        // ファンクションの実行
        await sendVerification(email.value);
      }
      navigate(COMMON.NLJ005);
    } catch (error) {
      const err = error as Error;
      checkActionErr(err);
    }
  };

  // 戻る
  const handleBack = () => {
    navigate(ADMIN.NLA001);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <Header
            leftControl={
              <Link
                url={HEADER_LINK}
                external={false}
                linkClassName="header__left-logo"
              >
                <Logo size="small" type="white-horizontal" />
              </Link>
            }
          />
        </div>
        <div className="util-mb-48" />
        <LayoutBox fullHeight fullWidth justify="center">
          <Sheet
            type="border"
            maxWidth="640px"
            minHeight="247px"
            className="util-flex--align-center util-flex--justify-center"
          >
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              gap="3x"
              fullWidth
            >
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="1x"
                fullWidth
              >
                {isFailed && (
                  <>
                    <Icon icon="error" size="large" color="#A21B11" />
                    <Text size="base">
                      メールの有効期限が過ぎているか処理中にエラーが発生しました。
                    </Text>
                    <br />
                  </>
                )}
                <Text size="xl" bold>
                  メールを送信します。
                </Text>
                <Text size="base">
                  メールアドレスを入力後、メール送信ボタンを押下してください。
                </Text>

                <FormSet
                  label="メールアドレス"
                  vertical
                  errorTextList={[email.validationMessage]}
                >
                  <Input
                    size="default"
                    placeholder="メールアドレス"
                    width="100%"
                    value={email.value}
                    onChange={(value) => setEmail(value)}
                    error={email.isError}
                  />
                </FormSet>
              </LayoutBox>
              <LayoutBox>
                {!isFailed && (
                  <Button
                    color="neutral"
                    type="sub"
                    size="large"
                    iconPosition="left"
                    width="230px"
                    onClick={handleBack}
                  >
                    戻る
                  </Button>
                )}

                <Button
                  size="large"
                  iconPosition="left"
                  width="230px"
                  onClick={() => {
                    void handleSendEmail();
                  }}
                  disabled={checkEmailInputError()}
                >
                  メール送信
                </Button>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
    </>
  );
};

export default NLJ004;
