import { useEffect, useState } from "react";
import {
  getAggregateNlb001ChartMonth,
  getAggregateNlb001ChartWeek,
  getAggregateNlb001ChartYear,
  getAggregateNlb001TaskChats,
  getAggregateNlb001TaskInterview,
  getAggregateNlb001TaskMri,
  getAggregateNlb001TaskNouknow,
  getAggregateNlb001TaskOrders,
  getAggregateNlb001TaskPet,
} from "../../../utils/query";
import {
  ModalityID,
  Collection,
  INIT_SEARCH_CRITERIA,
  UserStatusID,
  UserTypeID,
  Nlb001CategoryID,
} from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";

import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useStateCustomObj from "../../base/useStateCustomObj";

type Nlb001FetchParams = {
  searchCriteria: typeof INIT_SEARCH_CRITERIA.NLB001;
};

type ChartData = {
  chartData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
  maxYScale: number;
  yearOption: {
    label: string;
    value: string;
  }[];
  userCount?: number;
  yoseCount?: number;
  mriCount?: number;
  petCount?: number;
  interviewCount?: number;
};

type CountData = {
  _id: string;
  count: number;
};

// 符号付与
const differenceCountStr = (differenceCount: number) =>
  `${differenceCount === 0 ? "" : differenceCount >= 0 ? "+" : "-"}${Math.abs(
    differenceCount,
  )}`;

// 年から12の月を返す
const generateMonthStrings = (argYear: string) =>
  Array.from({ length: 12 }, (_, index) => {
    const month = index + 1;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${argYear}/${formattedMonth}`;
  });

// 月の日付を返す
const generateMonthDayStrings = (argYear: string, argMonth: string) => {
  const year = parseInt(argYear, 10);
  const month = parseInt(argMonth, 10);
  const lastDay = new Date(year, month, 0).getDate();

  return Array.from({ length: lastDay }, (_, index) => {
    const day = index + 1;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${argYear}/${formattedMonth}/${formattedDay}`;
  });
};

// 当日を含む週の日付を返す
const generateWeekDayStrings = (argDay: Date) => {
  const days = [...Array(7).keys()].map((index) => {
    const oneWeekAgo = new Date(
      argDay.getTime() - (6 - index) * 24 * 60 * 60 * 1000,
    );
    const year = oneWeekAgo.getFullYear();
    const month = oneWeekAgo.getMonth() + 1;
    const day = oneWeekAgo.getDate();
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}/${formattedMonth}/${formattedDay}`;
  });

  return days;
};

// 当日を含まない週の日付を返す
const generateWeekDayNoTodayStrings = (argDay: Date) => {
  const days = [...Array(7).keys()].map((index) => {
    const oneWeekAgo = new Date(
      argDay.getTime() - (7 - index) * 24 * 60 * 60 * 1000,
    );
    const year = oneWeekAgo.getFullYear();
    const month = oneWeekAgo.getMonth() + 1;
    const day = oneWeekAgo.getDate();
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}/${formattedMonth}/${formattedDay}`;
  });

  return days;
};

// 件数初期値
const initNumberResult = {
  user: {
    todayCount: 0,
    differenceCount: 0,
    differenceCountStr: "0",
  },
  interview: {
    todayCount: 0,
    differenceCount: 0,
    differenceCountStr: "0",
  },
  mri: {
    todayCount: 0,
    differenceCount: 0,
    differenceCountStr: "0",
  },
  pet: {
    todayCount: 0,
    differenceCount: 0,
    differenceCountStr: "0",
  },
};

// ユーザグラフ初期値
const initChartResult = {
  chartData: {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
      },
    ],
  },
  maxYScale: 0,
  yearOption: [
    {
      label: new Date().getFullYear().toString(),
      value: new Date().getFullYear().toString(),
    },
  ],
  userCount: 0,
  yoseCount: 0,
  mriCount: 0,
  petCount: 0,
  interviewCount: 0,
};

const useNlb001DbActions = ({ searchCriteria }: Nlb001FetchParams) => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  // 件数管理
  const [fetchNumberResult, setFetchNumberResult] =
    useStateCustomObj(initNumberResult);
  // ユーザグラフ管理
  const [userChartResult, setUserChartResult] =
    useStateCustomObj<ChartData>(initChartResult);
  // 寄席グラフ管理 再生回数無し
  // const [yoseChartResult, setYoseChartResult] =
  //   useStateCustomObj<ChartData>(initChartResult);
  // 予約グラフ管理
  const [appointmentChatResult, setAppointmentChatResult] =
    useStateCustomObj<ChartData>(initChartResult);
  // タスク管理
  const [taskResult, setTaskResult] = useStateCustomObj<Nlb001TaskStateType[]>(
    [],
  );

  const [fetchError, setFetchError] = useState<Error | null>(null);

  const {
    number: { selectedToggle, searchDay },
    user: {
      selectedMembershipToggle,
      year: userYear,
      month: userMonth,
      day: userDay,
    },
    appointment: {
      selectedIndex,
      selectedAppointmentToggle,
      year: appointmentYear,
      month: appointmentMonth,
      day: appointmentDay,
    },
    task: { category },
  } = searchCriteria;

  // 件数取得
  useEffect(() => {
    addLoadCount();
    const fetchNumberData = async () => {
      try {

        // 当日
        const startOfDay = new Date(searchDay);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(searchDay);
        endOfDay.setHours(23, 59, 59, 999);

        // 前日
        const startOfYesterday = new Date(
          startOfDay.getTime() - 24 * 60 * 60 * 1000,
        );
        startOfYesterday.setHours(0, 0, 0, 0);
        const endOfYesterday = new Date(
          startOfDay.getTime() - 24 * 60 * 60 * 1000,
        );
        endOfYesterday.setHours(23, 59, 59, 999);

        if (selectedToggle === "1") {
          /* 日 */
          // ユーザ 当日
          const queryUserToday = {
            activation_date: {
              $gte: startOfDay,
              $lt: endOfDay,
            },
          };
          const todayUserCount = (await appContext.functions(["mongo/client", { collection: Collection.USERS, countDocuments: { filter: queryUserToday } }])) as number;

          const queryUserBefore = {
            activation_date: {
              $gte: startOfYesterday,
              $lt: endOfYesterday,
            },
          };
          // ユーザ 昨日
          const beforeUserCount = (await appContext.functions(["mongo/client", { collection: Collection.USERS, countDocuments: { filter: queryUserBefore} }])) as number;

          const differenceUserCount = todayUserCount - beforeUserCount;
          const differenceUserCountStr =
            differenceCountStr(differenceUserCount);

          // 面談予約 当日
          const queryInterviewToday = {
            interview_fix_date_start: {
              $gte: startOfDay,
              $lt: endOfDay,
            },
          };
          const todayInterviewCount = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_INTERVIEWS, countDocuments: { filter:
            queryInterviewToday,
          } }])) as number;

          // 面談予約 昨日
          const queryInterviewBefore = {
            interview_fix_date_start: {
              $gte: startOfYesterday,
              $lt: endOfYesterday,
            },
          };
          const beforeInterviewCount = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_INTERVIEWS, countDocuments: { filter:
            queryInterviewBefore,
          } }])) as number;

          const differenceInterviewCount =
            todayInterviewCount - beforeInterviewCount;
          const differenceInterviewCountStr = differenceCountStr(
            differenceInterviewCount,
          );

          // MRI予約 当日
          const queryMriToday = {
            modality: ModalityID.MRI,
            fix_book_date_start: {
              $gte: startOfDay,
              $lt: endOfDay,
            },
          };
          const todayMriCount = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, countDocuments: { filter:
            queryMriToday,
          } }])) as number;

          // MRI予約 昨日
          const queryMriBefore = {
            modality: ModalityID.MRI,
            fix_book_date_start: {
              $gte: startOfYesterday,
              $lt: endOfYesterday,
            },
          };
          const beforeMriCount = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, countDocuments: { filter:
            queryMriBefore,
          } }])) as number;

          const differenceMriCount = todayMriCount - beforeMriCount;
          const differenceMriCountStr = differenceCountStr(differenceMriCount);

          // PET検査 当日
          const queryPetToday = {
            modality: ModalityID.PET,
            fix_book_date_start: {
              $gte: startOfDay,
              $lt: endOfDay,
            },
          };
          const todayPetCount = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, countDocuments: { filter:
            queryPetToday,
          } }])) as number;

          // PET検査 昨日
          const queryPetBefore = {
            modality: ModalityID.PET,
            fix_book_date_start: {
              $gte: startOfYesterday,
              $lt: endOfYesterday,
            },
          };
          const beforePetCount = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, countDocuments: { filter:
            queryPetBefore,
          } }])) as number;

          const differencePetCount = todayPetCount - beforePetCount;
          const differencePetCountStr = differenceCountStr(differencePetCount);

          setFetchNumberResult({
            user: {
              todayCount: todayUserCount,
              differenceCount: differenceUserCount,
              differenceCountStr: differenceUserCountStr,
            },
            interview: {
              todayCount: todayInterviewCount,
              differenceCount: differenceInterviewCount,
              differenceCountStr: differenceInterviewCountStr,
            },
            mri: {
              todayCount: todayMriCount,
              differenceCount: differenceMriCount,
              differenceCountStr: differenceMriCountStr,
            },
            pet: {
              todayCount: todayPetCount,
              differenceCount: differencePetCount,
              differenceCountStr: differencePetCountStr,
            },
          });
        } else if (selectedToggle === "2") {
          /* 週 */
          const weekDayArray = generateWeekDayStrings(searchDay);
          const startDateStr = weekDayArray[0].slice(-2);
          const lastDateStr = weekDayArray[weekDayArray.length - 1].slice(-2);
          const weekDayBeforeArray = generateWeekDayNoTodayStrings(searchDay);
          const startDateBeforeStr = weekDayBeforeArray[0].slice(-2);
          const lastDateBeforeStr =
            weekDayBeforeArray[weekDayArray.length - 1].slice(-2);
          const year = searchDay.getFullYear().toString();
          const month = searchDay.getMonth() + 1;
          const formattedMonth = month < 10 ? `0${month}` : `${month}`;

          // ユーザ
          // 当日を含む1週間前のユーザ数取得
          const todayUserSearchCondition = {
            activation_date: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
              ),
            },
          };
          const todayUserAggregate = getAggregateNlb001ChartWeek(
            todayUserSearchCondition,
            "activation_date",
          );
          const todayUserResult = (await appContext.functions(["mongo/client", {
            collection: Collection.USERS, aggregate:
              todayUserAggregate,
          }])) as CountData[];

          const todayUserCount = todayUserResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // ユーザ
          // 当日を含まない1週間前のユーザ数取得
          const beforeUserSearchCondition = {
            activation_date: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateBeforeStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateBeforeStr} 23:59:59`,
              ),
            },
          };
          const beforeUserAggregate = getAggregateNlb001ChartWeek(
            beforeUserSearchCondition,
            "activation_date",
          );
          const beforeUserResult = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate:
            beforeUserAggregate,
          }])) as CountData[];

          const beforeUserCount = beforeUserResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differenceUserCount = todayUserCount - beforeUserCount;

          const differenceUserCountStr =
            differenceCountStr(differenceUserCount);

          // 面談予約
          // 当日を含む1週間前の面談予約取得
          const todayInterviewSearchCondition = {
            interview_fix_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
              ),
            },
          };
          const todayInterviewAggregate = getAggregateNlb001ChartWeek(
            todayInterviewSearchCondition,
            "interview_fix_date_start",
          );
          const todayInterviewResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate:
            todayInterviewAggregate,
          }])) as CountData[];

          const todayInterviewCount = todayInterviewResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // 面談予約
          // 当日を含まない1週間前の面談予約取得
          const beforeInterviewSearchCondition = {
            interview_fix_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateBeforeStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateBeforeStr} 23:59:59`,
              ),
            },
          };
          const beforeInterviewAggregate = getAggregateNlb001ChartWeek(
            beforeInterviewSearchCondition,
            "interview_fix_date_start",
          );
          const beforeInterviewResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate:
            beforeInterviewAggregate,
          }])) as CountData[];

          const beforeInterviewCount = beforeInterviewResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differenceInterviewCount =
            todayInterviewCount - beforeInterviewCount;

          const differenceInterviewCountStr = differenceCountStr(
            differenceInterviewCount,
          );

          // MRI予約
          // 当日を含む1週間前の面談予約取得
          const todayMriSearchCondition = {
            modality: ModalityID.MRI,
            fix_book_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
              ),
            },
          };
          const todayMriAggregate = getAggregateNlb001ChartWeek(
            todayMriSearchCondition,
            "fix_book_date_start",
          );
          const todayMriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            todayMriAggregate,
          }])) as CountData[];

          const todayMriCount = todayMriResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // MRI予約
          // 当日を含まない1週間前の面談予約取得
          const beforeMriSearchCondition = {
            modality: ModalityID.MRI,
            fix_book_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateBeforeStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateBeforeStr} 23:59:59`,
              ),
            },
          };
          const beforeMriAggregate = getAggregateNlb001ChartWeek(
            beforeMriSearchCondition,
            "fix_book_date_start",
          );
          const beforeMriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            beforeMriAggregate,
          }])) as CountData[];

          const beforeMriCount = beforeMriResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differenceMriCount = todayMriCount - beforeMriCount;
          const differenceMriCountStr = differenceCountStr(differenceMriCount);

          // PET検査
          // 当日を含む1週間前の面談予約取得
          const todayPetSearchCondition = {
            modality: ModalityID.PET,
            fix_book_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
              ),
            },
          };
          const todayPetAggregate = getAggregateNlb001ChartWeek(
            todayPetSearchCondition,
            "fix_book_date_start",
          );
          const todayPetResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            todayPetAggregate,
          }])) as CountData[];
          const todayPetCount = todayPetResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // PET検査
          // 当日を含まない1週間前の面談予約取得
          const beforePetSearchCondition = {
            modality: ModalityID.PET,
            fix_book_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateBeforeStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateBeforeStr} 23:59:59`,
              ),
            },
          };
          const beforePetAggregate = getAggregateNlb001ChartWeek(
            beforePetSearchCondition,
            "fix_book_date_start",
          );
          const beforePetResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            beforePetAggregate,
          }])) as CountData[];
          const beforePetCount = beforePetResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differencePetCount = todayPetCount - beforePetCount;
          const differencePetCountStr = differenceCountStr(differenceMriCount);

          setFetchNumberResult({
            user: {
              todayCount: todayUserCount,
              differenceCount: differenceUserCount,
              differenceCountStr: differenceUserCountStr,
            },
            interview: {
              todayCount: todayInterviewCount,
              differenceCount: differenceInterviewCount,
              differenceCountStr: differenceInterviewCountStr,
            },
            mri: {
              todayCount: todayMriCount,
              differenceCount: differenceMriCount,
              differenceCountStr: differenceMriCountStr,
            },
            pet: {
              todayCount: todayPetCount,
              differenceCount: differencePetCount,
              differenceCountStr: differencePetCountStr,
            },
          });
        } else if (selectedToggle === "3") {
          /* 月 */
          // 当月
          const year = searchDay.getFullYear().toString();
          const month = searchDay.getMonth() + 1;
          const formattedMonth = month < 10 ? `0${month}` : `${month}`;
          const currentLastDay = new Date(
            Number(year),
            Number(month),
            0,
          ).getDate();
          // 先月
          let lastMonth;
          let lastFormattedMonth;
          let yearOfLastMonth;
          if (searchDay.getMonth() === 0) {
            lastMonth = 12;
            lastFormattedMonth = 12;
            yearOfLastMonth = searchDay.getFullYear() - 1;
          } else {
            lastMonth = searchDay.getMonth();
            lastFormattedMonth =
              lastMonth < 10 ? `0${lastMonth}` : `${lastMonth}`;
            yearOfLastMonth = searchDay.getFullYear();
          }
          // 先月の最終日
          const lastLastDay = new Date(
            Number(yearOfLastMonth),
            Number(lastMonth),
            0,
          ).getDate();

          // ユーザ 当月
          const todayUserSearchCondition = {
            activation_date: {
              $gte: new Date(`${year}/${formattedMonth}/01`),
              $lte: new Date(
                `${year}/${formattedMonth}/${currentLastDay} 23:59:59`,
              ),
            },
          };
          const todayUserAggregate = getAggregateNlb001ChartWeek(
            todayUserSearchCondition,
            "activation_date",
          );
          const todayUserResult = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate:
            todayUserAggregate,
          }])) as CountData[];

          const todayUserCount = todayUserResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // ユーザ 先月
          const beforeUserSearchCondition = {
            activation_date: {
              $gte: new Date(`${yearOfLastMonth}/${lastFormattedMonth}/01`),
              $lte: new Date(
                `${yearOfLastMonth}/${lastFormattedMonth}/${lastLastDay} 23:59:59`,
              ),
            },
          };
          const beforeUserAggregate = getAggregateNlb001ChartWeek(
            beforeUserSearchCondition,
            "activation_date",
          );
          const beforeUserResult = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate:
            beforeUserAggregate,
          }])) as CountData[];

          const beforeUserCount = beforeUserResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differenceUserCount = todayUserCount - beforeUserCount;
          const differenceUserCountStr =
            differenceCountStr(differenceUserCount);

          // 面談 当月
          const todayInterviewSearchCondition = {
            interview_fix_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/01`),
              $lte: new Date(`${year}/${formattedMonth}/${currentLastDay}`),
            },
          };
          const todayInterviewAggregate = getAggregateNlb001ChartMonth(
            todayInterviewSearchCondition,
            "interview_fix_date_start",
          );
          const todayInterviewResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate:
            todayInterviewAggregate,
          }])) as CountData[];

          const todayInterviewCount = todayInterviewResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // 面談予約 先月
          const beforeInterviewSearchCondition = {
            interview_fix_date_start: {
              $gte: new Date(`${yearOfLastMonth}/${lastFormattedMonth}/01`),
              $lte: new Date(
                `${yearOfLastMonth}/${lastFormattedMonth}/${lastLastDay}`,
              ),
            },
          };
          const beforeInterviewAggregate = getAggregateNlb001ChartMonth(
            beforeInterviewSearchCondition,
            "interview_fix_date_start",
          );
          const beforeInterviewResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate:
            beforeInterviewAggregate,
          }])) as CountData[];

          const beforeInterviewCount = beforeInterviewResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differenceInterviewCount =
            todayInterviewCount - beforeInterviewCount;
          const differenceInterviewCountStr = differenceCountStr(
            differenceInterviewCount,
          );

          // MRI予約 当月
          const todayMriSearchCondition = {
            modality: ModalityID.MRI,
            fix_book_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/01`),
              $lte: new Date(
                `${year}/${formattedMonth}/${currentLastDay} 23:59:59`,
              ),
            },
          };
          const todayMriAggregate = getAggregateNlb001ChartMonth(
            todayMriSearchCondition,
            "fix_book_date_start",
          );
          const todayMriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            todayMriAggregate,
          }])) as CountData[];

          const todayMriCount = todayMriResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // MRI予約 先月
          const beforeMriSearchCondition = {
            modality: ModalityID.MRI,
            fix_book_date_start: {
              $gte: new Date(`${yearOfLastMonth}/${lastFormattedMonth}/01`),
              $lte: new Date(
                `${yearOfLastMonth}/${lastFormattedMonth}/${lastLastDay} 23:59:59`,
              ),
            },
          };
          const beforeMriAggregate = getAggregateNlb001ChartMonth(
            beforeMriSearchCondition,
            "fix_book_date_start",
          );
          const beforeMriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            beforeMriAggregate,
          }])) as CountData[];

          const beforeMriCount = beforeMriResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differenceMriCount = todayMriCount - beforeMriCount;
          const differenceMriCountStr = differenceCountStr(differenceMriCount);

          // PET検査 当月
          const todayPetSearchCondition = {
            modality: ModalityID.PET,
            fix_book_date_start: {
              $gte: new Date(`${year}/${formattedMonth}/01`),
              $lte: new Date(
                `${year}/${formattedMonth}/${currentLastDay} 23:59:59`,
              ),
            },
          };
          const todayPetAggregate = getAggregateNlb001ChartMonth(
            todayPetSearchCondition,
            "fix_book_date_start",
          );
          const todayPetResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            todayPetAggregate,
          }])) as CountData[];

          const todayPetCount = todayPetResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          // PET検査 先月
          const beforePetSearchCondition = {
            modality: ModalityID.PET,
            fix_book_date_start: {
              $gte: new Date(`${yearOfLastMonth}/${lastFormattedMonth}/01`),
              $lte: new Date(
                `${yearOfLastMonth}/${lastFormattedMonth}/${lastLastDay} 23:59:59`,
              ),
            },
          };
          const beforePetAggregate = getAggregateNlb001ChartMonth(
            beforePetSearchCondition,
            "fix_book_date_start",
          );
          const beforePetResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
            beforePetAggregate,
          }])) as CountData[];

          const beforePetCount = beforePetResult.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          const differencePetCount = todayPetCount - beforePetCount;
          const differencePetCountStr = differenceCountStr(differenceMriCount);

          setFetchNumberResult({
            user: {
              todayCount: todayUserCount,
              differenceCount: differenceUserCount,
              differenceCountStr: differenceUserCountStr,
            },
            interview: {
              todayCount: todayInterviewCount,
              differenceCount: differenceInterviewCount,
              differenceCountStr: differenceInterviewCountStr,
            },
            mri: {
              todayCount: todayMriCount,
              differenceCount: differenceMriCount,
              differenceCountStr: differenceMriCountStr,
            },
            pet: {
              todayCount: todayPetCount,
              differenceCount: differencePetCount,
              differenceCountStr: differencePetCountStr,
            },
          });
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchNumberData();
  }, [currentUser, searchDay, setFetchNumberResult, selectedToggle, appContext]);

  // 会員取得
  useEffect(() => {
    addLoadCount();
    const fetchUserData = async () => {
      try {
        // 全ユーザ数取得
        const allUserResult = (await appContext.functions(["mongo/client", {
          collection: Collection.USERS, find: {
            filter: {
              status: UserStatusID.ACTIVE,
              user_type: UserTypeID.PATIENT,
            }
          }
        }])) as any[];

        // 年のセレクトボックス
        const uniqueYears = Array.from(
          new Set(
            allUserResult.map((item: { created_at: Date }) =>
              item.created_at.getFullYear().toString(),
            ),
          ),
        );
        const yearOption =
          uniqueYears.length > 0
            ? uniqueYears.map((year) => ({
                label: year,
                value: year,
              }))
            : [
                {
                  label: new Date().getFullYear().toString(),
                  value: new Date().getFullYear().toString(),
                },
              ];

        if (selectedMembershipToggle === "3") {
          // 月毎のラベル取得
          const monthArray = generateMonthStrings(userYear);

          // 月毎のユーザ数取得
          const searchCondition = {
            activation_date: {
              $gte: new Date(`${userYear}/01/01`),
              $lte: new Date(`${userYear}/12/31 23:59:59`),
            },
          };
          const aggregate = getAggregateNlb001ChartYear(
            searchCondition,
            "activation_date",
          );
          const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as CountData[];

          // 不足データを埋める
          const data = monthArray.map((month) => {
            const matchingResult = result.find((item) => item._id === month);

            return matchingResult ? matchingResult.count : 0;
          });

          // 一番大きい値 最低値10
          const maxYScale = Math.max(...data) > 10 ? Math.max(...data) : 10;

          // ユーザ数
          const userCount = result.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          setUserChartResult({
            chartData: {
              labels: monthArray,
              datasets: [
                {
                  label: "会員数",
                  data,
                },
              ],
            },
            maxYScale,
            yearOption,
            userCount,
          });
        } else if (selectedMembershipToggle === "2") {
          // 月の日毎のラベル取得
          const monthDayArray = generateMonthDayStrings(userYear, userMonth);

          // 日毎のユーザ数取得
          const lastDay = new Date(
            Number(userYear),
            Number(userMonth),
            0,
          ).getDate();
          const searchCondition = {
            activation_date: {
              $gte: new Date(`${userYear}/${userMonth}/01`),
              $lte: new Date(`${userYear}/${userMonth}/${lastDay} 23:59:59`),
            },
          };
          const aggregate = getAggregateNlb001ChartMonth(
            searchCondition,
            "activation_date",
          );
          const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as CountData[];

          // 不足データを埋める
          const data = monthDayArray.map((month) => {
            const matchingResult = result.find((item) => item._id === month);

            return matchingResult ? matchingResult.count : 0;
          });

          // 一番大きい値 最低値10
          const maxYScale = Math.max(...data) > 10 ? Math.max(...data) : 10;

          // ユーザ数
          const userCount = result.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          setUserChartResult({
            chartData: {
              labels: monthDayArray,
              datasets: [
                {
                  label: "会員数",
                  data,
                },
              ],
            },
            maxYScale,
            yearOption,
            userCount,
          });
        } else if (selectedMembershipToggle === "1") {
          // 週の日毎のラベル取得
          const weekDayArray = generateWeekDayStrings(userDay);

          const startDateStr = weekDayArray[0].slice(-2);
          const lastDateStr = weekDayArray[weekDayArray.length - 1].slice(-2);

          const year = userDay.getFullYear().toString();
          const month = userDay.getMonth() + 1;
          const formattedMonth = month < 10 ? `0${month}` : `${month}`;

          // 日毎のユーザ数取得
          const searchCondition = {
            activation_date: {
              $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
              $lte: new Date(
                `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
              ),
            },
          };
          const aggregate = getAggregateNlb001ChartWeek(
            searchCondition,
            "activation_date",
          );
          const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as CountData[];

          // 不足データを埋める
          const data = weekDayArray.map((month) => {
            const matchingResult = result.find((item) => item._id === month);

            return matchingResult ? matchingResult.count : 0;
          });

          // 一番大きい値 最低値10
          const maxYScale = Math.max(...data) > 10 ? Math.max(...data) : 10;

          // ユーザ数
          const userCount = result.reduce(
            (accumulator, current) => accumulator + current.count,
            0,
          );

          setUserChartResult((prevState) => ({
            ...prevState,
            chartData: {
              labels: weekDayArray,
              datasets: [
                {
                  label: "会員数",
                  data,
                },
              ],
            },
            maxYScale,
            userCount,
          }));
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchUserData();
  }, [currentUser, userYear, selectedMembershipToggle, userMonth, userDay, setUserChartResult, appContext]);

  // 寄席取得 再生回数無し
  // useEffect(() => {
  //   addLoadCount();
  //   const fetchYoseData = async () => {
  //     try {
  //       const yoseCollection = appContext.functions(["mongo/client", { collection: Collection.YOSES);

  //       // 全寄席取得
  //       const allYoseResult = await yoseCollection.find();

  //       // 年のセレクトボックス
  //       const uniqueYears = Array.from(
  //         new Set(
  //           allYoseResult.map((item: { created_at: Date }) =>
  //             item.created_at.getFullYear().toString(),
  //           ),
  //         ),
  //       );
  //       const yearOption =
  //         uniqueYears.length > 0
  //           ? uniqueYears.map((year) => ({
  //               label: year,
  //               value: year,
  //             }))
  //           : [
  //               {
  //                 label: new Date().getFullYear().toString(),
  //                 value: new Date().getFullYear().toString(),
  //               },
  //             ];

  //       // FIXME: API？

  //       if (selectedYoseToggle === "3") {
  //         setYoseChartResult({
  //           chartData: {
  //             labels: [
  //               "2022/01",
  //               "2022/02",
  //               "2022/03",
  //               "2022/04",
  //               "2022/05",
  //               "2022/06",
  //               "2022/07",
  //               "2022/08",
  //               "2022/09",
  //               "2022/10",
  //               "2022/11",
  //               "2022/12",
  //             ],
  //             datasets: [
  //               {
  //                 label: "再生回数",
  //                 data: [
  //                   20000, 15000, 18000, 8000, 12000, 4000, 13000, 24000, 9000,
  //                   4000, 6000, 12000,
  //                 ],
  //               },
  //             ],
  //           },
  //           maxYScale: 35000,
  //           yearOption,
  //           yoseCount: 12000,
  //         });
  //       } else if (selectedYoseToggle === "2") {
  //         setYoseChartResult({
  //           chartData: {
  //             labels: [
  //               "2022/10/1",
  //               "2022/10/2",
  //               "2022/10/3",
  //               "2022/10/4",
  //               "2022/10/5",
  //               "2022/10/6",
  //               "2022/10/7",
  //               "2022/10/8",
  //               "2022/10/9",
  //               "2022/10/10",
  //               "2022/10/11",
  //               "2022/10/12",
  //               "2022/10/13",
  //               "2022/10/14",
  //               "2022/10/15",
  //               "2022/10/16",
  //               "2022/10/17",
  //               "2022/10/18",
  //               "2022/10/19",
  //               "2022/10/20",
  //               "2022/10/21",
  //               "2022/10/22",
  //               "2022/10/23",
  //               "2022/10/24",
  //               "2022/10/25",
  //               "2022/10/26",
  //               "2022/10/27",
  //               "2022/10/28",
  //               "2022/10/29",
  //               "2022/10/30",
  //               "2022/10/31",
  //             ],
  //             datasets: [
  //               {
  //                 label: "再生回数",
  //                 data: [
  //                   20000, 15000, 18000, 8000, 12000, 4000, 13000, 24000, 9000,
  //                   4000, 6000, 12000, 20000, 15000, 18000, 8000, 12000, 4000,
  //                   13000, 24000, 9000, 4000, 6000, 12000, 20000, 15000, 18000,
  //                   8000, 12000, 4000, 13000,
  //                 ],
  //               },
  //             ],
  //           },
  //           maxYScale: 35000,
  //           yearOption,
  //           yoseCount: 12000,
  //         });
  //       } else if (selectedYoseToggle === "1") {
  //         setYoseChartResult((prevState) => ({
  //           ...prevState,
  //           chartData: {
  //             labels: [
  //               "2022/10/05",
  //               "2022/10/06",
  //               "2022/10/07",
  //               "2022/10/08",
  //               "2022/10/10",
  //               "2022/10/11",
  //               "2022/10/12",
  //             ],
  //             datasets: [
  //               {
  //                 label: "会員数",
  //                 data: [20000, 15000, 18000, 8000, 12000, 4000, 13000],
  //               },
  //             ],
  //           },
  //           maxYScale: 35000,
  //           yoseCount: 12000,
  //         }));
  //       }
  //     } catch (err) {
  //       setFetchError(checkFetchErr(err));
  //     } finally {
  //       decrementLoadCount();
  //     }
  //   };

  //   void fetchYoseData();
  // }, [
  //   currentUser,
  //   selectedYoseToggle,
  //   yoseYear,
  //   yoseMonth,
  //   yoseDay,
  //   setYoseChartResult,
  // ]);

  // 予約取得
  useEffect(() => {
    addLoadCount();
    const fetchAppointmentData = async () => {
      try {
        if (selectedIndex === 0) {
          // 検査予約タブ

          // 全件取得
          const allResults = (await appContext.functions(["mongo/client", {
            collection: Collection.PATIENT_MODALITY_BOOKS, find: { filter: {} }
          }])) as any[];

          // 年のセレクトボックス
          const uniqueYears = Array.from(
            new Set(
              allResults.map((item: { created_at: Date }) =>
                item.created_at.getFullYear().toString(),
              ),
            ),
          );
          const yearOption =
            uniqueYears.length > 0
              ? uniqueYears.map((year) => ({
                  label: year,
                  value: year,
                }))
              : [
                  {
                    label: new Date().getFullYear().toString(),
                    value: new Date().getFullYear().toString(),
                  },
                ];

          if (selectedAppointmentToggle === "3") {
            // 月毎のラベル取得
            const monthArray = generateMonthStrings(appointmentYear);

            // 月毎のMRI予約数取得
            const mriSearchCondition = {
              modality: ModalityID.MRI,
              fix_book_date_start: {
                $gte: new Date(`${appointmentYear}/01/01`),
                $lte: new Date(`${appointmentYear}/12/31 23:59:59`),
              },
            };
            const mriAggregate = getAggregateNlb001ChartYear(
              mriSearchCondition,
              "fix_book_date_start",
            );
            const mriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
              mriAggregate,
            }])) as CountData[];

            // 不足データを埋める
            const mriData = monthArray.map((month) => {
              const matchingResult = mriResult.find(
                (item) => item._id === month,
              );

              return matchingResult ? matchingResult.count : 0;
            });

            // 月毎のPET予約数取得
            const petSearchCondition = {
              modality: ModalityID.PET,
              fix_book_date_start: {
                $gte: new Date(`${appointmentYear}/01/01`),
                $lte: new Date(`${appointmentYear}/12/31 23:59:59`),
              },
            };
            const petAggregate = getAggregateNlb001ChartYear(
              petSearchCondition,
              "fix_book_date_start",
            );
            const petResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
              petAggregate,
            }])) as CountData[];

            // 不足データを埋める
            const petData = monthArray.map((month) => {
              const matchingResult = petResult.find(
                (item) => item._id === month,
              );

              return matchingResult ? matchingResult.count : 0;
            });

            // 一番大きい値 最低値10
            const maxMriYScale =
              Math.max(...mriData) > 10 ? Math.max(...mriData) : 10;
            const maxPetYScale =
              Math.max(...petData) > 10 ? Math.max(...petData) : 10;
            const maxYScale =
              maxMriYScale > maxPetYScale ? maxMriYScale : maxPetYScale;

            // MRI予約数
            const mriCount = mriResult.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );
            // PET予約数
            const petCount = petResult.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );

            setAppointmentChatResult({
              chartData: {
                labels: monthArray,
                datasets: [
                  {
                    label: "MRI予約",
                    data: mriData,
                  },
                  {
                    label: "FDG PET検査予約",
                    data: petData,
                  },
                ],
              },
              maxYScale,
              yearOption,
              mriCount,
              petCount,
            });
          } else if (selectedAppointmentToggle === "2") {
            // 月の日毎のラベル取得
            const monthDayArray = generateMonthDayStrings(
              appointmentYear,
              appointmentMonth,
            );

            // 日毎のMRI予約数取得
            const lastDay = new Date(
              Number(appointmentYear),
              Number(appointmentMonth),
              0,
            ).getDate();
            const mriSearchCondition = {
              modality: ModalityID.MRI,
              fix_book_date_start: {
                $gte: new Date(`${appointmentYear}/${appointmentMonth}/01`),
                $lte: new Date(
                  `${appointmentYear}/${appointmentMonth}/${lastDay} 23:59:59`,
                ),
              },
            };
            const mriAggregate = getAggregateNlb001ChartMonth(
              mriSearchCondition,
              "fix_book_date_start",
            );
            const mriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
              mriAggregate,
            }])) as CountData[];

            // 不足データを埋める
            const mriData = monthDayArray.map((month) => {
              const matchingResult = mriResult.find(
                (item) => item._id === month,
              );

              return matchingResult ? matchingResult.count : 0;
            });

            // 日毎のPET予約数取得
            const petSearchCondition = {
              modality: ModalityID.PET,
              fix_book_date_start: {
                $gte: new Date(`${appointmentYear}/${appointmentMonth}/01`),
                $lte: new Date(
                  `${appointmentYear}/${appointmentMonth}/${lastDay} 23:59:59`,
                ),
              },
            };
            const petAggregate = getAggregateNlb001ChartMonth(
              petSearchCondition,
              "fix_book_date_start",
            );
            const petResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
              petAggregate,
            }])) as CountData[];

            // 不足データを埋める
            const petData = monthDayArray.map((month) => {
              const matchingResult = petResult.find(
                (item) => item._id === month,
              );

              return matchingResult ? matchingResult.count : 0;
            });

            // 一番大きい値 最低値10
            const maxMriYScale =
              Math.max(...mriData) > 10 ? Math.max(...mriData) : 10;
            const maxPetYScale =
              Math.max(...petData) > 10 ? Math.max(...petData) : 10;
            const maxYScale =
              maxMriYScale > maxPetYScale ? maxMriYScale : maxPetYScale;

            // MRI予約数
            const mriCount = mriResult.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );
            // PET予約数
            const petCount = petResult.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );

            setAppointmentChatResult({
              chartData: {
                labels: monthDayArray,
                datasets: [
                  {
                    label: "MRI予約",
                    data: mriData,
                  },
                  {
                    label: "FDG PET検査予約",
                    data: petData,
                  },
                ],
              },
              maxYScale,
              yearOption,
              mriCount,
              petCount,
            });
          } else if (selectedAppointmentToggle === "1") {
            // 週の日毎のラベル取得
            const weekDayArray = generateWeekDayStrings(appointmentDay);

            const startDateStr = weekDayArray[0].slice(-2);
            const lastDateStr = weekDayArray[weekDayArray.length - 1].slice(-2);

            const year = appointmentDay.getFullYear().toString();
            const month = appointmentDay.getMonth() + 1;
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;

            // 日毎のMRI予約数取得
            const mriSearchCondition = {
              modality: ModalityID.MRI,
              fix_book_date_start: {
                $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
                $lte: new Date(
                  `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
                ),
              },
            };
            const mriAggregate = getAggregateNlb001ChartWeek(
              mriSearchCondition,
              "fix_book_date_start",
            );
            const mriResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
              mriAggregate,
            }])) as CountData[];

            // 不足データを埋める
            const mriData = weekDayArray.map((month) => {
              const matchingResult = mriResult.find(
                (item) => item._id === month,
              );

              return matchingResult ? matchingResult.count : 0;
            });

            // 日毎のPET予約数取得
            const petSearchCondition = {
              modality: ModalityID.PET,
              fix_book_date_start: {
                $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
                $lte: new Date(
                  `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
                ),
              },
            };
            const petAggregate = getAggregateNlb001ChartWeek(
              petSearchCondition,
              "fix_book_date_start",
            );
            const petResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_MODALITY_BOOKS, aggregate:
              petAggregate,
            }])) as CountData[];

            // 不足データを埋める
            const petData = weekDayArray.map((month) => {
              const matchingResult = petResult.find(
                (item) => item._id === month,
              );

              return matchingResult ? matchingResult.count : 0;
            });

            // 一番大きい値 最低値10
            const maxMriYScale =
              Math.max(...mriData) > 10 ? Math.max(...mriData) : 10;
            const maxPetYScale =
              Math.max(...petData) > 10 ? Math.max(...petData) : 10;
            const maxYScale =
              maxMriYScale > maxPetYScale ? maxMriYScale : maxPetYScale;

            // MRI予約数
            const mriCount = mriResult.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );
            // PET予約数
            const petCount = petResult.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );

            setAppointmentChatResult((prevState) => ({
              ...prevState,
              chartData: {
                labels: weekDayArray,
                datasets: [
                  {
                    label: "MRI予約",
                    data: mriData,
                  },
                  {
                    label: "FDG PET検査予約",
                    data: petData,
                  },
                ],
              },
              maxYScale,
              mriCount,
              petCount,
            }));
          }
        } else if (selectedIndex === 1) {
          // 面談予約タブ
          const allResults = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, find: {} }])) as any[];

          // 年のセレクトボックス
          const uniqueYears = Array.from(
            new Set(
              allResults.map((item: { created_at: Date }) =>
                item.created_at.getFullYear().toString(),
              ),
            ),
          );
          const yearOption =
            uniqueYears.length > 0
              ? uniqueYears.map((year) => ({
                  label: year,
                  value: year,
                }))
              : [
                  {
                    label: new Date().getFullYear().toString(),
                    value: new Date().getFullYear().toString(),
                  },
                ];

          if (selectedAppointmentToggle === "3") {
            // 月毎のラベル取得
            const monthArray = generateMonthStrings(appointmentYear);

            // 月毎の面談予約数取得
            const searchCondition = {
              interview_fix_date_start: {
                $gte: new Date(`${appointmentYear}/01/01`),
                $lte: new Date(`${appointmentYear}/12/31 23:59:59`),
              },
            };
            const aggregate = getAggregateNlb001ChartYear(
              searchCondition,
              "interview_fix_date_start",
            );
            const result = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate }])) as CountData[];

            // 不足データを埋める
            const data = monthArray.map((month) => {
              const matchingResult = result.find((item) => item._id === month);

              return matchingResult ? matchingResult.count : 0;
            });

            // 一番大きい値 最低値10
            const maxYScale = Math.max(...data) > 10 ? Math.max(...data) : 10;

            // 面談予約数
            const interviewCount = result.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );

            setAppointmentChatResult({
              chartData: {
                labels: monthArray,
                datasets: [
                  {
                    label: "面談予約",
                    data,
                  },
                ],
              },
              maxYScale,
              yearOption,
              interviewCount,
            });
          } else if (selectedAppointmentToggle === "2") {
            // 月の日毎のラベル取得
            const monthDayArray = generateMonthDayStrings(
              appointmentYear,
              appointmentMonth,
            );

            // 日毎の面談予約数取得
            const lastDay = new Date(
              Number(appointmentYear),
              Number(appointmentMonth),
              0,
            ).getDate();
            const searchCondition = {
              interview_fix_date_start: {
                $gte: new Date(`${appointmentYear}/${appointmentMonth}/01`),
                $lte: new Date(
                  `${appointmentYear}/${appointmentMonth}/${lastDay}`,
                ),
              },
            };
            const aggregate = getAggregateNlb001ChartMonth(
              searchCondition,
              "interview_fix_date_start",
            );
            const result = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate }])) as CountData[];

            // 不足データを埋める
            const data = monthDayArray.map((month) => {
              const matchingResult = result.find((item) => item._id === month);

              return matchingResult ? matchingResult.count : 0;
            });

            // 一番大きい値 最低値10
            const maxYScale = Math.max(...data) > 10 ? Math.max(...data) : 10;

            // 面談予約数
            const interviewCount = result.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );

            setAppointmentChatResult({
              chartData: {
                labels: monthDayArray,
                datasets: [
                  {
                    label: "面談予約",
                    data,
                  },
                ],
              },
              maxYScale,
              yearOption,
              interviewCount,
            });
          } else if (selectedAppointmentToggle === "1") {
            // 週の日毎のラベル取得
            const weekDayArray = generateWeekDayStrings(appointmentDay);

            const startDateStr = weekDayArray[0].slice(-2);
            const lastDateStr = weekDayArray[weekDayArray.length - 1].slice(-2);

            const year = appointmentDay.getFullYear().toString();
            const month = appointmentDay.getMonth() + 1;
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;

            // 日毎の面談予約数取得
            const searchCondition = {
              interview_fix_date_start: {
                $gte: new Date(`${year}/${formattedMonth}/${startDateStr}`),
                $lte: new Date(
                  `${year}/${formattedMonth}/${lastDateStr} 23:59:59`,
                ),
              },
            };
            const aggregate = getAggregateNlb001ChartWeek(
              searchCondition,
              "interview_fix_date_start",
            );
            const result = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate }])) as CountData[];

            // 不足データを埋める
            const data = weekDayArray.map((month) => {
              const matchingResult = result.find((item) => item._id === month);

              return matchingResult ? matchingResult.count : 0;
            });

            // 一番大きい値 最低値10
            const maxYScale = Math.max(...data) > 10 ? Math.max(...data) : 10;

            // 面談予約数
            const interviewCount = result.reduce(
              (accumulator, current) => accumulator + current.count,
              0,
            );

            setAppointmentChatResult((prevState) => ({
              ...prevState,
              chartData: {
                labels: weekDayArray,
                datasets: [
                  {
                    label: "面談予約",
                    data,
                  },
                ],
              },
              maxYScale,
              interviewCount,
            }));
          }
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchAppointmentData();
  }, [currentUser, appointmentYear, selectedAppointmentToggle, appointmentDay, appointmentMonth, selectedIndex, setAppointmentChatResult, appContext]);

  // タスク
  useEffect(() => {
    const fetchTaskData = async () => {
      addLoadCount();
      try {
        // 面談
        const interviewAggregate = getAggregateNlb001TaskInterview();

        // のうKNOW
        const nouknowAggregate = getAggregateNlb001TaskNouknow();

        // MRI
        const mriAggregate = getAggregateNlb001TaskMri();

        // PET
        const petAggregate = getAggregateNlb001TaskPet();

        // EC
        const ordersAggregate = getAggregateNlb001TaskOrders();

        // 医師チャット
        const chatsAggregate = getAggregateNlb001TaskChats();

        switch (category) {
          case Nlb001CategoryID.ALL: {
            // チャット検索
            const chatsResult = (await appContext.functions(["mongo/client", { collection: Collection.CHATS, aggregate:
              chatsAggregate,
            }])) as Nlb001TaskStateType[];

            // MRI検索
            const mriResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate:
              mriAggregate,
            }])) as Nlb001TaskStateType[];

            // のうKNOW検索
            const nouknowResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_NOUKNOWS, aggregate:
              nouknowAggregate,
            }])) as Nlb001TaskStateType[];

            // PET検索
            const petResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate:
              petAggregate,
            }])) as Nlb001TaskStateType[];

            // 面談検索
            const interviewResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate:
              interviewAggregate,
            }])) as Nlb001TaskStateType[];

            // EC検索
            const ordersResult = (await appContext.functions(["mongo/client", { collection: Collection.ORDERS, aggregate:
              ordersAggregate,
            }])) as Nlb001TaskStateType[];

            const combinedResults = [
              ...chatsResult,
              ...mriResult,
              ...nouknowResult,
              ...petResult,
              ...interviewResult,
              ...ordersResult,
            ];

            // ソート
            const sortedCombinedResults = combinedResults.sort((a, b) => {
              const dateA = new Date(a.updated_at).getTime();
              const dateB = new Date(b.updated_at).getTime();

              return dateB - dateA;
            });

            setTaskResult(sortedCombinedResults);
            break;
          }
          case Nlb001CategoryID.CHAT: {
            // チャット検索
            const chatsResult = (await appContext.functions(["mongo/client", { collection: Collection.CHATS, aggregate:
              chatsAggregate,
            }])) as Nlb001TaskStateType[];

            setTaskResult(chatsResult);
            break;
          }
          case Nlb001CategoryID.INSPECTION: {
            // MRI検索
            const mriResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate:
              mriAggregate,
            }])) as Nlb001TaskStateType[];

            // のうKNOW検索
            const nouknowResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_NOUKNOWS, aggregate:
              nouknowAggregate,
            }])) as Nlb001TaskStateType[];

            // PET検索
            const petResult = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate:
              petAggregate,
            }])) as Nlb001TaskStateType[];

            const combinedResults = [
              ...mriResult,
              ...nouknowResult,
              ...petResult,
            ];

            // ソート
            const sortedCombinedResults = combinedResults.sort((a, b) => {
              const dateA = new Date(a.updated_at).getTime();
              const dateB = new Date(b.updated_at).getTime();

              return dateB - dateA;
            });

            setTaskResult(sortedCombinedResults);
            break;
          }
          case Nlb001CategoryID.INTERVIEW: {
            // 面談検索
            const interviewResult = (await appContext.functions(["mongo/client", { collection: Collection.PATIENT_INTERVIEWS, aggregate:
              interviewAggregate,
            }])) as Nlb001TaskStateType[];

            setTaskResult(interviewResult);
            break;
          }
          case Nlb001CategoryID.EC: {
            // EC検索
            const ordersResult = (await appContext.functions(["mongo/client", { collection: Collection.ORDERS, aggregate:
              ordersAggregate,
            }])) as Nlb001TaskStateType[];

            setTaskResult(ordersResult);
            break;
          }
          default:
            setTaskResult([]);
            break;
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchTaskData();
  }, [currentUser, setTaskResult, category, appContext]);

  return {
    fetchNumberResult,
    userChartResult,
    appointmentChatResult,
    taskResult,
    fetchError,
  };
};

export default useNlb001DbActions;
