import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";

const useMic001DbActions = () => {
  const appContext = useFirebaseApp();
  const [updateNoExaminationError, setUpdateNoExaminationError] =
    useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const updateNoExamination = (id: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 受検なし設定API
        await appContext.functions(["booking/saveNonTaken", id]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setUpdateNoExaminationError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    updateNoExamination,
    updateNoExaminationError,
  };
};

export default useMic001DbActions;
