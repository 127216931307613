import React from "react";
import Text from "../Text/Text";

export type SuggestionItemProps = {
  label: string;
  value: string;
  subLabel?: string;
  isSelected: boolean;
  isFocused: boolean;
  selectItem: (value: string) => void;
  isVisible?: boolean;
};

const SuggestionItem = ({
  label,
  value,
  subLabel = "",
  isSelected = false,
  isFocused = false,
  selectItem,
  isVisible = true,
}: SuggestionItemProps) => {
  const suggestionItemClass = React.useMemo(() => {
    const selectedClass = isSelected ? ["suggestion-item--selected"] : [];
    const focusedClass = isFocused ? ["suggestion-item--focused"] : [];

    return ["suggestion-item", ...selectedClass, ...focusedClass].join(" ");
  }, [isFocused, isSelected]);

  const selectOptionInner = React.useCallback(
    () => selectItem(value),
    [selectItem, value],
  );

  if (!isVisible) return <></>;

  return (
    <div className={suggestionItemClass} onClick={selectOptionInner}>
      <div>{label}</div>
      {subLabel && (
        <div>
          <Text color="neutralLight" size="2xs">
            {subLabel}
          </Text>
        </div>
      )}
    </div>
  );
};

export default SuggestionItem;
