import Button from "../components/Button/Button";
import DialogCustom from "../components/Dialog/DialogCustom";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import FormSet from "../components/FormSet/FormSet";
import Heading from "../components/Heading/Heading";
import Input from "../components/Input/Input";
import useNlc015Dialog, {
  closeNlc015Dialog,
} from "../hooks/pages/NLC015/useNlc015Dialog";
import useNlc015Conditions, {
  setInterpretationNote,
  checkItemInputError,
} from "../hooks/pages/NLC015/useNlc015Conditions";
import { convertPatientInfo } from "../utils/convertDisplay";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNlc015DbActions from "../hooks/pages/NLC015/useNlc015DbActions";
import { MriStatusID, MriStatusLabels } from "../constants/common";
import { getMriStatusTagState } from "../utils/utils";
import useSignedMriUrl from "../hooks/common/useSignedMriUrl";

import InformationDialog from "../components/Dialog/InformationDialog";
import useNlc015CancelDialog, {
  closeNlc015CancelDialog,
  openNlc015CancelDialog,
} from "../hooks/pages/NLC015/useNlc015CancelDialog";
import useNlc015DbFetch from "../hooks/pages/NLC015/useNlc015DbFetch";
import useDicomUrl from "../hooks/common/useDicomUrl";

const NLC015 = () => {
  const [isOpen] = useNlc015Dialog();
  const [modalityInfo] = useNlc015Conditions();
  const { actionError, confirmSelection, sendCancel } = useNlc015DbActions();
  const { fetchError } = useNlc015DbFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const { fetchSignedMriUrl, signedMriUrlError } = useSignedMriUrl();
  const { fetchDicomUrl, dicomUrlError } = useDicomUrl();

  const [isCancelDialogOpen] = useNlc015CancelDialog();

  const { id, name, birth, genderStr, tel, email } =
    convertPatientInfo(patientInfo);

  useCheckErrorThrowError([
    fetchError,
    actionError,
    patientFetchError,
    signedMriUrlError,
    dicomUrlError,
  ]);

  const handleOpenReport = async () => {
    const signedUrl = await fetchSignedMriUrl(modalityInfo.modalityId);
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    }
  };

  const handleOpenDicom = async () => {
    const dicomUrl = await fetchDicomUrl(
      modalityInfo.modalityId,
      modalityInfo.studyUid,
    );
    if (dicomUrl) {
      window.open(dicomUrl, "_blank");
    }
  };

  const replaceNewLinesWithBr = (text: string | undefined) =>
    text?.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));

  return (
    <>
      <DialogCustom
        open={isOpen}
        closeDialog={closeNlc015Dialog}
        size="large"
        title="結果"
        height="1143px"
        footerRight={
          <LayoutBox justify="end" fullWidth>
            <Button
              type="sub"
              color="neutral"
              size="large"
              width="108px"
              onClick={closeNlc015Dialog}
            >
              キャンセル
            </Button>
            {modalityInfo.status === MriStatusID.USER_REPORTED ? (
              <Button
                type="primary"
                color="danger"
                size="large"
                width="128px"
                onClick={openNlc015CancelDialog}
              >
                結果送信取消
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                width="64px"
                onClick={confirmSelection}
                disabled={
                  (modalityInfo.status !== MriStatusID.DONE_AI_DIAGNOSED &&
                    modalityInfo.status !==
                      MriStatusID.DONE_DOCTOR_DIAGNOSED) ||
                  checkItemInputError()
                }
              >
                確定
              </Button>
            )}
          </LayoutBox>
        }
      >
        <LayoutBox direction="column" gap="3x" fullWidth>
          <Sheet type="border-blue" padding="15px 23px 15px 20px">
            <LayoutBox direction="column" gap="2x">
              <LayoutBox direction="column" gap="1x">
                <LayoutBox gap="1x">
                  <Text size="small" color="neutralLight">
                    {id}
                  </Text>
                  <Tag
                    label={
                      MriStatusLabels[
                        modalityInfo.status as keyof typeof MriStatusLabels
                      ]
                    }
                    showIcon={false}
                    state={getMriStatusTagState(
                      MriStatusLabels[
                        modalityInfo.status as keyof typeof MriStatusLabels
                      ],
                    )}
                  />
                </LayoutBox>
                <Text size="2xl" bold lineHeight="150%">
                  {name}
                </Text>
              </LayoutBox>
              <LayoutBox direction="column" fullWidth>
                <LayoutBox fullWidth>
                  <FormSet
                    label="受検日"
                    labelWidth="120px"
                    labelSize="medium"
                    base
                    formVertical={false}
                  >
                    <Text width="210px">{`${modalityInfo.date}`}</Text>
                  </FormSet>
                </LayoutBox>
                <LayoutBox fullWidth>
                  <FormSet
                    label="生年月日"
                    labelWidth="120px"
                    labelSize="medium"
                    base
                    formVertical={false}
                  >
                    <Text width="210px">{birth}</Text>
                  </FormSet>
                  <FormSet
                    label="性別"
                    labelWidth="120px"
                    labelSize="medium"
                    base
                    formVertical={false}
                  >
                    <Text width="210px">{genderStr}</Text>
                  </FormSet>
                </LayoutBox>
                <LayoutBox fullWidth>
                  <FormSet
                    label="電話番号"
                    labelWidth="120px"
                    labelSize="medium"
                    base
                    formVertical={false}
                  >
                    <Text width="210px">{tel}</Text>
                  </FormSet>
                  <FormSet
                    label="メールアドレス"
                    labelWidth="120px"
                    labelSize="medium"
                    base
                    formVertical={false}
                  >
                    <Text width="210px">{email}</Text>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <LayoutBox direction="column" fullWidth gap="2x">
            <Sheet type="header" padding="4px 8px 3px 5px">
              <Heading tag="h2">担当医</Heading>
            </Sheet>
            <Text>{modalityInfo.doctor && `${modalityInfo.doctor}先生`}</Text>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth gap="2x">
            <Sheet type="header" padding="4px 8px 3px 5px">
              <Heading tag="h2">医師所見</Heading>
            </Sheet>
            <Text size="xl">
              {replaceNewLinesWithBr(modalityInfo.doctorReview)}
            </Text>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth gap="2x">
            <Sheet type="header" padding="4px 8px 3px 5px">
              <Heading tag="h2">備考</Heading>
            </Sheet>
            <Text size="xl">
              {replaceNewLinesWithBr(modalityInfo.doctorNote)}
            </Text>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth gap="2x">
            <Sheet type="header" padding="4px 8px 3px 5px">
              <Heading tag="h2">検査画像</Heading>
            </Sheet>
            <Button
              type="secondary"
              icon="open_in_new"
              iconPosition="right"
              onClick={() => {
                void handleOpenDicom();
              }}
            >
              検査画像を確認
            </Button>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth gap="2x">
            <Sheet type="header" padding="4px 8px 3px 5px">
              <Heading tag="h2">レポート</Heading>
            </Sheet>
            <Button
              type="secondary"
              onClick={() => {
                void handleOpenReport();
              }}
            >
              レポートを確認
            </Button>
          </LayoutBox>
          <Sheet type="gray" padding="20px">
            <LayoutBox gap="2x" direction="column">
              <Sheet type="header" padding="4px 8px 3px 5px">
                <Heading tag="h2">読影</Heading>
              </Sheet>
              <FormSet label="会員へのコメント" vertical required="icon">
                {modalityInfo.status === MriStatusID.USER_REPORTED ? (
                  <Text size="xl">
                    {replaceNewLinesWithBr(
                      modalityInfo.interpretationNote ?? "",
                    )}
                  </Text>
                ) : (
                  <Input
                    multiLine
                    placeholder="会員へのコメント"
                    width="100%"
                    multiLineHeight="88px"
                    value={modalityInfo.interpretationNote}
                    onChange={setInterpretationNote}
                  />
                )}
              </FormSet>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </DialogCustom>
      {isCancelDialogOpen && (
        <InformationDialog
          alertLevel="error"
          open={isCancelDialogOpen}
          closeDialog={() => closeNlc015CancelDialog()}
          title="ユーザーに送信した結果を取り消しますか？"
          footer={
            <LayoutBox>
              <Button color="main" size="medium" onClick={() => sendCancel(id)}>
                OK
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => closeNlc015CancelDialog()}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          取り消したらユーザー側からも結果が表示されなくなります。
          <br />
          再度編集し、結果を送信してください。
        </InformationDialog>
      )}
    </>
  );
};

export default NLC015;
