import React from "react";

import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import { useCheckErrorThrowError } from "../utils/checkError";
import useHospitalInput, {
  checkHospitalInputError,
  setAddress,
  setBrainHealthCheckPrice,
  setEmail,
  setHasBrainHealthCheck,
  setHasMri,
  setHasNouknow,
  setHasPet,
  setHospitalName,
  setInitHospitalInput,
  setMriPrice,
  setNouknowPrice,
  setPetPrice,
  setPref,
  setTel,
} from "../hooks/input/useHospitalInput";
import Dropdown from "../components/Dropdown/Dropdown";
import { PREFECTURE_OPTIONS } from "../constants/common";
import Checkbox from "../components/Checkbox/Checkbox";
import MoneyInput from "../components/MoneyInput/MoneyInput";
import Table from "../components/Table/Table";
import TableCell from "../components/Table/TableCell";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import useNli030DbActions from "../hooks/pages/NLI030/useNli030DbActions";
import { _convertToCommaSeparatedString } from "../utils/convertDisplay";

type NLI030Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  hospitalData?: hospitalNli016StateType | null;
};

const NLI030: React.FC<NLI030Props> = ({
  isOpen,
  setIsOpen,
  title,
  hospitalData = null,
}) => {
  const [
    {
      hospitalName,
      address,
      tel,
      email,
      pref,
      hasMri,
      hasPet,
      hasBrainHealthCheck,
      hasNouknow,
      nouknowPrice,
      mriPrice,
      brainHealthCheckPrice,
      petPrice,
    },
  ] = useHospitalInput();
  const { actionError, handleRegisterHospital, handleUpdateHospital } =
    useNli030DbActions();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  // 編集時の値反映
  React.useEffect(() => {
    if (hospitalData) {
      setHospitalName(hospitalData.hospital_name);
      setTel(hospitalData.tel);
      setAddress(hospitalData.address);
      setEmail(hospitalData.email);
      setPref(hospitalData.prefecture);
      setHasMri(hospitalData.has_mri ?? false);
      setHasPet(hospitalData.has_pet ?? false);
      setHasBrainHealthCheck(hospitalData.has_brain_health_check ?? false);
      setHasNouknow(hospitalData.has_nouknow ?? false);
      setNouknowPrice(
        hospitalData.service_price?.nouknow !== undefined
          ? _convertToCommaSeparatedString(hospitalData.service_price.nouknow)
          : "",
      );
      setMriPrice(
        hospitalData.service_price?.mri !== undefined
          ? _convertToCommaSeparatedString(hospitalData.service_price.mri)
          : "",
      );
      setBrainHealthCheckPrice(
        hospitalData.service_price?.brain_health_check !== undefined
          ? _convertToCommaSeparatedString(
            hospitalData.service_price.brain_health_check,
          )
          : "",
      );
      setPetPrice(
        hospitalData.service_price?.pet !== undefined
          ? _convertToCommaSeparatedString(hospitalData.service_price.pet)
          : "",
      );
    }
  }, [hospitalData]);

  // 閉じるアイコン、キャンセル
  const handleCloseButton = () => {
    setIsOpen(false);
  };

  // 保存ボタン
  const handleRegisterHospitalButton = () => {
    if (hospitalData) {
      // 更新
      handleUpdateHospital(hospitalData.hospital_id, setIsOpen);
    } else {
      // 登録
      handleRegisterHospital(setIsOpen);
      setInitHospitalInput();
    }
  };

  return (
    <div className="admin-area">
      <div className="admin-inner">
        <DialogCustom
          title={title}
          open={isOpen}
          size="large"
          height="622px"
          closeDialog={handleCloseButton}
          footerRight={
            <LayoutBox>
              <Button
                type="sub"
                color="neutral"
                size="large"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
              <Button
                size="large"
                onClick={handleRegisterHospitalButton}
                disabled={checkHospitalInputError()}
              >
                保存
              </Button>
            </LayoutBox>
          }
        >
          <LayoutBox fullWidth direction="column">
            <FormSet
              label="医療機関名"
              base
              required="icon"
              errorTextList={[hospitalName.validationMessage]}
            >
              <Input
                width="100%"
                placeholder="医療機関名"
                size="default"
                value={hospitalName.value}
                onChange={(value) => setHospitalName(value)}
                error={hospitalName.isError}
              />
            </FormSet>
            <LayoutBox fullWidth>
              <FormSet
                label="電話番号"
                base
                required="icon"
                errorTextList={[tel.validationMessage]}
              >
                <Input
                  width="173px"
                  value={tel.value}
                  placeholder="電話番号"
                  size="default"
                  onChange={(value) => setTel(value)}
                  error={tel.isError}
                />
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth>
              <FormSet
                label="メールアドレス"
                base
                required="icon"
                errorTextList={[email.validationMessage]}
              >
                <Input
                  width="100%"
                  value={email.value}
                  placeholder="メールアドレス"
                  size="default"
                  onChange={(value) => setEmail(value)}
                  error={email.isError}
                />
              </FormSet>
            </LayoutBox>
            <LayoutBox fullWidth direction="column">
              <FormSet
                label="住所"
                base
                required="icon"
                errorTextList={[address.validationMessage]}
              >
                <LayoutBox direction="column" fullWidth>
                  <Dropdown
                    value={pref}
                    items={PREFECTURE_OPTIONS}
                    width="124px"
                    onChange={(value) => setPref(value)}
                  />
                  <Input
                    width="100%"
                    value={address.value}
                    placeholder="住所"
                    size="default"
                    onChange={(value) => setAddress(value)}
                    error={address.isError}
                  />
                </LayoutBox>
              </FormSet>
            </LayoutBox>
            <LayoutBox>
              <FormSet label="サービス・金額" base>
                <div className="util-py-16">
                  <Table
                    type="condensed"
                    width="100%"
                    head={
                      <TableRow>
                        <TableColumn id="col-1" width="266px">
                          サービス
                        </TableColumn>
                        <TableColumn id="col-2" width="266px">
                          金額
                        </TableColumn>
                      </TableRow>
                    }
                    body={
                      <>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              label="認知機能テスト"
                              checked={hasNouknow}
                              onChecked={() => {
                                setHasNouknow(!hasNouknow);
                              }}
                            />
                          </TableCell>
                          <TableCell padding="4px 8px">
                            <MoneyInput
                              value={nouknowPrice.value}
                              onChange={setNouknowPrice}
                              width="100%"
                              disabled={!hasNouknow}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              label="MRI検査"
                              checked={hasMri}
                              onChecked={() => {
                                setHasMri(!hasMri);
                              }}
                            />
                          </TableCell>
                          <TableCell padding="4px 8px">
                            <MoneyInput
                              value={mriPrice.value}
                              onChange={setMriPrice}
                              width="100%"
                              disabled={!hasMri}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              label="あたまの健康チェック"
                              checked={hasBrainHealthCheck}
                              onChecked={() => {
                                setHasBrainHealthCheck(!hasBrainHealthCheck);
                              }}
                            />
                          </TableCell>
                          <TableCell padding="4px 8px">
                            <MoneyInput
                              value={brainHealthCheckPrice.value}
                              onChange={setBrainHealthCheckPrice}
                              width="100%"
                              disabled={!hasBrainHealthCheck}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              label="PET検査"
                              checked={hasPet}
                              onChecked={() => {
                                setHasPet(!hasPet);
                              }}
                            />
                          </TableCell>
                          <TableCell padding="4px 8px">
                            <MoneyInput
                              value={petPrice.value}
                              onChange={setPetPrice}
                              width="100%"
                              disabled={!hasPet}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    }
                  />
                </div>
              </FormSet>
            </LayoutBox>
          </LayoutBox>
        </DialogCustom>
      </div>
    </div>
  );
};
export default NLI030;
