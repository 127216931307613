import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc021NewDialog } from "./useNlc021NewDialog";

const useNlc021CancelReservation = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 予約取消処理
  const cancelReservation = (id: string, isNg: boolean | undefined) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 面談取消API
        await appContext.functions(["interview/delete", [id, true, isNg]]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc021NewDialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    cancelReservation,
  };
};

export default useNlc021CancelReservation;
