import { useEffect, useMemo, useState } from "react";
import { ADMIN_USER_DETAIL_TAB_ITEMS, Collection } from "../constants/common";
import { ADMIN } from "../constants/pagePaths";
import useStateCustomObj from "./base/useStateCustomObj";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { checkFetchErr } from "../contexts/CustomErrorBoundary";
import {
  getSearchConditionsAdminUserDetailInterviews,
  getSearchConditionsAdminUserDetailNouknow,
  getSearchConditionsAdminUserDetailOrder,
} from "../utils/query";
import { addLoadCount, decrementLoadCount } from "./base/useLoadingPage";
import useId from "./common/useId";
import useForceUpdate from "./common/useForceUpdate";

// 管理者ユーザ詳細タブ
const useAdminUserDetailTabFetch = () => {
  // ユーザ _id
  const [id] = useId();
  const [forceUpdate] = useForceUpdate();

  // タブの通知対象のcollectionと検索条件取得関数
  const userDetailTabInfoArr = useMemo(
    () => [
      {
        // 登録情報
        collection: "",
        getSearchConditions: null,
        withId: false,
        isBatch: false,
      },
      {
        // 予約状況
        collection: Collection.PATIENT_INTERVIEWS,
        getSearchConditions: getSearchConditionsAdminUserDetailInterviews,
        withId: true,
        isBatch: false,
      },
      {
        // テスト結果
        collection: Collection.PATIENT_NOUKNOWS,
        getSearchConditions: getSearchConditionsAdminUserDetailNouknow,
        withId: true,
        isBatch: false,
      },
      {
        // 支払い状況
        collection: "",
        getSearchConditions: null,
        withId: false,
        isBatch: false,
      },
      {
        // 注文履歴
        collection: Collection.ORDERS,
        getSearchConditions: getSearchConditionsAdminUserDetailOrder,
        withId: true,
        isBatch: true,
      },
    ],
    [],
  );

  const appContext = useFirebaseApp();

  const [userDetailTabItems, setUserDetailTabItems] = useStateCustomObj<
    { counter?: number; title: string; url: ADMIN }[]
  >(ADMIN_USER_DETAIL_TAB_ITEMS);

  const [userDetailTabError, setUserDetailTabError] = useState<Error | null>(
    null,
  );

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 各通知数を取得
        const userDetailTabItems = await Promise.all(
          userDetailTabInfoArr.map(
            async (
              { collection, getSearchConditions, withId, isBatch },
              index,
            ) => {
              if (collection && getSearchConditions) {
                // 通知取得処理が設定されている場合
                const searchConditions = {
                  ...getSearchConditions(),
                  ...(withId && {
                    patient_id: id,
                  }),
                };
                const count = (await appContext.functions(["mongo/client", { collection, countDocuments: { filter: searchConditions } }])) as number;
                if (isBatch && count > 0) {
                  // バッチ表示
                  return {
                    ...ADMIN_USER_DETAIL_TAB_ITEMS[index],
                    icon: "error_outline",
                    iconColor: "#A21B11",
                  };
                }

                // 件数表示
                return {
                  ...ADMIN_USER_DETAIL_TAB_ITEMS[index],
                  counter: count,
                };
              }

              return {
                ...ADMIN_USER_DETAIL_TAB_ITEMS[index],
                counter: 0,
              };
            },
          ),
        );

        setUserDetailTabItems(userDetailTabItems);
      } catch (err) {
        setUserDetailTabError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [userDetailTabInfoArr, setUserDetailTabItems, id, forceUpdate, appContext]);

  return { userDetailTabError, userDetailTabItems };
};

export default useAdminUserDetailTabFetch;
