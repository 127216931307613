import { useState, useEffect } from "react";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { getAggregateNlh001 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import useForceUpdate from "../../common/useForceUpdate";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

// NLH001画面のデータ取得処理
const useNlh001Fetch = () => {
  const appContext = useFirebaseApp();
  const [forceUpdate] = useForceUpdate();

  const [errorAndResult, setErrorAndResult] = useState<{
    result: CampaignsNlh001StateType[];
    error: Error | null;
  }>({
    result: [],
    error: null,
  });

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.CAMPAIGN_LIST_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateNlh001();
        // mongoDBのデータ
        const result = (await appContext.functions(["mongo/client", { collection: Collection.CAMPAIGNS, aggregate }])) as CampaignsNlh001StateType[];

        setErrorAndResult({ result, error: null });
      } catch (err) {
        setErrorAndResult({ result: [], error: checkFetchErr(err) });
      } finally {
        closeTableLoading();
      }
    };

    if (hasBrowsingRole) void fetchData();
  }, [appContext, forceUpdate.forceUpdateCount, hasBrowsingRole]);

  return errorAndResult;
};

export default useNlh001Fetch;
