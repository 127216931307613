import React from "react";
import Icon from "../Icon/Icon";
import FileUploadButton from "./FileUploadButton";
import FileUploadItem from "./FileUploadItem";

export type FileType = {
  name: string;
  percentage: number;
  url: string;
  hasError?: boolean;
  errorMessage?: string;
  intervalId?: NodeJS.Timer;
  // changeEmp
  size?: number;
};

export type FileUploadProps = {
  width?: React.CSSProperties["width"];
  disabled?: boolean;
  draggable?: boolean;
  uploadFiles?: FileType[];
  type?: "single" | "multiple";
  fileType?: string;
  setUploadFiles: React.Dispatch<React.SetStateAction<FileType[]>>;
};

const FileUpload = ({
  width = 476,
  disabled = false,
  draggable = true,
  uploadFiles = [],
  type = "multiple",
  fileType = "image/*",
  setUploadFiles,
}: FileUploadProps) => {
  const [isDragOver, setIsDragOver] = React.useState<boolean>();
  const percentagesRef = React.useRef<FileType[]>([]);

  const onUpload = React.useCallback(
    (fileList: FileList) => {
      if (!fileList || !uploadFiles) return;
      const files = Array.from(fileList);
      const initialIndex = uploadFiles.length;

      setUploadFiles([
        ...uploadFiles,
        ...files.map((file) => ({
          name: file.name,
          percentage: 0,
          url: URL.createObjectURL(file),
          // changeEmp
          size: file.size,
        })),
      ]);
      percentagesRef.current = [
        ...uploadFiles,
        ...files.map((file) => ({
          name: file.name,
          percentage: 0,
          url: URL.createObjectURL(file),
          // changeEmp
          size: file.size,
        })),
      ];
      files.forEach((file, index) => {
        const intervalId = setInterval(() => {
          const fileIndex = initialIndex + index;
          if (!percentagesRef.current[fileIndex].intervalId) {
            percentagesRef.current[fileIndex].intervalId = intervalId;
          }
          let percentage = percentagesRef?.current[fileIndex].percentage;
          if (percentage === undefined) {
            percentage = 0;
          }
          percentage += 1;
          percentagesRef.current[fileIndex].percentage = percentage;
          setUploadFiles((prev) => {
            const newUploadFiles = [...prev];
            let prevPercentage = prev[fileIndex].percentage;
            if (prevPercentage === undefined) {
              prevPercentage = 0;
            }
            prevPercentage += 1;
            newUploadFiles[fileIndex] = {
              ...prev[fileIndex],
              percentage: prevPercentage,
            };

            return newUploadFiles;
          });
          if (percentagesRef.current[fileIndex].percentage === 100) {
            clearInterval(intervalId);
          }
        }, 10);
      });
    },
    [uploadFiles, setUploadFiles],
  );

  const onStop = React.useCallback(
    (index: number) => {
      clearInterval(uploadFiles[index].intervalId);
    },
    [uploadFiles],
  );

  const onDelete = React.useCallback(
    (index: number) => {
      setUploadFiles((prev) => prev.filter((_, fileInd) => fileInd !== index));
    },
    [setUploadFiles],
  );

  const onRetry = React.useCallback(
    (index: number) => {
      setUploadFiles((prev) => [
        ...prev.slice(0, index),
        {
          ...prev[index],
          percentage: 0,
        },
        ...prev.slice(index + 1),
      ]);

      percentagesRef.current = [
        ...percentagesRef.current.slice(0, index),
        {
          ...percentagesRef.current[index],
          percentage: 0,
        },
      ];

      const intervalId = setInterval(() => {
        if (percentagesRef.current[index]) {
          percentagesRef.current = [
            ...percentagesRef.current.slice(0, index),
            {
              ...percentagesRef.current[index],
              intervalId,
            },
            ...percentagesRef.current.slice(index + 1),
          ];
        }
        percentagesRef.current = [
          ...percentagesRef.current.slice(0, index),
          {
            ...percentagesRef.current[index],
            percentage: percentagesRef?.current[index].percentage ?? 0 + 1,
          },
          ...percentagesRef.current.slice(index + 1),
        ];
        setUploadFiles((prev) => [
          ...prev.slice(0, index),
          {
            ...prev[index],
            percentage: percentagesRef.current[index].percentage,
          },
          ...prev.slice(index + 1),
        ]);
        if (uploadFiles[index].percentage === 100) {
          clearInterval(intervalId);
          percentagesRef.current = [
            ...percentagesRef.current.slice(0, index),
            ...percentagesRef.current.slice(index + 1),
          ];
        }
      }, 10);
    },
    [uploadFiles, setUploadFiles],
  );

  const _onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;
    onUpload(event.target.files);
  };

  const onDrag = (event: React.DragEvent<HTMLDivElement>, type: string) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(type === "over");
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    if (event.dataTransfer?.files[0]) onUpload(event.dataTransfer?.files);
  };

  const fileUploadClass = React.useMemo(() => {
    const classes = ["file-upload__draggable-area"];
    if (disabled) {
      classes.push("file-upload__draggable-area--disabled");

      return classes.join(" ");
    }
    if (isDragOver) classes.push("file-upload__draggable-area--dragging");

    return classes.join(" ");
  }, [disabled, isDragOver]);

  return (
    <div className="file-upload" style={{ width }}>
      {draggable ? (
        (type === "multiple" || uploadFiles?.length === 0) && (
          <div
            className={fileUploadClass}
            onDragOver={(event) => onDrag(event, "over")}
            onDragEnter={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            onDragLeave={(event) => onDrag(event, "leave")}
            onDrop={onDrop}
          >
            <div className="file-upload__draggable-area__inner">
              <span className="file-upload__draggable-area__icon">
                <Icon icon="upload" size="large" />
              </span>
              <div className="file-upload__draggable-area__text-1">
                <span>このエリアにドラッグ&ドロップで</span>
                <span>ファイルをアップロード</span>
              </div>
              <span className="file-upload__draggable-area__text-2">
                もしくは
              </span>
              <FileUploadButton
                disabled={disabled}
                onUpload={_onUpload}
                fileType={fileType}
              >
                ファイルを選択
              </FileUploadButton>
            </div>
          </div>
        )
      ) : (
        <FileUploadButton
          disabled={disabled}
          onUpload={_onUpload}
          fileType={fileType}
        >
          ファイルを選択
        </FileUploadButton>
      )}
      {uploadFiles.length > 0 && (
        <div>
          {uploadFiles.map((file, index) => (
            <FileUploadItem
              key={index}
              name={file.name}
              percentage={file.percentage}
              hasError={file.hasError}
              errorMessage={file.errorMessage}
              url={file.url}
              onStop={() => onStop(index)}
              onDelete={() => onDelete(index)}
              onRetry={() => onRetry(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
