import { useState } from "react";
import { Collection } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";

// NLC033画面のデータ取得処理
const useNlc033DBActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);

  // ユーザ評価を更新
  const handleUpdateCheckList = (id: string, checkList: boolean) => {
    void (async () => {
      try {
        openUpdateActionLoading();
        await appContext.functions(["mongo/client", {
          collection: Collection.CHATS,
          updateOne: {
            filter: { _id: id },
            update: {
              $set: {
                check_list: checkList,
                updated_at: new Date(),
              },
            },
          }
        }]);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    handleUpdateCheckList,
    error,
  };
};

export default useNlc033DBActions;
