import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

type FilterType = {
  field: string;
  value: string;
};

type UpdateType = {
  field: string; //
  incrementValue: number;
};

type FindOneAndUpdateResult = {
  [key: string]: number;
};

// sequenceテーブルから指定のidを返し、インクリメントする
const useGenerateSequenceId = () => {
  const appContext = useFirebaseApp();


  const generateId = async (
    filterCondition: FilterType,
    updateCondition: UpdateType,
  ) => {
    try {
      const filter = { [filterCondition.field]: filterCondition.value };
      const update = {
        $inc: { [updateCondition.field]: updateCondition.incrementValue },
      };
      const result = (await appContext.functions(["mongo/client", {
        collection: Collection.SEQUENCE, findOneAndUpdate: {
          filter,
          update,
          options: { returnDocument: "after" }
        }
      }])) as FindOneAndUpdateResult;
      const generatedId = result[updateCondition.field];

      return generatedId;
    } catch (err) {
      throw checkFetchErr(err);
    }
  };

  return { generateId };
};

export default useGenerateSequenceId;
