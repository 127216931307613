import { useEffect, useMemo, useState } from "react";
import { Collection } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateOneItem } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import useNli008Dialog from "./useNli008Dialog";
import useProductMgmtId from "../../common/useProductMgmtId";
import useProductMgmtDbActions from "../../common/useProductMgmtDbActions";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useItemTagsFetch from "../../common/useItemTagsFetch";

// NLI008画面のデータ操作処理
const useNli008DbActions = () => {
  const appContext = useFirebaseApp();
  const [{ itemId }] = useProductMgmtId();
  const [isOpen] = useNli008Dialog();
  const [forceUpdate] = useForceUpdate();
  const [fetchResult, setFetchResult] = useState<ItemsTableType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  // 商品タグ取得
  const { fetchItemTagsResult, fetchItemTagsError } = useItemTagsFetch();
  const databaseTags = fetchItemTagsResult.map(
    ({ _id, tag_name: tagName }) => ({
      id: _id,
      label: tagName,
      value: tagName,
    }),
  );

  // 商品種別、SKUのDB操作
  const { actionError, insertItem, updateItem } = useProductMgmtDbActions();

  // 取得、編集対象
  const filter = useMemo(() => ({ _id: itemId }), [itemId]);

  // 編集時の取得処理
  useEffect(() => {
    const fetchData = async () => {
      try {
        const aggregate = getAggregateOneItem(filter);
        // mongoDBのデータ
        const result = (await appContext.functions(["mongo/client", { collection: Collection.ITEMS, aggregate }])) as ItemsTableType[];

        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    // itemIdがあり、編集ダイアログを開いている時のみデータ取得
    if (itemId && isOpen) void fetchData();
  }, [filter, forceUpdate, setFetchResult, itemId, isOpen, appContext]);

  return {
    fetchResult,
    fetchError,
    databaseTags,
    fetchItemTagsError,
    actionError,
    insertItem,
    updateItem,
  };
};

export default useNli008DbActions;
