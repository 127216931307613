import React from "react";
import ReactDOM from "react-dom";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export type InformationDialogProps = {
  open?: boolean;
  alertLevel?: "normal" | "warning" | "error" | "none";
  width?: string;
  closeDialog: () => void;
  title: string;
  height?: string;
  footer: JSX.Element;
  children: React.ReactNode;
};

const InformationDialog = ({
  open = false,
  alertLevel = "normal",
  width = "464px",
  closeDialog,
  title = "",
  footer,
  height = "",
  children,
}: InformationDialogProps) => {
  const dialogRef = React.useRef<HTMLDialogElement>(null!);
  const [isOpen, setIsOpen] = React.useState(false);

  const dialogClass = React.useMemo(() => {
    const alertLevelClass = [`information-dialog--${alertLevel}`];

    return ["information-dialog", ...alertLevelClass].join(" ");
  }, [alertLevel]);

  const icon = React.useMemo(() => {
    switch (alertLevel) {
      case "warning":
        return "warning";
      case "error":
        return "error";
      case "none":
        return undefined;
      default:
        return "information_outline";
    }
  }, [alertLevel]);

  const showHideDialog = React.useCallback(() => {
    if (!dialogRef?.current) {
      return;
    }
    if (open) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.classList.add("close");
      dialogRef.current.addEventListener(
        "animationend",
        () => {
          if (dialogRef.current) {
            dialogRef.current.classList.remove("close");
            dialogRef.current.close();
          }
        },
        { once: true },
      );
    }
  }, [open]);

  const onClose = React.useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    if (open !== isOpen) {
      showHideDialog();
      setIsOpen(open);
    }
  }, [isOpen, open, showHideDialog]);

  return ReactDOM.createPortal(
    <dialog ref={dialogRef} className={dialogClass} style={{ width, height }}>
      <span className="information-dialog__close-button">
        <Button
          icon="close"
          shape="square"
          size="small"
          color="neutral"
          type="sub"
          onClick={onClose}
        />
      </span>
      <div className="information-dialog__container">
        {icon !== undefined && (
          <span className="information-dialog__alert-level-icon">
            <Icon icon={icon} size="large" />
          </span>
        )}
        <div className="information-dialog__content-container">
          <div className="information-dialog__title">{title}</div>
          <div className="information-dialog__content">{children}</div>
          <div className="information-dialog__footer">{footer}</div>
        </div>
      </div>
    </dialog>,
    document.body,
  );
};

export default InformationDialog;
