import { useState, useEffect } from "react";

import { getAggregateNlf007 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection } from "../../../constants/common";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

const useNlf007DbActions = (salesId: string) => {
  const appContext = useFirebaseApp();

  const [fetchResult, setFetchResult] = useStateCustomObj<
    SalesNlf007StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  const colName = "settlement_time";
  const sortDirection = "asc";

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateNlf007(salesId, colName, sortDirection);
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.SALES, aggregate }])) as SalesNlf007StateType[];

        setFetchResult(aggregateResult);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [colName, sortDirection, setFetchResult, salesId, appContext]);

  return {
    fetchResult,
    fetchError,
  };
};

export default useNlf007DbActions;
