import { useState, useEffect } from "react";

import { getAggregateNli033 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SEARCH_INFO,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useMemoStatusArr } from "../../../utils/utils";

type Nli033FetchParams = {
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLI033;
};

const useNli033DBFetch = ({ submittedSearchCriteria }: Nli033FetchParams) => {
  const appContext = useFirebaseApp();
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;
  const [fetchResults, setFetchResults] = useStateCustomObj<Nli033TableType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();
  const {
    name,
    code,
    paymentSpan,
    campaignEnd,
    campaignStartDate,
    campaignEndDate,
  } = submittedSearchCriteria;

  const colName = "created_at";
  const sortDirection = "desc";

  // 月額・年額のチェック状態
  const paymentSpanArray = useMemoStatusArr(
    SEARCH_INFO.NLI033_PAYMENT,
    paymentSpan,
  );

  // キャンペーン終了後のチェック状態
  const afterCampaignArray = useMemoStatusArr(
    SEARCH_INFO.NLI033_CAMPAIGN,
    campaignEnd,
  );

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateNli033(
          name,
          code,
          paymentSpanArray,
          afterCampaignArray,
          campaignStartDate,
          campaignEndDate,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.CAMPAIGN_COUPONS, aggregate }])) as AggregateResultType<Nli033TableType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;
        setTotal(total);
        setFetchResults(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [skip, perPage, setFetchResults, forceUpdate.forceUpdateCount, name, code, paymentSpanArray, afterCampaignArray, campaignStartDate, campaignEndDate, appContext]);

  return {
    fetchResults,
    fetchError,
  };
};

export default useNli033DBFetch;
