import { useState } from "react";
import {
  Collection,
  ItemsSkusStatusID,
  RECOMMEND_SCOPES,
  RECOMMEND_TERMS,
  RECOMMEND_TERMS_CONDITIONS,
  ROLE_KEY,
} from "../../constants/common";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import {
  openDeleteActionLoading,
  closeActionLoading,
  openUpdateActionLoading,
} from "../base/useLoadingAction";
import useProductMgmtId, {
  initProductMgmtId,
  setSkuId,
} from "./useProductMgmtId";
import { useNavigateCustom } from "../base/usePageTransitionCustom";
import useForceUpdate from "./useForceUpdate";
import { convertStrToNum } from "../../utils/utils";
import useSkuInput from "../input/useSkuInput";
import { getBase64Data } from "../component/ImageUpload/useImageUpload";
import {
  closeNli010Dialog,
  openNli010Dialog,
} from "../pages/NLI010/useNli010Dialog";
import generateUniqueId from "../../utils/generateUniqueId";
import useItemInput from "../input/useItemInput";
import {
  closeNli008Dialog,
  openNli008Dialog,
} from "../pages/NLI008/useNli008Dialog";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import useGenerateSequenceId from "./useGenerateSequenceId";
import { checkMasterRole } from "./useMasterRoleDbActions";

// 商品種別、SKUのDB操作
const useProductMgmtDbActions = () => {
  const appContext = useFirebaseApp();
  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const navigate = useNavigateCustom();

  // sortの最新番号取得用
  const { generateId } = useGenerateSequenceId();
  const generateItemsSortNo = async () =>
    generateId(
      { field: "items_sort_id", value: "sort_no" },
      { field: "sort_no", incrementValue: 1 },
    );
  const generateSkusSortNo = async () =>
    generateId(
      { field: "skus_sort_id", value: "sort_no" },
      { field: "sort_no", incrementValue: 1 },
    );

  // 現在選択中の商品種別、SKUのID
  const [{ itemId: selectItemId, skuId: selectSkuId }] = useProductMgmtId();
  // 商品種別、SKUの取得、編集対象
  const itemFilter = { _id: selectItemId };

  // 現在日時
  const currentDate = new Date();

  // アップロードした画像一覧(共通)
  // 商品種別の入力項目の内容
  const [
    {
      code: itemCode,
      name: itemName,
      description: itemDescription,
      tags: itemTags,
      isShow: isShowItem,
    },
  ] = useItemInput();
  // 商品種別の入力(MongoDBセット用)
  const getItemInput = async (_id: string) => {
    const images = await getBase64Data();

    return {
      _id,
      item_code: itemCode.value,
      item_name: itemName.value,
      item_description: itemDescription.value,
      images,
      tag: itemTags,
      status: isShowItem ? ItemsSkusStatusID.VISIBLE : ItemsSkusStatusID.HIDDEN,
    };
  };
  // SKUの入力項目の内容
  const [
    {
      code: skuCode,
      name: skuName,
      amount: skuAmount,
      stock: skuStock,
      tax,
      options,
      isShow: isShowSku,
    },
  ] = useSkuInput();

  // SKUの入力(MongoDBセット用)
  const getSkuInput = async (_id: string) => {
    const images = await getBase64Data();

    return {
      _id,
      sku_code: skuCode.value,
      sku_name: skuName.value,
      amount: convertStrToNum(skuAmount.value),
      images,
      stock: convertStrToNum(skuStock.value),
      status: isShowSku ? ItemsSkusStatusID.VISIBLE : ItemsSkusStatusID.HIDDEN,
      consumption_tax_rate: tax,
    };
  };

  // オプションの入力(MongoDBセット用)
  const createOptionInput = (skuId: string) =>
    Object.entries(options).map(([key, { value }]) => ({
      option_config_id: key,
      description: value,
      sku_id: skuId,
    }));

  // 商品種別、SKUの固有ID(_id)
  const newItemsId = generateUniqueId();
  const newSkusId = generateUniqueId();

  // 商品種別新規追加処理
  const insertItem = () => {
    void (async () => {
      closeNli008Dialog();
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_ADD, appContext);

        const sort = await generateItemsSortNo();
        const itemInput = await getItemInput(newItemsId);
        const itemsData = {
          ...itemInput,
          sort,
          // レコメンド初期値
          conditions: Number(RECOMMEND_TERMS[6].value),
          range: RECOMMEND_SCOPES[2].value,
          search_contents: [],
        };

        await appContext.functions(["upsertItemApi", itemsData]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err, openNli008Dialog));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 商品種別更新処理
  const updateItem = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        const itemInput = await getItemInput(selectItemId);
        closeNli008Dialog();
        await appContext.functions(["upsertItemApi", itemInput]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err, openNli008Dialog));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 商品種別削除処理 紐づくSKUも削除
  const deleteItem = (pagePath = "") => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        await appContext.functions(["deleteItemApi", selectItemId]);
        initProductMgmtId();
        if (pagePath) {
          // 一覧画面遷移
          navigate(pagePath);
        } else {
          setForceUpdate({
            forceUpdateCount: forceUpdate.forceUpdateCount + 1,
          });
        }
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 商品種別をドラッグアンドドロップ
  const dragAndDropItem = (sortAndIdValues: { sort: number; id: string }[]) => {
    void (async () => {
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        await appContext.functions(["sortItemAPI", [sortAndIdValues, "item"]]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    })();
  };

  // SKU新規追加処理 親となる空の商品種別も作成
  const insertSku = () => {
    void (async () => {
      closeNli010Dialog();
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_ADD, appContext);

        const itemsSort = await generateItemsSortNo();
        const skusSort = await generateSkusSortNo();
        const itemsData = {
          _id: newItemsId,
          item_description: "",
          item_name: "",
          sort: itemsSort,
          tag: [],
          status: ItemsSkusStatusID.HIDDEN,
          images: [],
          image_urls: [],
          item_code: "",
          // レコメンド初期値
          conditions: RECOMMEND_TERMS[6].value,
          range: RECOMMEND_SCOPES[2].value,
          search_contents: [],
        };
        const skuInput = await getSkuInput(newSkusId);
        const skusData = {
          ...skuInput,
          item_id: newItemsId,
          sort: skusSort,
        };
        await appContext.functions(["upsertItemApi", [itemsData, skusData]]);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err, openNli010Dialog));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // SKU更新処理
  const updateSku = () => {
    void (async () => {
      closeNli010Dialog();
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        const skuInput = await getSkuInput(selectSkuId);
        const optionInput = createOptionInput(selectSkuId);

        await appContext.functions(["upsertItemApi", [null, skuInput, optionInput]]);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err, openNli010Dialog));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // SKU削除処理
  const deleteSku = () => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        await appContext.functions(["deleteSkuApi", selectSkuId]);
        setSkuId("");
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // SKU追加処理 すでに作成されている商品種別に追加
  const addSku = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_ADD, appContext);

        const sort = await generateSkusSortNo();
        const skuInput = await getSkuInput(newSkusId);
        const skusData = {
          ...skuInput,
          item_id: selectItemId,
          sort,
        };
        const optionInput = createOptionInput(newSkusId);
        await appContext.functions(["upsertItemApi", [null, skusData, optionInput]]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // SKUをドラッグアンドドロップ
  const dragAndDropSku = (sortAndIdValues: { sort: number; id: string }[]) => {
    void (async () => {
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        await appContext.functions(["sortItemAPI", [sortAndIdValues, "sku"]]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    })();
  };

  // レコメンドの検索範囲を取得
  const getRecommendSearchContents = (conditions: string, range: string) => {
    const conditionsNum = Number(conditions);

    if (conditions === RECOMMEND_TERMS[0].value) {
      return [conditionsNum]; // すべて
    }
    if (conditions === RECOMMEND_TERMS[6].value) {
      return []; // 指定しない
    }

    const index = RECOMMEND_TERMS_CONDITIONS.findIndex(
      (term) => term === conditionsNum,
    );

    switch (range) {
      case RECOMMEND_SCOPES[0].value: // 以上
        return RECOMMEND_TERMS_CONDITIONS.slice(0, index + 1);
      case RECOMMEND_SCOPES[1].value: // 以下
        return RECOMMEND_TERMS_CONDITIONS.slice(index);
      case RECOMMEND_SCOPES[2].value: // 等しい
        return [conditionsNum];
      default:
        return [];
    }
  };

  const selectRecommendTerm = (conditions: string, range: string) => {
    void (async () => {
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        const searchContents = getRecommendSearchContents(conditions, range);
        const updateData = {
          conditions: Number(conditions),
          range,
          search_contents: searchContents,
          updated_at: currentDate,
        };

        await appContext.functions(["mongo/client", { collection: Collection.ITEMS, updateOne: { filter: itemFilter, update: { $set: updateData } } }]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    })();
  };

  const selectRecommendScope = (conditions: string, range: string) => {
    void (async () => {
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, appContext);

        const searchContents = getRecommendSearchContents(conditions, range);
        const updateData = {
          range,
          search_contents: searchContents,
          updated_at: currentDate,
        };

        await appContext.functions(["mongo/client", { collection: Collection.ITEMS, updateOne: { filter: itemFilter, update: { $set: updateData } } }]);
      } catch (err) {
        setActionError(checkActionErr(err));
      }
    })();
  };

  return {
    actionError,
    insertItem,
    updateItem,
    deleteItem,
    insertSku,
    updateSku,
    deleteSku,
    addSku,
    dragAndDropSku,
    dragAndDropItem,
    selectRecommendTerm,
    selectRecommendScope,
  };
};

export default useProductMgmtDbActions;
