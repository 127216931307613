import React from "react";

export type SheetProps = {
  type?:
    | "default"
    | "border"
    | "gray"
    | "border-blue"
    | "header"
    | "gray-custom"
    | "primary-lightest";
  className?: string;
  children: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  padding?: string;
  footer?: JSX.Element;
  borderRadius?: React.CSSProperties["borderRadius"];
  borderWidth?: React.CSSProperties["borderWidth"];
};

const Sheet = ({
  type = "default",
  className = "",
  children,
  footer,
  minWidth = "",
  maxWidth = "",
  minHeight = "",
  padding = "24px",
  borderRadius,
  borderWidth,
}: SheetProps) => {
  const sheetClass = React.useMemo(() => {
    const typeClass = [`sheet--${type}`];

    return [`sheet ${className}`, ...typeClass].join(" ");
  }, [type, className]);

  const isShowFooter = React.useMemo(() => footer !== undefined, [footer]);

  return (
    <div
      className={sheetClass}
      style={{
        minWidth,
        padding,
        minHeight,
        maxWidth,
        borderRadius,
        borderWidth,
      }}
    >
      {children}
      {isShowFooter && (
        <div className="sheet--footer">
          <div>{footer}</div>
        </div>
      )}
    </div>
  );
};

export default Sheet;
