import React from "react";

export type TableRowProps = {
  isSelected?: boolean;
  isError?: boolean;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest?: any[];
};

const TableRow = React.forwardRef(
  (
    { isSelected = false, isError = false, children, ...rest }: TableRowProps,
    ref: React.Ref<HTMLTableRowElement>,
  ) => {
    const rowClass = React.useMemo(() => {
      const selectedClass = isSelected ? [`table__row--selected`] : [];
      const errorClass = isError ? [`table__row--error`] : [];

      return ["table__row", ...selectedClass, ...errorClass].join(" ");
    }, [isError, isSelected]);

    return (
      <tr className={rowClass} ref={ref} {...rest}>
        {children}
      </tr>
    );
  },
);

export default TableRow;
