import { useState, useEffect } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregatePetHospitals } from "../../utils/query";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import useStateCustomObj from "../base/useStateCustomObj";

// Pet受診可能な病院を都道府県で取得
const useMriHospitalsFetch = (prefecture: string) => {
  const appContext = useFirebaseApp();

  const [petHospitals, setPetHospitals] = useStateCustomObj<PetHospital[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const aggregate = getAggregatePetHospitals(prefecture);
        const fetchResult = (await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, aggregate }])) as PetHospital[];

        setPetHospitals(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (prefecture) void fetchData();
  }, [setPetHospitals, prefecture, appContext]);

  return { error, petHospitals };
};

export default useMriHospitalsFetch;
