import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection } from "../../../constants/common";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useGenerateSequenceId from "../../common/useGenerateSequenceId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";

const useMie005DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const { generateId } = useGenerateSequenceId();

  // 医療機関登録
  const handleRegisterHospital = (
    hospitalName: string,
    tel: string,
    address: string,
    pref: string,
    email: string,
    hasMri: boolean,
    hasPet: boolean,
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 病院ID取得
        const id = await generateId(
          { field: "hospital_id", value: "hospital_no" },
          { field: "hospital_no", incrementValue: 1 },
        );

        // 登録データ
        const insertData = {
          _id: id.toString(),
          hospital_name: hospitalName,
          address,
          tel,
          email,
          prefecture: pref,
          status: 0,
          hasMri,
          hasPet,
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, insertOne: { doc: insertData } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 医療機関更新
  const handleUpdateHospital = (
    _id: string,
    hospitalName: string,
    tel: string,
    address: string,
    email: string,
    pref: string,
    hasMri: boolean,
    hasPet: boolean,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新データ
        const updateData = {
          hospital_name: hospitalName,
          prefecture: pref,
          address,
          tel,
          email,
          hasMri,
          hasPet,
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, updateOne: { filter: { _id }, update: { $set: updateData } } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospital,
    handleUpdateHospital,
  };
};

export default useMie005DbActions;
