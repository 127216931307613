import React, { CSSProperties } from "react";

export type TextProps = {
  color?:
    | "neutralBlack"
    | "neutralLight"
    | "placeholder"
    | "white"
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "information"
    | "warning"
    | "disabled";
  size?:
    | "5xl"
    | "4xl"
    | "3xl"
    | "2xl"
    | "xl"
    | "large"
    | "base"
    | "small"
    | "xs"
    | "2xs"
    | "3xs";
  textAlign?: "left" | "right" | "center" | "justify";
  bold?: boolean;
  lineHeight?: string;
  noWrap?: boolean;
  ellipsis?: boolean;
  width?: string;
  backgroundColor?: string;
  children: React.ReactNode;
  // changeEmp
  whiteSpace?: CSSProperties["whiteSpace"];
};

const Text = ({
  color = "neutralBlack",
  size = "base",
  textAlign = "left",
  bold = false,
  lineHeight = "",
  noWrap = false,
  ellipsis = false,
  width = undefined,
  backgroundColor = undefined,
  children,
  // changeEmp
  whiteSpace = "normal",
}: TextProps) => {
  const textClass = React.useMemo(() => {
    const boldClass = bold ? ["text--bold"] : [];
    const colorClass = color ? [`text--${color}`] : [];
    const noWrapClass = noWrap ? ["text--nowrap"] : [];
    const ellipsisClass = ellipsis ? ["text--ellipsis"] : [];
    const backgroundColorClass = backgroundColor
      ? ["text--background-color"]
      : [];

    return [
      "text",
      `text--${size}`,
      ...boldClass,
      ...colorClass,
      ...noWrapClass,
      ...ellipsisClass,
      ...backgroundColorClass,
    ].join(" ");
  }, [backgroundColor, bold, color, ellipsis, noWrap, size]);

  return (
    <span
      className={textClass}
      style={{
        lineHeight,
        width,
        backgroundColor,
        textAlign,
        // changeEmp
        whiteSpace: noWrap ? "nowrap" : whiteSpace,
      }}
    >
      {children}
    </span>
  );
};

export default Text;
