import React from "react";

export type HeaderProps = {
  leftControl?: JSX.Element;
  content?: JSX.Element;
  rightControl?: JSX.Element;
};

const Header = ({ leftControl, content, rightControl }: HeaderProps) => (
  <div className="header">
    <div className="header__left">
      {leftControl ? (
        <div className="header__left-ctrl">{leftControl}</div>
      ) : null}
      {content}
    </div>
    {rightControl ? <div className="header__right">{rightControl}</div> : null}
  </div>
);

export default Header;
