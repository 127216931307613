import { useState } from "react";

import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type DicomUrl = {
  url: string;
};

const useDicomUrl = () => {
  const appContext = useFirebaseApp();

  const [dicomUrlError, setDicomUrlError] = useState<Error | null>(null);

  const fetchDicomUrl = async (modalityId: string, studyUid: string) => {
    addLoadCount();
    try {
      const response = (await appContext.functions(["mri_pet/getDicomURL", [
        modalityId,
        studyUid,
      ]])) as DicomUrl;

      const dicomUrl = response.url;

      if (!dicomUrl) {
        const error = new Error("Dicom URL not received");
        setDicomUrlError(checkActionErr(error));
      }

      return dicomUrl;
    } catch (err) {
      setDicomUrlError(checkActionErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    fetchDicomUrl,
    dicomUrlError,
  };
};

export default useDicomUrl;
