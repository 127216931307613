import { PatientInterviewStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  interviewId: string;
} & Nlc021StateType;

const INIT_DATA: Nlc021StateType = {
  name: "",
  status: PatientInterviewStatusID.NEW_RESERVATION,
  birth: "",
  created_at: "",
  email: "",
  tel: "",
  gender: "",
  invitation_ticket_id: "",
  candidate_interview_dates: {
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
  },
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  interviewId: "",
  ...INIT_DATA,
});

const useNlc021Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setInterviewId = (interviewId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    interviewId,
  });
};

export const setNlc021Data = (data: Nlc021StateType) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...data,
  });
};

export default useNlc021Conditions;
