import { useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  HospitalPaymentsExaminationID,
  PatientBrainCheckStatusID,
} from "../../../constants/common";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import { FileType } from "../../../components/FileUpload/FileUpload";
import useNlc031Conditions from "./useNlc031Conditions";
import { closeNlc031Dialog } from "./useNlc031Dialog";
import { closeNlc031CancelDialog } from "./useNlc031CancelDialog";
import generateUniqueId from "../../../utils/generateUniqueId";

type GcsUrl = {
  file_name: string;
  signedURL: string;
};

type UploadUpdateData = {
  report_path?: string;
  updated_at: Date;
  note?: string;
  status: number;
  commented_user_id?: string;
};

const useNlc031DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [{ brainCheckId, note }] = useNlc031Conditions();

  // 診断画像アップロード
  const uploadFile = async (
    files: FileType[],
    setUploadFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  ) => {
    openUpdateActionLoading();
    try {
      // Blob URLからBlobオブジェクトを取得
      const blobResponse = await fetch(files[0].url);
      if (!blobResponse.ok) {
        const error = new Error("Failed to read Blob");
        setActionError(checkActionErr(error));

        return;
      }
      const blob = await blobResponse.blob();

      // GCS URL取得
      const gcsUrl = (await appContext.functions(["braincheck/uploadSignUrl", [
        brainCheckId,
        files[0].name,
      ]])) as GcsUrl;

      // アップロード
      const uploadResponse = await fetch(gcsUrl.signedURL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: blob,
      });

      if (uploadResponse.ok) {
        const updateData: UploadUpdateData = {
          report_path: gcsUrl.file_name,
          status: PatientBrainCheckStatusID.COMPLETED,
          commented_user_id: currentUser?.uid as string ?? "",
          updated_at: new Date(),
        };

        if (note) {
          updateData.note = note;
        }

        await appContext.functions(["mongo/client", { collection: Collection.PATIENT_BRAIN_CHECKS, updateOne: { filter: { _id: brainCheckId }, update: { $set: updateData } } }]);
        setUploadFiles([]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc031Dialog();
      } else {
        const error = new Error("Failed to upload File");
        setActionError(checkActionErr(error));
      }
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      closeActionLoading();
    }
  };

  // (あたまの健康チェック)結果送信取消
  const sendCancel = (patientId: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const updateData: UploadUpdateData = {
          status: PatientBrainCheckStatusID.COMPLETED,
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.PATIENT_BRAIN_CHECKS, updateOne: { filter: { _id: brainCheckId }, update: { $set: updateData } } }]);

        const uniqueId = generateUniqueId();
        // 検査費用支払いデータ登録
        const insertData = {
          _id: uniqueId,
          patient_id: patientId,
          hospital_id: process.env.REACT_APP_MIRENIA_ID,
          examination: HospitalPaymentsExaminationID.BRAIN_HEALTH_CHECK,
          amount: -1000,
          exam_date: new Date(),
          created_at: new Date(),
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITAL_PAYMENTS, insertOne: { doc: insertData } }]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc031Dialog();
        closeNlc031CancelDialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    uploadFile,
    sendCancel,
    actionError,
  };
};

export default useNlc031DbActions;
