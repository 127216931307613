import { useEffect, useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  FORMAT_STYLE,
  convertItemCheckedToDefaultSchedule,
  generateInterviewDefaultItemChecked,
} from "../../../constants/common";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import { formatDate, formatHourTime } from "../../../utils/utils";
import useForceUpdate from "../../common/useForceUpdate";
import useNli031Conditions, {
  setDailyScheduleItem,
  setIsChangedTemplate,
  setTimeItems,
} from "./useNli031Conditions";

const useNli031DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [fetchResult, setFetchResult] = useState<Nli031StateType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [
    {
      timeFrame,
      amStartTime,
      amEndTime,
      pmStartTime,
      pmEndTime,
      itemChecked,
      dailyScheduleItem,
    },
    setCondition,
  ] = useNli031Conditions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const defaultSchedules =
          (await appContext.functions(["mongo/client", { collection: Collection.INTERVIEW_DEFAULT_SCHEDULES, find: {} }])) as InterviewDefaultSchedule[];

        const dailySchedules = (await appContext.functions(["mongo/client", { collection:           Collection.INTERVIEW_DAILY_SCHEDULES, find: {} }])) as InterviewDailySchedule[];

        const settings = (await appContext.functions(["mongo/client", { collection: Collection.SETTINGS, findOne: {} }])) as SettingsType;

        setFetchResult({
          interview_open_am: settings.interview_open_am,
          interview_open_pm: settings.interview_open_pm,
          interview_interval: settings.interview_interval,
          schedule_info: defaultSchedules,
          schedule_daily_info: dailySchedules,
        });

        if (defaultSchedules) {
          setTimeItems(generateInterviewDefaultItemChecked(defaultSchedules));
        }
        if (dailySchedules) {
          setDailyScheduleItem(
            dailySchedules.map((item) => ({
              datetime: item.datetime,
              is_open: item.is_open,
            })),
          );
        }

        const originDailyScheduleItem = dailySchedules.map((item) => ({
          datetime: item.datetime,
          is_open: item.is_open,
        }));

        setCondition((prevCondition) => ({
          ...prevCondition,
          timeInterval: settings.interview_interval
            ? String(settings.interview_interval)
            : "15",
          timeFrame: settings.interview_interval
            ? (settings.interview_interval as 0 | 15 | 30 | 60 | 90 | 120)
            : 15,
          amStartTime:
            settings.interview_open_am?.start ?? new Date(0, 0, 0, 9, 0),
          amEndTime:
            settings.interview_open_am?.end ?? new Date(0, 0, 0, 12, 0),
          pmStartTime:
            settings.interview_open_pm?.start ?? new Date(0, 0, 0, 13, 0),
          pmEndTime:
            settings.interview_open_pm?.end ?? new Date(0, 0, 0, 18, 0),
          initAmStartTime:
            settings.interview_open_am?.start ?? new Date(0, 0, 0, 9, 0),
          initAmEndTime:
            settings.interview_open_am?.end ?? new Date(0, 0, 0, 12, 0),
          initPmStartTime:
            settings.interview_open_pm?.start ?? new Date(0, 0, 0, 13, 0),
          initPmEndTime:
            settings.interview_open_pm?.end ?? new Date(0, 0, 0, 18, 0),
          originData: {
            ...prevCondition.originData,
            amStartTimeStr: settings.interview_open_am?.start
              ? formatHourTime(settings.interview_open_am?.start)
              : "09:00",
            amEndTimeStr: settings.interview_open_am?.end
              ? formatHourTime(settings.interview_open_am?.end)
              : "12:00",
            pmStartTimeStr: settings.interview_open_pm?.start
              ? formatHourTime(settings.interview_open_pm?.start)
              : "13:00",
            pmEndTimeStr: settings.interview_open_pm?.end
              ? formatHourTime(settings.interview_open_pm.end)
              : "18:00",
            timeInterval: settings.interview_interval
              ? String(settings.interview_interval)
              : "15",
            timeFrame: settings.interview_interval
              ? (settings.interview_interval as 0 | 15 | 30 | 60 | 90 | 120)
              : 15,
            timeItems: generateInterviewDefaultItemChecked(defaultSchedules),
            dailyScheduleItem: originDailyScheduleItem,
          },
        }));
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setCondition, forceUpdate, appContext]);

  // デフォルト スケジュール更新
  const handleUpdateDefaultSchedule = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新データ
        const updateData = {
          booking_type: "interview",
          interval: timeFrame,
          opening_am: {
            start: formatHourTime(amStartTime),
            end: formatHourTime(amEndTime),
          },
          opening_pm: {
            start: formatHourTime(pmStartTime),
            end: formatHourTime(pmEndTime),
          },
          default_schedule: convertItemCheckedToDefaultSchedule(itemChecked),
        };

        // デフォルトスケジュール更新API
        await appContext.functions(["booking/saveAvailableBooking", [
          updateData.booking_type,
          updateData.interval,
          updateData.opening_am,
          updateData.opening_pm,
          "",
          updateData.default_schedule,
        ]]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsChangedTemplate(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 日別 スケジュール更新
  const handleUpdateDaily = (startOfWeek: Date, endOfWeek: Date) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const updateRange = {
          start: formatDate(startOfWeek, FORMAT_STYLE["YYYY-MM-DD"]),
          end: formatDate(endOfWeek, FORMAT_STYLE["YYYY-MM-DD"]),
        };

        // 範囲内のみ更新
        const dailyScheduleItemCopy = dailyScheduleItem
          .filter((item) => {
            const itemDate = new Date(item.datetime).getTime();
            const startDate = new Date(updateRange.start);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(updateRange.end);
            endDate.setHours(23, 59, 59, 999);

            const startDateTime = startDate.getTime();
            const endDateTime = endDate.getTime();

            return itemDate >= startDateTime && itemDate <= endDateTime;
          })
          .map((item) => ({
            ...item,
          }));

        // 更新データ
        const updateData = {
          booking_type: "interview",
          update_range: updateRange,
          schedules: dailyScheduleItemCopy,
        };

        // 日別スケジュール更新API
        await appContext.functions(["booking/saveAvailableDailyBooking", [
          updateData.booking_type,
          "",
          updateData.update_range,
          updateData.schedules,
        ]]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsChangedTemplate(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    actionError: error || fetchError,
    handleUpdateDefaultSchedule,
    handleUpdateDaily,
  };
};

export default useNli031DbActions;
