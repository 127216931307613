import React from "react";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import ProgressBar from "../Loading/ProgressBar";
import Link from "../Link/Link";

export type FileUploadItemProps = {
  name: string;
  percentage: number;
  url?: string;
  hasError?: boolean;
  errorMessage?: string;
  onStop: () => void;
  onRetry: () => void;
  onDelete: () => void;
};

const FileUploadItem = ({
  name = "",
  percentage = 0,
  url,
  hasError = false,
  errorMessage,
  onStop,
  onDelete,
  onRetry,
}: FileUploadItemProps) => {
  const fileUploadItemClass = React.useMemo(() => {
    const classes = ["file-upload-item_content"];
    if (hasError) {
      classes.push("file-upload-item_content--error");

      return classes.join(" ");
    }
    if (percentage === 0) classes.push("file-upload-item_name--incomplete");

    return classes.join(" ");
  }, [hasError, percentage]);

  return (
    <div>
      <div className={fileUploadItemClass}>
        {hasError ? (
          <span className="file-upload-item_content__error-icon">
            <Icon icon="error" size="xs" />
          </span>
        ) : (
          <Icon icon="attach_file" size="xs" />
        )}

        {hasError || (!hasError && percentage !== 100 && <span>{name}</span>)}
        {percentage === 100 && (
          <Link url={url} external={false}>
            {name}
          </Link>
        )}

        <div className="file-upload-item_content__right-item">
          {!hasError && percentage !== 100 && (
            <ProgressBar percentage={percentage} width="212px" />
          )}
          {hasError && (
            <Button
              color="main"
              type="secondary"
              icon="upload"
              size="small"
              onClick={onRetry}
            >
              再アップロード
            </Button>
          )}
          {percentage !== 100 && (
            <Button
              color="neutral"
              type="sub"
              icon="close"
              shape="circle"
              size="small"
              onClick={onStop}
            />
          )}

          {!hasError && percentage === 100 && (
            <Button
              color="danger"
              type="sub"
              icon="delete"
              shape="square"
              size="small"
              onClick={onDelete}
            />
          )}
        </div>
      </div>
      {hasError && (
        <span className="file-upload-item_content__error-message">
          <span>{errorMessage}</span>
        </span>
      )}
    </div>
  );
};

export default FileUploadItem;
