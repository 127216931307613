import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateStripeSubscription } from "../../utils/query";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import useForceUpdate from "./useForceUpdate";
import { formatDate } from "../../utils/utils";

// Stripeのサブスクリプション情報を取得
const useStripeSubscriptionFetch = () => {
  const appContext = useFirebaseApp();

  const [nextUpdateDateStr, setNextUpdateDateStr] = useState("");
  const [lastUpdateDateStr, setLastUpdateDateStr] = useState("");
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // 特定IDのサブスクリプション情報を取得
        const conditions = { _id: id };
        const aggregate = getAggregateStripeSubscription(conditions);
        const result = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as {
          stripeSubscriptionId: string;
        }[];

        if (result.length < 1) redirectToNoDataPage();

        const { stripeSubscriptionId } = result[0];
        if (!stripeSubscriptionId) {
          setNextUpdateDateStr("");
          setLastUpdateDateStr("");

          return;
        }

        // ストライプ情報取得API
        const stripeInfo = (await appContext.functions([
          "stripe/subscription/info"
        , stripeSubscriptionId])) as SubscriptionInfo;

        if (!stripeInfo) {
          setNextUpdateDateStr("");
          setLastUpdateDateStr("");

          return;
        }
        const nextBillingDateStr = stripeInfo.nextBillingDate
          ? formatDate(stripeInfo.nextBillingDate)
          : "";
        const lastBillingDateStr = stripeInfo.lastBillingDate
          ? formatDate(stripeInfo.lastBillingDate)
          : "";
        setNextUpdateDateStr(nextBillingDateStr);
        setLastUpdateDateStr(lastBillingDateStr);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [id, forceUpdate.forceUpdateCount, appContext]);

  return { error, nextUpdateDateStr, lastUpdateDateStr };
};

export default useStripeSubscriptionFetch;
