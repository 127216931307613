import React from "react";
import Image from "../Image/Image";
import LayoutBox from "../LayoutBox/LayoutBox";

export type ImageGalleryProps = {
  urls?: {
    id: string;
    url: string;
  }[];
};

const ImageGallery = ({ urls = [] }: ImageGalleryProps) => {
  const [selectedImage, setSelectedImage] = React.useState<string | undefined>(
    urls?.[0]?.url,
  );

  const handleImageClick = (image?: string) => {
    setSelectedImage(image);
  };

  return (
    <LayoutBox direction="column" fullWidth>
      <LayoutBox fullWidth>
        {selectedImage && (
          <Image
            url={selectedImage}
            alt="Selected"
            width="286px"
            height="162px"
            className="image-gallery--displayed"
          />
        )}
      </LayoutBox>
      <LayoutBox justify="between" fullWidth>
        {urls?.map((url, index) => (
          <Image
            key={url.id}
            url={url.url}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => handleImageClick(url.url)}
            width="64px"
            height="64px"
            className={
              selectedImage === url.url ? "image-gallery--selected" : ""
            }
          />
        ))}
      </LayoutBox>
    </LayoutBox>
  );
};

export default ImageGallery;
