import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNlc028 } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useNlc028Conditions, { setNlc028Data } from "./useNlc028Conditions";
import useNlc028Dialog from "./useNlc028Dialog";

const useNlc028DbFetch = () => {
  const appContext = useFirebaseApp();

  const [fetchError, setFetchError] = useState<Error | null>(null);

  const [isOpen] = useNlc028Dialog();
  const [{ modalityId }] = useNlc028Conditions();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {

        const aggregate = getAggregateNlc028(modalityId);
        const result = (await appContext.functions(["mongo/client", { collection:           Collection.PATIENT_MODALITY_BOOKS, aggregate }])) as PatientNlc028StateType[];

        if (result[0]) setNlc028Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [modalityId, isOpen, appContext]);

  return { fetchError };
};

export default useNlc028DbFetch;
