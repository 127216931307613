/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  Title,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
);

export type LineChartProps = {
  chartData: ChartData;
  chartOptions?: ChartOptions;
  height?: number;
  unit?: string;
  unitDirection?: "left" | "right";
  maxYScale?: number;
};

const COLOR_LIST = ["#096AE2", "#0A810F", "#935D01"];
const POINT_STYLE_LIST = ["circle", "rect", "triangle"];

const LineChart = ({
  chartData,
  chartOptions,
  height,
  unit = "¥",
  unitDirection = "left",
  maxYScale = 1000,
}: LineChartProps) => {
  const lineChartData: any = React.useMemo(
    () => ({
      ...chartData,
      datasets: chartData.datasets.map((dataset, index) => ({
        ...dataset,
        borderColor: COLOR_LIST[index],
        pointStyle: POINT_STYLE_LIST[index],
        pointRadius: 7,
        pointHoverRadius: 7,
        pointBorderWidth: 2,
        pointBackgroundColor: "#ffffff",
      })),
    }),
    [chartData],
  );

  const lineChartOptions: any = React.useMemo(
    () =>
      chartOptions || {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            max: maxYScale,
            ticks: {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              callback: (value: string) =>
                unitDirection === "right"
                  ? `${value.toLocaleString()}${unit}`
                  : `${unit}${value.toLocaleString()}`,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,
            position: "nearest",

            external: (context: any) => {
              // Tooltip Element
              let tooltipEl = document.getElementById("linechart-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "linechart-tooltip";
                tooltipEl.innerHTML =
                  '<table></table><div style="border:4px solid transparent; border-top-color:rgba(0, 0, 0, 0.8);position:absolute;left:calc(50% - 58px);bottom:-8px;"></div>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              const tooltipModel = context.tooltip;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = "0";

                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (tooltipModel.yAlign) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // Set Text
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (tooltipModel.body) {
                let innerHtml = "<thead>";

                innerHtml += "</thead><tbody>";

                chartData.datasets.forEach((dataset, index) => {
                  let background =
                    "background: url(assets/chart-mark-1.svg) no-repeat center;background-size:contain;width:18px;height:16px;";
                  const label = dataset?.label ?? "";
                  if (index === 1) {
                    background =
                      "background: url(assets/chart-mark-3.svg) no-repeat center;background-size:contain;width:18px;height:16px;";
                  } else if (index === 2) {
                    background =
                      "background: url(assets/chart-mark-2.svg) no-repeat center;background-size:contain;width:18px;height:16px;";
                  }
                  innerHtml +=
                    `<tr><td><div style="${background};margin-right:4px;"></div>` +
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `</td><td>${
                      (unit &&
                        unitDirection === "right" &&
                        `${label}:${
                          dataset?.data[
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            tooltipModel.dataPoints[0].dataIndex
                          ]?.toLocaleString() || ""
                        }${unit}`) ||
                      ""
                    }
                    ${
                      (unit &&
                        unitDirection === "left" &&
                        `${unit}${
                          dataset?.data[
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            tooltipModel.dataPoints[0].dataIndex
                          ]?.toLocaleString() || ""
                        }`) ||
                      ""
                    }</td></tr>`;
                });
                innerHtml += "</tbody>";

                const tableRoot = tooltipEl.querySelector("table");
                if (tableRoot) tableRoot.innerHTML = innerHtml;
              }

              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              const position = context.chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = "1";
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left = `${
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access
                position.left + window.pageXOffset + tooltipModel.caretX - 40
              }px`;
              tooltipEl.style.top = `${
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access
                position.top +
                window.pageYOffset +
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-plus-operands
                tooltipModel.caretY -
                chartData.datasets.length * 24 -
                24
              }px`;
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
              tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
              tooltipEl.style.pointerEvents = "none";
              tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
              tooltipEl.style.borderRadius = "4px";
              tooltipEl.style.color = "#fff";
              tooltipEl.style.padding = "8px";
              tooltipEl.style.fontSize = "13px";
            },
          },
        },
      },
    [chartData.datasets, chartOptions, unit, unitDirection, maxYScale],
  );

  return (
    <Line
      style={{ width: "100%", height }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data={lineChartData}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      options={lineChartOptions}
      height={height}
    />
  );
};

export default LineChart;
