import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection, ROLE_KEY } from "../../../constants/common";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useGenerateSequenceId from "../../common/useGenerateSequenceId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";
import useHospitalInput from "../../input/useHospitalInput";

// hospitalsに保存するservice_priceの形に編集
const createServicePriceObject = (
  nouknowPrice: string,
  mriPrice: string,
  brainHealthCheckPrice: string,
  petPrice: string,
) => ({
  ...(nouknowPrice !== "" && {
    nouknow: Number(nouknowPrice.replace(/,/g, "")),
  }),
  ...(mriPrice !== "" && {
    mri: Number(mriPrice.replace(/,/g, "")),
  }),
  ...(brainHealthCheckPrice !== "" && {
    brain_health_check: Number(brainHealthCheckPrice.replace(/,/g, "")),
  }),
  ...(petPrice !== "" && {
    pet: Number(petPrice.replace(/,/g, "")),
  }),
});

const useNli030DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const { generateId } = useGenerateSequenceId();
  const [
    {
      hospitalName,
      pref,
      address,
      tel,
      email,
      hasNouknow,
      hasMri,
      hasBrainHealthCheck,
      hasPet,
      nouknowPrice,
      mriPrice,
      brainHealthCheckPrice,
      petPrice,
    },
  ] = useHospitalInput();

  // 医療機関登録
  const handleRegisterHospital = (setIsOpen: (isOpen: boolean) => void) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_ADD, appContext);

        // 病院ID取得
        const id = await generateId(
          { field: "hospital_id", value: "hospital_no" },
          { field: "hospital_no", incrementValue: 1 },
        );

        // サービス金額
        const servicePrice = createServicePriceObject(
          nouknowPrice.value,
          mriPrice.value,
          brainHealthCheckPrice.value,
          petPrice.value,
        );

        // 登録データ
        const insertData = {
          _id: id.toString(),
          hospital_name: hospitalName.value,
          prefecture: pref,
          address: address.value,
          tel: tel.value,
          email: email.value,
          has_mri: hasMri,
          has_pet: hasPet,
          has_brain_health_check: hasBrainHealthCheck,
          has_nouknow: hasNouknow,
          service_price: servicePrice,
          status: 0,
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, insertOne: { doc: insertData } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsOpen(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 医療機関更新
  const handleUpdateHospital = (
    _id: string,
    setIsOpen: (isOpen: boolean) => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // サービス金額
        const servicePrice = createServicePriceObject(
          nouknowPrice.value,
          mriPrice.value,
          brainHealthCheckPrice.value,
          petPrice.value,
        );

        // 更新データ
        const updateData = {
          hospital_name: hospitalName.value,
          prefecture: pref,
          address: address.value,
          tel: tel.value,
          email: email.value,
          has_mri: hasMri,
          has_pet: hasPet,
          has_brain_health_check: hasBrainHealthCheck,
          has_nouknow: hasNouknow,
          service_price: servicePrice,
        };

        await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, updateOne: { filter: { _id }, update: { $set: updateData } } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsOpen(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospital,
    handleUpdateHospital,
  };
};

export default useNli030DbActions;
