import { useState } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useFirebaseApp } from "../../contexts/FirebaseApp";

type UserDataType = {
  _id: string;
  email: string;
};

// usersに同一emailが存在するかチェックします
const useCheckUsersEmail = () => {
  const appContext = useFirebaseApp();

  const [error, setError] = useState<Error | null>(null);

  const checkExistingEmail = async (email: string) => {
    try {
      const existingEmail = (await appContext.functions(["mongo/client", {
        collection: Collection.USERS, findOne: {
          filter: {
            email,
          }
        }
      }])) as UserDataType | null;

      if (existingEmail) {
        return true;
      }

      return false;
    } catch (err) {
      setError(checkFetchErr(err));

      return false;
    }
  };

  return { error, checkExistingEmail };
};

export default useCheckUsersEmail;
