import { useEffect, useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import {
  checkMasterRole,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

const useNli001DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [masterRoles, setMasterRoles] = useState<MasterRolesStateType[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_AUTHORITY_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const result = await appContext.functions(["mongo/client", { collection: Collection.MASTER_ROLES, find: { filter: {}, options: { sort: { role_sort_no: 1 } } } }]);
        setMasterRoles(result as MasterRolesStateType[]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限があるときのみデータ取得
    if (hasBrowsingRole) void fetchData();
  }, [appContext, currentUser, forceUpdate.forceUpdateCount, hasBrowsingRole]);

  // 権限更新
  const handleUpdate = (
    submittedRoles: MasterRolesStateType[],
    closeEdit: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_AUTHORITY_EDIT, appContext);

        const updatePromise = submittedRoles.map((role) =>
          appContext.functions(["mongo/client", {
            collection: Collection.MASTER_ROLES, updateOne: {
              filter: { _id: role._id },
              update: {
                $set: {
                  has_admin: role.has_admin,
                  has_doctor: role.has_doctor,
                  has_user: role.has_user,
                  updated_at: new Date(),
                },
              },
            }
          }]),
        );

        await Promise.all(updatePromise).then(() => {
          setForceUpdate({
            forceUpdateCount: forceUpdate.forceUpdateCount + 1,
          });
          closeEdit();
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, masterRoles, handleUpdate };
};

export default useNli001DbActions;
