import React from "react";
import Icon from "../Icon/Icon";
import Spinner from "../Loading/Spinner";

export type ButtonProps = {
  color?: "main" | "danger" | "neutral" | "background-dark";
  type?: "primary" | "secondary" | "sub";
  shape?: "square" | "circle";
  size?: "small" | "medium" | "large";
  icon?: string;
  iconPosition?: "right" | "left";
  iconType?: "outlined" | "filled";
  disabled?: boolean;
  width?: string;
  loading?: boolean;
  className?: string;
  loadingPosition?: "left" | "center";
  onClick?: (value?: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  borderLess?: boolean;
  buttonType?: "button" | "reset" | "submit";
};

const Button = ({
  color = "main",
  type = "primary",
  shape = "square",
  size = "medium",
  icon = "",
  iconPosition = "left",
  iconType = "filled",
  disabled = false,
  width = "",
  loading = false,
  className = "",
  loadingPosition = "center",
  onClick = () => { },
  children,
  borderLess = false,
  buttonType = "button",
}: ButtonProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const buttonClass = React.useMemo(() => {
    const colorClass =
      (type === "primary" && color === "neutral") ||
      (type === "secondary" && color === "neutral")
        ? [`btn--main`]
        : [`btn--${color}`];
    const typeClass = [`btn--${type}`];
    const sizeClass = [`btn--${size}`];
    const shapeClass = [`btn--${shape}`];
    const iconOnlyClass = !children ? [`btn--icon-only`] : [];
    const loadingClass = loading ? [`btn--loading`] : [];
    const borderlessClass = borderLess ? [`btn--borderless`] : [];

    return [
      "btn",
      ...colorClass,
      ...typeClass,
      ...sizeClass,
      ...shapeClass,
      ...iconOnlyClass,
      ...loadingClass,
      ...borderlessClass,
    ].join(" ");
  }, [borderLess, children, color, loading, shape, size, type]);

  const iconSize = React.useMemo(() => {
    if (size === "large") {
      return "medium";
    }
    if (size === "medium") {
      return "small";
    }

    return "xs";
  }, [size]);

  const loadingWidth = React.useMemo(() => {
    if (loading && loadingPosition === "center") {
      if (width) {
        return width;
      }
      if (buttonRef.current) {
        return `${buttonRef.current.getBoundingClientRect().width}px`;
      }

      return "";
    }

    return "";
  }, [loading, loadingPosition, width]);

  const isReplacedBySpinner = React.useCallback(
    (content: string) => {
      if (!loading) {
        return false;
      }

      if (loadingPosition === "center") {
        if (content === "slot") {
          return true;
        }
      } else if (loadingPosition === "left") {
        if (content === "slot") {
          return false;
        }
        if (content === "icon") {
          return true;
        }
      }

      return false;
    },
    [loading, loadingPosition],
  );

  const spinnerContainerSize = React.useMemo(() => {
    if (size === "small" || size === "medium") {
      return "20px";
    }

    return "24px";
  }, [size]);

  const spinnerSize = React.useMemo(() => {
    if (size === "small" || size === "medium") {
      return "small";
    }

    return "medium";
  }, [size]);

  const spinnerColor = React.useMemo(() => {
    if (color === "background-dark") {
      if (type === "primary") {
        return "main";
      }

      return "white";
    }

    if (type === "primary") {
      return "white";
    }
    if (color === "main") {
      return "main";
    }
    if (color === "danger") {
      return "danger";
    }

    return "neutral";
  }, [color, type]);

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      ref={buttonRef}
      className={`${buttonClass} ${className}`}
      disabled={disabled}
      style={{ width: loadingWidth || width }}
      onClick={onClick}
    >
      {isReplacedBySpinner("slot") ? (
        <Spinner size={spinnerSize} color={spinnerColor} />
      ) : (
        <span className="btn__inner">
          {isReplacedBySpinner("icon") ? (
            <span
              className="btn__spinner-container"
              style={{
                width: spinnerContainerSize,
                height: spinnerContainerSize,
              }}
            >
              <Spinner size={spinnerSize} color={spinnerColor} />
            </span>
          ) : icon && iconPosition === "left" ? (
            <Icon icon={icon} size={iconSize} type={iconType} />
          ) : null}
          {children && <span className="btn__content">{children}</span>}
          {icon && iconPosition === "right" && !isReplacedBySpinner("icon") && (
            <Icon icon={icon} size={iconSize} type={iconType} />
          )}
        </span>
      )}
    </button>
  );
};

export default Button;
