import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import {
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  HospitalReservationStatusID,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
} from "../constants/common";
import {
  getHospitalStatusTagEmphasis,
  getHospitalStatusTagState,
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
} from "../utils/utils";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertMic001Table } from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import useMic001Conditions from "../hooks/pages/MIC001/useMic001Conditions";
import { HOSPITAL } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setId } from "../hooks/common/useId";
import Input from "../components/Input/Input";
import { setModalityId } from "../hooks/pages/MID002/useMid002Conditions";
import DetailsMenu from "../components/Popover/DetailsMenu";
import InformationDialog from "../components/Dialog/InformationDialog";
import useMic001DbFetch from "../hooks/pages/MIC001/useMic001DbFetch";
import useMic001DbActions from "../hooks/pages/MIC001/useMic001DbActions";

const MIC001 = () => {
  const [conditions, setConditions] = useMic001Conditions();
  const [schedules, setSchedules] = useStateCustomObj<Mic001TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { fetchResult, fetchError } = useMic001DbFetch({
    sortExamples,
    submittedSearchCriteria,
  });
  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();
  const { updateNoExamination, updateNoExaminationError } =
    useMic001DbActions();
  const [isNoExaminationConfirmOpen, setIsNoExaminationConfirmOpen] =
    React.useState(false);
  const [noExaminationModalityId, setNoExaminationModalityId] =
    React.useState("");

  useCheckErrorThrowError([mainTabError, fetchError, updateNoExaminationError]);

  useEffect(() => {
    const convertResult = convertMic001Table(fetchResult);
    setSchedules(convertResult);
  }, [fetchResult, setSchedules]);

  // ID
  const handleChangeUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, userId: value }));
  };

  // お客様名
  const handleChangePatientName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, patientName: value }));
  };

  // 電話番号
  const handleChangeTel = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, tel: value }));
  };

  // 予約日変更
  const handleChangeDate = React.useCallback((date: Date) => {
    setSearchCriteria((prevState) => ({ ...prevState, fixBookDate: date }));
  }, []);

  // ステータスチェックボックス
  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 今日ボタン押下
  const handleSetFixBookDate = () => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      fixBookDate: new Date(),
    }));
  };

  // 絞り込みボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.MIC001);

  // 詳細ボタン押下
  const navigate = useNavigateWithUrl();
  const navigateToDetail = (id: string, modalityId: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
    });
    setId(id);
    setModalityId(modalityId);
    navigate(HOSPITAL.MID002);
  };

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  const handleChangeCheckItemContent = (
    checked: boolean,
    checkedItem: string,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      content: {
        ...searchCriteria.content,
        [checkedItem]: checked,
      },
    }));
  };

  // 受検なしダイアログopen
  const handleNoExaminationConfirm = (modalityId: string) => {
    setNoExaminationModalityId(modalityId);
    setIsNoExaminationConfirmOpen(true);
  };

  // 受検なしダイアログclose
  const handleCloseNoExaminationConfirm = () => {
    setIsNoExaminationConfirmOpen(false);
  };

  // 受検なしに設定
  const handleNoExamination = () => {
    updateNoExamination(noExaminationModalityId);
    setIsNoExaminationConfirmOpen(false);
  };

  // 受検なし非活性制御
  const isDisabledNoExamination = (
    statusNum: number,
    fixBookDateStart: Date | undefined,
  ) => {
    if (!fixBookDateStart) {
      return true;
    }
    const isDateTodayOrPast =
      new Date(fixBookDateStart).setHours(0, 0, 0, 0) <=
      new Date().setHours(0, 0, 0, 0);

    if (
      statusNum === HospitalReservationStatusID.CONFIRM &&
      isDateTodayOrPast
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={1}
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <LayoutBox align="center" justify="around" gap="3x" fullWidth>
                  <FormSet label="ID" labelWidth="96px" base flex="1">
                    <Input
                      value={searchCriteria.userId}
                      placeholder="ID"
                      width="100%"
                      onChange={handleChangeUserId}
                    />
                  </FormSet>
                  <FormSet label="お客様名" labelWidth="96px" base flex="1">
                    <Input
                      value={searchCriteria.patientName}
                      placeholder="お客様名"
                      width="100%"
                      onChange={handleChangePatientName}
                    />
                  </FormSet>
                  <FormSet label="電話番号" labelWidth="96px" base flex="1">
                    <Input
                      value={searchCriteria.tel}
                      placeholder="電話番号"
                      width="100%"
                      onChange={handleChangeTel}
                    />
                  </FormSet>
                </LayoutBox>

                <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                  <LayoutBox gap="1x">
                    <FormSet label="予約日" labelWidth="96px" base>
                      <div>
                        <DatePicker
                          width="187px"
                          selectedDate={searchCriteria.fixBookDate}
                          onChangeDate={handleChangeDate}
                        />
                      </div>
                    </FormSet>
                    <Button type="secondary" onClick={handleSetFixBookDate}>
                      今日
                    </Button>
                  </LayoutBox>
                  <FormSet
                    label="内容"
                    labelWidth="96px"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" justify="start" gap="2x">
                      <Checkbox
                        label="MRI検査"
                        checked={searchCriteria.content.MRI}
                        onChecked={(checked) =>
                          handleChangeCheckItemContent(checked, "MRI")
                        }
                      />
                      <Checkbox
                        label="FDG PET検査"
                        checked={searchCriteria.content.PET}
                        onChecked={(checked) =>
                          handleChangeCheckItemContent(checked, "PET")
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>

                <LayoutBox align="center" justify="start" gap="3x" fullWidth>
                  <FormSet
                    label="ステータス"
                    labelWidth="96px"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" justify="start" gap="2x">
                      <Checkbox
                        label="新規予約"
                        checked={searchCriteria.status.isNew}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isNew")
                        }
                      />
                      <Checkbox
                        label="確定"
                        checked={searchCriteria.status.isConfirm}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isConfirm")
                        }
                      />
                      <Checkbox
                        label="検査済"
                        checked={searchCriteria.status.isInspected}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isInspected")
                        }
                      />
                      <Checkbox
                        label="全候補日NG"
                        checked={searchCriteria.status.isNg}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isNg")
                        }
                      />
                      <Checkbox
                        label="取り下げ申請"
                        checked={
                          searchCriteria.status.isApplicationForWithdrawal
                        }
                        onChecked={(checked) =>
                          handleChangeCheckItem(
                            checked,
                            "isApplicationForWithdrawal",
                          )
                        }
                      />
                      <Checkbox
                        label="予約取り下げ"
                        checked={searchCriteria.status.isWithdrawal}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isWithdrawal")
                        }
                      />
                      <Checkbox
                        label="管理者取消"
                        checked={searchCriteria.status.isAdminCancel}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isAdminCancel")
                        }
                      />
                      <Checkbox
                        label="受検なし"
                        checked={searchCriteria.status.isNoExamination}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isNoExamination")
                        }
                      />
                      <Checkbox
                        label="会員キャンセル"
                        checked={searchCriteria.status.isUserCancel}
                        onChecked={(checked) =>
                          handleChangeCheckItem(checked, "isUserCancel")
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <Table
                width="100%"
                head={
                  <TableRow>
                    <TableColumn
                      width="125px"
                      id="col-1"
                      canSort
                      sortedDirection={sortExamples[0].sortDirection}
                      onClickSort={onClickSort}
                    >
                      ID
                    </TableColumn>
                    <TableColumn id="col-2">お客様名</TableColumn>
                    <TableColumn width="77px" id="col-3" />
                    <TableColumn
                      width="125px"
                      id="col-4"
                      canSort
                      sortedDirection={sortExamples[1].sortDirection}
                      onClickSort={onClickSort}
                    >
                      内容
                    </TableColumn>
                    <TableColumn
                      width="144px"
                      id="col-5"
                      canSort
                      sortedDirection={sortExamples[2].sortDirection}
                      onClickSort={onClickSort}
                    >
                      ステータス
                    </TableColumn>
                    <TableColumn
                      width="251px"
                      id="col-6"
                      canSort
                      sortedDirection={sortExamples[3].sortDirection}
                      onClickSort={onClickSort}
                    >
                      予約日
                    </TableColumn>
                    <TableColumn width="147px" id="col-7">
                      電話番号
                    </TableColumn>
                    <TableColumn id="col-8">メールアドレス</TableColumn>
                    <TableColumn width="48px" maxWidth="48px" id="col-9" />
                  </TableRow>
                }
                body={schedules.map((schedule) => (
                  <TableRow key={schedule.id}>
                    <TableCell>{schedule.userId}</TableCell>
                    <TableCell>{schedule.patientName}</TableCell>
                    <TableCell textAlign="center">
                      <Button
                        type="sub"
                        size="small"
                        onClick={() =>
                          navigateToDetail(schedule.patientId, schedule.id)
                        }
                      >
                        詳細
                      </Button>
                    </TableCell>
                    <TableCell>{schedule.modalityStr}</TableCell>
                    <TableCell>
                      <Tag
                        label={schedule.statusStr}
                        showIcon={false}
                        state={getHospitalStatusTagState(schedule.statusNum)}
                        emphasis={getHospitalStatusTagEmphasis(
                          schedule.statusNum,
                        )}
                      />
                    </TableCell>
                    <TableCell>{schedule.fixBookDateRange}</TableCell>
                    <TableCell>{schedule.patientTel}</TableCell>
                    <TableCell>{schedule.patientEmail}</TableCell>
                    <TableCell textAlign="center">
                      <DetailsMenu
                        items={[
                          {
                            label: "受検なし",
                            value: "0",
                            icon: "close",
                            danger: true,
                            disabled: isDisabledNoExamination(
                              schedule.statusNum,
                              schedule.fixBookDateStart,
                            ),
                          },
                        ]}
                        width={133}
                        onClick={() => {
                          handleNoExaminationConfirm(schedule.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              />
              <div className="util-full-width">
                <LayoutBox justify="end">
                  <Pagination input={false} />
                </LayoutBox>
              </div>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isNoExaminationConfirmOpen && (
        <InformationDialog
          alertLevel="error"
          open={isNoExaminationConfirmOpen}
          closeDialog={handleCloseNoExaminationConfirm}
          title="受検なしに設定してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button
                color="danger"
                size="medium"
                onClick={handleNoExamination}
              >
                受検なし
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={handleCloseNoExaminationConfirm}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          この操作は取り消せません。
        </InformationDialog>
      )}
    </>
  );
};

export default MIC001;
