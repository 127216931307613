import { useState } from "react";
import { format } from "date-fns";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeNlc017CancelDialog,
  closeNlc017ModifyDialog,
  closeNlc017SendDialog,
} from "./useNlc017Dialog";
import useNlc017Conditions from "./useNlc017Conditions";
import { parseDateString } from "../../../utils/utils";

const useNlc017DBActions = () => {
  const appContext = useFirebaseApp();

  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [actionError, setActionError] = useState<Error | null>(null);
  const [{ selectPetId, updateDate, updateTime, interval }] =
    useNlc017Conditions();

  const sendResult = () => {
    void (async () => {
      try {
        closeNlc017SendDialog();
        openUpdateActionLoading();

        // ユーザ報告API
        await appContext.functions(["booking/userReport", selectPetId]);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約取消
  const cancelReservation = () => {
    void (async () => {
      try {
        closeNlc017CancelDialog();
        openUpdateActionLoading();

        // 予約取消API
        await appContext.functions(["booking/cancelBooking", [selectPetId]]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約変更
  const modifyReservation = () => {
    void (async () => {
      try {
        closeNlc017ModifyDialog();
        openUpdateActionLoading();

        const fixDateStartStr = `${format(updateDate, "yyyy/MM/dd")} ${format(
          updateTime,
          "HH:mm",
        )}`;
        const fixStartDate = parseDateString(fixDateStartStr);

        const fixEndDate = fixStartDate
          ? new Date(
              fixStartDate.getFullYear(),
              fixStartDate.getMonth(),
              fixStartDate.getDate(),
              fixStartDate.getHours() + Math.floor(interval / 60),
              fixStartDate.getMinutes() + (interval % 60),
              fixStartDate.getSeconds(),
            )
          : null;

        // 予約取消API
        await appContext.functions(["booking/saveBooking", [
          selectPetId,
          fixStartDate,
          fixEndDate,
          "",
        ]]);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, sendResult, cancelReservation, modifyReservation };
};

export default useNlc017DBActions;
