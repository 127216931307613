import React from "react";
import Icon from "../Icon/Icon";
import Badge from "../Badge/Badge";

export type TabProps = {
  size?: "medium" | "large";
  counter?: number;
  icon?: string;
  iconColor?: string;
  disabled?: boolean;
  error?: boolean;
  isSelected: boolean;
  selectTab: (index: number) => void;
  children: React.ReactNode;
  index: number;
  tabClassName?: string;
};

const Tab = ({
  size = "large",
  counter = undefined,
  icon = undefined,
  iconColor = undefined,
  disabled = false,
  error = false,
  isSelected,
  selectTab,
  children,
  index,
  tabClassName = "",
}: TabProps) => {
  const tabClass = React.useMemo(() => {
    const disabledClass = disabled ? [`tab--disabled`] : "";
    const selectedClass = isSelected ? [`tab--active`] : "";
    const errorClass = error ? [`tab--error`] : "";

    return ["tab", ...disabledClass, ...selectedClass, ...errorClass].join(" ");
  }, [disabled, error, isSelected]);

  const tabLabelClass = React.useMemo(() => {
    const sizeClass = [`tab__label--${size}`];

    return ["tab__label", ...sizeClass].join(" ");
  }, [size]);

  const activateTab = () => {
    selectTab(index);
  };

  const showBadge = React.useMemo(() => +(counter ?? 0) > 0, [counter]);

  return (
    <div className={tabClass} onClick={activateTab}>
      <div className="tab__label-wrapper">
        <div className={`${tabLabelClass} ${tabClassName}`}>
          {error && (
            <span className="tab__error-icon">
              <Icon icon="error" size="xs" />
            </span>
          )}
          {icon !== undefined && (
            <span className="tab__icon">
              <Icon icon={icon} size="xs" color={iconColor} />
            </span>
          )}

          {children}
          {showBadge && (
            <Badge
              counter={counter}
              color={disabled ? "disabled" : "primary"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Tab;
