import { useEffect, useState, useTransition } from "react";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { getAggregateEventHistories } from "../../utils/query";
import {
  Collection,
  EventHistoryStatusID,
  ROLE_KEY,
} from "../../constants/common";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import useForceUpdate from "./useForceUpdate";
import { checkMasterRole } from "./useMasterRoleDbActions";
import generateUniqueId from "../../utils/generateUniqueId";
import {
  openCreateActionLoading,
  closeActionLoading,
  openUpdateActionLoading,
  openDeleteActionLoading,
} from "../base/useLoadingAction";

type FetchParams = {
  eventSort: string;
  searchCriteria: string;
};

// event_histories
const useEventHistoriesDbActions = ({
  eventSort,
  searchCriteria,
}: FetchParams) => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  const [eventHistories, setEventHistories] = useState<
    EventHistoriesStateType[]
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [, startTransition] = useTransition();

  const [id] = useId();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        if (!id) redirectToInvalidAccessPage();
        const results = await appContext.functions([
          "mongo/client",
          {
            collection: Collection.EVENT_HISTORIES,
            aggregate: getAggregateEventHistories(
              id,
              eventSort,
              searchCriteria,
            ),
          },
        ]) as EventHistoriesStateType[];

        if (!isCancelled) {
          setEventHistories(results);
        }
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    startTransition(() => {
      void fetchData();
    });

    return () => {
      isCancelled = true;
    };
  }, [currentUser, id, forceUpdate.forceUpdateCount, eventSort, searchCriteria, appContext]);

  // 応対履歴登録・更新
  const handleUpsert = (
    inputObj: {
      eventId?: string;
      priority?: string;
      description: string;
    },
    handleClear: () => void,
  ) => {
    void (async () => {
      if (inputObj.eventId) {
        // 更新
        openUpdateActionLoading();
      } else {
        // 登録
        openCreateActionLoading();
      }
      try {
        // 更新可能か権限チェック
        await checkMasterRole(
          ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
          appContext,
        );

        if (inputObj.eventId) {
          // 更新データ
          const updateData = {
            priority: Number(inputObj.priority) || 0,
            description: inputObj.description,
            registered_user_id: appContext.userObject?._id as string,
            updated_at: new Date(),
          };

          await appContext.functions(["mongo/client", { collection: Collection.EVENT_HISTORIES,
            updateOne: {
              filter: { _id: inputObj.eventId },
              update: { $set: updateData },
            }
          }]);
        } else {
          const uniqueId = generateUniqueId();

          // 登録データ
          const insertData = {
            _id: uniqueId,
            target_patient_id: id,
            registered_date: new Date(),
            priority: Number(inputObj.priority) || 0,
            description: inputObj.description,
            registered_user_id: appContext.userObject?._id as string,
            status: EventHistoryStatusID.VALID,
            created_at: new Date(),
            updated_at: new Date(),
          };

          await appContext.functions(["mongo/client", {
            collection: Collection.EVENT_HISTORIES,
            insertOne: { doc: insertData }
          }]);
        }

        handleClear();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 応対履歴削除
  const handleDelete = (
    inputObj: {
      eventId: string;
      priority: string;
      description: string;
    },
    handleClear: () => void,
    closeOpenDeleteConfirm: () => void,
  ) => {
    void (async () => {
      openDeleteActionLoading();
      closeOpenDeleteConfirm();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(
          ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
          appContext,
        );

        // 論理削除
        await appContext.functions(["mongo/client", {
          collection: Collection.EVENT_HISTORIES, updateOne: {
            filter: { _id: inputObj.eventId },
            update: { $set: { status: EventHistoryStatusID.DELETED } },
          }
        }]);

        handleClear();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, eventHistories, handleUpsert, handleDelete };
};

export default useEventHistoriesDbActions;
