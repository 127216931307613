import { useEffect, useState } from "react";
import { Collection } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateNli005 } from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import { setTotal } from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";

const useNli005DbActions = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const [error, setError] = useState<Error | null>(null);
  const [fetchResult, setFetchResult] = useStateCustomObj<Nli005Type>({
    _id: "",
    user_id: "",
    full_name: "",
  });
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const id = appContext.userObject?._id as string;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 検索条件
        const searchConditions = {
          _id: id,
        };

        const aggregate = getAggregateNli005(searchConditions);

        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as Nli005Type[];

        setFetchResult(aggregateResult[0]);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setFetchResult, id, appContext]);

  const handleUpdateUser = (_id: string, userName: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新データ
        const updateData = {
          full_name: userName,
        };

        await appContext.functions(["mongo/client", { collection: Collection.USERS, updateOne: { filter: { _id }, update: { $set: updateData } } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    fetchError,
    actionError: error,
    handleUpdateUser,
  };
};

export default useNli005DbActions;
