import React from "react";

export type HeadingProps = {
  tag?: "h1" | "h2" | "h3" | "h4";
  children: React.ReactNode;
  className?: string;
};

const Heading = ({ tag = "h1", children, className = "" }: HeadingProps) => {
  const heading = React.useMemo(() => {
    if (tag === "h2") return <h2 className={className}>{children}</h2>;
    if (tag === "h3") return <h3 className={className}>{children}</h3>;
    if (tag === "h4") return <h4 className={className}>{children}</h4>;

    return <h1 className={className}>{children}</h1>;
  }, [children, tag, className]);

  return <>{heading}</>;
};

export default Heading;
