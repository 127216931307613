import { useState } from "react";

import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc021NewDialog } from "./useNlc021NewDialog";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";

const useNlc021NoExamination = () => {
  const appContext = useFirebaseApp();
  const { functions } = appContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 受験なしの処理
  const noExamination = (id: string, isNoExam: boolean | undefined) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 面談取消API
        await functions(["interview/delete", [
          id,
          true,
          false,
          isNoExam,
        ]]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc021NewDialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    noExamination,
  };
};

export default useNlc021NoExamination;
