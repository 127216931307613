import { useEffect, useState } from "react";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { getAggregateHospitalTimeInterval } from "../utils/query";
import { Collection } from "../constants/common";
import { addLoadCount, decrementLoadCount } from "./base/useLoadingPage";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../contexts/CustomErrorBoundary";

// 病院の枠時間を取得
const useHospitalTimeIntervalFetch = () => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;

  // 病院ID
  const hospitalId: string = currentUser
    ? (appContext.userObject?.hospital_id as string)
    : "";

  const [hospitalInfo, setHospitalInfo] =
    useState<HospitalTimeIntervalType | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const conditions = { _id: hospitalId };
        const aggregate = getAggregateHospitalTimeInterval(conditions);
        const results = (await appContext.functions(["mongo/client", { collection: Collection.HOSPITALS, aggregate }])) as HospitalTimeIntervalType[];

        if (results.length < 1) redirectToNoDataPage();
        setHospitalInfo(results[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, currentUser, hospitalId]);

  return { error, hospitalInfo };
};

export default useHospitalTimeIntervalFetch;
