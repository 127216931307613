import { useState, useEffect } from "react";

import { getAggregateNli003 } from "../../../utils/query";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  ROLE_KEY,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import {
  closeActionLoading,
  openDeleteActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import {
  checkMasterRole,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

type Nli003FetchParams = {
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLI003;
};

const useNli003DbActions = ({ submittedSearchCriteria }: Nli003FetchParams) => {
  const appContext = useFirebaseApp();
  const { currentUser } = appContext;
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;
  const [fetchResult, setFetchResult] = useStateCustomObj<Nli003StateType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const { search } = submittedSearchCriteria;

  const colName = "created_at";
  const sortDirection = "desc";

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_USER_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const aggregate = getAggregateNli003(
          search,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await appContext.functions(["mongo/client", { collection: Collection.USERS, aggregate }])) as AggregateResultType<Nli003StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    if (hasBrowsingRole) void fetchData();
  }, [currentUser, skip, perPage, colName, sortDirection, search, setFetchResult, forceUpdate.forceUpdateCount, hasBrowsingRole, appContext]);

  // ユーザ一括削除
  const handleUseUserBulkDelete = (users: Nli003TableType[]) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, appContext);

        const idArray = users.map((user) => user.id);
        // ファンクション
        await Promise.all(
          idArray.map((id) =>
            appContext.functions(["auth/deleteUser",id]),
          ),
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // ユーザ削除
  const handleUseUserDelete = (id: string, email: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, appContext);

        // ファンクション
        await appContext.functions(["auth/deleteUser", [
          id,
          email,
        ]]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    fetchError,
    handleUseUserBulkDelete,
    handleUseUserDelete,
    actionError,
  };
};

export default useNli003DbActions;
