import { MriStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  status: MriStatusID;
  selectMriId: string;
  updateDate: Date;
  updateTime: Date;
  interval: number;
};

// 結果、ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  status: MriStatusID.ALL,
  selectMriId: "",
  updateDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  updateTime: new Date(),
  interval: 0,
});
const useNlc014Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export const setSelectMriId = (selectMriId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectMriId,
  });
};

export const setUpdateDate = (updateDate: Date) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    updateDate,
  });
};

export const setUpdateTime = (updateTime: Date) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    updateTime,
  });
};

export const setInterval = (interval: number) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    interval,
  });
};

export default useNlc014Conditions;
