import { useState } from "react";

import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { Collection } from "../../../constants/common";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useOrganizationInput, {
  setCampaignError,
} from "../../input/useOrganizationInput";
import generateUniqueId from "../../../utils/generateUniqueId";
import { closeNli020Dialog } from "./useNli020Dialog";

const useNli020DbActions = () => {
  const appContext = useFirebaseApp();
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [{ organizationName, tel, address, campaignCouponId }] =
    useOrganizationInput();

  // 団体登録
  const handleRegisterOrganization = () => {
    void (async () => {
      openCreateActionLoading();
      try {
        // キャンペーンクーポン重複チェック
        if (campaignCouponId) {
          const organizationData = (await appContext.functions(["mongo/client", {
            collection: Collection.ORGANIZATIONS, findOne: {
              filter: {
                campaign_coupon_id: campaignCouponId,
              }
            }
          }])) as OrganizationType;
          if (organizationData) {
            setCampaignError(
              "選択したキャンペーンは既に使用されています。キャンセルを押下し、再度お試しください。",
            );

            return;
          }
        }

        setCampaignError("");

        // 登録データ
        const insertData = {
          _id: generateUniqueId(),
          organization_name: organizationName.value,
          tel: tel.value,
          address: address.value,
          campaign_coupon_id: campaignCouponId,
          created_at: new Date(),
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.ORGANIZATIONS, insertOne: { doc: insertData } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNli020Dialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 団体更新
  const handleUpdateOrganization = (_id: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // キャンペーンクーポン重複チェック
        const organizationData = (await appContext.functions(["mongo/client", {
          collection: Collection.ORGANIZATIONS, findOne: {
            filter: {
              campaign_coupon_id: campaignCouponId,
              _id: { $ne: _id },
            }
          }
        }])) as OrganizationType;
        if (organizationData) {
          setCampaignError(
            "選択したキャンペーンは既に使用されています。キャンセルを押下し、再度お試しください。",
          );

          return;
        }
        setCampaignError("");

        // 更新データ
        const updateData = {
          organization_name: organizationName.value,
          tel: tel.value,
          address: address.value,
          campaign_coupon_id: campaignCouponId,
          updated_at: new Date(),
        };

        await appContext.functions(["mongo/client", { collection: Collection.ORGANIZATIONS, updateOne: { filter: { _id }, update: { $set: updateData } } }]);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNli020Dialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterOrganization,
    handleUpdateOrganization,
  };
};

export default useNli020DbActions;
