import { useState, useEffect } from "react";

import {
  getAggregateNotSetCampaignCoupons,
} from "../../utils/query";
import { useFirebaseApp } from "../../contexts/FirebaseApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";

// 未設定のキャンペーンを取得
const useNotSetCampaignCouponsFetch = () => {
  const appContext = useFirebaseApp();

  const [fetchResult, setFetchResult] = useState<NotSetCampaignCouponType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 未設定のキャンペーンクーポンを取得
        const aggregate = getAggregateNotSetCampaignCoupons();
        const result = (await appContext.functions(["mongo/client", { collection: Collection.CAMPAIGN_COUPONS, aggregate }])) as NotSetCampaignCouponType[];

        setFetchResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [appContext, forceUpdate.forceUpdateCount]);

  return { fetchError, fetchResult };
};

export default useNotSetCampaignCouponsFetch;
