import React from "react";

export type BadgeProps = {
  counter?: number;
  size?: "small" | "medium" | "large";
  color?: "primary" | "red" | "warning" | "disabled" | "white";
  label?: string;
};

const Badge = ({
  counter = 0,
  size = "medium",
  color = "primary",
  label = "",
}: BadgeProps) => {
  const badgeClass = React.useMemo(() => {
    const colorClass = [`badge--${color}`];
    const sizeClass = [`badge--${size}`];

    return ["badge", ...colorClass, ...sizeClass].join(" ");
  }, [color, size]);

  return (
    <span className={badgeClass}>
      {label === "" ? (counter > 99 ? "99+" : counter) : label}
    </span>
  );
};

export default Badge;
