import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { applyActionCode, confirmPasswordReset, getAuth } from "firebase/auth";

import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Header from "../components/Header/Header";
import Link from "../components/Link/Link";
import { ADMIN, COMMON, HEADER_LINK } from "../constants/pagePaths";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Icon from "../components/Icon/Icon";
import usePasswordInput, {
  checkPasswordInputError,
  setPassword,
  setPasswordError,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import Logo from "../components/Logo/Logo";
import { useFirebaseApp } from "../contexts/FirebaseApp";

/* メールリンクからの画面 */
const NLJ003 = () => {
  const { currentUser, logOut } = useFirebaseApp();
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get("oobCode") || "";
  const mode = queryParams.get("mode") || "";
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [{ password, rePassword }] = usePasswordInput();
  const [isChangeEmailFail, _setIsChangeEmailFail] = useState(false);
  const loaded = useRef(false);

  useEffect(() => {
    // ユーザ新規登録
    const confirmUser = async () => {
      try {
        addLoadCount();
        await applyActionCode(getAuth(), oobCode);
       if (mode === "verifyEmail") {
         setIsConfirmed(true);
       } else if (mode === "verifyAndChangeEmail") {
         setIsChanged(true);
       }
      } catch (error) {
        // メール再送画面に遷移
        if (mode === "verifyEmail") {
          navigate(COMMON.NLJ004, { state: { isFailed: true } });
        } else if (mode === "verifyAndChangeEmail") {
          navigate(COMMON.NLJ004, {
            state: { isFailed: true, isChangeEmailFailed: true },
          });
        }
      } finally {
        decrementLoadCount();
      }
    };

    if (
      (mode === "verifyEmail" && !isConfirmed && !loaded.current) ||
      (mode === "verifyAndChangeEmail" && !isChanged && !loaded.current)
    ) {
      if (!loaded.current) loaded.current = true
      void confirmUser();
    } else if (mode === "resetPassword") {
      // パスワードリセット
      setIsResetPassword(true);
    }
  }, [isChanged, isConfirmed, mode, navigate, oobCode]);

  // ログイン画面に遷移
  const handleNavigateLogin = () => {
    if (currentUser) logOut().finally(() => { navigate(ADMIN.NLA001) });
    else navigate(ADMIN.NLA001);
  };

  // パスワード送信
  const handleSendPassword = async () => {
    // パスワードバリデーション
    if (password.value !== rePassword.value) {
      setPasswordError();

      return;
    }
    try {
      addLoadCount();
      await confirmPasswordReset(getAuth(), oobCode, password.value);
      // 変更完了
      navigate(COMMON.NLJ008);
    } catch (error) {
      // メール再送画面に遷移
      navigate(COMMON.NLJ004, { state: { isFailed: true } });
    } finally {
      decrementLoadCount();
    }
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <Header
            leftControl={
              <Link
                url={HEADER_LINK}
                external={false}
                linkClassName="header__left-logo"
              >
                <Logo size="small" type="white-horizontal" />
              </Link>
            }
          />
        </div>
        {(isConfirmed || isChanged) && (
          <>
            <div className="util-mb-48" />
            <LayoutBox fullHeight fullWidth justify="center">
              <Sheet
                type="border"
                maxWidth="640px"
                minHeight="247px"
                className="util-flex--align-center util-flex--justify-center"
              >
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                  fullWidth
                >
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    gap="1x"
                    fullWidth
                  >
                    <Text size="xl" bold>
                      メールアドレスを認証しました。
                    </Text>
                    {isConfirmed && <Text size="base">
                      ログインしてサービスを利用できます。
                    </Text>}
                  </LayoutBox>
                  <Button
                    size="large"
                    iconPosition="left"
                    width="230px"
                    onClick={handleNavigateLogin}
                  >
                    ログイン画面へ
                  </Button>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </>
        )}
        {isResetPassword && (
          <>
            <div className="util-mb-48" />
            <LayoutBox fullHeight fullWidth justify="center">
              <Sheet
                type="border"
                maxWidth="500px"
                minHeight="247px"
                className="util-flex--align-center util-flex--justify-center"
              >
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                  fullWidth
                >
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    gap="1x"
                    fullWidth
                  >
                    <Text size="xl" bold>
                      パスワードを再設定します。
                    </Text>
                    <Text size="base">
                      パスワードを入力後、パスワード再設定ボタンを押下してください。
                    </Text>

                    <FormSet
                      label="新しいパスワード"
                      vertical
                      errorTextList={[password.validationMessage]}
                    >
                      <Input
                        size="default"
                        width="100%"
                        password
                        rightButton="password"
                        autoComplete="new-password"
                        value={password.value}
                        onChange={(value) => setPassword(value)}
                        error={password.isError}
                      />
                    </FormSet>
                    <FormSet
                      label="新しいパスワード（確認用）"
                      vertical
                      errorTextList={[rePassword.validationMessage]}
                    >
                      <Input
                        size="default"
                        width="100%"
                        password
                        rightButton="password"
                        autoComplete="new-password"
                        value={rePassword.value}
                        onChange={(value) => setRePassword(value)}
                        error={rePassword.isError}
                      />
                    </FormSet>
                  </LayoutBox>
                  <Button
                    size="large"
                    iconPosition="left"
                    width="230px"
                    disabled={checkPasswordInputError()}
                    onClick={() => {
                      void handleSendPassword();
                    }}
                  >
                    パスワード再設定
                  </Button>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </>
        )}
        {isChangeEmailFail && (
          <>
            <div className="util-mb-48" />
            <LayoutBox fullHeight fullWidth justify="center">
              <Sheet
                type="border"
                maxWidth="640px"
                minHeight="247px"
                className="util-flex--align-center util-flex--justify-center"
              >
                <LayoutBox
                  direction="column"
                  justify="center"
                  align="center"
                  gap="3x"
                  fullWidth
                >
                  <LayoutBox
                    direction="column"
                    justify="center"
                    align="center"
                    gap="1x"
                    fullWidth
                  >
                    <Icon icon="error" size="large" color="#A21B11" />
                    <Text size="xl" bold>
                      メールアドレス変更に失敗しました。
                    </Text>
                    <Text size="base">
                      ログインした状態で再度URLを押下してください。
                    </Text>
                  </LayoutBox>
                  <Button
                    size="large"
                    iconPosition="left"
                    width="230px"
                    onClick={handleNavigateLogin}
                  >
                    ログイン画面へ
                  </Button>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </>
        )}
      </div>
    </>
  );
};

export default NLJ003;
