import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { ADMIN, COMMON, HOSPITAL } from "../constants/pagePaths";
import { useFirebaseApp } from "../contexts/FirebaseApp";
import { UserStatusID, UserTypeID } from "../constants/common";

// ログイン必須画面
const RequiredLoginElement: React.FC<{
  element: JSX.Element;
  path: string;
}> = ({ element, path }) => {
//   const navigate = useNavigate();
  const appContext = useFirebaseApp();
  const UserAccessPaths = {
    [UserTypeID.ADMIN.toString()]: Object.values(ADMIN),
    [UserTypeID.HOSPITAL.toString()]: Object.values(HOSPITAL),
  };
  const [showLogin, setShowLogin] = useState(false);


  useEffect(() => {
    if (!appContext.loading && !appContext.currentUser) {
        setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  }, [appContext.currentUser, appContext.loading]);

  if (showLogin) return <Navigate to={ADMIN.NLA001} />;
  if (appContext.loading) return <div>appContext.loading is true</div>; // Waiting for recovering session if exist


  // ステータスチェック
  const statusObject = appContext.userObject?.status as usersNumberIntType;
  const statusString = (statusObject || statusObject === 0) ? (statusObject.$numberInt ?? String(appContext.userObject?.status ?? "")) : null;
  const status = statusString ? Number(statusString) : null;

  if (
    status === UserStatusID.SUSPENDED ||
    status === UserStatusID.UNREGISTERED
  ) {
    // ステータスエラー
    return <Navigate to={COMMON.NLJ007} />;
  }

  // ユーザーチェック
  const userTypeObject = appContext.userObject?.user_type as usersNumberIntType;
  const userTypeString = userTypeObject ? (userTypeObject.$numberInt ?? String(appContext.userObject?.user_type ?? "")) : "";

  // ユーザタイプが存在しない場合、ログイン画面
  if (userTypeString === "") {
    return <Navigate to={ADMIN.NLA001} />;
  }

  // 管理者、病院それぞれの画面に遷移しようとしてない場合、エラーページ
  const isInvalid = !(UserAccessPaths[userTypeString] as string[])?.includes(
    path,
  );
  if (isInvalid) {
    return <Navigate to={COMMON.NOT_FOUND} />;
  }

  return element;
};

export default RequiredLoginElement;
