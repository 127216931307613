import { useEffect, useMemo, useState } from "react";
import { Collection } from "../../../constants/common";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { getAggregateOneSku } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import useNli010Dialog from "./useNli010Dialog";
import useProductMgmtId from "../../common/useProductMgmtId";
import useProductMgmtDbActions from "../../common/useProductMgmtDbActions";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

// NLI010画面のデータ操作処理
const useNli010DbActions = () => {
  const appContext = useFirebaseApp();
  const [{ skuId }] = useProductMgmtId();
  const [isOpen] = useNli010Dialog();
  const [forceUpdate] = useForceUpdate();
  const [fetchResult, setFetchResult] = useState<SkusTableType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  // 商品種別、SKUのDB操作
  const { actionError, insertSku, updateSku } = useProductMgmtDbActions();

  // 取得、編集対象
  const filter = useMemo(() => ({ _id: skuId }), [skuId]);

  // 編集時の取得処理
  useEffect(() => {
    const fetchData = async () => {
      try {
        const aggregate = getAggregateOneSku(filter);
        // mongoDBのデータ
        const result = (await appContext.functions(["mongo/client", { collection: Collection.SKUS, aggregate }])) as SkusTableType[];

        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    // skuIdがあり、編集ダイアログを開いている時のみデータ取得
    if (skuId && isOpen) void fetchData();
  }, [filter, forceUpdate, setFetchResult, skuId, isOpen, appContext]);

  return { fetchResult, fetchError, actionError, insertSku, updateSku };
};

export default useNli010DbActions;
