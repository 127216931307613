import { useState } from "react";
import { useFirebaseApp } from "../../../contexts/FirebaseApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc030SendDialog } from "./useNlc030Dialog";
import useNlc030Conditions from "./useNlc030Conditions";

// 頭の健康チェックテスト結果送信
const useNlc030DbActions = () => {
  const appContext = useFirebaseApp();

  const [{ selectBrainCheckId }] = useNlc030Conditions();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [actionError, setActionError] = useState<Error | null>(null);

  const sendResult = () => {
    void (async () => {
      try {
        closeNlc030SendDialog();
        openUpdateActionLoading();
        // ユーザー報告API
        await appContext.functions(["braincheck/userReport",
          selectBrainCheckId,
        ]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, sendResult };
};

export default useNlc030DbActions;
